import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card, CardBody, FormCheckbox } from 'shards-react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { warning } from 'react-toastify-redux';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  getDeliverySlot,
  createDeliverySlots,
  updateDeliverySlot,
  deleteDeliverySlot,
} from '../../store/actions/deliverySlots';
import { MainStage } from '../../components/layout';
import {
  FormCard,
  FormGroup,
  IconButton,
  MaterialIcon,
  DialogButton,
} from '../../components/common';
import { hasAccess } from '../../utils/auth';

const SlotContainer = styled.div`
  border: 1px solid;
  border-radius: 6px;
  padding: 7px;
  display: block;
  width: 190px;

  .material-icons {
    cursor: pointer;
  }
`;

const DeliverySlotForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [dateFrom, setDateFrom] = useState(moment().toDate());
  const [dateTo, setDateTo] = useState(moment().add(1, 'month').toDate());
  const [timeFrom, setTimeFrom] = useState(moment({ hours: 9 }).toDate());
  const [timeTo, setTimeTo] = useState(moment({ hour: 21 }).toDate());
  const [interval, setInterval] = useState(3);
  const [maxPerSlot, setMaxPerSlot] = useState(5);
  const [deliverySlotState, setDeliverySlotState] = useState(null);
  const [applyAll, setApplyAll] = useState(false);
  const isLoading = useSelector(({ ui }) => ui.isLoading);
  const deliverySlotData = useSelector(
    ({ deliverySlots: { deliverySlot } }) => deliverySlot
  );
  const { control, errors, handleSubmit, setValue } = useForm();
  const canUpdate = hasAccess('deliverySlots', 'update');
  const canDelete = hasAccess('deliverySlots', 'delete');
  const canCreate = hasAccess('deliverySlots', 'create');

  useEffect(() => {
    if (id) dispatch(getDeliverySlot(id));
    else {
      setDeliverySlotState({
        id: '',
        items: [],
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (deliverySlotData && id && deliverySlotData.id === id) {
      deliverySlotData.date = moment
        .unix(deliverySlotData.date.seconds)
        .toDate();
      setDeliverySlotState({
        id: deliverySlotData.id,
        items: [deliverySlotData],
      });
      setValue('maxPerSlot', deliverySlotData.maxPerSlot);
    }
  }, [id, deliverySlotData]);

  const onSubmit = async (data) => {
    const timeFromMoment = moment(timeFrom);
    const timeToMoment = moment(timeTo);
    const times = [timeFromMoment.format('HH:mm')];
    while (timeFromMoment.add(interval, 'hours').diff(timeToMoment) <= 0) {
      times.push(timeFromMoment.format('HH:mm'));
    }

    const timesRange = [];
    for (let i = 0; i < times.length; i++) {
      if (i + 1 < times.length)
        timesRange.push(
          `${moment(times[i], 'hh:mm').format('hh:mm A')} - ${moment(
            times[i + 1],
            'hh:mm'
          ).format('hh:mm A')}`
        );
    }

    // Create
    if (!id) {
      const currDate = moment(dateFrom).startOf('day');
      const lastDate = moment(dateTo).startOf('day');
      const dates = [currDate.toDate()];

      while (currDate.add(1, 'days').diff(lastDate) <= 0) {
        dates.push(currDate.clone().toDate());
      }

      const items = [];
      dates.forEach((date) => {
        const item = {
          maxPerSlot: data.maxPerSlot,
          date,
          slots: [],
        };
        timesRange.forEach((time) => {
          item.slots.push({ displayInStore: true, time, orders: [] });
        });
        items.push(item);
      });
      setDeliverySlotState({
        items,
      });
    } else {
      const item = deliverySlotState.items[0];

      timesRange.forEach((time) => {
        if (!item.slots.find((slot) => slot.time === time))
          item.slots.push({ displayInStore: true, time, orders: [] });
      });
      setDeliverySlotState({
        items: [item],
      });
    }
  };

  const handleRemoveSlot = (index, slotIndex) => {
    if (applyAll) {
      deliverySlotState.items = deliverySlotState.items.map((item) => {
        item.slots.splice(slotIndex, 1);
        return item;
      });
    } else {
      deliverySlotState.items[index].slots.splice(slotIndex, 1);
    }

    setDeliverySlotState({ items: deliverySlotState.items });
  };

  const handleDisplyInStore = (index, slotIndex) => {
    const newValue =
      !deliverySlotState.items[index].slots[slotIndex].displayInStore;

    if (applyAll) {
      deliverySlotState.items = deliverySlotState.items.map((item) => {
        item.slots[slotIndex].displayInStore = newValue;
        return item;
      });
    } else {
      deliverySlotState.items[index].slots[slotIndex].displayInStore = newValue;
    }
    setDeliverySlotState({ items: deliverySlotState.items });
  };

  const handleRemoveDate = (index) => {
    deliverySlotState.items.splice(index, 1);
    setDeliverySlotState({ items: deliverySlotState.items });
  };

  const handleOnChangeApplyAll = () => {
    setApplyAll(!applyAll);
  };

  const handleSave = () => {
    if (!id && !canCreate) {
      dispatch(warning('You do not have permission to create delivery slot'));
      return;
    }
    if (id && !canUpdate) {
      dispatch(warning('You do not have permission to update delivery slot'));
      return;
    }
    if (!deliverySlotState.items.length) return;

    if (!id) dispatch(createDeliverySlots(deliverySlotState.items));
    else dispatch(updateDeliverySlot(id, deliverySlotState.items[0]));
  };

  const handleDelete = () => {
    if (id && !canDelete) {
      dispatch(warning('You do not have permission to update delivery slot'));
      return;
    }
    dispatch(deleteDeliverySlot(id));
  };

  const handleChangeMaxOrderPerSlot = (value) => {
    if (id) {
      deliverySlotState.items[0].maxPerSlot = parseInt(value);
      setDeliverySlotState({ items: deliverySlotState.items });
    } else {
      setMaxPerSlot(value);
    }
  };

  return (
    <MainStage
      title={!id ? 'New' : 'Update'}
      subtitle="Delivery Slot"
      to="/delivery-slots"
    >
      <FormCard form title="Enter details" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {!id && (
            <>
              <FormGroup
                col={{ lg: '2' }}
                type="datepicker"
                name="dateFrom"
                label="Date From"
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
                defaultValue={dateFrom}
                dateProps={{ maxDate: dateTo }}
                onChange={(value) => setDateFrom(value)}
              />
              <FormGroup
                col={{ lg: '2' }}
                type="datepicker"
                name="dateTo"
                label="Date To"
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
                defaultValue={dateTo}
                dateProps={{ minDate: dateFrom }}
                onChange={(value) => setDateTo(value)}
              />
            </>
          )}

          <FormGroup
            col={{ lg: '2' }}
            type="datepicker"
            name="timeFrom"
            label="Time From"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
            defaultValue={timeFrom}
            onChange={(value) => setTimeFrom(value)}
            dateProps={{
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeIntervals: 60,
              timeCaption: 'Time',
              dateFormat: 'h:mm aa',
            }}
          />
          <FormGroup
            col={{ lg: '2' }}
            type="datepicker"
            name="timeTo"
            label="Time To"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
            defaultValue={timeTo}
            onChange={(value) => setTimeTo(value)}
            dateProps={{
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeIntervals: 60,
              timeCaption: 'Time',
              dateFormat: 'h:mm aa',
            }}
          />

          <FormGroup
            col={{ lg: '2' }}
            type="number"
            min={1}
            name="interval"
            label="Interval (hours)"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
            defaultValue={interval}
            onChange={(e) => setInterval(e.target.value)}
          />
          <FormGroup
            col={{ lg: '2' }}
            type="number"
            min={1}
            name="maxPerSlot"
            label="Max Order Per Slot"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
            defaultValue={maxPerSlot}
            onChange={(e) => handleChangeMaxOrderPerSlot(e.target.value)}
          />
        </Row>
        <div className="d-flex">
          <Button type="submit" theme="success">
            Generate
          </Button>
          {deliverySlotState && deliverySlotState.items.length > 0 && (
            <Button
              type="button"
              className="ml-2"
              theme="primary"
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </div>
      </FormCard>

      {deliverySlotState &&
        (id ? deliverySlotData && deliverySlotData.id === id : true) && (
          <Row>
            <Col md="12">
              {deliverySlotState.items.length > 0 && (
                <Card>
                  <CardBody>
                    {!id && (
                      <>
                        <FormCheckbox
                          className="m-0"
                          onChange={() => handleOnChangeApplyAll()}
                          checked={applyAll}
                        >
                          Apply all - all the same time range slots will be
                          saved by the edited time slot
                        </FormCheckbox>
                        <hr className="mb-4" />
                      </>
                    )}
                    {deliverySlotState.items.map((item, index) => {
                      const dateMoment = moment(item.date);
                      return (
                        <div key={index}>
                          <Row>
                            <Col lg="12">
                              <h5>
                                {!id && (
                                  <span>
                                    <IconButton
                                      className="mr-2 d-inline py-1 px-2"
                                      onClick={() => handleRemoveDate(index)}
                                      theme="danger"
                                      disabled={isLoading}
                                      icon="delete_outline"
                                      type="button"
                                    />
                                  </span>
                                )}
                                <span>
                                  {dateMoment.format('D MMM YYYY')} -{' '}
                                  {dateMoment.format('dddd')}
                                </span>
                              </h5>
                            </Col>
                            {item.slots.map((slot, slotIndex) => {
                              return (
                                <div
                                  key={slotIndex}
                                  className="d-flex mb-3 mx-2"
                                >
                                  <div>
                                    <SlotContainer>
                                      <div>
                                        <div className="d-flex">
                                          <span>{slot.time}</span>
                                          {!slot.orders.length && (
                                            <MaterialIcon
                                              className="ml-auto"
                                              icon="cancel"
                                              onClick={() => {
                                                handleRemoveSlot(
                                                  index,
                                                  slotIndex
                                                );
                                              }}
                                            />
                                          )}
                                        </div>
                                        {slot.orders.length > 0 && (
                                          <ul className="pl-4 m-0 mt-1">
                                            {slot.orders.map((o, oIndex) => {
                                              return (
                                                <li key={oIndex}>
                                                  <Link
                                                    data-tip
                                                    data-for={`li_${slot.time}_${oIndex}`}
                                                    to={`/orders/edit/${o.orderId}`}
                                                  >
                                                    {o.refNum}
                                                  </Link>
                                                  <ReactTooltip
                                                    id={`li_${slot.time}_${oIndex}`}
                                                    place="top"
                                                    effect="solid"
                                                  >
                                                    {o.products.map(
                                                      (p, pIndex) => {
                                                        return (
                                                          <div key={pIndex}>
                                                            <span className="mr-2">
                                                              Qty: {p.qty}
                                                            </span>
                                                            {p.name}--
                                                            {p.variant}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </ReactTooltip>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        )}
                                      </div>
                                    </SlotContainer>
                                    <div className="mt-2">
                                      <FormCheckbox
                                        className="m-0"
                                        onChange={() =>
                                          handleDisplyInStore(index, slotIndex)
                                        }
                                        checked={
                                          slot.displayInStore === undefined
                                            ? true
                                            : slot.displayInStore
                                        }
                                      >
                                        Display in store
                                      </FormCheckbox>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </Row>

                          <hr />
                        </div>
                      );
                    })}

                    {id && (
                      <div className="d-flex">
                        <Button
                          type="button"
                          theme="primary"
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                        <DialogButton
                          mainButtonText="Delete"
                          className="ml-2"
                          theme="danger"
                          icon="delete_outline"
                          onConfirm={handleDelete}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        )}
    </MainStage>
  );
};

export default DeliverySlotForm;
