import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  Badge,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Button,
  FormCheckbox,
  FormInput,
  InputGroup,
  InputGroupAddon,
  Tooltip,
} from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { success, error, warning } from 'react-toastify-redux';
import {
  getOrder,
  getLastOrder,
  getPaymentLink,
  getOrderLog,
  createOrderLog,
  deleteOrderLog,
  createOrder,
  updateOrder,
  updateOrderStatus,
  sendInvoice,
  sendDeliveryScheduleLink,
  sendPaymentLink,
  downloadInvoice,
  downloadInvoiceVersion,
  downloadDO,
  sendDO,
  OrderActionTypes,
} from '../../store/actions/orders';
import {
  createPaymentIntent,
  cnacelPayment,
} from '../../store/actions/payments';
import { getOrderByPaymentToken } from '../../store/actions/misc';
import { getOrderReport } from '../../store/actions/orderReports';
import { UIActionTypes } from '../../store/actions/ui';
import { getProducts } from '../../store/actions/products';
import { applyPromo } from '../../store/actions/promotions';
import { getUsers } from '../../store/actions/users';
import { getAvailableDeliverySlots } from '../../store/actions/deliverySlots';
import { MainStage } from '../../components/layout';
import {
  FormCard,
  FormGroup,
  FormInputGroup,
  DialogButton,
  IconButton,
  OrderLog,
  MaterialIcon,
  StripePaymentInfo,
  AtomePaymentInfo,
  HitPayPaymentInfo,
  PageLoader,
} from '../../components/common';
import ProductModal from './ProductModal';
import CustomProductModal from './CustomProductModal';
import PromoModal from './PromoModal';
import BundleModal from './BundleModal';
import ScheduleModal from './ScheduleModal';
import PreviewModal from './PreviewModal';
import PaymentModal from './PaymentModal';
import { calculateTax } from '../../utils';
import { hasAccess, getAdmin } from '../../utils/auth';
import {
  DeliveryOptions,
  OrderStatus,
  PromosColor,
  ShippingCountries,
  PromoTypes,
  PromoBundleGetTypes,
  StatusColors,
  ProductStatusColor,
  ProductStatus,
  PaymentTypes,
  PaymentStatus,
  PaymentStatusColors,
} from '../../constants';

const _ = require('lodash');

const ProductTable = styled.table`
  th {
    border-top: none;
  }
  td {
    vertical-align: middle;
    img {
      max-width: 100px;
      margin-right: 10px;
    }
  }
`;
const SummaryTable = styled.table`
  min-width: 40%;
  float: right;
  td {
    font-weight: 500;
    padding: 3px;
  }
`;

const CommonList = styled.ul`
  list-style: none;
  padding: 0;
  li {
    display: flex;
    padding: 7px 0;
    button {
      margin-left: auto;
    }
  }
`;

const ActivityList = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  li {
    display: flex;
    padding: 0;
  }
`;

const ProductTd = styled.td`
  display: flex;
  align-items: center;
`;

const ProductForm = () => {
  const { control, handleSubmit, errors, getValues, setValue } = useForm();
  const { id } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(({ ui }) => ui.isLoading);
  const pageLoading = useSelector(({ ui }) => ui.pageLoading);
  const productsData = useSelector(({ products: { products } }) => products);
  const orderData = useSelector(({ orders: { order } }) => order);
  const paymentLinkData = useSelector(
    ({ orders: { paymentLink } }) => paymentLink
  );
  const lastOrderData = useSelector(({ orders: { lastOrder } }) => lastOrder);
  const { items } = useSelector(({ users: { users } }) => users);
  const deliverySlotsData = useSelector(
    ({ deliverySlots: { availableDeliverySlots } }) => availableDeliverySlots
  );
  const orderReportData = useSelector(
    ({ orderReports: { orderReport } }) => orderReport
  );
  const orderLogData = useSelector(({ orders: { orderLog } }) => orderLog);
  const paymentTokenData = useSelector(
    ({ misc: { paymentToken } }) => paymentToken
  );

  const paymentLinkRef = useRef(null);
  const [orderState, setOrderState] = useState(null);
  const [orderProducts, setOrderProducts] = useState([]);
  const [orderPromos, setOrderPromos] = useState([]);
  const [deliverySchedules, setDeliverySchedules] = useState([]);
  const [deliveryScheduleItems, setDeliveryScheduleItems] = useState([]);
  const [itemView, setItemView] = useState(true);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [payments, setPayments] = useState([]);
  const [orderReport, setOrderReport] = useState(null);
  const [orderLog, setOrderLog] = useState(null);
  const [orderComment, setOrderComment] = useState('');
  const [availableDeliverySlots, setAvailableDeliverySlots] = useState([]);
  const [orderActions, setOrderActions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openCustomProductModal, setOpenCustomProductModal] = useState(false);
  const [openPromoModal, setOpenPromoModal] = useState(false);
  const [openBundleModal, setOpenBundleModal] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [bundlePromotion, setBundlePromotion] = useState(null);
  const [showGuestEmail, setShowGuestEmail] = useState(false);
  const [copyAddress, setCopyAddress] = useState(false);
  const [tooltipClipboard, setTooltipClipboard] = useState(false);
  const [paymentModalItemIndex, setPaymentModalItemIndex] = useState(-1);
  const canCreate = hasAccess('orders', 'create');
  const canUpdate = hasAccess('orders', 'update');
  const currentAdmin = getAdmin();

  const orderStatusOptions = [];
  Object.keys(OrderStatus).forEach((key) => {
    const status = OrderStatus[key];
    if (status !== OrderStatus.CANCELLED && status !== OrderStatus.REFUNDED)
      orderStatusOptions.push({ value: status, label: status });
  });

  const shippingCountriesOptions = Object.keys(ShippingCountries).map((key) => {
    return {
      value: ShippingCountries[key],
      label: ShippingCountries[key],
    };
  });

  useEffect(() => {
    if (id) {
      dispatch(getOrder(id));
      dispatch(getOrderLog(id));
      dispatch(getOrderReport(id));
    } else {
      const newOrder = {
        deliveryOption: DeliveryOptions.ALL,
        shippingFee: 0,
        referral: {},
        billing: {},
        shipping: {},
      };
      setOrderState(newOrder);
    }
    dispatch(getProducts());
    // clear payment link in reducer
    dispatch({
      type: OrderActionTypes.GET_PAYMENT_LINK,
      paymentLink: '',
    });
  }, [id]);

  useEffect(() => {
    if (deliverySlotsData.items.length)
      setAvailableDeliverySlots(deliverySlotsData.items);
    else dispatch(getAvailableDeliverySlots());
  }, [deliverySlotsData]);

  useEffect(() => {
    if (orderReportData && id && orderReportData.id === id) {
      setOrderReport(orderReportData);
    }
  }, [orderReportData]);

  useEffect(() => {
    if (paymentTokenData && id && paymentTokenData.id === id) {
      dispatch(
        createPaymentIntent(paymentTokenData.token, PaymentTypes.HITPAY)
      );
    }
  }, [paymentTokenData]);

  useEffect(() => {
    if (copyAddress) {
      const data = getValues();
      setValue('shipping.address', data['billing.address']);
      setValue('shipping.company', data['billing.company']);
      setValue('shipping.country', data['billing.country']);
      setValue('shipping.postalCode', data['billing.postalCode']);
      setValue('shipping.email', data['billing.email']);
      setValue('shipping.firstName', data['billing.firstName']);
      setValue('shipping.lastName', data['billing.lastName']);
      setValue('shipping.contactNo', data['billing.contactNo']);
    }
  }, [copyAddress]);

  useEffect(() => {
    if (orderLogData) setOrderLog(orderLogData);
  }, [orderLogData]);

  useEffect(() => {
    const options = [{ value: 'Guest', label: 'Guest' }];
    if (items.length) {
      const [users] = items;
      users.forEach((i) => {
        options.push({ value: i.id, label: `${i.firstName} ${i.lastName}` });
      });
    }
    setCustomerOptions(options);
  }, [items]);

  useEffect(() => {
    if (orderData && id && orderData.id === id) {
      const paymentStatus = new URLSearchParams(search).get('status');
      const paymentId = new URLSearchParams(search).get('reference');
      if (paymentStatus && paymentId) {
        switch (paymentStatus) {
          case 'canceled':
          case 'cancelled':
            const paymentIndex = orderData.payments
              ? orderData.payments.findIndex(
                  (p) =>
                    p[p.type].id === paymentId &&
                    p.status === PaymentStatus.PENDING
                )
              : null;
            if (paymentIndex !== -1) {
              dispatch(
                cnacelPayment(
                  id,
                  paymentId,
                  orderData.payments[paymentIndex].type
                )
              );
              orderData.payments.splice(paymentIndex, 1);
            }
            break;
          case 'completed':
            dispatch(success('Payment has been successfully made'));
            break;
          case 'failed':
            dispatch(error('Payment failed, please try again'));
            break;
          default:
            break;
        }
        history.push(`/orders/edit/${id}`);
      }

      orderData.orderedOn = moment.unix(orderData.orderedOn.seconds).toDate();
      setOrderState(orderData);

      const actions = [];
      if (
        orderData.status === OrderStatus.PENDING_PAYMENT ||
        orderData.status === OrderStatus.PROCESSING ||
        orderData.status === OrderStatus.COMPLETED
      ) {
        actions.push(
          { text: 'Send schedule link', showDialog: true },
          { text: 'Send updated invoice', showDialog: true },
          { text: 'Print invoice', showDialog: true }
        );
      }

      if (orderData.status === OrderStatus.PENDING_PAYMENT) {
        dispatch(getPaymentLink(id));
        actions.push({ text: 'Send payment link', showDialog: true });
        // actions.push({ text: 'HitPay checkout', showDialog: true });
      }

      if (
        orderData.status !== OrderStatus.REFUNDED &&
        orderData.status !== OrderStatus.CANCELLED
      ) {
        if (orderData.status !== OrderStatus.PENDING_PAYMENT)
          actions.push({ text: 'Refund', showDialog: true });
        actions.push({ text: 'Cancel order', showDialog: true });
      }

      setOrderActions(actions);

      if (orderData.products) {
        orderData.products.forEach((p) => {
          if (typeof p.shippable === 'undefined') p.shippable = true;
          if (p.statusDate)
            p.statusDate = moment.unix(p.statusDate.seconds).toDate();
        });
        setOrderProducts(orderData.products);
      }
      if (orderData.promos) setOrderPromos(orderData.promos);
      if (orderData.schedules) {
        orderData.schedules.map((s) => {
          s.date = moment.unix(s.date.seconds).toDate();
          s.printable = true;
          if (!s.uuid) setItemView(false);
          return s;
        });
        setDeliverySchedules(orderData.schedules);
      }
      if (orderData.payments) {
        orderData.payments.map((p) => {
          if (p.paidOn) p.paidOn = moment.unix(p.paidOn.seconds).toDate();
          return p;
        });
        setPayments(orderData.payments);
      }
    }
    // eslint-disable-next-line
  }, [orderData]);

  useEffect(() => {
    const scheduledProducts = [];
    deliverySchedules.forEach((s) => {
      s.products.forEach((p) => {
        let qtyCount = p.qty;
        let allocatedQtyCount = p.allocatedQty;
        while (qtyCount > 0) {
          scheduledProducts.push({
            date: s.date,
            time: s.time,
            status: s.status,
            allocated: allocatedQtyCount > 0,
            ...p,
          });
          qtyCount -= 1;
          allocatedQtyCount -= 1;
        }
      });
    });

    const scheduleItems = [];
    orderProducts
      .filter((p) => p.shippable)
      .forEach((p) => {
        let qtyCount = p.qty;
        while (qtyCount > 0) {
          const spIndex = scheduledProducts.findIndex((s) => s.uuid === p.uuid);
          if (spIndex !== -1) {
            const sp = scheduledProducts[spIndex];
            if (sp) scheduledProducts.splice(spIndex, 1);
            scheduleItems.push({
              ...sp,
            });
          } else {
            scheduleItems.push({
              name: p.name,
              variant: p.variant,
            });
          }
          qtyCount -= 1;
        }
      });
    setDeliveryScheduleItems(_.orderBy(scheduleItems, 'date'));
  }, [deliverySchedules, orderProducts]);

  useEffect(() => {
    if (lastOrderData !== null) {
      const { billing, shipping } = lastOrderData;
      setValue('billing.firstName', billing.firstName);
      setValue('billing.lastName', billing.lastName);
      setValue('billing.email', billing.email);
      setValue('billing.contactNo', billing.contactNo);
      setValue('billing.company', billing.company);
      setValue('billing.address', billing.address);
      setValue('billing.country', billing.country);
      setValue('billing.postalCode', billing.postalCode);

      setValue('shipping.firstName', shipping.firstName);
      setValue('shipping.lastName', shipping.lastName);
      setValue('shipping.email', shipping.email);
      setValue('shipping.contactNo', shipping.contactNo);
      setValue('shipping.company', shipping.company);
      setValue('shipping.address', shipping.address);
      setValue('shipping.country', shipping.country);
      setValue('shipping.postalCode', shipping.postalCode);
    }
  }, [lastOrderData]);

  const computeOrder = () => {
    const data = getValues();
    // order object
    const order = {
      orderedOn: data.orderedOn,
      userId: id ? orderState.userId : data.customer.value,
      shippingFee: parseFloat(data.shippingFee),
      shipping: {
        country: data['shipping.country'],
      },
      products: _.cloneDeep(orderProducts),
    };
    // calculate order price
    const totalPrice = order.products.reduce(
      (total, p) => total + (p.subtotal || 0),
      0
    );
    order.totalPrice = totalPrice;
    return order;
  };

  const onSubmit = async (data) => {
    if (id && !canUpdate) {
      dispatch(warning('You do not have permission to update order'));
      return;
    }
    if (!id && !canCreate) {
      dispatch(warning('You do not have permission to create order'));
      return;
    }

    if (!orderProducts.length) {
      dispatch(warning('Please add at least one product'));
      return;
    }

    // order object
    const order = {
      ...orderState,
      remarks: data.remarks,
      status: data.status,
      orderedOn: data.orderedOn,
      shippingFee: parseFloat(data.shippingFee),
      billing: {
        ...data.billing,
      },
      shipping: {
        ...data.shipping,
      },
      payments: _.cloneDeep(payments),
      products: _.cloneDeep(orderProducts),
      schedules: _.cloneDeep(deliverySchedules),
      currency: orderProducts[0].currency,
    };

    delete order.id;
    delete order.user;

    if (!id) {
      order.userId = data.customer.value;
      order.guestEmail = data.guestEmail;
      order.guestFirstName = data.guestFirstName;
      order.guestLastName = data.guestLastName;
      order.referral = data.referral;
      const promoCodes = orderPromos.map((op) => {
        return op.code;
      });
      dispatch(createOrder(order, promoCodes));
    } else {
      order.promos = orderPromos;
      order.userId = orderState.userId;
      dispatch(updateOrder(id, order));
    }
  };

  const handleOrderAction = (index, action) => {
    switch (action.text) {
      case 'Cancel order':
        dispatch(updateOrderStatus(id, OrderStatus.CANCELLED));
        break;
      case 'Refund':
        dispatch(updateOrderStatus(id, OrderStatus.REFUNDED));
        break;
      case 'Send updated invoice':
        dispatch(sendInvoice(id, true));
        break;
      case 'Send schedule link':
        dispatch(sendDeliveryScheduleLink(id));
        break;
      case 'Print invoice':
        dispatch(downloadInvoice(id));
        break;
      case 'Send payment link':
        dispatch(sendPaymentLink(id, true));
        break;
      case 'HitPay checkout':
        dispatch(getOrderByPaymentToken(id));
        break;
      default:
    }
  };

  const handleApplyOrderPromo = async (code) => {
    const order = computeOrder();
    dispatch({ type: UIActionTypes.START_LOADING });
    const promoCodes = orderPromos.map((op) => {
      return op.code;
    });
    const res = await applyPromo(code, order, promoCodes);
    dispatch({ type: UIActionTypes.STOP_LOADING });
    const { promotion, message } = res;
    if (promotion) {
      if (orderPromos.findIndex((p) => p.code === code) !== -1) {
        dispatch(warning('Promo code already applied in this order'));
        return;
      }
      if (!promotion.usageLimits.canStack && orderPromos.length) {
        dispatch(warning('This code cannot be stacked'));
        return;
      }

      setOrderPromos((prev) => [
        ...prev,
        {
          ...promotion,
        },
      ]);
      setOpenPromoModal(false);
      if (
        promotion.type === PromoTypes.BUNDLE &&
        promotion.bundle.gets &&
        promotion.bundle.gets.find(
          (g) =>
            g.type === PromoBundleGetTypes.COLLECTIONS ||
            g.type === PromoBundleGetTypes.PRODUCTS
        )
      ) {
        setOpenBundleModal(true);
        setBundlePromotion(promotion);
      }
    } else dispatch(warning(message));
  };
  const handleRemovePromo = (promoCode, index) => {
    setOrderProducts(
      orderProducts.filter((value) => value.promoCode !== promoCode)
    );
    setOrderPromos(orderPromos.filter((_value, i) => i !== index));
  };

  const handleConfirmBundle = (redeemProducts) => {
    if (!redeemProducts) return;
    redeemProducts.forEach((product) => {
      setOrderProducts((prev) => [
        ...prev,
        {
          ...product,
        },
      ]);
    });
    setOpenBundleModal(false);
  };

  const handleAddProduct = async (product) => {
    setOrderProducts((prev) => [
      ...prev,
      {
        ...product,
      },
    ]);
    setOpenProductModal(false);
  };

  const handleRemoveProduct = (index) => {
    const removeProduct = orderProducts[index];
    for (const schedule of deliverySchedules) {
      if (schedule.products.find((p) => p.uuid === removeProduct.uuid)) {
        dispatch(
          warning(
            `Please remove delivery schedule before you can remove the product`
          )
        );
        return;
      }
    }
    setOrderProducts(orderProducts.filter((value, i) => i !== index));
  };

  const handleAddCustomProduct = async (product) => {
    setOrderProducts((prev) => [
      ...prev,
      {
        ...product,
      },
    ]);
    setOpenCustomProductModal(false);
  };

  const handleInputChange = _.debounce((value) => {
    if (value && value.length >= 3) {
      dispatch(getUsers({ filter: value }));
    }
  }, 1000);

  const handleSelectCustomer = (option) => {
    if (option.value === 'Guest') setShowGuestEmail(true);
    else {
      setShowGuestEmail(false);
      dispatch(getLastOrder(option.value));
    }

    setOrderPromos([]);
  };

  const handleChangeProductQty = (e, index) => {
    const { value } = e.target;
    if (!value) return;

    const newData = [...orderProducts];
    newData[index].qty = Number(value);
    newData[index].subtotal = newData[index].qty * newData[index].unitPrice;

    let totalScheduledQty = 0;
    deliverySchedules.forEach((s) => {
      totalScheduledQty += _.sumBy(
        s.products.filter((sp) => sp.uuid === newData[index].uuid),
        'qty'
      );
    });
    if (newData[index].qty < totalScheduledQty) {
      dispatch(warning('Warning, order qty less than delivery qty'));
    }

    setOrderProducts(newData);
  };

  const handleChangeProductPrice = (e, index) => {
    const { value } = e.target;
    if (!value) return;
    const newData = [...orderProducts];
    newData[index].unitPrice = Number(parseFloat(value).toFixed(2));
    newData[index].subtotal = newData[index].qty * newData[index].unitPrice;
    setOrderProducts(newData);
  };
  const handleChangeComparePrice = (e, index) => {
    const { value } = e.target;
    if (!value) return;
    const newData = [...orderProducts];
    newData[index].comparePrice = Number(parseFloat(value).toFixed(2));
    setOrderProducts(newData);
  };

  const handleAddSchedule = (value) => {
    setOpenScheduleModal(false);
    const availableDelivery = availableDeliverySlots.find(
      (s) => s.id === value.id
    );
    const slot = availableDelivery.slots.find((s) => s.time === value.time);
    slot.count += 1;
    const newAvailableDeliverySlots = [...availableDeliverySlots];
    setAvailableDeliverySlots(newAvailableDeliverySlots);
    deliverySchedules.push(value);
    setDeliverySchedules([...deliverySchedules]);
  };

  const handleUpdateSchedule = (value) => {
    setOpenScheduleModal(false);
    deliverySchedules.map((d) => {
      if (d.uuid === value.uuid) d = value;
      return d;
    });
    setDeliverySchedules([...deliverySchedules]);
  };

  const handleRemoveSchedule = (index, delivery) => {
    setDeliverySchedules(deliverySchedules.filter((_value, i) => i !== index));
    const availableDelivery = availableDeliverySlots.find(
      (s) => s.id === delivery.id
    );
    if (availableDelivery) {
      const slot = availableDelivery.slots.find(
        (s) => s.time === delivery.time
      );
      if (slot) slot.count -= 1;
      setAvailableDeliverySlots([...availableDeliverySlots]);
    }
    setOpenScheduleModal(false);
  };

  const handleAddPayment = (value) => {
    setOpenPaymentModal(false);
    setPayments((prev) => [
      ...prev,
      {
        ...value,
      },
    ]);
  };
  const handleRemovePayment = (index) => {
    setPayments(payments.filter((_value, i) => i !== index));
    setOpenPaymentModal(false);
  };
  const handleUpdatePayment = (value, index) => {
    setOpenPaymentModal(false);
    const newPayments = [...payments];
    newPayments[index] = value;
    setPayments(newPayments);
  };

  const handlePrintDO = (delivery) => {
    dispatch(downloadDO(id, delivery.createdUnix));
  };

  const handleSendDO = (delivery) => {
    dispatch(sendDO(id, delivery.createdUnix));
  };

  const handleDownloadInvoiceVersion = (version) => {
    dispatch(downloadInvoiceVersion(id, version));
  };

  const handleCreateOrderComment = () => {
    if (!orderComment) return;
    const log = {
      uuid: uuidv4(),
      type: 'comment',
      comment: orderComment,
      createdOn: moment().toDate(),
      name: currentAdmin.name,
    };
    dispatch(createOrderLog(id, log));
    setOrderComment('');
  };

  const handleDeleteOrderLog = (logId, uuid) => {
    dispatch(deleteOrderLog(logId, uuid));
  };

  const handleCopyPaymentLink = () => {
    navigator.clipboard.writeText(paymentLinkData);
  };

  const renderPaymentInfo = (payment) => {
    switch (payment.type) {
      case PaymentTypes.ATOME:
        return <AtomePaymentInfo payment={payment} />;
      case PaymentTypes.HITPAY:
        return <HitPayPaymentInfo payment={payment} />;
      default:
        return <StripePaymentInfo payment={payment} />;
    }
  };

  const renderTaxInfo = () => {
    const tax = orderData.tax || calculateTax(orderData.finalPrice);
    return (
      <tr>
        <td>
          <small>GST ({(tax.rate * 100).toFixed(0)}%) Inclusive:</small>
        </td>
        <td>
          <small>SGD {tax.amount.toFixed(2)}</small>
        </td>
      </tr>
    );
  };

  return (
    orderState && (
      <MainStage
        title={!id ? 'New' : 'Update'}
        subtitle="Products"
        to="/orders"
      >
        <PageLoader loading={pageLoading} />
        <Row form>
          <Col md="12">
            <FormCard
              title={`Order details${id ? ` #${orderState.refNum}` : ''}`}
              actions={orderActions}
              onAction={handleOrderAction}
            >
              <Row>
                <Col md="4">
                  <h5>General</h5>
                  <Row>
                    {orderState.wpId && (
                      <Col>
                        <label>Old Reference</label>
                        <h6>#{orderState.wpId}</h6>
                      </Col>
                    )}
                    {id ? (
                      <FormGroup
                        col={{ lg: '12' }}
                        name="customer"
                        label="Customer"
                        control={control}
                        readOnly
                        defaultValue={`${orderState.user.firstName} ${orderState.user.lastName}`}
                      />
                    ) : (
                      <FormGroup
                        col={{ lg: '12' }}
                        type="searchable-select"
                        name="customer"
                        label="Customer"
                        placeholder="Type at least 3 characters"
                        onInputChange={handleInputChange}
                        onChange={handleSelectCustomer}
                        options={customerOptions}
                        control={control}
                        rules={{ required: 'Required field' }}
                        errors={errors}
                      />
                    )}

                    {showGuestEmail && (
                      <>
                        <FormGroup
                          col={{ lg: '12' }}
                          name="guestEmail"
                          label="Guest Email"
                          control={control}
                          rules={{ required: 'Required field' }}
                          errors={errors}
                        />
                        <FormGroup
                          col={{ lg: '6' }}
                          name="guestFirstName"
                          label="Guest First Name"
                          control={control}
                          rules={{ required: 'Required field' }}
                          errors={errors}
                        />
                        <FormGroup
                          col={{ lg: '6' }}
                          name="guestLastName"
                          label="Guest Last Name"
                          control={control}
                          rules={{ required: 'Required field' }}
                          errors={errors}
                        />
                      </>
                    )}

                    <FormGroup
                      col={{ lg: '12' }}
                      type="datepicker"
                      name="orderedOn"
                      label="Order Date"
                      control={control}
                      rules={{ required: 'Required field' }}
                      errors={errors}
                      readOnly={!!id}
                      defaultValue={orderState.orderedOn}
                    />

                    <FormGroup
                      col={{ lg: '12' }}
                      type="select"
                      options={orderStatusOptions}
                      name="status"
                      label="Status"
                      control={control}
                      rules={{ required: 'Required field' }}
                      errors={errors}
                      defaultValue={orderState.status}
                    />

                    <FormGroup
                      col={{ lg: '12' }}
                      name="referral.code"
                      label="Referral Code"
                      control={control}
                      readOnly={!!id}
                      defaultValue={
                        orderState.referral ? orderState.referral.code : ''
                      }
                    />

                    <FormGroup
                      type="textarea"
                      name="remarks"
                      col={{ lg: '12' }}
                      label="Remarks"
                      control={control}
                      defaultValue={orderState.remarks}
                    />

                    {paymentLinkData && (
                      <Col lg={12}>
                        <label>Payment link</label>
                        <InputGroup>
                          <FormInput
                            ref={paymentLinkRef}
                            readOnly
                            value={paymentLinkData}
                          />
                          <InputGroupAddon type="append">
                            <Button
                              id="btnClipboard"
                              theme="primary"
                              type="button"
                              onClick={() => handleCopyPaymentLink()}
                            >
                              <MaterialIcon icon="content_copy" />
                            </Button>
                            <Tooltip
                              target="#btnClipboard"
                              open={tooltipClipboard}
                              toggle={() => {
                                setTooltipClipboard(!tooltipClipboard);
                              }}
                            >
                              Copy url to clipboard
                            </Tooltip>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col md="4">
                  <h5>Billing</h5>
                  <Row>
                    <FormGroup
                      name="billing.firstName"
                      col={{ lg: '6' }}
                      label="First Name"
                      control={control}
                      defaultValue={orderState.billing.firstName}
                    />
                    <FormGroup
                      name="billing.lastName"
                      col={{ lg: '6' }}
                      label="Last Name"
                      control={control}
                      defaultValue={orderState.billing.lastName}
                    />
                    <FormGroup
                      type="email"
                      name="billing.email"
                      col={{ lg: '12' }}
                      label="Email"
                      control={control}
                      errors={errors}
                      defaultValue={orderState.billing.email}
                    />

                    <FormGroup
                      name="billing.company"
                      col={{ lg: '6' }}
                      label="Company"
                      control={control}
                      defaultValue={orderState.billing.company}
                    />
                    <FormGroup
                      name="billing.contactNo"
                      col={{ lg: '6' }}
                      label="Contact"
                      control={control}
                      defaultValue={orderState.billing.contactNo}
                    />
                    <FormGroup
                      name="billing.address"
                      col={{ lg: '12' }}
                      label="Address"
                      control={control}
                      defaultValue={orderState.billing.address}
                    />
                    <FormGroup
                      type="select"
                      options={shippingCountriesOptions}
                      name="billing.country"
                      label="Country"
                      control={control}
                      defaultValue={orderState.billing.country}
                    />
                    <FormGroup
                      name="billing.postalCode"
                      col={{ lg: '6' }}
                      label="Postal"
                      control={control}
                      defaultValue={orderState.billing.postalCode}
                    />
                  </Row>
                </Col>
                <Col md="4">
                  <h5>
                    Shipping
                    <small className="d-inline-block ml-3">
                      <FormCheckbox
                        className="m-0"
                        checked={copyAddress}
                        onChange={() => setCopyAddress(!copyAddress)}
                      >
                        same as billing address
                      </FormCheckbox>
                    </small>
                  </h5>
                  <Row>
                    <FormGroup
                      name="shipping.firstName"
                      col={{ lg: '6' }}
                      label="First Name"
                      control={control}
                      defaultValue={orderState.shipping.firstName}
                    />
                    <FormGroup
                      name="shipping.lastName"
                      col={{ lg: '6' }}
                      label="Last Name"
                      control={control}
                      defaultValue={orderState.shipping.lastName}
                    />
                    <FormGroup
                      type="email"
                      name="shipping.email"
                      col={{ lg: '12' }}
                      label="Email"
                      control={control}
                      errors={errors}
                      defaultValue={orderState.shipping.email}
                    />
                    <FormGroup
                      name="shipping.company"
                      col={{ lg: '6' }}
                      label="Company"
                      control={control}
                      defaultValue={orderState.shipping.company}
                    />
                    <FormGroup
                      name="shipping.contactNo"
                      col={{ lg: '6' }}
                      label="Contact"
                      control={control}
                      defaultValue={orderState.shipping.contactNo}
                    />
                    <FormGroup
                      name="shipping.address"
                      col={{ lg: '12' }}
                      label="Address"
                      control={control}
                      defaultValue={orderState.shipping.address}
                    />
                    <FormGroup
                      type="select"
                      options={shippingCountriesOptions}
                      name="shipping.country"
                      label="Country"
                      control={control}
                      defaultValue={orderState.shipping.country}
                    />
                    <FormGroup
                      name="shipping.postalCode"
                      col={{ lg: '6' }}
                      label="Postal"
                      control={control}
                      defaultValue={orderState.shipping.postalCode}
                    />
                    <FormGroup
                      name="shipping.notes"
                      col={{ lg: '12' }}
                      label="Notes"
                      control={control}
                      defaultValue={orderState.shipping.notes}
                    />
                    <FormGroup
                      type="number"
                      name="shippingFee"
                      col={{ lg: '6' }}
                      label="Shipping Fee (SGD)"
                      control={control}
                      defaultValue={orderState.shippingFee}
                    />
                  </Row>
                </Col>
              </Row>
            </FormCard>
          </Col>

          <Col lg="9">
            <Card className="mb-4">
              <CardHeader className="border-bottom">
                <div className="d-flex align-items-center">
                  <h6 className="m-0 mr-auto">Products</h6>
                  <div className="d-flex">
                    <Button
                      theme="secondary"
                      type="button"
                      className="btn-sm mr-2"
                      disabled={isLoading}
                      onClick={() => {
                        setOpenCustomProductModal(!openCustomProductModal);
                      }}
                    >
                      Ad-hoc
                    </Button>
                    <IconButton
                      className="p-2"
                      theme="success"
                      onClick={() => setOpenProductModal(!openProductModal)}
                      icon="add"
                    />
                  </div>
                </div>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      {orderProducts.length > 0 && (
                        <div className="table-responsive">
                          <ProductTable className="table form-table">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Variant</th>
                                <th width="80">Quantity</th>
                                <th width="100">Unit Price</th>
                                <th width="100">Compare Price</th>
                                <th width="120">Total</th>
                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderProducts.map((product, index) => {
                                return (
                                  <tr key={index}>
                                    <ProductTd className="d-flex">
                                      <img src={product.image} alt="" />
                                      <div className="p-2">
                                        <div>
                                          {product.shippable && product.status && (
                                            <small>
                                              <Badge
                                                theme={
                                                  ProductStatusColor[
                                                    product.status
                                                      .replaceAll(' ', '_')
                                                      .replaceAll('-', '_')
                                                      .toUpperCase()
                                                  ]
                                                }
                                              >
                                                {product.status}
                                              </Badge>
                                              <span
                                                className={
                                                  product.status ===
                                                  ProductStatus.PRE_ORDER
                                                    ? 'ml-2'
                                                    : 'd-none'
                                                }
                                              >
                                                {product.statusDate &&
                                                  moment(
                                                    product.statusDate
                                                  ).format('DD MMM YYYY')}
                                                {product.statusNote || ''}
                                              </span>
                                              <br />
                                            </small>
                                          )}
                                          <div className="my-1">
                                            <strong>
                                              {product.collection}
                                            </strong>
                                          </div>
                                          {product.name}
                                          {product.subVariants &&
                                            product.subVariants.length > 0 &&
                                            product.subVariants
                                              .filter((sub) => sub.value)
                                              .map((sub, i) => {
                                                return (
                                                  <div key={`${sub}_${i}`}>
                                                    <small>+ {sub.value}</small>
                                                  </div>
                                                );
                                              })}
                                        </div>
                                        {product.promos &&
                                          product.promos.map(
                                            (promo, pIndex) => {
                                              return (
                                                <Badge
                                                  key={pIndex}
                                                  theme={
                                                    PromosColor[
                                                      promo.type
                                                        .replace(' ', '_')
                                                        .toUpperCase()
                                                    ]
                                                  }
                                                >
                                                  {promo.code}
                                                </Badge>
                                              );
                                            }
                                          )}
                                      </div>
                                    </ProductTd>
                                    <td>{product.variant}</td>
                                    <td>
                                      <FormGroup
                                        name={`products[${index}].qty`}
                                        control={control}
                                        rules={{ required: 'Required field' }}
                                        errors={errors}
                                        className="p-0"
                                        type="number"
                                        min={1}
                                        readOnly={!!product.promoCode}
                                        defaultValue={product.qty}
                                        onChange={(e) => {
                                          handleChangeProductQty(e, index);
                                          return 0;
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <FormGroup
                                        name={`products[${index}].unitPrice`}
                                        control={control}
                                        rules={{ required: 'Required field' }}
                                        errors={errors}
                                        className="p-0"
                                        type="number"
                                        min={1}
                                        defaultValue={product.unitPrice}
                                        onChange={(e) => {
                                          handleChangeProductPrice(e, index);
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <FormGroup
                                        name={`products[${index}].comparePrice`}
                                        control={control}
                                        rules={{ required: 'Required field' }}
                                        errors={errors}
                                        className="p-0"
                                        type="number"
                                        min={1}
                                        defaultValue={product.comparePrice}
                                        onChange={(e) => {
                                          handleChangeComparePrice(e, index);
                                        }}
                                      />
                                    </td>
                                    <td>
                                      {product.currency}{' '}
                                      {product.subtotal.toFixed(2)}
                                    </td>
                                    <td>
                                      {!product.promoCode && (
                                        <DialogButton
                                          className="p-1"
                                          theme="danger"
                                          icon="delete_outline"
                                          message=""
                                          onConfirm={() => {
                                            handleRemoveProduct(index);
                                          }}
                                          disabled={isLoading}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </ProductTable>
                        </div>
                      )}

                      {id && (
                        <>
                          <hr />
                          <SummaryTable>
                            <tbody>
                              <tr>
                                <td width="110">Sub Total: </td>
                                <td width="110">
                                  SGD {orderData.totalPrice.toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td>Discount: </td>
                                <td className="text-danger">
                                  SGD {orderData.discount.toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td>Shipping: </td>
                                <td>
                                  SGD{' '}
                                  {orderData.shippingFee
                                    ? orderData.shippingFee.toFixed(2)
                                    : '0'}
                                </td>
                              </tr>
                              <tr>
                                <td>Grand Total: </td>
                                <td>SGD {orderData.finalPrice.toFixed(2)}</td>
                              </tr>
                              {renderTaxInfo()}
                            </tbody>
                          </SummaryTable>
                        </>
                      )}
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>

            <Card className="mb-4">
              <CardHeader className="border-bottom">
                <div className="d-flex align-items-center">
                  <h6 className="m-0 mr-auto">Payments</h6>
                  <IconButton
                    className="p-2"
                    theme="success"
                    onClick={() => {
                      setOpenPaymentModal(true);
                      setPaymentModalItemIndex(-1);
                    }}
                    icon="add"
                  />
                </div>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  {payments.length > 0 && (
                    <Row>
                      <Col>
                        <div className="table-responsive">
                          <ProductTable className="table table-striped">
                            <thead>
                              <tr>
                                <th>Payment Date</th>
                                <th>Payment Type</th>
                                <th>Status</th>
                                <th>Amount</th>
                                <th>Remarks</th>
                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>
                              {payments.map((p, index) => {
                                // For older payment, they might not have status, so we assume they are "success"
                                const paymentStatus = !p.status
                                  ? PaymentStatus.SUCCESS
                                  : p.status;

                                return (
                                  <tr key={index}>
                                    <td>
                                      {moment(p.paidOn).format(
                                        'D MMM YYYY h:mm a'
                                      )}
                                    </td>
                                    <td>{p.type}</td>
                                    <td>
                                      <Badge
                                        theme={
                                          PaymentStatusColors[
                                            _.snakeCase(
                                              paymentStatus
                                            ).toUpperCase()
                                          ]
                                        }
                                      >
                                        {_.startCase(paymentStatus)}
                                      </Badge>
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          p.amount < 0 ||
                                          [
                                            PaymentStatus.PAYMENT_ERROR,
                                          ].includes(p.status)
                                            ? 'text-danger'
                                            : 'text-success'
                                        }
                                      >
                                        {p.currency} {p.amount.toFixed(2)}
                                      </span>
                                    </td>
                                    <td>{renderPaymentInfo(p)}</td>
                                    <td className="d-flex">
                                      <IconButton
                                        className="p-1 mr-2"
                                        theme="info"
                                        icon="edit"
                                        onClick={() => {
                                          setOpenPaymentModal(true);
                                          setPaymentModalItemIndex(index);
                                        }}
                                      />
                                      <DialogButton
                                        className="p-1"
                                        theme="danger"
                                        icon="delete_outline"
                                        message=""
                                        onConfirm={() => {
                                          handleRemovePayment(index);
                                        }}
                                        disabled={isLoading}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </ProductTable>
                        </div>
                      </Col>
                    </Row>
                  )}
                </ListGroupItem>
              </ListGroup>
            </Card>

            {orderProducts.length > 0 && (
              <Card className="mb-4">
                <CardHeader className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h6 className="m-0 mr-auto">Schedules</h6>
                    <IconButton
                      className="p-2"
                      theme="success"
                      onClick={() => setOpenScheduleModal(!openScheduleModal)}
                      icon="add"
                    />
                  </div>
                </CardHeader>
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <FormCheckbox
                      toggle
                      small
                      className="mb-2"
                      checked={itemView}
                      onChange={() => setItemView(!itemView)}
                    >
                      Item View
                    </FormCheckbox>
                    <Row>
                      <Col>
                        {itemView && deliveryScheduleItems.length > 0 && (
                          <div className="table-responsive">
                            <ProductTable className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Item</th>
                                  <th>Variant</th>
                                  <th>Delivery Date</th>
                                  <th>Delivery Time</th>
                                  <th>Delivery Status</th>
                                  <th>Stock Allocated</th>
                                </tr>
                              </thead>
                              <tbody>
                                {deliveryScheduleItems.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.name}</td>
                                      <td>{item.variant}</td>
                                      <td>
                                        {item.date
                                          ? moment(item.date).format(
                                              'D MMM YYYY'
                                            )
                                          : '--'}
                                      </td>
                                      <td>{item.time ? item.time : '--'}</td>
                                      <td>
                                        {item.status ? (
                                          <Badge
                                            theme={
                                              StatusColors[
                                                item.status
                                                  .replace(' ', '_')
                                                  .toUpperCase()
                                              ]
                                            }
                                          >
                                            {item.status}
                                          </Badge>
                                        ) : (
                                          '--'
                                        )}
                                      </td>
                                      <td>
                                        <Badge
                                          theme={
                                            item.allocated
                                              ? 'success'
                                              : 'danger'
                                          }
                                        >
                                          <MaterialIcon
                                            icon={
                                              item.allocated ? 'done' : 'close'
                                            }
                                          />
                                        </Badge>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </ProductTable>
                          </div>
                        )}

                        {!itemView && deliverySchedules.length > 0 && (
                          <div className="table-responsive">
                            <ProductTable className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Delivery Items</th>
                                  <th>Delivery Date</th>
                                  <th>Delivery Time</th>
                                  <th>Delivery Status</th>
                                  <th> </th>
                                </tr>
                              </thead>
                              <tbody>
                                {deliverySchedules.map((delivery, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {delivery.products.map((p, pIndex) => {
                                          return (
                                            <div key={pIndex}>
                                              <span className="mr-3">
                                                Qty: {p.qty}
                                              </span>
                                              {p.name} -- {p.variant}
                                            </div>
                                          );
                                        })}
                                      </td>
                                      <td>
                                        {moment(delivery.date).format(
                                          'D MMM YYYY'
                                        )}
                                      </td>
                                      <td>{delivery.time}</td>
                                      <td>
                                        {delivery.status && (
                                          <Badge
                                            theme={
                                              StatusColors[
                                                delivery.status
                                                  .replace(' ', '_')
                                                  .toUpperCase()
                                              ]
                                            }
                                          >
                                            {delivery.status}
                                          </Badge>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {delivery.uuid && (
                                            <IconButton
                                              disabled={isLoading}
                                              className="mr-2 p-1"
                                              theme="primary"
                                              onClick={() => {
                                                setSelectedDelivery(delivery);
                                                setOpenScheduleModal(
                                                  !openScheduleModal
                                                );
                                              }}
                                              icon="edit"
                                            />
                                          )}
                                          {delivery.printable && (
                                            <>
                                              <IconButton
                                                disabled={isLoading}
                                                className="mr-2 p-1"
                                                theme="primary"
                                                onClick={() => {
                                                  handlePrintDO(delivery);
                                                }}
                                                icon="print"
                                              />
                                              <DialogButton
                                                disabled={isLoading}
                                                className="mr-2 p-1"
                                                theme="success"
                                                icon="mail_outline"
                                                onConfirm={() => {
                                                  handleSendDO(delivery);
                                                }}
                                              />
                                            </>
                                          )}
                                          <DialogButton
                                            className="p-1"
                                            theme="danger"
                                            icon="delete_outline"
                                            onConfirm={() => {
                                              handleRemoveSchedule(
                                                index,
                                                delivery
                                              );
                                            }}
                                            disabled={isLoading}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </ProductTable>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            )}
          </Col>
          <Col lg="3">
            <Card className="mb-4">
              <CardHeader className="border-bottom">
                <div className="d-flex align-items-center">
                  <h6 className="m-0 mr-auto">Redeems</h6>
                  <IconButton
                    className="p-2"
                    theme="success"
                    onClick={() => setOpenPromoModal(!openPromoModal)}
                    icon="add"
                  />
                </div>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <CommonList>
                        {orderPromos.map((promo, index) => {
                          return (
                            <li key={index}>
                              <Badge
                                theme={
                                  PromosColor[
                                    promo.type.replace(' ', '_').toUpperCase()
                                  ]
                                }
                              >
                                {promo.code}
                              </Badge>
                              <DialogButton
                                className="p-1"
                                theme="danger"
                                icon="delete_outline"
                                message=""
                                onConfirm={() => {
                                  handleRemovePromo(promo.code, index);
                                }}
                                disabled={isLoading}
                              />
                            </li>
                          );
                        })}
                      </CommonList>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>

            {orderReport && (
              <Card className="mb-4">
                <CardHeader className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h6 className="m-0 mr-auto">Invoice Version</h6>
                  </div>
                </CardHeader>
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <CommonList>
                          {orderReport.invoices.map((invoice, index) => {
                            return (
                              <li key={index}>
                                {moment
                                  .unix(invoice.date.seconds)
                                  .format('DD MMM YYYY, hh:mm a')}
                                <IconButton
                                  disabled={isLoading}
                                  className="mr-2 p-1"
                                  theme="primary"
                                  onClick={() => {
                                    handleDownloadInvoiceVersion(
                                      invoice.version
                                    );
                                  }}
                                  icon="print"
                                />
                              </li>
                            );
                          })}
                        </CommonList>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            )}

            {orderLog && orderLog.id === id && (
              <Card className="mb-4">
                <CardHeader className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h6 className="m-0 mr-auto">Activity Logs</h6>
                  </div>
                </CardHeader>
                <ListGroup flush>
                  <ListGroupItem className="p-0">
                    <div className="border-bottom d-block pt-2 px-2">
                      <Row>
                        <FormInputGroup
                          className="m-0"
                          col={{ lg: '12' }}
                          name="comment"
                          control={control}
                          value={orderComment}
                          placeholder="Type a comment"
                          onChange={(value) => setOrderComment(value)}
                          onClick={() => handleCreateOrderComment()}
                        />
                      </Row>
                    </div>
                    <ActivityList>
                      {orderLog.logs.map((log, index) => {
                        return (
                          <li className="border-bottom" key={index}>
                            <OrderLog
                              data={log}
                              onDelete={() => {
                                handleDeleteOrderLog(orderLog.id, log.uuid);
                              }}
                            />
                          </li>
                        );
                      })}
                    </ActivityList>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            )}
          </Col>

          <Col lg={12} className="mt-4">
            <Button
              theme="primary"
              disabled={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
            {id && (
              <Button
                theme="secondary"
                type="button"
                className="btn-sm ml-2"
                disabled={isLoading}
                onClick={() => setOpenPreviewModal(!openPreviewModal)}
              >
                Preview
              </Button>
            )}
          </Col>
        </Row>

        <ProductModal
          open={openProductModal}
          onClose={() => setOpenProductModal(false)}
          onAddProduct={handleAddProduct}
          products={productsData.items}
        />

        <CustomProductModal
          open={openCustomProductModal}
          onClose={() => setOpenCustomProductModal(false)}
          onAddProduct={handleAddCustomProduct}
        />

        <PaymentModal
          open={openPaymentModal}
          onClose={() => setOpenPaymentModal(false)}
          onAddPayment={(value) => handleAddPayment(value)}
          onUpdatePayment={handleUpdatePayment}
          payments={payments}
          editIndex={paymentModalItemIndex}
        />

        {orderProducts.length > 0 && (
          <ScheduleModal
            open={openScheduleModal}
            delivery={selectedDelivery}
            products={orderProducts}
            deliverySlots={availableDeliverySlots}
            existingSchdules={deliverySchedules}
            onClose={() => {
              setSelectedDelivery(null);
              setOpenScheduleModal(false);
            }}
            onAddSchedule={(value) => handleAddSchedule(value)}
            onUpdateSchedule={(value) => handleUpdateSchedule(value)}
          />
        )}

        <PromoModal
          open={openPromoModal}
          onClose={() => setOpenPromoModal(false)}
          onApply={handleApplyOrderPromo}
        />

        <PreviewModal
          open={openPreviewModal}
          onClose={() => setOpenPreviewModal(false)}
          order={orderState}
        />

        {bundlePromotion && (
          <BundleModal
            open={openBundleModal}
            data={bundlePromotion}
            onClose={() => setOpenBundleModal(false)}
            onConfirm={handleConfirmBundle}
          />
        )}
      </MainStage>
    )
  );
};

export default ProductForm;
