import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleMenu } from '../../../store/actions/ui';
import { MaterialIcon } from '../../common';

const NavbarToggle = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleMenu());
  };

  return (
    <nav className="nav">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        onClick={handleClick}
        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
      >
        <MaterialIcon icon="&#xE5D2;" />
      </a>
    </nav>
  );
};

export default NavbarToggle;
