import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Badge, Button } from 'shards-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Dialog } from '../../components/common';
import { StatusColors, ReferralEarnStatus } from '../../constants';
import { getReferralRebateStatus } from '../../utils/rebate';
import { updateUserReferral } from '../../store/actions/users';

const StyledBadge = styled(Badge)`
  cursor: ${(props) =>
    props.status === ReferralEarnStatus.PROCESSING ? 'pointer' : 'initial'};
`;

const CustomerReferralStatusBadge = ({ referee }) => {
  const status = getReferralRebateStatus(referee);
  const { id } = useParams();
  const { isLoading } = useSelector(({ ui }) => ui);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    event.preventDefault();
    if (status !== ReferralEarnStatus.PROCESSING) return;
    confirmAlert({
      customUI: (res) => {
        return (
          <Dialog>
            <h4>Set withdrawal as paid?</h4>
            <p>This is a non-reversable action.</p>
            <div className="d-flex justify-content-end">
              <Button
                className="mr-2"
                outline
                theme="secondary"
                type="button"
                onClick={res.onClose}
                disabled={isLoading}
              >
                No
              </Button>
              <Button
                outline
                theme="danger"
                type="button"
                disabled={isLoading}
                onClick={() => {
                  dispatch(updateUserReferral(id, referee.id, true));
                  res.onClose();
                }}
              >
                Confirm
              </Button>
            </div>
          </Dialog>
        );
      },
    });
  };

  return (
    <StyledBadge
      theme={StatusColors[status.toUpperCase()]}
      className="text-capitalize"
      status={status}
      onClick={handleClick}
    >
      {status}
    </StyledBadge>
  );
};

CustomerReferralStatusBadge.propTypes = {
  referee: PropTypes.object.isRequired,
};

export default CustomerReferralStatusBadge;
