import { error } from 'react-toastify-redux';
import _ from 'lodash';
import moment from 'moment';
import {
  ordersCollection,
  productsCollection,
  usersCollection,
  deliverySlotsCollection,
} from '../../firebase';
import { startLoading, stopLoading } from './ui';
import {
  OrderStatus,
  PeriodSelections,
  ProductAssetKey,
} from '../../constants/index';
import { isArrayObjectEqual } from '../../utils/index';

export const ReportActionTypes = {
  LAST_6_MONTHS_REVENUE: 'LAST_6_MONTHS_REVENUE',
  GET_MONTHLY_REVENUE: 'GET_MONTHLY_REVENUE',
  PRODUCT_SOLD_QTY: 'PRODUCT_SOLD_QTY',
  TOP_PRODUCT_SOLD: 'TOP_PRODUCT_SOLD',
  GET_NO_OF_ORDERS: 'GET_NO_OF_ORDERS',
  GET_NO_OF_DELIVERIES: 'GET_NO_OF_DELIVERIES',
  GET_NO_OF_CUSTOMERS: 'GET_NO_OF_CUSTOMERS',
  GET_DELIVERY_EVENTS: 'GET_DELIVERY_EVENTS',
  GET_ORDER_PROMOTIONS: 'GET_ORDER_PROMOTIONS',
  GET_IVENTORY_REPORT: 'GET_IVENTORY_REPORT',
};

const backgroundColors = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 205, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(201, 203, 207, 0.2)',
  'rgba(255, 159, 64, 0.2)',
];

const borderColors = [
  'rgba(255, 99, 132)',
  'rgba(153, 102, 255)',
  'rgba(255, 205, 86)',
  'rgba(75, 192, 192)',
  'rgba(54, 162, 235)',
  'rgba(201, 203, 207)',
  'rgba(255, 159, 64)',
];

/**
 * Retrieve last 6 months revenue
 */
export const getLast6MonthRevenue = (dateFrom, dateTo) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const dateFromMoment = moment(dateFrom);
      const dateToMoment = moment(dateTo);

      const result = {
        dateFrom: dateFromMoment.toDate(),
        dateTo: dateToMoment.toDate(),
        labels: [],
        revenues: [],
        borderColors: [],
        backgroundColors: [],
      };

      do {
        const fromMoment = dateFromMoment.startOf('month');
        const toMoment = moment(fromMoment).endOf('month');
        result.labels.push(fromMoment.format(`MMM' YY`));
        result.borderColors.push('rgba(54, 162, 235)');
        result.backgroundColors.push('rgba(54, 162, 235, 0.2)');

        const from = fromMoment.toDate();
        const to = toMoment.toDate();
        const documentSnapshots = await ordersCollection
          .where('orderedOn', '>=', from)
          .where('orderedOn', '<=', to)
          .where('status', 'in', [
            OrderStatus.COMPLETED,
            OrderStatus.PROCESSING,
          ])
          .get();
        const orders = [];
        documentSnapshots.docs.map(async (doc) => {
          const data = doc.data();
          orders.push({ id: doc.id, ...data });
        });

        const sumFinalPrice = _.sumBy(orders, 'finalPrice');
        result.revenues.push(sumFinalPrice);
      } while (dateFromMoment.add(1, 'months').diff(dateToMoment) <= 0);

      result.labels = _.reverse(result.labels);
      result.revenues = _.reverse(result.revenues);

      dispatch({
        type: ReportActionTypes.LAST_6_MONTHS_REVENUE,
        last6MonthsRevenue: result,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve monthly revenue
 */
export const getMonthlyRevenue = (date) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const dateMoment = moment(date);
      const fromMoment = dateMoment.startOf('month');
      const toMoment = moment(fromMoment).endOf('month');

      const result = {
        date: dateMoment.toDate(),
        labels: [],
        revenues: [],
      };

      const from = fromMoment.toDate();
      const to = toMoment.toDate();
      const documentSnapshots = await ordersCollection
        .where('orderedOn', '>=', from)
        .where('orderedOn', '<=', to)
        .where('status', 'in', [OrderStatus.COMPLETED, OrderStatus.PROCESSING])
        .get();
      const orders = [];
      documentSnapshots.docs.map(async (doc) => {
        const data = doc.data();
        data.formattedDate = moment
          .unix(data.orderedOn.seconds)
          .format('DD MMM');
        orders.push({ id: doc.id, ...data });
      });

      do {
        const label = fromMoment.format('DD MMM');
        const finalPrice = _.sumBy(
          orders.filter((o) => o.formattedDate === label),
          'finalPrice'
        );
        result.revenues.push(finalPrice);
        result.labels.push(label);
      } while (fromMoment.add(1, 'days').diff(toMoment) <= 0);

      dispatch({
        type: ReportActionTypes.GET_MONTHLY_REVENUE,
        monthlyRevenue: result,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve product sold qty
 */
export const getProductSoldQty = (id, dateFrom, dateTo) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      dateFrom = moment(dateFrom).startOf('day').toDate();
      dateTo = moment(dateTo).endOf('day').toDate();

      const result = {
        labels: [],
        quantites: [],
        borderColors: [],
        backgroundColors: [],
        productId: id,
        dateFrom,
        dateTo,
      };
      const products = [];
      const productDoc = await productsCollection.doc(id).get();

      const productData = productDoc.data();
      productData.variants.forEach((variant, index) => {
        products.push({
          id: productDoc.id,
          collection: productData.collection,
          name: productData.name,
          variant: variant.type,
          soldQty: 0,
        });
        result.labels.push(`${variant.type}`);
        result.backgroundColors.push(backgroundColors[index]);
        result.borderColors.push(borderColors[index]);
      });

      const orderSnapshots = await ordersCollection
        .where('orderedOn', '>=', dateFrom)
        .where('orderedOn', '<=', dateTo)
        .where('status', 'in', [OrderStatus.COMPLETED, OrderStatus.PROCESSING])
        .get();
      const orders = [];
      orderSnapshots.docs.map(async (doc) => {
        const data = doc.data();
        orders.push({ id: doc.id, ...data });
      });
      orders.forEach((order) => {
        order.products
          .filter((p) => p.id)
          .forEach((p) => {
            const dbProduct = products.find(
              (dp) => dp.id === p.id && p.variant === dp.variant
            );
            if (dbProduct) dbProduct.soldQty += p.qty;
          });
      });

      products.forEach((p) => {
        result.quantites.push(p.soldQty);
      });
      dispatch({
        type: ReportActionTypes.PRODUCT_SOLD_QTY,
        productSoldQty: result,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve top product sold
 */
export const getTopProductsSold = (dateFrom, dateTo) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const products = [];
      const productDocs = await productsCollection
        .where('shippable', '==', true)
        .get();
      productDocs.forEach((doc) => {
        const data = doc.data();
        data.variants.forEach((v) => {
          v.soldQty = 0;
        });
        products.push({ id: doc.id, ...data, soldQty: 0 });
      });

      dateFrom = moment(dateFrom).startOf('day').toDate();
      dateTo = moment(dateTo).endOf('day').toDate();

      const result = {
        orders: [],
        dateFrom,
        dateTo,
      };

      const orderSnapshots = await ordersCollection
        .where('orderedOn', '>=', dateFrom)
        .where('orderedOn', '<=', dateTo)
        .where('status', 'in', [OrderStatus.COMPLETED, OrderStatus.PROCESSING])
        .get();
      const orders = [];
      orderSnapshots.docs.map(async (doc) => {
        const data = doc.data();
        orders.push({ id: doc.id, ...data });
      });
      orders.forEach((order) => {
        order.products
          .filter((p) => p.id)
          .forEach((p) => {
            const dbProduct = products.find((dp) => dp.id === p.id);
            if (dbProduct) {
              dbProduct.soldQty += p.qty;
              const dbVariant = dbProduct.variants.find(
                (dv) =>
                  dv.type === p.variant &&
                  isArrayObjectEqual(p.subVariants, dv.subVariants, [
                    'key',
                    'assetId',
                  ])
              );
              if (dbVariant) dbVariant.soldQty += p.qty;
            }
          });
      });

      products.forEach((p) => {
        p.variants = _.orderBy(p.variants, 'soldQty', 'desc');
      });

      result.orders = _.orderBy(products, 'soldQty', 'desc');

      dispatch({
        type: ReportActionTypes.TOP_PRODUCT_SOLD,
        topProductsSold: result,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve number of orders
 */
export const getNoOfOrders = (period) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      let dateFrom;
      let dateTo;

      switch (period) {
        case PeriodSelections.THISWEEK:
          dateFrom = moment().startOf('week').toDate();
          dateTo = moment().endOf('week').toDate();
          break;
        case PeriodSelections.LASTWEEK:
          dateFrom = moment().subtract(1, 'weeks').startOf('week').toDate();
          dateTo = moment().subtract(1, 'weeks').endOf('week').toDate();
          break;
        case PeriodSelections.THISMONTH:
          dateFrom = moment().startOf('month').toDate();
          dateTo = moment().endOf('month').toDate();
          break;
        case PeriodSelections.LASTMONTH:
          dateFrom = moment().subtract(1, 'months').startOf('month').toDate();
          dateTo = moment().subtract(1, 'months').endOf('month').toDate();
          break;
        case PeriodSelections.TODAY:
        default:
          dateFrom = moment().startOf('day').toDate();
          dateTo = moment().endOf('day').toDate();
          break;
      }

      const documentSnapshots = await ordersCollection
        .where('orderedOn', '>=', dateFrom)
        .where('orderedOn', '<=', dateTo)
        .where('status', 'in', [OrderStatus.COMPLETED, OrderStatus.PROCESSING])
        .get();

      const result = {
        period,
        count: documentSnapshots.docs.length,
      };

      dispatch({
        type: ReportActionTypes.GET_NO_OF_ORDERS,
        noOfOrders: result,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve number of customers
 */
export const getNoOfCustomers = (period) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      let dateFrom;
      let dateTo;

      switch (period) {
        case PeriodSelections.THISWEEK:
          dateFrom = moment().startOf('week').toDate();
          dateTo = moment().endOf('week').toDate();
          break;
        case PeriodSelections.LASTWEEK:
          dateFrom = moment().subtract(1, 'weeks').startOf('week').toDate();
          dateTo = moment().subtract(1, 'weeks').endOf('week').toDate();
          break;
        case PeriodSelections.THISMONTH:
          dateFrom = moment().startOf('month').toDate();
          dateTo = moment().endOf('month').toDate();
          break;
        case PeriodSelections.LASTMONTH:
          dateFrom = moment().subtract(1, 'months').startOf('month').toDate();
          dateTo = moment().subtract(1, 'months').endOf('month').toDate();
          break;
        case PeriodSelections.TODAY:
        default:
          dateFrom = moment().startOf('day').toDate();
          dateTo = moment().endOf('day').toDate();
          break;
      }

      const documentSnapshots = await usersCollection
        .where('joinedOn', '>=', dateFrom)
        .where('joinedOn', '<=', dateTo)
        .get();

      const result = {
        period,
        count: documentSnapshots.docs.length,
      };

      dispatch({
        type: ReportActionTypes.GET_NO_OF_CUSTOMERS,
        noOfCustomers: result,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve number of deliveries
 */
export const getNoOfDeliveries = (date) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      date = moment(date).startOf('day').toDate();

      const documentSnapshots = await deliverySlotsCollection
        .where('date', '==', date)
        .get();

      const result = {
        count: 0,
        id: null,
        date,
      };
      if (documentSnapshots.docs.length) {
        const data = documentSnapshots.docs[0].data();
        result.id = documentSnapshots.docs[0].id;
        data.slots.forEach((s) => {
          result.count += s.orders ? s.orders.length : 0;
        });
      }

      dispatch({
        type: ReportActionTypes.GET_NO_OF_DELIVERIES,
        noOfDeliveries: result,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve delivery slots into calendar events
 * @param {Date[] | { start: string | Date, end: string | Date }} range
 */
export const getDeliverySlotCalendarEvents = (range) => {
  return async (dispatch) => {
    try {
      let start;
      let end;

      if (Array.isArray(range)) {
        [start] = range;
        if (range.length > 2) {
          end = range[range.length - 1];
        } else {
          [end] = range;
        }
      } else {
        ({ start, end } = range);
      }

      const dateFrom = moment(start).startOf('day');
      const dateTo = moment(end).endOf('day');

      dispatch(startLoading());

      const documentSnapshots = await deliverySlotsCollection
        .where('date', '>=', dateFrom.toDate())
        .where('date', '<=', dateTo.toDate())
        .get();

      const events = documentSnapshots.docs.reduce((evts, doc) => {
        const deliverySlotData = doc.data();
        const { slots, date } = deliverySlotData;
        const formatDate = moment.unix(date.seconds);

        const slotEvents = slots.reduce((slotEvts, slot) => {
          const { time, orders } = slot;
          const [fromTimeStr, toTimeStr] = time.split('-');
          const fromTime = moment(fromTimeStr.trim(), 'hh:mm A');
          const toTime = moment(toTimeStr.trim(), 'hh:mm A');

          const mappedSlotEvt = orders
            .filter((o) =>
              [OrderStatus.PROCESSING, OrderStatus.COMPLETED].includes(o.status)
            )
            .map((o) => {
              return {
                start: moment(
                  `${formatDate.format('YYYY-MM-DD')} ${fromTime.format(
                    'HH:mm:ss'
                  )}`
                ).toDate(),
                end: moment(
                  `${formatDate.format('YYYY-MM-DD')} ${toTime.format(
                    'HH:mm:ss'
                  )}`
                ).toDate(),
                title: o.refNum,
                resource: o,
              };
            });

          return [...slotEvts, ...mappedSlotEvt];
        }, []);

        return [...evts, ...slotEvents];
      }, []);

      dispatch({
        type: ReportActionTypes.GET_DELIVERY_EVENTS,
        deliveryEvents: { events },
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Get Order Promos
 */
export const getOrderPromos = (dateFrom, dateTo) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      dateFrom = moment(dateFrom).startOf('day').toDate();
      dateTo = moment(dateTo).endOf('day').toDate();

      const result = {
        dateFrom,
        dateTo,
        labels: [],
        counts: [],
        sales: [],
        borderColors: [],
        backgroundColors: [],
      };

      const documentSnapshots = await ordersCollection
        .where('orderedOn', '>=', dateFrom)
        .where('orderedOn', '<=', dateTo)
        .where('status', 'in', [OrderStatus.COMPLETED, OrderStatus.PROCESSING])
        .get();

      let orderPromos = [];
      documentSnapshots.docs.map(async (doc) => {
        const data = doc.data();
        if (data.promos && data.promos.length > 0) {
          data.promos.forEach((p) => {
            const orderPromo = orderPromos.find(
              (op) => op.code.toUpperCase() === p.code.toUpperCase()
            );
            if (orderPromo) {
              orderPromo.count += 1;
              orderPromo.sale += data.finalPrice;
            } else {
              orderPromos.push({
                code: p.code.toUpperCase(),
                count: 1,
                sale: data.finalPrice,
              });
            }
          });
        }
      });

      orderPromos = _.orderBy(orderPromos, 'count', 'desc');
      orderPromos.forEach((op, index) => {
        result.labels.push(op.code);
        result.counts.push(op.count);
        result.sales.push(op.sale);
        result.backgroundColors.push(backgroundColors[index]);
        result.borderColors.push(borderColors[index]);
      });

      dispatch({
        type: ReportActionTypes.GET_ORDER_PROMOTIONS,
        orderPromos: result,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve inventory reports
 */
export const getProductInventory = (dateFrom, dateTo) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      dateFrom = moment(dateFrom).startOf('day').toDate();
      dateTo = moment(dateTo).endOf('day').toDate();

      const orderRef = await ordersCollection
        .where('orderedOn', '>=', dateFrom)
        .where('orderedOn', '<=', dateTo)
        .where('status', 'in', [OrderStatus.COMPLETED, OrderStatus.PROCESSING])
        .get();

      let orderProducts = [];
      orderRef.docs.forEach((doc) => {
        const data = doc.data();
        data.products
          .filter((p) => p.shippable)
          .forEach((p) => {
            p.refNum = data.refNum;
            const orderProduct = orderProducts.find((op) => op.id === p.id);
            if (!orderProduct) {
              orderProducts.push({
                id: p.id,
                collection: p.collection,
                name: p.name,
                products: [p],
              });
            } else {
              orderProduct.products.push(p);
            }
          });
      });

      orderProducts = _.orderBy(orderProducts, 'collection', 'desc');

      orderProducts.forEach((o) => {
        o.totalQty = 0;
        o.products.forEach((p) => {
          if (!Number.isNaN(p.qty)) o.totalQty += p.qty;

          if (p.subVariants) {
            let groupDescription = '';
            const designSub = p.subVariants.find(
              (s) => s.key === ProductAssetKey.DESIGN
            );
            if (designSub)
              groupDescription += `${groupDescription ? ' / ' : ''}${
                designSub.value
              }`;

            const materialSub = p.subVariants.find(
              (s) => s.key === ProductAssetKey.MATERIAL
            );
            if (materialSub)
              groupDescription += `${groupDescription ? ' / ' : ''}${
                materialSub.value
              }`;

            const colourSub = p.subVariants.find(
              (s) => s.key === ProductAssetKey.COLOUR
            );
            if (colourSub)
              groupDescription += `${groupDescription ? ' / ' : ''}${
                colourSub.value
              }`;

            if (
              groupDescription &&
              o.products.findIndex(
                (vv) => vv.groupDescription === groupDescription
              ) === -1
            )
              p.groupDescription = groupDescription;
          }
        });
      });
      console.log('orderProducts', orderProducts);

      const result = {
        orderProducts,
      };

      dispatch({
        type: ReportActionTypes.GET_IVENTORY_REPORT,
        productInventory: result,
      });
    } catch (err) {
      console.log(err);
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
