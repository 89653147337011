import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'shards-react';
import { Link } from 'react-router-dom';

const CalendarEventModal = ({ event, open, onClose }) => {
  const { resource } = event;
  const refNum = _.get(resource, 'refNum', '');
  const orderId = _.get(resource, 'orderId', '');
  const shipping = _.get(resource, 'shipping', {});
  const products = _.get(resource, 'products', []);

  return (
    <Modal centered open={open} toggle={onClose}>
      <ModalHeader>
        <Link to={`/orders/view/${orderId}`}>{refNum}</Link>
      </ModalHeader>
      <ModalBody>
        Name: {shipping.firstName} {shipping.lastName}
        <br />
        Email: {shipping.email}
        <br />
        Contact:{' '}
        <a href={`tel:${shipping.contactNo}`} target="_blank" rel="noreferrer">
          {shipping.contactNo}
        </a>
        <br />
        Delivery Address: {shipping.address} {shipping.postalCode}
        <br />
        Items to be delivered: <br />
        <ul>
          {products.map((p, i) => (
            <li key={`${p.name}-${p.variant}-${i}`}>
              {p.qty} x {p.name} - {p.variant}
              {p.subVariants && (
                <ul>
                  {p.subVariants.map((s, j) => (
                    <li key={`${p.name}-${p.variant}-${i}-${s.value}-${j}`}>
                      {_.capitalize(s.key)}: {s.value}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        Notes: {shipping.notes}
      </ModalBody>
      <ModalFooter>
        <Button type="button" theme="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CalendarEventModal.propTypes = {
  event: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    resource: PropTypes.shape({
      orderId: PropTypes.string,
      refNum: PropTypes.string,
      shipping: PropTypes.shape({
        address: PropTypes.string,
        company: PropTypes.string,
        contactNo: PropTypes.string,
        country: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        notes: PropTypes.string,
        postalCode: PropTypes.string,
      }),
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          qty: PropTypes.number,
          uuid: PropTypes.string,
          variant: PropTypes.string,
          allocatedQty: PropTypes.number,
        })
      ),
    }),
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

CalendarEventModal.defaultProps = {
  onClose: () => {},
};

export default CalendarEventModal;
