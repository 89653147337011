import { AuthActionTypes } from '../actions/auth';
import { getToken, getAdmin } from '../../utils/auth';

const initialState = {
  token: getToken(),
  admin: getAdmin(),
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {AuthActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return { ...state, admin: action.admin, token: action.token };
    case AuthActionTypes.LOGOUT:
      return { ...state, admin: null, token: null };
    default:
      return state;
  }
}
