import React from 'react';
import { Row } from 'shards-react';
import PropTypes from 'prop-types';
import { FormGroup, IconButton } from '../../components/common';
import { ProductStatus, StatusType } from '../../constants';

const ProductVariant = ({
  handleAddVariant,
  handleRemoveVariant,
  variantStatusChange,
  variantValueChange,
  variantQtyChange,
  variantPriceChange,
  openVariantThumbnailModal,
  variantsState,
  isLoading,
  thumbnail,
  productStatusOptions,
  control,
  errors,
}) => {
  const minQty = -1000

  return (
    <div
      className="custom-table-responsive mb-4"
      style={{ minHeight: '250px' }}
    >
      <div className="mb-3">
        {!thumbnail.thumbnail && (
          <IconButton
            onClick={() => openVariantThumbnailModal()}
            theme="info"
            disabled={isLoading}
            icon="crop_original"
            type="button"
          >
            <span className="ml-2">Thumbnail</span>
          </IconButton>
        )}
        {thumbnail.thumbnail && (
          <div onClick={() => openVariantThumbnailModal()}>
            <img
              alt=""
              title="Upload Thumbnail"
              style={{ height: '70px' }}
              src={thumbnail.thumbnail}
            />
          </div>
        )}
      </div>
      <table className="table form-table">
        <thead>
          <tr>
            <th width="60">
              <IconButton
                onClick={() => handleAddVariant()}
                theme="success"
                disabled={isLoading}
                icon="add"
                type="button"
              />
            </th>
            <th width="180">Variant</th>
            <th width="130">Status</th>
            <th width="150">Status Date/Note</th>
            <th width="150">SKU</th>
            <th width="100">On Hand Qty</th>
            <th width="100">Reserved Qty</th>
            <th width="100">Available Qty</th>
            <th width="120">Selling Price</th>
            <th width="120">Compare Price</th>
          </tr>
        </thead>
        <tbody>
          {variantsState &&
            variantsState.map((variant, index) => {
              return (
                <tr key={variant.rowId}>
                  <td>
                    <IconButton
                      onClick={() => handleRemoveVariant(index)}
                      theme="danger"
                      disabled={isLoading}
                      icon="delete_outline"
                      type="button"
                    />
                  </td>
                  <td>
                    <Row>
                      <FormGroup
                        name={`variants[${index}].type`}
                        control={control}
                        rules={{ required: 'Required field' }}
                        errors={errors}
                        defaultValue={variant.type}
                        onChange={(e) => variantValueChange(e, index, 'type')}
                      />
                    </Row>
                  </td>
                  <td>
                    <Row>
                      <FormGroup
                        name={`variants[${index}].status`}
                        type="select"
                        options={productStatusOptions}
                        control={control}
                        rules={{ required: 'Required field' }}
                        errors={errors}
                        defaultValue={variant.status}
                        onChange={(e) => variantStatusChange(e, index)}
                      />
                    </Row>
                  </td>
                  <td>
                    {variant.statusType === StatusType.STANDARD &&
                      variant.status === ProductStatus.PRE_ORDER && (
                        <Row>
                          <FormGroup
                            type="datepicker"
                            preventOverflow
                            name={`variants[${index}].statusDate`}
                            control={control}
                            rules={{ required: 'Required field' }}
                            errors={errors}
                            defaultValue={variant.statusDate}
                            onChange={(e) =>
                              variantValueChange(e, index, 'statusDate')
                            }
                          />
                        </Row>
                      )}
                    {variant.statusType === StatusType.CUSTOM &&
                      variant.status === ProductStatus.PRE_ORDER && (
                        <Row>
                          <FormGroup
                            name={`variants[${index}].statusNote`}
                            control={control}
                            rules={{ required: 'Required field' }}
                            errors={errors}
                            defaultValue={variant.statusNote}
                            onChange={(e) =>
                              variantValueChange(e, index, 'statusNote')
                            }
                          />
                        </Row>
                      )}
                    {variant.status !== ProductStatus.PRE_ORDER && (
                      <p className="m-0 text-center">--</p>
                    )}
                  </td>
                  <td>
                    <Row>
                      <FormGroup
                        name={`variants[${index}].sku`}
                        control={control}
                        defaultValue={variant.sku}
                        onChange={(e) => variantValueChange(e, index, 'sku')}
                      />
                    </Row>
                  </td>
                  <td>
                    <Row>
                      <FormGroup
                        name={`variants[${index}].onHandQty`}
                        type="number"
                        control={control}
                        rules={{ required: 'Required field' }}
                        errors={errors}
                        defaultValue={variant.onHandQty}
                        min={minQty}
                        onChange={(e) => {
                          variantQtyChange(e, index, 'onHandQty');
                        }}
                      />
                    </Row>
                  </td>
                  <td>
                    <Row>
                      <FormGroup
                        name={`variants[${index}].reservedQty`}
                        type="number"
                        control={control}
                        rules={{ required: 'Required field' }}
                        errors={errors}
                        defaultValue={variant.reservedQty}
                        min={minQty}
                        onChange={(e) => {
                          variantQtyChange(e, index, 'reservedQty');
                        }}
                      />
                    </Row>
                  </td>
                  <td>
                    <Row>
                      <FormGroup
                        name={`variants[${index}].availableQty`}
                        type="number"
                        control={control}
                        rules={{ required: 'Required field' }}
                        min={minQty}
                        errors={errors}
                        readOnly
                        defaultValue={variant.availableQty}
                      />
                    </Row>
                  </td>
                  <td>
                    <Row>
                      <FormGroup
                        name={`variants[${index}].prices[0].amount`}
                        type="number"
                        control={control}
                        rules={{ required: 'Required field' }}
                        errors={errors}
                        defaultValue={variant.prices[0].amount}
                        onChange={(e) => variantPriceChange(e, index, 'amount')}
                      />
                    </Row>
                  </td>
                  <td>
                    <Row>
                      <FormGroup
                        name={`variants[${index}].prices[0].compareAmount`}
                        type="number"
                        control={control}
                        rules={{ required: 'Required field' }}
                        defaultValue={
                          variant.prices ? variant.prices[0].compareAmount : 0
                        }
                        errors={errors}
                        onChange={(e) =>
                          variantPriceChange(e, index, 'compareAmount')
                        }
                      />
                    </Row>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

ProductVariant.propTypes = {
  handleAddVariant: PropTypes.func,
  handleRemoveVariant: PropTypes.func,
  variantStatusChange: PropTypes.func,
  variantValueChange: PropTypes.func,
  variantQtyChange: PropTypes.func,
  variantPriceChange: PropTypes.func,
  openVariantThumbnailModal: PropTypes.func,
  variantsState: PropTypes.array,
  thumbnail: PropTypes.object,
  productStatusOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  control: PropTypes.any,
  errors: PropTypes.any,
};

ProductVariant.defaultProps = {
  handleAddVariant: () => {},
  handleRemoveVariant: () => {},
  variantStatusChange: () => {},
  variantValueChange: () => {},
  variantQtyChange: () => {},
  variantPriceChange: () => {},
  openVariantThumbnailModal: () => {},
  variantsState: [],
  thumbnail: null,
  productStatusOptions: [],
  isLoading: false,
  control: null,
  errors: null,
};

export default ProductVariant;
