import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Row, Col, Button } from 'shards-react';
import { useForm } from 'react-hook-form';

import { SmartTable, SearchCard, FormGroup } from '../../components/common';
import { MainStage } from '../../components/layout';
import { getUsers, exportCSV } from '../../store/actions/users';
import { CustomerType } from '../../constants/index';

const customerTypeOptions = Object.keys(CustomerType).map((key) => {
  return { value: CustomerType[key], label: CustomerType[key] };
});

const CustomerListing = () => {
  const dispatch = useDispatch();
  const { control } = useForm();
  const [filter, setFilter] = useState('');
  const [filterType, setFilterType] = useState('');
  const [joinedDate, setJoinedDate] = useState('');
  const { items, paging } = useSelector(({ users: { users } }) => users);
  const isLoading = useSelector(({ ui }) => ui.isLoading);

  const columns = [
    {
      header: 'Email',
      accessor: 'email',
      width: '20%',
      sort: true,
      cell: (row, data) => (
        <Link to={`/customers/${data.id}`}>{row.value}</Link>
      ),
    },
    {
      header: 'Name',
      accessor: 'firstName',
      sort: true,
      cell: (row, data) => (
        <span>
          {row.value} {data.lastName}
        </span>
      ),
    },
    {
      header: 'Join Date',
      accessor: 'joinedOn',
      sort: true,
      cell: ({ value }) => moment.unix(value.seconds).format('lll'),
    },
  ];

  useEffect(() => {
    if (items.length === 0) {
      dispatch(getUsers(paging));
    }
    // eslint-disable-next-line
  }, []);

  const handleExportCsv = () => {
    console.log('handleExportCsv', filter, filterType, joinedDate);
    dispatch(exportCSV({ filter, filterType, joinedDate }));
  };

  return (
    <MainStage title="Overview" subtitle="Customers">
      <Row>
        <Col lg="12">
          <SearchCard title="Export CSV">
            <Row>
              <FormGroup
                col={{ lg: '3' }}
                type="select"
                options={customerTypeOptions}
                name="filterType"
                label="Customer Type"
                control={control}
                onChange={(e) => setFilterType(e.target.value)}
              />
              <FormGroup
                col={{ lg: '3' }}
                type="datepicker"
                name="joinedDate"
                label="Join From"
                control={control}
                onChange={(selected) => setJoinedDate(selected)}
              />
              <FormGroup
                col={{ lg: '3' }}
                name="filter"
                label="Name / Email"
                control={control}
                placeholder="Type at least 3 characters"
                onChange={(e) => setFilter(e.target.value)}
              />
            </Row>
            <div className="d-flex">
              <Button
                className="ml-2"
                type="button"
                theme="primary"
                disabled={isLoading}
                onClick={handleExportCsv}
              >
                Export
              </Button>
            </div>
          </SearchCard>
          <SmartTable
            title="Active Customers"
            data={items}
            columns={columns}
            page={paging.page}
            defaultSortFilter={paging.orderBy}
            onSorted={(values) => {
              const orderBy = Object.keys(values)[0];
              const order = values[orderBy];
              dispatch(getUsers({ orderBy, order }));
            }}
            onFilter={(filter) => {
              dispatch(getUsers({ filter }));
            }}
            onPageChange={(page) => {
              dispatch(getUsers({ page }));
            }}
          />
        </Col>
      </Row>
    </MainStage>
  );
};

export default CustomerListing;
