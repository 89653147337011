import { error, success } from 'react-toastify-redux';
import { referralsCollection, usersCollection, firebase } from '../../firebase';
import { startLoading, stopLoading } from './ui';
import { getAdmin as findAdmin } from '../../utils/auth';

export const ReferralActionTypes = {
  GET_REFERRAL: 'GET_REFERRAL',
  UPDATE_REFERRAL: 'UPDATE_REFERRAL',
};

/**
 * Retrieve referral program from firebase
 */
export const getReferral = () => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const docRef = await referralsCollection.limit(1).get();
      const doc = docRef.docs[0];

      const referral = {
        id: doc.id,
        ...doc.data(),
      };

      dispatch({
        type: ReferralActionTypes.GET_REFERRAL,
        referral,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Update referral from firebase
 * @param {string} id
 * @param {Object} referral
 */
export const updateReferral = (id, referral) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      referral.audit = {
        updatedBy: findAdmin().name,
        updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };
      await referralsCollection.doc(id).set(referral);
      dispatch(success('Successfully updated!'));
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Enter referral code
 * @param {string} code
 * @param {Object} order
 */
export const applyReferral = async (code, order) => {
  // if user already referred by other
  const userDoc = await usersCollection.doc(order.userId).get();
  const user = userDoc.data();
  if (user.referrals && user.referrals.referrer && user.referrals.referrer.id)
    return null;

  const referrersRef = await usersCollection
    .where('referrals.code', '==', code)
    .limit(1)
    .get();
  if (!referrersRef.docs.length) return null;
  const referrerDoc = referrersRef.docs[0];
  const referrerUser = { id: referrerDoc.id, ...referrerDoc.data() };

  const docRef = await referralsCollection.limit(1).get();
  const doc = docRef.docs[0];
  const referral = doc.data();

  let referrerRebate = 0;
  let refereeRebate = 0;
  const { products } = order;
  const { referrer, referee } = referral;
  if (!referrer.active && !referee.active) return null;

  products.forEach((p) => {
    if (!p.promoCode) {
      if (referrer.active) {
        const referrerProduct = referrer.products.find((rp) => rp.id === p.id);
        if (referrerProduct) {
          const referrerVariant = referrerProduct.variants.find(
            (rv) => rv.type === p.variant
          );
          referrerRebate += referrerVariant.amount * p.qty;
        }
      }

      if (referee.active) {
        const refereeProduct = referee.products.find((rp) => rp.id === p.id);
        if (refereeProduct) {
          const refereeVariant = refereeProduct.variants.find(
            (rv) => rv.type === p.variant
          );
          refereeRebate += refereeVariant.amount * p.qty;
        }
      }
    }
  });

  return {
    referrer: referrerUser,
    referrerRebate,
    refereeRebate,
  };
};
