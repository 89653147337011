import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Tooltip } from 'shards-react';

const TooltipBadge = ({ id, theme, badge, children }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);

  return (
    <>
      <Badge id={id} theme={theme}>
        {badge}
      </Badge>
      <Tooltip target={`#${id}`} open={open} toggle={handleToggle}>
        {children}
      </Tooltip>
    </>
  );
};

TooltipBadge.propTypes = {
  id: PropTypes.string.isRequired,
  theme: PropTypes.string,
  badge: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

TooltipBadge.defaultProps = {
  theme: 'primary',
};

export default TooltipBadge;
