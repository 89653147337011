import React from 'react';
import classNames from 'classnames';
import { Col } from 'shards-react';
import { useSelector } from 'react-redux';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarNavItems from './SidebarNavItems';

const MainSidebar = () => {
  const menuVisible = useSelector(({ ui }) => ui.menuVisible);
  const classes = classNames(
    'main-sidebar',
    'px-0',
    'col-12',
    menuVisible && 'open'
  );

  return (
    <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
      <SidebarMainNavbar />
      <SidebarNavItems />
    </Col>
  );
};

export default MainSidebar;
