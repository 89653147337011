import React from 'react';
import { Container } from 'shards-react';
import PropTypes from 'prop-types';
import Base from './Base';

const BlankLayout = ({ children }) => (
  <Base>
    <Container>{children}</Container>
  </Base>
);

BlankLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BlankLayout;
