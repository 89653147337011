import { error, success } from 'react-toastify-redux';

import { startLoading, stopLoading, redirect } from './ui';
import {
  postsCollection,
  firebase,
  firestore,
  archivesCollection,
} from '../../firebase';
import { getFirebasePagingQuery } from '../../utils';
import { getToken } from '../../utils/auth';

export const PostActionTypes = {
  GET_POSTS: 'GET_POSTS',
  GET_POST: 'GET_POST',
  CLEAR_POST: 'CLEAR_POST',
  CREATE_POST: 'CREATE_POST',
  UPDATE_POST: 'UPDATE_POST',
  DELETE_POST: 'DELETE_POST',
  GET_PREVIEW_TOKEN: 'GET_PREVIEW_TOKEN',
};

/**
 * Retrieve multiple posts from firebase
 * @param {Object} options
 * @param {string} options.orderBy order by field name
 * @param {string} options.order desc or asc order
 * @param {number} options.page the current page number
 * @param {number} options.size per page size
 * @param {string} options.filter search filter
 */
export const getPosts = (options) => {
  return async (dispatch, getState) => {
    try {
      const {
        posts: { posts },
      } = getState();

      dispatch(startLoading());

      const result = await getFirebasePagingQuery(
        options,
        posts,
        postsCollection
      );

      if (!result) return;

      dispatch({
        type: PostActionTypes.GET_POSTS,
        posts: {
          ...result,
        },
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Rerieve post document by id from firebase
 * @param {string} id
 */
export const getPost = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const postRef = await postsCollection.doc(id).get();
      const post = { id: postRef.id, ...postRef.data() };

      dispatch({
        type: PostActionTypes.GET_POST,
        post,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Gets a preview token
 * @param {string} id
 */
export const getPreviewToken = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const tokenEndpoint = `${process.env.REACT_APP_WOOSA_API_URI}/api/auth/token`;
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
        body: JSON.stringify({
          id,
          type: 'posts',
        }),
      });
      if (response.status !== 201 && response.status !== 200) throw response;
      const data = await response.json();

      dispatch({
        type: PostActionTypes.GET_PREVIEW_TOKEN,
        previewToken: data.token,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * @typedef Post
 * @property {Object} categories
 * @property {string} content
 * @property {Date} publishedOn
 * @property {string} slug
 * @property {string} status
 * @property {string} thumbnail
 * @property {string} title
 *
 * Create new post
 * @param {Post} post
 */
export const createPost = (post) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const {
        auth: { admin },
      } = getState();

      if (post.publishedOn && !post.publishedOn.seconds) {
        post.publishedOn = firebase.firestore.Timestamp.fromDate(
          post.publishedOn
        );
      } else if (!post.publishedOn) {
        post.publishedOn = firebase.firestore.Timestamp.fromDate(new Date());
      }

      post.audit = {
        createdBy: admin.name,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const postRef = await postsCollection.add(post);

      dispatch(getPosts({}));
      dispatch(redirect(`/posts/${postRef.id}`));
      setTimeout(() => dispatch(success('Successfully created!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Clear post and set to default new post data
 */
export const clearPost = () => {
  return (dispatch, getState) => {
    const {
      auth: { admin },
    } = getState();

    dispatch({
      type: PostActionTypes.CLEAR_POST,
      post: {
        author: {
          id: admin.id,
          name: admin.name,
        },
        publishedOn: firebase.firestore.Timestamp.fromDate(new Date()),
      },
    });
  };
};

/**
 * Update existing post
 * @param {string} id
 * @param {Post} role
 */
export const updatePost = (id, post) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const {
        auth: { admin },
      } = getState();

      if (post.publishedOn && !post.publishedOn.seconds) {
        post.publishedOn = firebase.firestore.Timestamp.fromDate(
          post.publishedOn
        );
      } else if (!post.publishedOn) {
        post.publishedOn = firebase.firestore.Timestamp.fromDate(new Date());
      }

      post.audit = {
        ...post.audit,
        updatedBy: admin.name,
        updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await postsCollection.doc(id).set(post);
      dispatch(success('Successfully updated!'));
      dispatch({
        type: PostActionTypes.UPDATE_POST,
        post,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Delete post and move to archive collection
 * @param {string} id
 */
export const deletePost = (id) => {
  return async (dispatch, getState) => {
    try {
      const {
        auth: { admin },
        posts: { post },
      } = getState();

      dispatch(startLoading());

      post.audit = {
        ...post.audit,
        deletedBy: admin.name,
        deletedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const batch = firestore.batch();
      batch.delete(postsCollection.doc(id));
      batch.update(archivesCollection.doc('posts'), { [id]: post });

      await batch.commit();

      dispatch({
        type: PostActionTypes.DELETE_POST,
      });
      dispatch(getPosts({}));
      dispatch(redirect('/posts'));
      setTimeout(() => dispatch(success('Successfully deleted!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
