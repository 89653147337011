import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { warning } from 'react-toastify-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from 'shards-react';
import { useForm } from 'react-hook-form';
import { FormGroup, IconButton } from '../../components/common';
import {
  readFileInBase64,
  getFileType,
  isFileSizeValid,
} from '../../utils/media';
import { ProductAssetKey, ProductImage } from '../../constants';

const ProductAssetModal = ({
  productAsset,
  onAddProductAsset,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [attachedMaterialFile, setAttachedMaterialFile] = useState(null);
  const { control, handleSubmit, errors, setValue } = useForm();

  const assetKeyOptions = Object.keys(ProductAssetKey).map((key) => {
    return { value: ProductAssetKey[key], label: ProductAssetKey[key] };
  });

  useEffect(() => {
    if (open && productAsset) {
      if (productAsset.image)
        setAttachedMaterialFile({
          file: null,
          image: productAsset.image,
        });
    } else {
      setAttachedMaterialFile(null);
    }
  }, [open]);

  const handleSelectMaterialFile = (event) => {
    console.log('event', event);
    if (!event.target.files.length) return;
    const file = event.target.files[0];
    if (getFileType(file.name) !== 'image') {
      dispatch(warning('Invalid file type'));
      return;
    }
    if (!isFileSizeValid(file.size, ProductImage.MAX_SIZE)) {
      dispatch(
        warning(
          `Image size has been exceeded the limit of ${ProductImage.MAX_SIZE}KB`
        )
      );
      return;
    }
    readFileInBase64(file).then((res) => {
      const newAttachedMaterialFile = {
        file,
        image: res.path,
      };
      setAttachedMaterialFile(newAttachedMaterialFile);
    });
  };

  const handleRemoveMaterialUpload = () => {
    setAttachedMaterialFile(null);
  };

  const handleClear = () => {
    setValue('key', '');
    setValue('value', '');
    setAttachedMaterialFile(null);
  };

  const handleClose = (e) => {
    console.log('handleClose', e);
    setAttachedMaterialFile(null);
    onClose();
  };

  const onSubmit = (data) => {
    data.value = data.value.trim();
    data.image = attachedMaterialFile ? attachedMaterialFile.image : '';
    data.file = attachedMaterialFile ? attachedMaterialFile.file : null;

    if (productAsset) {
      data.isNew = false;
      data.assetId = productAsset.assetId;
    } else {
      data.isNew = true;
      data.assetId = uuidv4();
    }
    console.log('data', data);
    onAddProductAsset(data);
  };

  return (
    <Modal centered open={open} toggle={() => {}} size="lg">
      <ModalHeader>Product Asset</ModalHeader>
      <ModalBody>
        <Row>
          <FormGroup
            col={{ md: '12' }}
            label="Asset Type"
            name="key"
            type="select"
            options={assetKeyOptions}
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
            defaultValue={productAsset ? productAsset.key : ''}
          />
        </Row>
        <Row>
          <FormGroup
            col={{ md: '12' }}
            label="Asset value"
            name="value"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
            defaultValue={productAsset ? productAsset.value : ''}
          />
        </Row>
        <Row>
          <Col md="6">
            <label>
              Upload Image <small>300px x 300px</small>
            </label>
            <Row>
              <Col md="6">
                {!attachedMaterialFile && (
                  <Row>
                    <FormGroup
                      name="file"
                      type="file"
                      control={control}
                      rules={{ required: 'Required field' }}
                      errors={errors}
                      onChange={(event) => handleSelectMaterialFile(event)}
                    />
                  </Row>
                )}
                {attachedMaterialFile && (
                  <Card className="product-card">
                    <CardImg top src={attachedMaterialFile.image} />
                    <CardBody className="p-2">
                      <div>
                        <IconButton
                          theme="danger"
                          icon="delete_outline"
                          onClick={() => handleRemoveMaterialUpload()}
                        />
                      </div>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" theme="primary" onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
        <Button type="button" theme="secondary" onClick={handleClear}>
          Clear
        </Button>
        <Button type="button" theme="outline-secondary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ProductAssetModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAddProductAsset: PropTypes.func,
  productAsset: PropTypes.object,
};

ProductAssetModal.defaultProps = {
  onAddProductAsset: () => {},
  onClose: () => {},
  productAsset: null,
};

export default ProductAssetModal;
