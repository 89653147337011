import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Button,
} from 'shards-react';
import styled from 'styled-components';

import { MainStage } from '../../components/layout';
import { MaterialIcon, IconButton, PageLoader } from '../../components/common';
import { getPosts } from '../../store/actions/posts';
import {
  getBitbucketPipeline,
  runBitbucketPipeline,
} from '../../store/actions/pipelines';
import PostCard from './PostCard';

const ActionBar = styled(InputGroup)`
  display: flex;
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  justify-content: flex-end;
`;

const PostListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { items, paging } = useSelector(({ posts }) => posts.posts);
  const { pageLoading } = useSelector(({ ui }) => ui);
  const { isPipelineRunning } = useSelector(({ pipeline }) => pipeline);

  useEffect(() => {
    dispatch(getPosts(paging));
    dispatch(getBitbucketPipeline());
  }, []);

  const handlePublishButtonClick = () => {
    dispatch(runBitbucketPipeline());
  };

  return (
    <MainStage
      title="Overview"
      subtitle="Blog Posts"
      className="position-relative"
    >
      <PageLoader loading={pageLoading} />
      <ActionBar>
        <InputGroup className="w-25" seamless>
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <MaterialIcon icon="search" />
            </InputGroupText>
          </InputGroupAddon>
          <FormInput
            placeholder="Search"
            className="bg-transparent border-top-0 border-left-0 border-right-0"
            value={paging.filter}
            onChange={(event) => {
              const filter = event.target.value;
              dispatch(getPosts({ filter }));
            }}
          />
        </InputGroup>
        <IconButton
          className="ml-3"
          theme="success"
          onClick={() => {
            history.push('/posts/new');
          }}
          icon="add"
        />
        <Button
          className="ml-3"
          theme={isPipelineRunning ? 'warning' : 'primary'}
          onClick={handlePublishButtonClick}
          disabled={isPipelineRunning}
        >
          {!isPipelineRunning ? 'Publish Changes' : 'Working in Progress'}
        </Button>
      </ActionBar>
      <Row>
        {items.map((itemPage, key1) => {
          return itemPage.map((post, key2) => {
            return (
              <Col
                lg="4"
                md="6"
                sm="12"
                className="mb-4"
                key={`${key1}-${key2}`}
              >
                <PostCard post={post} />
              </Col>
            );
          });
        })}
      </Row>
      <div className="d-flex justify-content-center mt-4">
        <Button
          onClick={() => {
            const page = paging.page + 1;
            dispatch(getPosts({ page }));
          }}
        >
          Show more
        </Button>
      </div>
    </MainStage>
  );
};

export default PostListing;
