import { PipelineActionTypes } from '../actions/pipelines';

const initialState = {
  pipeline: {},
  isPipelineRunning: true,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {PipelineActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PipelineActionTypes.GET_BITBUCKET_PIPELINE: {
      const { pipeline = {} } = action;
      let isPipelineRunning = true;

      if (!pipeline) {
        isPipelineRunning = false;
      } else if (!pipeline.status) {
        isPipelineRunning = false;
      } else if (pipeline.status === 'COMPLETED') {
        isPipelineRunning = false;
      }

      return {
        ...state,
        pipeline,
        isPipelineRunning,
      };
    }
    default:
      return state;
  }
}
