import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Alert,
} from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';
import _ from 'lodash';
import { MainStage } from '../../components/layout';
import { FormCard, FormGroup } from '../../components/common';
import {
  createRole,
  getRole,
  updateRole,
  deleteRole,
} from '../../store/actions/roles';

const modules = [
  {
    label: 'Roles',
    name: 'roles',
    acl: ['create', 'read', 'update', 'delete'],
  },
  {
    label: 'Admins',
    name: 'admins',
    acl: ['create', 'read', 'update', 'delete'],
  },
  {
    label: 'Customers',
    name: 'customers',
    acl: ['read', 'update', 'delete'],
  },
  {
    label: 'Products',
    name: 'products',
    acl: ['create', 'read', 'update', 'delete'],
  },
  {
    label: 'Promos',
    name: 'promos',
    acl: ['create', 'read', 'update', 'delete'],
  },
  {
    label: 'Referrals',
    name: 'referrals',
    acl: ['create', 'read', 'update', 'delete'],
  },
  {
    label: 'Orders',
    name: 'orders',
    acl: ['create', 'read', 'update', 'delete'],
  },
  {
    label: 'Delivery Slots',
    name: 'deliverySlots',
    acl: ['create', 'read', 'update', 'delete'],
  },
  {
    label: 'Reviews',
    name: 'reviews',
    acl: ['read', 'update', 'delete'],
  },
  {
    label: 'Blog',
    name: 'posts',
    acl: ['create', 'read', 'update', 'delete'],
  },
  {
    label: 'Dashboard',
    name: 'dashboard',
    acl: [
      'no of orders',
      'revenue overview',
      'product sold',
      'monthly overview',
      'promotion overview',
      'top products',
      'delivery schedules',
      'inventory report',
    ],
  },
];

const RoleForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(({ ui }) => ui.isLoading);
  const roleData = useSelector(({ roles }) => roles.role);
  const { control, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    if (id) {
      dispatch(getRole(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id && roleData.modules) {
      setValue('name', roleData.name);

      Object.keys(roleData.modules).forEach((key) => {
        if (!(key in roleData.modules)) return;
        if (!roleData.modules[key]) return;
        roleData.modules[key].forEach((action) => {
          setValue(key, { [action]: true });
        });
      });
    }
  }, [roleData, id, setValue]);

  const onSubmit = (data) => {
    const role = {
      name: data.name,
      modules: {},
    };

    Object.keys(data).forEach((module) => {
      if (module === 'name') return;
      role.modules[module] = [];
      Object.keys(data[module]).forEach((action) => {
        if (!data[module][action]) return;
        role.modules[module].push(action);
      });
    });

    if (!id) {
      dispatch(createRole(role));
    } else {
      role.audit = roleData.audit;
      dispatch(updateRole(roleData.id, role));
    }
  };

  const handleDelete = () => {
    dispatch(deleteRole(roleData.id));
  };

  return (
    <MainStage title={!id ? 'New' : 'Update'} subtitle="Roles" to="/roles">
      <Row>
        <Col lg="8">
          <FormCard
            title="Enter role details"
            button={{ theme: 'primary', text: 'Save', isLoading }}
            onSubmit={handleSubmit(onSubmit)}
            onDelete={id ? handleDelete : null}
          >
            <Row form>
              <FormGroup
                name="name"
                label="Name"
                control={control}
                rules={{ required: 'Required field' }}
                col={{ md: '6' }}
                errors={errors}
                defaultValue=""
              />
            </Row>
            <label className="mb-3">Permissions</label>
            <div className="table-responsive">
              <table className="table table-sm">
                <tbody>
                  {modules.map(({ label, name, acl }) => (
                    <tr key={name} className="d-flex border-0">
                      <td width="40%">
                        <b>{label}</b>
                      </td>
                      {acl.map((action, index) => (
                        <td key={`${name}-${action}-${index}`}>
                          <FormGroup
                            label={_.capitalize(action)}
                            type="checkbox"
                            control={control}
                            name={`${name}.${action}`}
                            defaultValue={false}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </FormCard>
        </Col>
        {id && (
          <Col lg="4">
            <Card small>
              <CardHeader className="border-bottom">Assigned To</CardHeader>
              <CardBody>
                <ListGroup>
                  {roleData.admins &&
                    roleData.admins.map((admin) => (
                      <ListGroupItem key={admin.id}>
                        <Link to={`/admins/${admin.id}`}>
                          {admin.name} <small>{admin.email}</small>
                        </Link>
                      </ListGroupItem>
                    ))}
                  {roleData.admins && roleData.admins.length === 0 && (
                    <Alert theme="info">Not assigned to anyone yet</Alert>
                  )}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </MainStage>
  );
};

export default RoleForm;
