import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MainNavbar, MainSidebar, MainFooter } from '../components/layout';
import Base from './Base';
import { auth as firebaseAuth } from '../firebase';
import { getToken, setToken } from '../utils/auth';
import { redirect } from '../store/actions/ui';

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  const redirectTo = useSelector(({ ui }) => ui.redirectTo);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      const token = getToken();
      if (!token || !user) return;
      const expUnix = moment(token.exp).unix();
      const currentUnix = moment().unix();
      if (currentUnix >= expUnix && user)
        user.getIdTokenResult(true).then((newToken) => {
          setToken(newToken);
        });
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!redirectTo) return;
    history.push(redirectTo);
    dispatch(redirect(''));
  }, [redirectTo, history, dispatch]);

  return (
    <Base>
      <Container fluid>
        <Row>
          <MainSidebar />
          <Col
            className="main-content p-0"
            lg={{ size: 10, offset: 2 }}
            md={{ size: 9, offset: 3 }}
            sm="12"
            tag="main"
          >
            {!noNavbar && <MainNavbar />}
            {children}
            {!noFooter && <MainFooter />}
          </Col>
        </Row>
      </Container>
    </Base>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  noNavbar: PropTypes.bool,
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default DefaultLayout;
