import React from 'react';
import PropTypes from 'prop-types';

const AtomePaymentInfo = ({ payment, separator }) => {
  const sep = separator === 'lineBreak' ? <br /> : <> </>;
  const { remarks, ipAddress } = payment;

  return (
    <div>
      {remarks.toLowerCase().includes('via')
        ? remarks
        : `Payment via ${remarks}`}
      {process.env.REACT_APP_ATOME_MERCHANT_PORTAL_URL && (
        <>
          {sep}
          <a
            href={process.env.REACT_APP_ATOME_MERCHANT_PORTAL_URL}
            target="_blank"
            rel="noreferrer"
          >
            {process.env.REACT_APP_ATOME_MERCHANT_PORTAL_URL}
          </a>
        </>
      )}
      {ipAddress && (
        <>
          {sep}
          Customer IP: {ipAddress}
        </>
      )}
    </div>
  );
};

AtomePaymentInfo.propTypes = {
  payment: PropTypes.shape({
    type: PropTypes.string,
    remarks: PropTypes.string,
    ipAddress: PropTypes.string,
  }),
  separator: PropTypes.oneOf(['lineBreak', 'space']),
};

AtomePaymentInfo.defaultProps = {
  payment: {},
  separator: 'lineBreak',
};

export default AtomePaymentInfo;
