import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Badge, CardBody } from 'shards-react';
import moment from 'moment';

import avatar from '../../images/avatars/empty-avatar.svg';

const PostCard = ({ post }) => {
  const getExcerpt = (content, limit = 50) => {
    if (!content) return '';

    const regex = new RegExp('(<([^>]+)>)', 'gi');
    const filter = content.replace(regex, '');
    const wordsarr = filter.split(' ');

    if (wordsarr.length < limit) {
      return filter;
    }
    let result = '';

    for (let i = 0; i < limit; i++) {
      result = `${result} ${wordsarr[i]} `;
    }

    return `${result}...`;
  };

  return (
    <Card small className="card-post card-post--1">
      <div
        className="card-post__image"
        style={{ backgroundImage: `url(${post.thumbnail})` }}
      >
        <div className="d-flex p-2">
          {post.isFeatured && (
            <div>
              <Badge pill className="bg-success">
                Featured
              </Badge>
            </div>
          )}
          <div className="ml-auto d-flex flex-wrap">
            {post.categories &&
              post.categories.map((c) => {
                return (
                  <Badge key={c.id} pill className="bg-dark ml-1">
                    {c.name}
                  </Badge>
                );
              })}
          </div>
        </div>
        <div className="card-post__author d-flex">
          <Link
            to={`/admins/${post.author.id}`}
            className="card-post__author-avatar card-post__author-avatar--small bg-white"
            style={{
              backgroundImage: `url('${
                post.author.displayPic ? post.author.displayPic : avatar
              }')`,
            }}
          >
            Written by {post.author.name}
          </Link>
        </div>
      </div>
      <CardBody>
        <h5 className="card-title">
          <Link to={`/posts/${post.id}`} className="text-fiord-blue">
            {post.title}
          </Link>
        </h5>
        <p className="card-text d-inline-block mb-3">
          {getExcerpt(post.content)}
        </p>
        {post.publishedOn && (
          <span className="text-muted">
            {moment.unix(post.publishedOn.seconds).format('lll')}
          </span>
        )}
      </CardBody>
    </Card>
  );
};

PostCard.propTypes = {
  post: PropTypes.shape({
    thumbnail: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })),
    author: PropTypes.shape({
      displayPic: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    id: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    publishedOn: PropTypes.shape({
      seconds: PropTypes.number,
    }),
    isFeatured: PropTypes.bool,
  }).isRequired,
};

export default PostCard;
