import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DatePicker,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Collapse,
  Badge,
} from 'shards-react';
import moment from 'moment';
import { warning } from 'react-toastify-redux';
import styled from 'styled-components';
import MaterialIcon from '../../components/common/MaterialIcon';
import { getTopProductsSold } from '../../store/actions/reports';

const CollapseIcon = styled(MaterialIcon)`
  cursor: pointer !important;
  position: absolute !important;
  right: 8px !important;
  top: 10px !important;
  font-size: 20px !important;
`;

const HeaderUl = styled.ul`
   {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #f9f9f9;
    margin: 0;
    border-bottom: 1px solid #e1e5eb;
    font-weight: bold;
    > li {
      padding: 7px 15px;
      width: 30%;
      &:first-child {
        width: 40%;
      }
    }
  }
`;
const BodyUl = styled.ul`
   {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #e1e5eb;
    > li {
      padding: 9px 15px;
      width: 30%;
      &:first-child {
        width: 40%;
      }
    }
  }
`;

const VariantUl = styled.ul`
   {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    background-color: #f9f9f9;
    font-size: 13px;
    > li {
      padding: 7px 15px;
      width: 30%;
      &:first-child {
        width: 40%;
      }
      span {
        text-wrap: wrap;
      }
    }
  }
`;

const TopProductSoldBoard = () => {
  const dispatch = useDispatch();
  const topProductsSoldData = useSelector(
    ({ reports: { topProductsSold } }) => topProductsSold
  );
  const [productsSold, setProductsSold] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate());

  useEffect(() => {
    if (topProductsSoldData) {
      setProductsSold(topProductsSoldData.orders);
      setStartDate(topProductsSoldData.dateFrom);
      setEndDate(topProductsSoldData.dateTo);
    }
  }, [topProductsSoldData]);

  useEffect(() => {
    if (!topProductsSoldData) dispatch(getTopProductsSold(startDate, endDate));
  }, []);

  const handleGetTopProductsSold = (from, to) => {
    const fromMoment = moment(from);
    const toMoment = moment(to);
    if (toMoment.diff(fromMoment, 'month') > 11)
      dispatch(warning('Date range must within 12 months'));
    else if (fromMoment.diff(toMoment) > 0)
      dispatch(warning('Invalid date range'));
    else dispatch(getTopProductsSold(from, to));
  };

  const handleStartDateChange = (value) => {
    setStartDate(new Date(value));
    handleGetTopProductsSold(value, endDate);
  };

  const handleEndDateChange = (value) => {
    setEndDate(new Date(value));
    handleGetTopProductsSold(startDate, value);
  };

  const handleSetCollapse = (index) => {
    productsSold[index].open = !productsSold[index].open;
    setProductsSold([...productsSold]);
  };

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Top Products - Items Sold</h6>
      </CardHeader>
      <CardBody className="p-0">
        <HeaderUl>
          <li>Product</li>
          <li>Collection</li>
          <li>Qty</li>
        </HeaderUl>
        {productsSold.length > 0 &&
          productsSold.map((p, index) => {
            return (
              <div
                key={p.id}
                className="position-relative"
                onClick={() => handleSetCollapse(index)}
              >
                <CollapseIcon icon={p.open ? 'expand_less' : 'expand_more'} />
                <BodyUl>
                  <li>{p.name}</li>
                  <li>{p.collection}</li>
                  <li>{p.soldQty}</li>
                </BodyUl>
                <Collapse open={p.open} className="border-bottom">
                  {p.variants.map((v, i) => {
                    return (
                      <VariantUl key={`${p.id}-${v.type}-${i}`}>
                        <li>{v.type}</li>
                        <li>
                          <Badge theme="primary">
                            {(v.subVariants || [])
                              .filter((s) => s && s.value && s.key)
                              .map((s) => s.value)
                              .join(', ')}
                          </Badge>
                        </li>
                        <li>{v.soldQty}</li>
                      </VariantUl>
                    );
                  })}
                </Collapse>
              </div>
            );
          })}
      </CardBody>
      <CardFooter className="border-top">
        <div className="d-flex align-items-center">
          <div className="ml-2">
            <DatePicker
              size="sm"
              selected={startDate}
              style={{ maxWidth: '130px' }}
              onChange={handleStartDateChange}
              placeholderText="Start Date"
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <MaterialIcon className="mx-2" icon="sync_alt" />
          <div>
            <DatePicker
              size="sm"
              selected={endDate}
              style={{ maxWidth: '130px' }}
              onChange={handleEndDateChange}
              placeholderText="End Date"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default TopProductSoldBoard;
