import { DeliverySlotActionTypes } from '../actions/deliverySlots';

const initialState = {
  availableDeliverySlots: {
    items: [],
    paging: {
      page: 1,
      size: 1000,
      orderBy: 'date',
      order: 'desc',
    },
  },
  deliverySlots: {
    items: [],
    paging: {
      page: 1,
      size: 50,
      orderBy: 'date',
      order: 'desc',
    },
  },
  deliverySlot: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {DeliverySlotActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DeliverySlotActionTypes.GET_AVAILABLE_DELIVERY_SLOTS:
      return {
        ...state,
        availableDeliverySlots: { ...action.availableDeliverySlots },
      };
    case DeliverySlotActionTypes.GET_DELIVERY_SLOTS:
      return { ...state, deliverySlots: { ...action.deliverySlots } };
    case DeliverySlotActionTypes.GET_DELIVERY_SLOT:
      return { ...state, deliverySlot: { ...action.deliverySlot } };
    default:
      return state;
  }
}
