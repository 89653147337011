import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Badge } from 'shards-react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { SmartTable, SearchCard, FormGroup } from '../../components/common';
import { getDeliverySlots } from '../../store/actions/deliverySlots';
import { MainStage } from '../../components/layout';

const DeliverySlotListing = () => {
  const history = useHistory();
  const { control } = useForm();
  const [dateFrom, setDateFrom] = useState(moment().toDate());
  const [dateTo, setDateTo] = useState(moment().add(1, 'month').toDate());
  const dispatch = useDispatch();
  const { items, paging } = useSelector(
    ({ deliverySlots: { deliverySlots } }) => deliverySlots
  );

  const columns = [
    {
      header: 'Date',
      accessor: 'date',
      cell: (row, data) => (
        <Link to={`/delivery-slots/${data.id}`}>
          {moment.unix(row.value.seconds).format('D MMM YYYY')}
        </Link>
      ),
    },
    {
      header: 'Available Slots',
      accessor: 'slots',
      cell: (row, data) => (
        <Badge theme="success" className="ml-2">
          <strong>
            {row.value.filter((v) => data.maxPerSlot > v.orders.length).length}
          </strong>
        </Badge>
      ),
    },
    {
      header: 'Scheduled Slots',
      accessor: 'slots',
      cell: (row) => (
        <Badge theme="danger" className="ml-2">
          <strong>{row.value.filter((v) => v.orders.length > 0).length}</strong>
        </Badge>
      ),
    },
  ];

  const handleSearchDeliverySlots = (page) => {
    const params = {
      dateFrom,
      dateTo,
    };
    dispatch(getDeliverySlots({ page, params }));
  };
  useEffect(() => {
    handleSearchDeliverySlots();
    // eslint-disable-next-line
  }, []);

  return (
    <MainStage title="Overview" subtitle="Delivery Slots">
      <Row>
        <Col lg="12">
          <SearchCard title="Search delivery slots">
            <Row>
              <FormGroup
                col={{ lg: '3' }}
                type="datepicker"
                name="dateFrom"
                label="Order Date From"
                control={control}
                defaultValue={dateFrom}
                onChange={(selected) => setDateFrom(selected)}
                dateProps={{ maxDate: dateTo }}
              />
              <FormGroup
                col={{ lg: '3' }}
                type="datepicker"
                name="dateTo"
                label="Order Date To"
                control={control}
                defaultValue={dateTo}
                onChange={(selected) => setDateTo(selected)}
                dateProps={{ minDate: dateFrom }}
              />
            </Row>
            <div className="d-flex">
              <Button
                type="button"
                theme="success"
                onClick={handleSearchDeliverySlots}
              >
                Search
              </Button>
            </div>
          </SearchCard>
        </Col>
        <Col>
          <SmartTable
            title="Active Delivery Slots"
            data={items}
            columns={columns}
            onCreate={() => history.push('/delivery-slots/new')}
            page={paging.page}
            onPageChange={(page) => {
              handleSearchDeliverySlots(page);
            }}
          />
        </Col>
      </Row>
    </MainStage>
  );
};

export default DeliverySlotListing;
