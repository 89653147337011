import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col } from 'shards-react';

const Title = styled.span`
  font-size: 0.7rem;
  display: block;
`;

const CustomerRefereeDataBlock = ({ title, children, className }) => {
  return (
    <Col className={`p-4 text-center ${className}`}>
      <Title>{title}</Title>
      <div className="h-100  d-flex flex-column justify-content-center align-items-center">
        {children}
      </div>
    </Col>
  );
};

CustomerRefereeDataBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

CustomerRefereeDataBlock.defaultProps = {
  className: 'border-right',
};

export default CustomerRefereeDataBlock;
