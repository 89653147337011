import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormInput,
  FormGroup as ShardsFormGroup,
} from 'shards-react';

const PromoModal = ({ onApply, open, onClose }) => {
  const [promoCode, setPromoCode] = useState('');
  const isLoading = useSelector(({ ui }) => ui.isLoading);

  const handleApplyPromo = () => {
    if (!promoCode) return;
    onApply(promoCode);
    setPromoCode('');
  };

  return (
    <Modal centered open={open} toggle={onClose}>
      <ModalHeader>Apply coupon</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg="12">
            <ShardsFormGroup>
              <label>Promo Code</label>
              <FormInput
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
            </ShardsFormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          disabled={isLoading}
          theme="primary"
          onClick={handleApplyPromo}
        >
          Apply
        </Button>
        <Button
          type="button"
          disabled={isLoading}
          theme="secondary"
          onClick={onClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PromoModal.propTypes = {
  onApply: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

PromoModal.defaultProps = {
  onApply: () => {},
  onClose: () => {},
};

export default PromoModal;
