import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const Icon = styled.i`
  font-size: ${({ size }) => size || 'inherit'}px !important;
`;

const MaterialIcon = ({ className, icon, size, onClick }) => {
  const classes = classNames(className, 'material-icons');

  return (
    <Icon className={classes} size={size} onClick={onClick}>
      {icon}
    </Icon>
  );
};

MaterialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

MaterialIcon.defaultProps = {
  className: '',
  size: 0,
  onClick: () => {},
};

export default MaterialIcon;
