import { error, success } from 'react-toastify-redux';
import moment from 'moment';
import {
  ordersCollection,
  deliverySlotsCollection,
  archivesCollection,
  firebase,
  firestore,
} from '../../firebase';
import { startLoading, stopLoading, redirect } from './ui';
import { getAdmin } from '../../utils/auth';

export const DeliverySlotActionTypes = {
  GET_AVAILABLE_DELIVERY_SLOTS: 'GET_AVAILABLE_DELIVERY_SLOTS',
  GET_DELIVERY_SLOTS: 'GET_DELIVERY_SLOTS',
  GET_DELIVERY_SLOT: 'GET_DELIVERY_SLOT',
  CREATE_DELIVERY_SLOT: 'CREATE_DELIVERY_SLOT',
  UPDATE_DELIVERY_SLOT: 'UPDATE_DELIVERY_SLOT',
  DELETE_DELIVERY_SLOT: 'DELETE_DELIVERY_SLOT',
};

/**
 * Retrieve multiple delivery slots from firebase
 * @param {object} paging
 * @param {string} paging.orderBy order by field name
 * @param {string} paging.order get desc or asc
 * @param {number} paging.page current page
 * @param {number} paging.size per page size
 */
export const getAvailableDeliverySlots = (paging = {}) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      if (!paging.orderBy) paging.orderBy = 'date';
      if (!paging.order) paging.order = 'asc';
      if (!paging.page) paging.page = 1;
      if (!paging.size) paging.size = 1000;

      const response = await deliverySlotsCollection
        .where('date', '>=', moment().startOf('year').toDate())
        .limit(paging.size)
        .get();

      const items = [];

      response.forEach((doc) => {
        const data = doc.data();
        if (!data.slots) return;
        data.slots.map((s) => {
          s.count = s.orders.length;
          return s;
        });
        data.slots = data.slots.filter((s) => s.count < data.maxPerSlot);
        if (data.slots.length > 0) items.push({ id: doc.id, ...data });
      });

      dispatch({
        type: DeliverySlotActionTypes.GET_AVAILABLE_DELIVERY_SLOTS,
        availableDeliverySlots: { items, paging },
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve multiple delivery slots from firebase
 * @param {string} paging.orderBy order by field name
 * @param {string} paging.order get desc or asc
 * @param {number} paging.page current page
 * @param {number} paging.size per page size
 */
export const getDeliverySlots = ({ orderBy, order, page, size, params }) => {
  return async (dispatch, getState) => {
    try {
      const {
        deliverySlots: { deliverySlots },
      } = getState();

      orderBy = orderBy || deliverySlots.paging.orderBy;
      order = order || deliverySlots.paging.order;
      page = page !== null ? page : deliverySlots.paging.page;
      size = size || deliverySlots.paging.size;
      params = params || deliverySlots.paging.params;

      // if nothing has changed, return
      if (
        deliverySlots.items.length - 1 >= page &&
        deliverySlots.paging.page === page &&
        deliverySlots.paging.order === order &&
        deliverySlots.paging.orderBy === orderBy &&
        deliverySlots.paging.size === size &&
        deliverySlots.paging.params === params
      )
        return;

      let items = [...deliverySlots.items];
      let { lastSnapshot } = deliverySlots;
      const paging = { page, order, orderBy, size, params };
      let { dateFrom, dateTo } = params;

      // if the page already has delivery slots data just update the paging
      if (
        deliverySlots.items.length - 1 >= page &&
        deliverySlots.paging.page !== page
      ) {
        dispatch({
          type: DeliverySlotActionTypes.GET_DELIVERY_SLOTS,
          deliverySlots: {
            items,
            paging,
            lastSnapshot,
          },
        });
        return;
      }

      dispatch(startLoading());

      let query = deliverySlotsCollection.orderBy(orderBy, order);
      if (dateFrom && dateTo) {
        dateFrom = moment(dateFrom).startOf('day').toDate();
        dateTo = moment(dateTo).endOf('day').toDate();
        query = query.where('date', '>=', dateFrom).where('date', '<=', dateTo);
      }

      if (
        deliverySlots.paging.page !== page &&
        deliverySlots.items.length - 1 < page
      ) {
        query = query.startAfter(lastSnapshot);
      } else {
        items = [];
        paging.page = 0;
      }

      const documentSnapshots = await query.limit(size).get();
      const results = [];

      documentSnapshots.docs.map(async (doc) => {
        const data = doc.data();
        results.push({ id: doc.id, ...data });
      });

      lastSnapshot = documentSnapshots.docs[documentSnapshots.docs.length - 1];

      if (results.length > 0) {
        items.push(results);

        dispatch({
          type: DeliverySlotActionTypes.GET_DELIVERY_SLOTS,
          deliverySlots: {
            items,
            paging,
            lastSnapshot,
          },
        });
      }
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve single delivery slot from firebase
 * @param {string} id get by object id
 */
export const getDeliverySlot = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const doc = await deliverySlotsCollection.doc(id).get();
      const data = doc.data();

      if (data.slots) {
        await Promise.all(
          data.slots.map(async (s) => {
            if (s.refNum) {
              const orderRef = await ordersCollection
                .where('refNum', '==', s.refNum)
                .limit(1)
                .get();
              if (orderRef.docs.length) s.orderId = orderRef.docs[0].id;
            }
            return s;
          })
        );
      }

      const deliverySlot = {
        id: doc.id,
        ...data,
      };

      dispatch({
        type: DeliverySlotActionTypes.GET_DELIVERY_SLOT,
        deliverySlot,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Create multiple slots
 * @param {Array} DeliverySlots
 */
export const createDeliverySlots = (items) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      if (!items.length) return;

      const batch = firestore.batch();
      await Promise.all(
        items.map(async (item) => {
          // audit
          item.audit = {
            createdBy: getAdmin().name,
            createdOn: firebase.firestore.FieldValue.serverTimestamp(),
          };

          const existRef = await deliverySlotsCollection
            .where('date', '==', item.date)
            .get();
          if (existRef.docs.length) return;

          batch.set(deliverySlotsCollection.doc(), item);
        })
      );
      await batch.commit();

      dispatch(redirect(`/delivery-slots`));
      setTimeout(() => dispatch(success('Successfully created!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

export const updateDeliverySlot = (id, item) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      item.audit = {
        ...item.audit,
        modifiedBy: getAdmin().name,
        modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      item.slots.map((s) => {
        delete s.orderId;
        return s;
      });

      await deliverySlotsCollection.doc(id).set(item);
      setTimeout(() => dispatch(success('Successfully updated!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Delete delivery slot
 * @param {string} id
 */
export const deleteDeliverySlot = (id) => {
  return async (dispatch) => {
    try {
      const deliverySlotRef = await deliverySlotsCollection.doc(id).get();
      const deliverySlot = deliverySlotRef.data();

      dispatch(startLoading());

      deliverySlot.audit = {
        ...deliverySlot.audit,
        deletedBy: getAdmin().name,
        deletedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const batch = firestore.batch();
      batch.delete(deliverySlotsCollection.doc(id));
      batch.update(archivesCollection.doc('deliverySlots'), {
        [id]: deliverySlot,
      });
      await batch.commit();

      dispatch({
        type: DeliverySlotActionTypes.DELETE_DELIVERY_SLOT,
      });
      dispatch(redirect('/delivery-slots'));

      setTimeout(() => dispatch(success('Successfully deleted!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
