import React from 'react';
import { Navbar, NavbarBrand } from 'shards-react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { MaterialIcon } from '../../common';
import { toggleMenu } from '../../../store/actions/ui';

const Logo = styled.img`
  width: 70%;
  margin: 0 auto;
  display: block;
  max-width: 180px;
`;

const SidebarMainNavbar = () => {
  const dispatch = useDispatch();

  return (
    <div className="main-navbar">
      <Navbar
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        type="light"
      >
        <NavbarBrand
          className="w-100 mr-0"
          href="#"
          style={{ lineHeight: '25px' }}
        >
          <div className="d-table m-auto">
            <Logo
              id="main-logo"
              src="/images/logo.svg"
              alt="Shards Dashboard"
            />
          </div>
        </NavbarBrand>
        {/* eslint-disable-next-line */}
        <a
          className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
          onClick={() => dispatch(toggleMenu())}
        >
          <MaterialIcon icon="&#xE5C4;" />
        </a>
      </Navbar>
    </div>
  );
};

export default SidebarMainNavbar;
