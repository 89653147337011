import moment from 'moment';

import {
  PromoTypes,
  DiscountAppliesTo,
  DiscountMinTypes,
  DiscountTypes,
  PromoShippingTypes,
  PromoBundleBuyTypes,
  PromoBundleGetTypes,
  PromoBundleGetSubTypes,
} from '../constants';

/**
 * Helps to consolidate summary for discount promo type
 * @private
 * @param {Object} promo
 * @returns {string[]}
 */
const _getDiscountPromoSummary = (promo) => {
  const builder = [];

  const discount = promo[promo.type];
  let percentTypeSuffix = '';

  switch (discount.appliesTo) {
    case DiscountAppliesTo.ENTIRE:
      builder.push('Applies to entire order.');
      percentTypeSuffix = 'entire order';
      break;
    case DiscountAppliesTo.COLLECTIONS:
      if (discount.collections && Array.isArray(discount.collections)) {
        builder.push(
          `Applies to specific collections ${discount.collections.join(' ,')}.`
        );
      }
      percentTypeSuffix = 'valid selected collections';
      break;
    case DiscountAppliesTo.PRODUCTS:
      if (discount.products && Array.isArray(discount.products)) {
        builder.push(
          `Applies to specific products ${discount.products
            .map((p) => `${p.name} (${p.variant})`)
            .join('/ ')}.`
        );
      }
      percentTypeSuffix = 'valid selected products';
      break;
    default:
  }

  if (discount.minType === DiscountMinTypes.PURCHASE_AMOUNT) {
    builder.push(
      `Requires min. order amount $${discount.minValue.toFixed(2)}.`
    );
  } else if (discount.minType === DiscountMinTypes.PURCHASE_QTY) {
    builder.push(`Requires min. product qty of $${discount.minValue}.`);
  }

  builder.push(
    `Can be used ${
      discount.oncePerOrder ? 'once' : 'multiple times'
    } per order.`
  );

  if (discount.type === DiscountTypes.FIXED) {
    builder.push(
      `Discounted amount is fixed at $${discount.value.toFixed(2)}.`
    );
  } else if (discount.type === DiscountTypes.PERCENT) {
    builder.push(
      `Discounted amount is ${discount.value}% of ${percentTypeSuffix}.`
    );
  }

  return builder;
};

/**
 * Helps to consolidate summary for shipping promo type
 * @param {Object} promo
 * @returns {string[]}
 */
const _getShippingPromoSummary = (promo) => {
  const builder = [];
  const shipping = promo[promo.type];

  const prefix =
    shipping.minAmount > 0
      ? `Requires $${shipping.minAmount.toFixed(2)} to get free`
      : 'Free';

  builder.push(
    `${prefix} shipping for ${
      shipping.type === PromoShippingTypes.ALL
        ? 'all'
        : shipping.countries.join(', ')
    }.`
  );

  return builder;
};

/**
 * Helps to consolidate summary for bundle promo type
 * @param {Object} promo
 * @returns {string[]|string[][]}
 */
const _getBundlePromoSummary = (promo) => {
  const builder = [];
  const bundle = promo[promo.type];

  let buyText = '';
  let getText = '';

  bundle.buys.forEach((buy) => {
    let buyTextSuffix = '';

    if (buy.type === PromoBundleBuyTypes.COLLECTIONS) {
      buyTextSuffix = buy.collections.join(', ');
    } else {
      buyTextSuffix = buy.products
        .map((p) => `${p.name} (${p.variant})`)
        .join('/ ');
    }

    if (buyText === '') {
      buyText = `Buy ${buy.qty} ${buyTextSuffix}`;
    } else {
      buyText = `${buyText} and ${buy.qty} ${buyTextSuffix}`;
    }
  });

  bundle.gets.forEach((get) => {
    let getTextSuffix = '';

    if (get.type === PromoBundleGetTypes.COLLECTIONS) {
      getTextSuffix = get.collections.map((c) => `${c.name}`).join(', ');
    } else if (get.type === PromoBundleGetTypes.PRODUCTS) {
      getTextSuffix = get.products
        .map((p) => `${p.name} (${p.variant})`)
        .join('/ ');
    }

    let textFormat = '';

    if (get.type === PromoBundleGetTypes.DISCOUNT) {
      textFormat = `${
        get.subType === PromoBundleGetSubTypes.PERCENT
          ? `${get.value}%`
          : `$${get.value.toFixed(2)}`
      } off entire order`;
    } else {
      textFormat = `${get.qty} ${getTextSuffix} for ${
        get.subType === PromoBundleGetSubTypes.FREE ? 'free' : `${get.value}%`
      }`;
    }

    if (getText === '') {
      getText = `Get ${textFormat}`;
    } else {
      getText = `${getText} and ${textFormat}`;
    }
  });

  builder.push([buyText, getText]);
  builder.push(
    `Can be use ${
      bundle.usesPerOrder > 0 ? bundle.usesPerOrder : 'multiple'
    } times per order`
  );

  return builder;
};

/**
 * Generate the summary for a promotion code in plain english format
 * for better understanding
 * @param {Object} promo
 * @returns {string[]|string[][]}
 */
export const getPromoSummary = (promo) => {
  let builder = [];

  switch (promo.type) {
    case PromoTypes.DISCOUNT: {
      builder = [..._getDiscountPromoSummary(promo)];
      break;
    }
    case PromoTypes.SHIPPING:
      builder = [..._getShippingPromoSummary(promo)];
      break;
    case PromoTypes.BUNDLE:
      builder = [..._getBundlePromoSummary(promo)];
      break;
    default:
      builder.push(`Invalid promo type ${promo.type}.`);
  }

  if (promo.startOn) {
    builder.push(
      `Valid from ${moment.unix(promo.startOn.seconds).format('lll')} ${
        promo.endsOn
          ? `till ${moment.unix(promo.endsOn.seconds).format('lll')}.`
          : '.'
      }`
    );
  }

  if (promo.eligibility) {
    builder.push(`Eligible for ${promo.eligibility.type} users.`);
  }

  if (promo.usageLimits) {
    const { canStack, excludeSale, oncePerUser, totalUses } = promo.usageLimits;

    builder.push(
      `Can${
        canStack ? '' : 'not'
      } be used in conjunction with other promo codes.`
    );

    builder.push(
      `Can${
        excludeSale ? '' : 'not'
      } be used with orders that includes sale items.`
    );

    builder.push(
      `Can be used ${oncePerUser ? 'only once' : 'multiple times'} per user.`
    );

    builder.push(
      `Can be used ${totalUses > 0 ? totalUses : '∞'} times in total.`
    );
  }

  return builder;
};
