import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'shards-react';
import styled from 'styled-components';
import { objectAccessor } from '../../utils';
import { useDebounce } from '../../utils/hooks';
import IconButton from './IconButton';
import MaterialIcon from './MaterialIcon';

const HeaderCell = styled.th`
  border: 0;
  cursor: ${({ sort }) => (sort ? 'pointer' : 'initial')};
  text-decoration: ${({ sort }) => (sort ? 'underline' : 'initial')};
  width: ${({ width }) => width || 'initial'};
  vertical-align: center;

  i {
    font-size: 1rem;
    font-weight: bold;
  }
`;

const SmartTable = ({
  title,
  columns,
  page,
  data,
  onCreate,
  onDelete,
  onFilter,
  onSorted,
  onPageChange,
  defaultFilter,
  defaultSortFilter,
}) => {
  const [sortValues, setSortValues] = useState({ [defaultSortFilter]: 'asc' });
  const [searchTerm, setSearchTerm] = useState(defaultFilter);
  const isInitialMount = useRef(true);
  const debounceSearchTerm = useDebounce(searchTerm, 500);

  const handleSort = (accessor) => {
    let newSortValues = { ...sortValues };
    if (accessor in newSortValues) {
      newSortValues[accessor] =
        newSortValues[accessor] === 'asc' ? 'desc' : 'asc';
    } else {
      newSortValues = { [accessor]: 'asc' };
    }
    setSortValues(newSortValues);
    return onSorted(newSortValues);
  };

  const renderData = () => {
    if (page !== null) {
      return data.length > page ? data[page] : [];
    }
    return data;
  };

  useEffect(() => {
    if (onFilter && !isInitialMount.current) {
      onFilter(debounceSearchTerm.toLocaleLowerCase());
    } else {
      isInitialMount.current = false;
    }
    // eslint-disable-next-line
  }, [debounceSearchTerm]);

  return (
    <>
      <Card small className="mb-4">
        {(title || onFilter || onCreate || onDelete) && (
          <CardHeader className="border-bottom d-flex align-items-center">
            {title && <h6 className="m-0">{title}</h6>}
            <div className="d-flex ml-auto">
              {onFilter && (
                <InputGroup seamless>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <MaterialIcon icon="search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                  />
                </InputGroup>
              )}
              {onCreate && (
                <IconButton
                  className="ml-3"
                  theme="success"
                  onClick={onCreate}
                  icon="add"
                />
              )}
              {onDelete && (
                <IconButton
                  className="ml-3"
                  theme="danger"
                  onClick={onDelete}
                  icon="delete_outline"
                />
              )}
            </div>
          </CardHeader>
        )}
        <CardBody className="p-0 pb-3">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  {columns.map(({ header, sort, accessor, width }, key) => (
                    <HeaderCell
                      key={key}
                      scope="col"
                      sort={sort}
                      width={width}
                      onClick={() => {
                        if (!sort) return;
                        handleSort(accessor);
                      }}
                    >
                      {header}
                      {sort && (
                        <>
                          {!(accessor in sortValues) && (
                            <MaterialIcon icon="unfold_more" />
                          )}
                          {accessor in sortValues &&
                            sortValues[accessor] === 'desc' && (
                              <MaterialIcon icon="expand_less" />
                            )}
                          {accessor in sortValues &&
                            sortValues[accessor] === 'asc' && (
                              <MaterialIcon icon="expand_more" />
                            )}
                        </>
                      )}
                    </HeaderCell>
                  ))}
                </tr>
              </thead>
              <tbody>
                {renderData().map((d, i) => (
                  <tr key={i}>
                    {columns.map(({ accessor, cell }, j) => {
                      const row = { value: objectAccessor(d, accessor) };

                      return (
                        <td key={j}>
                          {cell && cell(row, d)}
                          {!cell && row.value.toString()}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
      {page !== null && (
        <div className="d-flex justify-content-between align-items-center">
          <IconButton
            onClick={() => onPageChange(page - 1)}
            disabled={page < 1}
            icon="navigate_before"
          >
            Previous
          </IconButton>
          <div className="mx-3">
            {data.map((_, i) => (
              <Button
                squared
                theme={i === page ? 'primary' : 'light'}
                key={i}
                disabled={i === page}
                onClick={() => onPageChange(i)}
              >
                {i + 1}
              </Button>
            ))}
          </div>
          <IconButton
            icon="navigate_next"
            position="before"
            onClick={() => onPageChange(page + 1)}
          >
            Next
          </IconButton>
        </div>
      )}
    </>
  );
};

SmartTable.propTypes = {
  title: PropTypes.string,
  onFilter: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onSorted: PropTypes.func,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      sort: PropTypes.bool,
      accessor: PropTypes.string,
      cell: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  ]),
  defaultSortFilter: PropTypes.string,
  defaultFilter: PropTypes.string,
};

SmartTable.defaultProps = {
  title: '',
  page: null,
  onPageChange: null,
  onFilter: null,
  onCreate: null,
  onDelete: null,
  onSorted: null,
  data: [],
  defaultSortFilter: '',
  defaultFilter: '',
};

export default SmartTable;
