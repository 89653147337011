import { error, success } from 'react-toastify-redux';
import { startLoading, stopLoading, redirect } from './ui';
import {
  reviewsCollection,
  archivesCollection,
  firestore,
  firebase,
} from '../../firebase';
import { getFirebasePagingQuery } from '../../utils';

export const ReviewActionTypes = {
  GET_REVIEWS: 'GET_REVIEWS',
  GET_REVIEW: 'GET_REVIEW',
  UPDATE_REVIEW: 'UPDATE_REVIEW',
  DELETE_REVIEW: 'DELETE_REVIEW',
};

/**
 * Retrieve multiple reviews from firebase
 * @param {Object} options
 * @param {string} options.orderBy order by field name
 * @param {string} options.order desc or asc order
 * @param {number} options.page the current page number
 * @param {number} options.size per page size
 * @param {string} options.filter search filter
 */
export const getReviews = (options) => {
  return async (dispatch, getState) => {
    try {
      const {
        reviews: { reviews },
      } = getState();

      dispatch(startLoading());

      const result = await getFirebasePagingQuery(
        options,
        reviews,
        reviewsCollection
      );

      if (!result) return;

      dispatch({
        type: ReviewActionTypes.GET_REVIEWS,
        reviews: {
          ...result,
        },
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve a single review document from firebase
 * @param {string} id
 */
export const getReview = (id) => {
  return async (dispatch) => {
    let review = {};

    try {
      dispatch(startLoading());

      const reviewRef = await reviewsCollection.doc(id).get();
      review = { id: reviewRef.id, ...reviewRef.data() };
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
      dispatch({
        type: ReviewActionTypes.GET_REVIEW,
        review,
      });
    }
  };
};

/**
 * Update existing review
 * @param {string} id
 * @param {Review} review
 */
export const updateReview = (id, { rating, display, content, submittedOn }) => {
  return async (dispatch, getState) => {
    const {
      auth: { admin },
      reviews: { review, reviews },
    } = getState();

    try {
      dispatch(startLoading());

      // make sure display is boolean and not string type
      if (typeof display !== 'boolean') {
        display = display === 'true';
      }

      // update audit
      const audit = {
        ...review.audit,
        updatedBy: admin.name,
        updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      // invoke firebase update
      await reviewsCollection
        .doc(id)
        .update({ audit, rating, display, content, submittedOn });

      const updatedReview = await reviewsCollection.doc(id).get();
      const updatedReviewData = updatedReview.data();

      // update review in store
      review.audit = audit;
      review.rating = parseFloat(rating);
      review.display = display;
      review.content = content;
      review.submittedOn = updatedReviewData.submittedOn;

      // update reviews in store
      reviews.items.forEach((items) => {
        for (let i = 0; i < items.length; ++i) {
          if (items[i].id !== id) continue;
          items[i] = review;
        }
      });

      dispatch(success('Successfully updated!'));
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
      dispatch({
        type: ReviewActionTypes.UPDATE_REVIEW,
        review,
        reviews,
      });
    }
  };
};

/**
 * Delete review and move to archive collection
 * @param {string} id
 */
export const deleteReview = (id) => {
  return async (dispatch, getState) => {
    try {
      const {
        auth: { admin },
        reviews: { review },
      } = getState();

      dispatch(startLoading());

      review.audit = {
        ...review.audit,
        deletedBy: admin.name,
        deletedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const batch = firestore.batch();
      batch.delete(reviewsCollection.doc(id));
      batch.update(archivesCollection.doc('reviews'), { [id]: review });

      await batch.commit();

      dispatch({
        type: ReviewActionTypes.DELETE_REVIEW,
      });
      dispatch(getReviews({}));
      dispatch(redirect('/reviews'));
      setTimeout(() => dispatch(success('Successfully deleted!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
