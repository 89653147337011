import { createGlobalStyle } from 'styled-components';

// Important note: the side bar has a z-index of 1070

export const Theme = createGlobalStyle`
  .cursor-pointer {
    cursor: pointer;
  }

  .toast-custom {
    .Toastify__toast {
      border-radius: .5rem;
      align-items: center;
      min-height: 50px;
      padding: 8px 16px;
    }

    .Toastify__close-button {
      align-self: inherit;
    }
  }

  .react-confirm-alert-overlay {
    z-index: 1071;
  }

  
  @media (max-width: 768px) {
    .form-table{
      .form-control {
        min-width: 80px;
      }
    }
  }
  .form-table{
    font-size: 14px;
    .btn {
      padding: 0.3rem 0.4rem 0.4rem 0.4rem !important;
    }
    .form-group{
      margin-bottom: 0;
      .form-control {
        padding: 0.375rem;
      }
      select.form-control {
        padding: 0.375rem;
        background-position: right 5px center;
        background-size: 7px;
      }
    }
    .react-datepicker {
      font-size: 0.7rem;
      .react-datepicker__navigation {
        top: 15px;
      }
      .react-datepicker__header {
        padding-top: 10px;
      }
      .react-datepicker__month {
        margin: 0;
        padding: 6px 10px !important;
      }
      .react-datepicker__day-name, 
      .react-datepicker__day, 
      .react-datepicker__time-name {
        width: 1.4rem !important;
        line-height: 1.4rem !important;
      }
    }
    tbody td {
      padding: .4rem .6rem;
      vertical-align: middle;
      img {
        height: 45px;
      }
    }
    thead th {
      padding: 0 .7rem;
      padding-bottom: 0.2rem;
      border-top: 0;
      border-bottom: 1px solid #dee2e6;
      font-weight: normal;
    }
    .menu-outer-top {
      min-width: 150px;
    }
  }

  .custom-table-responsive{
    overflow-x: auto;
    width: 100%;
    display: block;
    .table {
      width: max-content !important;
      th {
        .form-control {
          padding: 0 0.25rem;
          border: none;
          border-bottom: 1px solid #e1e5eb;
          border-radius: 0;
          color: #000;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  
  .RRT {
    &__container {
      box-shadow: 0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1);
      border-radius: .625rem;
    }

    &__panel {
      background-color: #fff;
      border: 0;
      border-bottom-left-radius: .625rem;
      border-bottom-right-radius: .625rem;
    }

    &__tabs {
      background: #e9ecef;
      border-top-left-radius: .625rem;
      border-top-right-radius: .625rem;
    }

    &__tab {
      border: 0;
      background: transparent;

      &--selected {
        background: #fff;
      }

      &--first {
        border-top-left-radius: .625rem;
      }
    }

    &__accordion {
      .RRT__tab {
        &--first {
          border-top-left-radius: .625rem;
          border-top-right-radius: .625rem;
        }

        &:last-child {
          border-top-right-radius: 0;
          border-bottom-left-radius: .625rem;
          border-bottom-right-radius: .625rem;
        }
      }

      .RRT__panel {
        border-radius: 0;

        &:last-child {
          border-bottom-left-radius: .625rem;
          border-bottom-right-radius: .625rem;
        }
      }
    }
  }

  .product-card {
    margin-bottom: 1.5rem;

    img {
      max-height: 100%;
      max-width: 100%;
    }

    iframe {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .form-group {
    input[type=file] {
      font-size: 9.5px;
    }
  }

  .hightlight-yellow {
    background-color: yellow;
  }

  .react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
      width:100%;
    }
  }
  .react-datepicker__input-time-container{
    .react-datepicker-time__caption{
      float: left;
      margin: 10px;
    }
    .react-datepicker-time__input-container{
      float: left;
      margin: 10px;
    }
  }
  .react-datepicker--time-only {
    .react-datepicker__header {
      padding-top:8px;
    }

    .react-datepicker__time-container {
      width: 110px;

      .react-datepicker__time .react-datepicker__time-box {
        width: 110px;
      }
    }

    .react-datepicker__time-container--with-today-button {
      left:0
    }
  }

  .menu-outer-top {
    .css-26l3qy-menu{
      position: absolute !important;
      z-index:9999 !important;
      top:30px !important;
      display:block !important;
    }
  }

  @media (min-width: 992px){
    .modal-xl {
      max-width: 1000px;
    }
    .modal {
      z-index: 9999;
    }
  }
  .react-confirm-alert-overlay {
    z-index: 10000;
  }
  .react-datepicker__month {
    cursor: pointer !important;
  }

  .bg-orders {
    background-color: #3598dc;
  }
  .bg-deliveries {
    background-color: #36c6d3;
  }
  .bg-customers {
    background-color: #e7505a;
  }
`;
