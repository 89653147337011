import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  DatePicker,
  Card,
  CardHeader,
  CardFooter,
  FormSelect,
} from 'shards-react';
import moment from 'moment';
import styled from 'styled-components';
import MaterialIcon from '../../components/common/MaterialIcon';
import { PeriodSelections } from '../../constants/index';
import {
  getNoOfOrders,
  getNoOfDeliveries,
  getNoOfCustomers,
} from '../../store/actions/reports';

const periodOptions = [];
Object.keys(PeriodSelections).forEach((key) => {
  const value = PeriodSelections[key];
  periodOptions.push({ value, label: value });
});

const CardWidget = styled(CardHeader)`
  .material-icons {
    font-size: 120px !important;
    position: absolute;
    left: -5px;
    top: 15px;
    color: #fff;
    opacity: 0.6;
  }
`;

const WidgetsOverview = () => {
  const dispatch = useDispatch();
  const { noOfOrders, noOfDeliveries, noOfCustomers } = useSelector(
    ({ reports }) => reports
  );
  const [orderPeriod, setOrderPeriod] = useState(PeriodSelections.THISMONTH);
  const [deliveryDate, setDeliveryDate] = useState(
    moment().startOf('day').toDate()
  );
  const [customerPeriod, setCustomerPeriod] = useState(
    PeriodSelections.THISMONTH
  );

  useEffect(() => {
    if (!noOfOrders) dispatch(getNoOfOrders(orderPeriod));
    if (!noOfDeliveries) dispatch(getNoOfDeliveries(deliveryDate));
    if (!noOfCustomers) dispatch(getNoOfCustomers(customerPeriod));
  }, []);

  useEffect(() => {
    if (noOfOrders) setOrderPeriod(noOfOrders.period);
  }, [noOfOrders]);

  useEffect(() => {
    if (noOfDeliveries) setDeliveryDate(noOfDeliveries.date);
  }, [noOfDeliveries]);

  useEffect(() => {
    if (noOfCustomers) setCustomerPeriod(noOfCustomers.period);
  }, [noOfCustomers]);

  const handleChangeOrderPeriod = (e) => {
    const { value } = e.target;
    setOrderPeriod(e.target.value);
    dispatch(getNoOfOrders(value));
  };

  const handleChangeCustomerPeriod = (e) => {
    const { value } = e.target;
    setCustomerPeriod(e.target.value);
    dispatch(getNoOfCustomers(value));
  };

  const handleChangeDeliveryDate = (value) => {
    setDeliveryDate(new Date(value));
    dispatch(getNoOfDeliveries(value));
  };

  return (
    <Row>
      <Col xs={12} lg={4} className="mb-4 mb-lg-0">
        <Card small className="overflow-hidden">
          <CardWidget className="p-4 bg-orders">
            <MaterialIcon icon="shopping_cart" />
            <div className="float-right text-center">
              <h4 className="mb-1 text-white">Orders</h4>
              <h3 className="mb-0 text-white">
                {noOfOrders ? noOfOrders.count : 0}
              </h3>
            </div>
          </CardWidget>
          <CardFooter className="border-top p-2">
            <div className="d-flex align-items-center">
              <div className="ml-2">
                <FormSelect
                  size="sm"
                  style={{ maxWidth: '130px' }}
                  value={orderPeriod}
                  onChange={handleChangeOrderPeriod}
                >
                  {periodOptions.map((p) => {
                    return (
                      <option key={p.value} value={p.value}>
                        {p.label}
                      </option>
                    );
                  })}
                </FormSelect>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
      <Col xs={12} lg={4} className="mb-4 mb-lg-0">
        <Card small>
          <CardWidget className="p-4 bg-deliveries overflow-hidden">
            <MaterialIcon icon="local_shipping" />
            <div className="float-right text-center">
              <h4 className="mb-1 text-white">Deliveries</h4>
              <h3 className="mb-0 text-white">
                {noOfDeliveries && noOfDeliveries.id ? (
                  <Link
                    className="text-white text-underline"
                    to={`/delivery-slots/${noOfDeliveries.id}`}
                  >
                    {noOfDeliveries.count}
                  </Link>
                ) : (
                  <span>
                    {noOfDeliveries && noOfDeliveries.count
                      ? noOfDeliveries.count
                      : 0}
                  </span>
                )}
              </h3>
            </div>
          </CardWidget>
          <CardFooter className="border-top p-2">
            <div className="d-flex align-items-center">
              <div className="ml-2">
                <DatePicker
                  size="sm"
                  selected={deliveryDate}
                  style={{ maxWidth: '130px' }}
                  onChange={handleChangeDeliveryDate}
                  placeholderText="Delivery Date"
                />
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
      <Col xs={12} lg={4}>
        <Card small className="overflow-hidden">
          <CardWidget className="p-4 bg-customers">
            <MaterialIcon icon="account_circle" />
            <div className="float-right text-center">
              <h4 className="mb-1 text-white">Customers</h4>
              <h3 className="mb-0 text-white">
                {noOfCustomers ? noOfCustomers.count : 0}
              </h3>
            </div>
          </CardWidget>
          <CardFooter className="border-top p-2">
            <div className="d-flex align-items-center">
              <div className="ml-2">
                <FormSelect
                  size="sm"
                  style={{ maxWidth: '130px' }}
                  value={customerPeriod}
                  onChange={handleChangeCustomerPeriod}
                >
                  {periodOptions.map((p) => {
                    return (
                      <option key={p.value} value={p.value}>
                        {p.label}
                      </option>
                    );
                  })}
                </FormSelect>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default WidgetsOverview;
