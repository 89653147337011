import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DatePicker,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'shards-react';
import moment from 'moment';
import { Polar } from 'react-chartjs-2';
import { warning } from 'react-toastify-redux';
import { getProducts } from '../../store/actions/products';
import { getProductSoldQty } from '../../store/actions/reports';
import MaterialIcon from '../../components/common/MaterialIcon';

const ProductSoldOverview = () => {
  const dispatch = useDispatch();
  const productSoldQtyData = useSelector(
    ({ reports: { productSoldQty } }) => productSoldQty
  );
  const productsData = useSelector(({ products: { products } }) => products);
  const [chartData, setChartData] = useState(null);
  const [productId, setProductId] = useState('pbo1XADg2PacQiL6Kydx');
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate());

  useEffect(() => {
    if (!productSoldQtyData)
      dispatch(getProductSoldQty(productId, startDate, endDate));
    dispatch(getProducts());
  }, []);

  useEffect(() => {
    if (productSoldQtyData) {
      const data = {
        labels: productSoldQtyData.labels,
        datasets: [
          {
            label: 'Product Sold Qty',
            data: productSoldQtyData.quantites,
            backgroundColor: productSoldQtyData.backgroundColors,
            borderColor: productSoldQtyData.borderColors,
            barPercentage: 0.8,
            borderWidth: 1,
          },
        ],
      };
      setChartData(data);
      setStartDate(productSoldQtyData.dateFrom);
      setEndDate(productSoldQtyData.dateTo);
      setProductId(productSoldQtyData.productId);
    }
  }, [productSoldQtyData]);

  const handleGetProductSoldQty = (id, from, to) => {
    const fromMoment = moment(from);
    const toMoment = moment(to);
    if (toMoment.diff(fromMoment, 'month') > 11)
      dispatch(warning('Date range must be within 12 months'));
    else if (fromMoment.diff(toMoment) > 0)
      dispatch(warning('Invalid date range'));
    else dispatch(getProductSoldQty(id, from, to));
  };

  const handleProductChange = (e) => {
    const { value } = e.target;
    setProductId(value);
    handleGetProductSoldQty(value, startDate, endDate);
  };

  const handleStartDateChange = (value) => {
    setStartDate(new Date(value));
    handleGetProductSoldQty(productId, value, endDate);
  };

  const handleEndDateChange = (value) => {
    setEndDate(new Date(value));
    handleGetProductSoldQty(productId, startDate, value);
  };

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Total Product Sold</h6>
      </CardHeader>
      <CardBody className="d-flex py-0">
        {chartData && <Polar data={chartData} />}
      </CardBody>
      <CardFooter className="border-top">
        <div className="d-flex align-items-center">
          <FormSelect
            size="sm"
            value={productId}
            style={{ maxWidth: '130px' }}
            onChange={handleProductChange}
          >
            {productsData.items
              .filter((p) => p.shippable)
              .map((p) => {
                return (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                );
              })}
          </FormSelect>
          <div className="ml-2">
            <DatePicker
              size="sm"
              selected={startDate}
              style={{ maxWidth: '130px' }}
              onChange={handleStartDateChange}
              placeholderText="Start Date"
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <MaterialIcon className="mx-2" icon="sync_alt" />
          <div>
            <DatePicker
              size="sm"
              selected={endDate}
              style={{ maxWidth: '130px' }}
              onChange={handleEndDateChange}
              placeholderText="End Date"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default ProductSoldOverview;
