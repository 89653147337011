import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'shards-react';

const TooltipCard = ({ id, children, message, placement }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);

  return (
    <>
      <div id={id}>{children}</div>
      <Tooltip
        target={`#${id}`}
        open={open}
        toggle={handleToggle}
        placement={placement}
      >
        {message}
      </Tooltip>
    </>
  );
};

TooltipCard.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  placement: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

TooltipCard.defaultProps = {
  placement: 'top',
};

export default TooltipCard;
