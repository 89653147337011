import { MiscActionTypes } from '../actions/misc';

const initialState = {
  paymentToken: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {MiscActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MiscActionTypes.GET_PAYMENT_TOKEN:
      return { ...state, paymentToken: action.paymentToken };
    default:
      return state;
  }
}
