import React, { useEffect, useState } from 'react';
import { Row, Col } from 'shards-react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { warning } from 'react-toastify-redux';
import moment from 'moment';
import {
  getPromotions,
  getPromotion,
  createPromotion,
  updatePromotion,
  deletePromotion,
} from '../../store/actions/promotions';
import { getUsers } from '../../store/actions/users';
import { getProducts } from '../../store/actions/products';
import { MainStage } from '../../components/layout';
import {
  FormCard,
  FormGroup,
  IconButton,
  DialogButton,
} from '../../components/common';
import { hasAccess } from '../../utils/auth';
import {
  PromoTypes,
  PromoShippingTypes,
  ShippingCountries,
  DiscountAppliesTo,
  DiscountMinTypes,
  DiscountTypes,
  PromoBundleBuyTypes,
  PromoBundleGetTypes,
  PromoBundleGetSubTypes,
  PromoEligibilityTypes,
  ProductCollections,
  PromoStackTypes,
  ProductAssetKey,
} from '../../constants';
import { isArrayObjectEqual } from '../../utils/index';
import * as _ from 'lodash';

const collectionOptions = Object.keys(ProductCollections).map((key) => {
  return { value: ProductCollections[key], label: ProductCollections[key] };
});

const promoTypesOptions = Object.keys(PromoTypes).map((key) => {
  return { value: PromoTypes[key], label: PromoTypes[key] };
});
const promoShippingCountriesOptions = [
  { value: 'all', label: 'All the countries' },
];
Object.keys(ShippingCountries).forEach((key) => {
  promoShippingCountriesOptions.push({
    value: ShippingCountries[key],
    label: ShippingCountries[key],
  });
});
const discountAppliesToOptions = Object.keys(DiscountAppliesTo).map((key) => {
  return { value: DiscountAppliesTo[key], label: DiscountAppliesTo[key] };
});
const discountMinTypesOptions = Object.keys(DiscountMinTypes).map((key) => {
  return { value: DiscountMinTypes[key], label: DiscountMinTypes[key] };
});
const discountTypesOptions = Object.keys(DiscountTypes).map((key) => {
  return { value: DiscountTypes[key], label: DiscountTypes[key] };
});
const promoBundleBuyTypesOptions = Object.keys(PromoBundleBuyTypes).map(
  (key) => {
    return { value: PromoBundleBuyTypes[key], label: PromoBundleBuyTypes[key] };
  }
);
const promoStackTypesOptions = Object.keys(PromoStackTypes).map((key) => {
  return { value: PromoStackTypes[key], label: PromoStackTypes[key] };
});
const promoBundleGetTypesOptions = [
  {
    label: `free ${PromoBundleGetTypes.PRODUCTS}`,
    value: PromoBundleGetTypes.PRODUCTS,
  },
  {
    label: `free ${PromoBundleGetTypes.COLLECTIONS}`,
    value: PromoBundleGetTypes.COLLECTIONS,
  },
  {
    label: `entire order ${PromoBundleGetTypes.DISCOUNT}`,
    value: PromoBundleGetTypes.DISCOUNT,
  },
];

const promoBundleGetSubTypesOptions = [
  {
    label: PromoBundleGetSubTypes.FIXED,
    value: PromoBundleGetSubTypes.FIXED,
  },
  {
    label: PromoBundleGetSubTypes.PERCENT,
    value: PromoBundleGetSubTypes.PERCENT,
  },
];

const PromotionForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [promotionState, setPromotionState] = useState(null);
  const [bundleBuysState, setBundleBuysState] = useState([]);
  const [bundleBuyTypes, setBundleBuyTypes] = useState([]);
  const [bundleGetsState, setBundleGetsState] = useState([]);
  const [bundleGetTypes, setBundleGetTypes] = useState([]);
  const [discountAppliesTo, setDiscountAppliesTo] = useState([]);
  const [promoType, setPromoType] = useState(null);
  const [startOn, setStartOn] = useState(moment().toDate());
  const [endsOn, setEndsOn] = useState(moment().toDate());
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [promoOptions, setPromoOptions] = useState([]);
  const isLoading = useSelector(({ ui }) => ui.isLoading);
  const promoData = useSelector(({ promotions: { promotion } }) => promotion);
  const promos = useSelector(
    ({ promotions: { promotions } }) => promotions.items
  );
  const { items } = useSelector(({ users: { users } }) => users);
  const productsData = useSelector(({ products: { products } }) => products);
  const { control, handleSubmit, errors, setValue } = useForm();
  const canUpdate = hasAccess('promos', 'update');
  const canCreate = hasAccess('promos', 'create');

  useEffect(() => {
    dispatch(getProducts());
    if (id) dispatch(getPromotion(id));
    else {
      const newPromotion = {
        eligibility: {
          type: '',
          selectedUsers: [],
          users: [],
        },
        discount: {},
        shipping: {},
        bundle: {},
        usageLimits: {
          excludeSale: false,
          oncePerUser: false,
          totalUses: 0,
          canStack: false,
          stackType: '',
          stackPromos: [],
        },
      };
      setPromotionState(newPromotion);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id && promoData && promoData.id === id) {
      setStartOn(moment.unix(promoData.startOn.seconds).toDate());
      setEndsOn(moment.unix(promoData.endsOn.seconds).toDate());
      setPromoType(promoData.type);

      if (promoData.usageLimits.canStack) {
        promoData.usageLimits.selectedStackPromos = promoData.usageLimits
          .stackPromos
          ? promoData.usageLimits.stackPromos.map((s) => {
              return { value: s, label: s };
            })
          : [];
        if (!promoData.usageLimits.selectedStackPromos.length) {
          promoData.usageLimits.selectedStackPromos = [
            { value: '', label: 'All' },
          ];
        }
      }

      switch (promoData.type) {
        case 'shipping': {
          if (promoData.shipping.type === 'all') {
            promoData.shipping.selectedCountries = [
              { value: 'all', label: 'All the countries' },
            ];
          } else {
            promoData.shipping.selectedCountries =
              promoData.shipping.countries.map((c) => {
                return { value: c, label: c };
              });
          }
          break;
        }
        case 'bundle': {
          promoData.bundle.buys.map((buy) => {
            setBundleBuyTypes((prev) => [...prev, buy.type]);
            const buyItems = buy[buy.type];
            if (buy.type === PromoBundleBuyTypes.PRODUCTS) {
              if (buyItems.length) {
                buy.selectedProducts = setSelectedProductOptions(buyItems);
              }
            } else if (buy.type === PromoBundleBuyTypes.COLLECTIONS) {
              buy.selectedCollections = buyItems.map((item) => {
                return {
                  value: `${item.id}`,
                  label: `${item.name}`,
                };
              });
            }
            return buy;
          });
          setBundleBuysState(promoData.bundle.buys);

          promoData.bundle.gets.map((get) => {
            setBundleGetTypes((prev) => [...prev, get.type]);
            const getItems = get[get.type];
            if (get.type === PromoBundleGetTypes.PRODUCTS) {
              get.selectedProducts = setSelectedProductOptions(getItems);
            } else if (get.type === PromoBundleGetTypes.COLLECTIONS) {
              get.selectedCollections = getItems.map((item) => {
                return {
                  value: `${item.id}`,
                  label: `${item.name}`,
                };
              });
            }
            return get;
          });
          setBundleGetsState(promoData.bundle.gets);
          break;
        }
        case 'discount': {
          setDiscountAppliesTo(promoData.discount.appliesTo);
          if (promoData.discount.appliesTo === DiscountAppliesTo.PRODUCTS) {
            promoData.discount.selectedProducts = setSelectedProductOptions(
              promoData.discount.products
            );
          } else if (
            promoData.discount.appliesTo === DiscountAppliesTo.COLLECTIONS
          ) {
            promoData.discount.selectedCollections =
              promoData.discount.collections.map((item) => {
                return {
                  value: `${item.id}`,
                  label: `${item.name}`,
                };
              });
          }
          break;
        }
        default:
      }

      if (promoData.eligibility.type === 'all') {
        promoData.eligibility.selectedUsers = [
          { value: 'all', label: 'All the users' },
        ];
      } else {
        promoData.eligibility.selectedUsers = promoData.eligibility.users.map(
          (u) => {
            return { value: u.id, label: `${u.firstName} ${u.lastName}` };
          }
        );
      }
      setPromotionState(promoData);
    }
    // eslint-disable-next-line
  }, [promoData]);

  useEffect(() => {
    const options = [{ value: 'all', label: 'All the users' }];
    if (items.length) {
      const [users] = items;
      users.forEach((i) => {
        options.push({ value: i.id, label: `${i.firstName} ${i.lastName}` });
      });
    }
    setCustomerOptions(options);
  }, [items]);

  useEffect(() => {
    const options = [{ value: '', label: 'All' }];
    if (promos.length) {
      const [promosItems] = promos;
      promosItems.forEach((p) => {
        options.push({ value: p.code, label: p.code });
      });
    }
    setPromoOptions(options);
  }, [promos]);

  useEffect(() => {
    if (productsData.items.length) {
      const pOptions = [];
      _.orderBy(productsData.items, 'collection', 'desc').forEach((item) => {
        item.variants.forEach((variant) => {
          let value = `${item.id}|${variant.type}`;
          let label = `${item.name} [${variant.type}]`;
          if (variant.subVariants && variant.subVariants.length) {
            const headboard = variant.subVariants.find(
              (s) => s.key === ProductAssetKey.DESIGN
            );
            const material = variant.subVariants.find(
              (s) => s.key === ProductAssetKey.MATERIAL
            );
            label = `${item.name} [${variant.type}] - ${
              headboard.name ? `${headboard.name} / ` : ''
            }${`${material.name}`}`;

            value = `${item.id}|${
              variant.type
            }|${`${headboard.name}-${material.name}`}`;
          }
          pOptions.push({
            value: value,
            label: label,
          });
        });
      });
      setProductOptions(pOptions);
    }
  }, [productsData]);

  const handleInputChange = _.debounce((value) => {
    if (value && value.length >= 3) {
      dispatch(getUsers({ filter: value }));
    }
  }, 1000);

  const handlePromoInputChange = _.debounce((value) => {
    if (value && value.length >= 3) {
      dispatch(getPromotions({ filter: value }));
    }
  }, 1000);

  const handleAddBuyBundle = () => {
    setBundleBuysState((prev) => [
      ...prev,
      {
        qty: '',
        type: '',
      },
    ]);
  };

  const handleRemoveBundleBuy = (index) => {
    setBundleBuysState(bundleBuysState.filter((value, i) => i !== index));
  };

  const handleAddGetBundle = () => {
    setBundleGetsState((prev) => [
      ...prev,
      {
        qty: 0,
        type: '',
        value: 0,
      },
    ]);
  };

  const handleRemoveBundleGet = (index) => {
    setBundleGetsState(bundleGetsState.filter((value, i) => i !== index));
  };

  const handleAppliesToChange = (e) => {
    setDiscountAppliesTo(e.target.value);
  };

  const handleAllowStackChange = (checked) => {
    promotionState.usageLimits.canStack = checked;
    setPromotionState({ ...promotionState });
  };

  const handleStackTypeChange = (e) => {
    const { value } = e.target;
    if (value === PromoStackTypes.INCLUDE)
      setValue('usageLimits.stackPromos', [{ value: '', label: 'All' }]);
    else setValue('usageLimits.stackPromos', []);
  };

  const getSelectedPromoProducts = (product) => {
    const [pid, pvariant, subvariant] = product.value.split('|');
    const selectedProduct = productsData.items.find((i) => i.id === pid);
    if (!selectedProduct) {
      dispatch(warning(`${selectedProduct.name} not found`));
      return;
    }
    const imageMedia = selectedProduct.media.find((m) => m.default);

    let selectedVariant = null;
    if (subvariant) {
      const [heaboardName, materialName] = subvariant.split('-');
      const selectedSubVairants = [
        {
          key: ProductAssetKey.DESIGN,
          name: heaboardName,
        },
        {
          key: ProductAssetKey.MATERIAL,
          name: materialName,
        },
      ];
      selectedProduct.variants.map((v) => {
        if (
          v.type === pvariant &&
          isArrayObjectEqual(v.subVariants, selectedSubVairants, [
            'key',
            'assetId',
          ])
        ) {
          selectedVariant = v;
          return;
        }
      });
    } else
      selectedVariant = selectedProduct.variants.find(
        (v) => v.type === pvariant
      );

    if (!selectedVariant) {
      dispatch(
        warning(`${selectedProduct.name} - variant ${pvariant} not found`)
      );
      return;
    }

    return {
      id: selectedProduct.id,
      name: selectedProduct.name,
      variant: selectedVariant.type,
      subVariants: selectedVariant.subVariants || [],
      image: imageMedia ? imageMedia.path : '',
    };
  };

  const setSelectedProductOptions = (items) => {
    console.log('items', items);
    const options = [];
    items.forEach((item) => {
      let value = `${item.id}|${item.variant}`;
      let label = `${item.name} [${item.variant}]`;
      if (item.subVariants && item.subVariants.length) {
        const headboard = item.subVariants.find(
          (s) => s.key === ProductAssetKey.DESIGN
        );
        const material = item.subVariants.find(
          (s) => s.key === ProductAssetKey.MATERIAL
        );
        label = `${item.name} [${item.variant}] - ${
          headboard.value ? `${headboard.value} / ` : ''
        }${`${material.value}`}`;

        value = `${item.id}|${
          item.variant
        }|${`${headboard.value}-${material.value}`}`;
      }
      options.push({
        value: value,
        label: label,
      });
    });
    return options;
  };

  const onSubmit = async (data) => {
    if (!id && !canCreate) {
      dispatch(warning('You do not have permission to create promotion'));
      return;
    }
    if (id && !canUpdate) {
      dispatch(warning('You do not have permission to update promotion'));
      return;
    }

    const promotion = {
      audit: promotionState.audit,
      ...data,
    };

    promotion.code = promotion.code.toUpperCase();

    // usageLimits
    if (promotion.usageLimits.canStack) {
      promotion.usageLimits.stackPromos = promotion.usageLimits.stackPromos
        ? promotion.usageLimits.stackPromos.map((s) => {
            return s.value;
          })
        : [];
      if (promotion.usageLimits.stackPromos.includes(''))
        promotion.usageLimits.stackPromos = [];
    } else {
      delete promotion.usageLimits.stackPromos;
      delete promotion.usageLimits.stackType;
    }

    // eligibility
    promotion.eligibility.users = promotion.eligibility.users.map((u) => {
      return u.value;
    });
    if (promotion.eligibility.users.findIndex((u) => u === 'all') !== -1) {
      promotion.eligibility.type = PromoEligibilityTypes.ALL;
      promotion.eligibility.users = [];
    } else {
      promotion.eligibility.type = PromoEligibilityTypes.SPECIFIC;
      promotion.eligibility.users = promotion.eligibility.users.filter(
        (u) => u !== 'all'
      );
    }

    // promo type
    switch (promotion.type) {
      case 'shipping': {
        const shipping = promotion[promotion.type];
        shipping.minAmount = parseFloat(shipping.minAmount);
        shipping.countries = shipping.countries.map((c) => {
          return c.value;
        });

        if (shipping.countries.findIndex((u) => u === 'all') !== -1) {
          shipping.type = PromoShippingTypes.ALL;
          shipping.countries = [];
        } else {
          shipping.type = PromoShippingTypes.SPECIFIC;
          shipping.countries = shipping.countries.filter((u) => u !== 'all');
        }

        promotion.shipping = {
          ...shipping,
        };
        delete promotion.discount;
        delete promotion.bundle;
        break;
      }
      case 'discount': {
        const discount = promotion[promotion.type];
        discount.minValue = parseFloat(discount.minValue);
        discount.value = parseFloat(discount.value);
        if (discount.appliesTo === DiscountAppliesTo.PRODUCTS) {
          discount.products = discount.products.map((p) => {
            const selected = getSelectedPromoProducts(p);
            return selected;
          });
        } else if (discount.appliesTo === DiscountAppliesTo.COLLECTIONS) {
          discount.collections = discount.collections.map((c) => {
            return {
              id: c.value,
              name: c.label,
            };
          });
        } else {
          discount.products = [];
          discount.collections = [];
        }
        promotion.discount = {
          ...discount,
        };
        delete promotion.shipping;
        delete promotion.bundle;
        break;
      }
      case 'bundle': {
        const bundle = promotion[promotion.type];
        if (bundle.buys) {
          bundle.buys.map((buy) => {
            if (buy.type === PromoBundleBuyTypes.PRODUCTS) {
              buy.products = buy.products.map((p) => {
                const selected = getSelectedPromoProducts(p);
                return selected;
              });
            } else if (buy.type === PromoBundleBuyTypes.COLLECTIONS) {
              buy.collections = buy.collections.map((c) => {
                return {
                  id: c.value,
                  name: c.label,
                };
              });
            }
            buy.qty = parseFloat(buy.qty);
            return buy;
          });
        }

        if (bundle.gets) {
          bundle.gets.map((get) => {
            if (get.type === PromoBundleGetTypes.PRODUCTS) {
              get.collections = [];
              get.products = get.products.map((p) => {
                const selected = getSelectedPromoProducts(p);
                return selected;
              });
              get.subType = PromoBundleGetSubTypes.FREE;
            } else if (get.type === PromoBundleGetTypes.COLLECTIONS) {
              get.products = [];
              get.collections = get.collections.map((c) => {
                return {
                  id: c.value,
                  name: c.label,
                };
              });

              get.collections.forEach((collection) => {
                const selectedProduct = productsData.items.find(
                  (item) => item.collection === collection.name
                );
                const imageMedia = selectedProduct.media.find((m) => m.default);
                selectedProduct.variants.forEach((variant) => {
                  get.products.push({
                    id: selectedProduct.id,
                    name: selectedProduct.name,
                    variant: variant.type,
                    image: imageMedia ? imageMedia.path : '',
                  });
                });
              });
              get.subType = PromoBundleGetSubTypes.FREE;
            }

            if (get.qty) get.qty = parseFloat(get.qty);
            if (get.value) get.value = parseFloat(get.value);
            return get;
          });

          if (bundle.gets.find((g) => g.type === PromoBundleGetTypes.DISCOUNT))
            bundle.oncePerOrder = true;
        }
        promotion.bundle = {
          ...bundle,
        };
        delete promotion.shipping;
        delete promotion.discount;
        break;
      }
      default:
    }

    delete promotion.id;
    if (id && canUpdate) dispatch(updatePromotion(id, promotion));
    else if (!id && canCreate) dispatch(createPromotion(promotion));
  };

  const handleDelete = () => {
    dispatch(deletePromotion(id));
  };

  const generateShippingPromo = () => {
    const shipping = promotionState[promoType];
    if (!shipping) return null;
    return (
      <Row>
        <FormGroup
          col={{ lg: '6' }}
          type="searchable-select"
          isMulti
          options={promoShippingCountriesOptions}
          name="shipping.countries"
          label="Shipping Countries"
          control={control}
          rules={{ required: 'Required field' }}
          errors={errors}
          defaultValue={shipping.selectedCountries}
        />
        <FormGroup
          col={{ lg: '6' }}
          label="Min Purchase Amount"
          type="number"
          step={0.01}
          control={control}
          rules={{ required: 'Required field' }}
          errors={errors}
          name="shipping.minAmount"
          defaultValue={shipping.minAmount}
        />
      </Row>
    );
  };

  const generateDiscountPromo = () => {
    const discount = promotionState[promoType];
    if (!discount) return null;
    return (
      <Row>
        <FormGroup
          col={{ lg: '6' }}
          type="select"
          options={discountAppliesToOptions}
          name="discount.appliesTo"
          label="Applies To"
          control={control}
          rules={{ required: 'Required field' }}
          errors={errors}
          defaultValue={discount.appliesTo}
          onChange={handleAppliesToChange}
        />
        {discountAppliesTo === DiscountAppliesTo.COLLECTIONS && (
          <FormGroup
            type="searchable-select"
            isMulti
            options={collectionOptions}
            label="Collections"
            name="discount.collections"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
            defaultValue={discount.selectedCollections}
          />
        )}
        {discountAppliesTo === DiscountAppliesTo.PRODUCTS && (
          <FormGroup
            type="searchable-select"
            isMulti
            options={productOptions}
            label="Products"
            name="discount.products"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
            defaultValue={discount.selectedProducts}
          />
        )}
        {discountAppliesTo !== DiscountAppliesTo.ENTIRE && (
          <FormGroup
            col={{ lg: '6' }}
            type="checkbox"
            label="Once Per Order"
            control={control}
            name="discount.oncePerOrder"
            defaultValue={discount.oncePerOrder}
          />
        )}
        <FormGroup
          col={{ lg: '6' }}
          type="select"
          options={discountMinTypesOptions}
          name="discount.minType"
          label="Min Purchase Type"
          control={control}
          rules={{ required: 'Required field' }}
          errors={errors}
          defaultValue={discount.minType}
        />
        <FormGroup
          col={{ lg: '6' }}
          label="Min Purchase Value"
          type="number"
          step={0.01}
          control={control}
          rules={{ required: 'Required field' }}
          errors={errors}
          name="discount.minValue"
          defaultValue={discount.minValue}
        />
        <FormGroup
          col={{ lg: '6' }}
          type="select"
          options={discountTypesOptions}
          name="discount.type"
          label="Type"
          control={control}
          rules={{ required: 'Required field' }}
          errors={errors}
          defaultValue={discount.type}
        />
        <FormGroup
          col={{ lg: '6' }}
          label="Type Value"
          type="number"
          step={0.01}
          control={control}
          rules={{ required: 'Required field' }}
          errors={errors}
          name="discount.value"
          defaultValue={discount.value}
        />
      </Row>
    );
  };

  const generatePromoBundleBuy = () => {
    const bundle = promotionState[promoType];
    if (!bundle) return null;
    return (
      <>
        {!bundleGetTypes.find((t) => t === PromoBundleGetTypes.DISCOUNT) && (
          <Row>
            <FormGroup
              col={{ lg: '6' }}
              type="checkbox"
              label="Once Per Order"
              control={control}
              name="bundle.oncePerOrder"
              defaultValue={bundle.oncePerOrder}
            />
          </Row>
        )}

        <h5>BUY X</h5>
        <div className="table-responsive-md">
          <table className="table form-table">
            <thead>
              <tr>
                <th>Bundle Type</th>
                <th width="20%">Qty</th>
                <th width="50%">Products / Collections</th>
                <th width="70">
                  <IconButton
                    theme="success"
                    icon="add"
                    onClick={handleAddBuyBundle}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {bundleBuysState.map((buy, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Row>
                        <FormGroup
                          type="select"
                          options={promoBundleBuyTypesOptions}
                          name={`bundle.buys[${index}].type`}
                          control={control}
                          rules={{ required: 'Required field' }}
                          errors={errors}
                          defaultValue={buy.type}
                          onChange={(e) => {
                            const newBundle = bundleBuyTypes.map((b) => {
                              return b;
                            });
                            newBundle[index] = e.target.value;
                            setBundleBuyTypes(newBundle);
                          }}
                        />
                      </Row>
                    </td>
                    <td>
                      <Row>
                        <FormGroup
                          type="number"
                          name={`bundle.buys[${index}].qty`}
                          control={control}
                          rules={{ required: 'Required field' }}
                          errors={errors}
                          defaultValue={buy.qty}
                        />
                      </Row>
                    </td>
                    <td>
                      <Row>
                        {bundleBuyTypes[index] ===
                          PromoBundleBuyTypes.PRODUCTS && (
                          <FormGroup
                            type="searchable-select"
                            isMulti
                            options={productOptions}
                            name={`bundle.buys[${index}].products`}
                            control={control}
                            rules={{ required: 'Required field' }}
                            errors={errors}
                            defaultValue={buy.selectedProducts}
                          />
                        )}
                        {bundleBuyTypes[index] ===
                          PromoBundleBuyTypes.COLLECTIONS && (
                          <FormGroup
                            type="searchable-select"
                            isMulti
                            options={collectionOptions}
                            name={`bundle.buys[${index}].collections`}
                            control={control}
                            rules={{ required: 'Required field' }}
                            errors={errors}
                            defaultValue={buy.selectedCollections}
                          />
                        )}
                      </Row>
                    </td>
                    <td>
                      <DialogButton
                        theme="danger"
                        icon="delete_outline"
                        message=""
                        onConfirm={() => handleRemoveBundleBuy(index)}
                        disabled={isLoading}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generatePromoBundleGet = () => {
    const bundle = promotionState[promoType];
    if (!bundle) return null;
    return (
      <>
        <h5>GET Y</h5>
        <div className="table-responsive-md">
          <table className="table form-table">
            <thead>
              <tr>
                <th>Bundle Type</th>
                <th width="20%">Qty / Value</th>
                <th width="50%">Discount Unit</th>
                <th width="70">
                  <IconButton
                    theme="success"
                    icon="add"
                    onClick={handleAddGetBundle}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {bundleGetsState.map((get, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Row>
                        <FormGroup
                          type="select"
                          options={promoBundleGetTypesOptions}
                          name={`bundle.gets[${index}].type`}
                          control={control}
                          rules={{ required: 'Required field' }}
                          errors={errors}
                          defaultValue={get.type}
                          onChange={(e) => {
                            const newBundle = bundleGetTypes.map((b) => {
                              return b;
                            });
                            newBundle[index] = e.target.value;
                            setBundleGetTypes(newBundle);
                          }}
                        />
                      </Row>
                    </td>
                    <td>
                      {bundleGetTypes[index] !==
                        PromoBundleGetTypes.DISCOUNT && (
                        <Row>
                          <FormGroup
                            type="number"
                            name={`bundle.gets[${index}].qty`}
                            control={control}
                            rules={{ required: 'Required field' }}
                            errors={errors}
                            defaultValue={get.qty}
                          />
                        </Row>
                      )}
                      {bundleGetTypes[index] ===
                        PromoBundleGetTypes.DISCOUNT && (
                        <Row>
                          <FormGroup
                            type="number"
                            name={`bundle.gets[${index}].value`}
                            control={control}
                            rules={{ required: 'Required field' }}
                            errors={errors}
                            defaultValue={get.value}
                          />
                        </Row>
                      )}
                    </td>
                    <td>
                      {bundleGetTypes[index] ===
                        PromoBundleGetTypes.PRODUCTS && (
                        <Row>
                          <FormGroup
                            type="searchable-select"
                            isMulti
                            options={productOptions}
                            name={`bundle.gets[${index}].products`}
                            control={control}
                            rules={{ required: 'Required field' }}
                            errors={errors}
                            defaultValue={get.selectedProducts}
                          />
                        </Row>
                      )}
                      {bundleGetTypes[index] ===
                        PromoBundleGetTypes.COLLECTIONS && (
                        <Row>
                          <FormGroup
                            type="searchable-select"
                            isMulti
                            options={collectionOptions}
                            name={`bundle.gets[${index}].collections`}
                            control={control}
                            rules={{ required: 'Required field' }}
                            errors={errors}
                            defaultValue={get.selectedCollections}
                          />
                        </Row>
                      )}
                      {bundleGetTypes[index] ===
                        PromoBundleGetTypes.DISCOUNT && (
                        <Row>
                          <FormGroup
                            type="select"
                            options={promoBundleGetSubTypesOptions}
                            name={`bundle.gets[${index}].subType`}
                            control={control}
                            rules={{ required: 'Required field' }}
                            errors={errors}
                            defaultValue={get.subType}
                          />
                        </Row>
                      )}
                    </td>
                    <td>
                      <DialogButton
                        theme="danger"
                        icon="delete_outline"
                        message=""
                        onConfirm={() => handleRemoveBundleGet(index)}
                        disabled={isLoading}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const handlePromoTypeChange = (e) => {
    const { value } = e.target;
    setPromoType(value);
    promotionState[value] = {};
    setPromotionState(promotionState);
  };

  return (
    promotionState &&
    (id ? promotionState && promotionState.id === id : true) && (
      <MainStage
        title={!id ? 'New' : 'Update'}
        subtitle="Promotions"
        to="/promotions"
      >
        <Row form>
          <Col md="12">
            <FormCard title="Enter promotion details">
              <Row>
                <Col lg="6">
                  <Row>
                    <FormGroup
                      col={{ lg: '12' }}
                      name="code"
                      label="Promo Code"
                      control={control}
                      rules={{ required: 'Required field' }}
                      errors={errors}
                      defaultValue={promotionState.code}
                      readOnly={!!id}
                    />
                    <FormGroup
                      col={{ lg: '12' }}
                      type="searchable-select"
                      isMulti
                      name="eligibility.users"
                      label="Customer"
                      placeholder="Type at least 3 characters"
                      onInputChange={handleInputChange}
                      options={customerOptions}
                      control={control}
                      rules={{ required: 'Required field' }}
                      errors={errors}
                      defaultValue={promotionState.eligibility.selectedUsers}
                    />
                  </Row>
                </Col>
                <Col lg="6">
                  <Row>
                    <FormGroup
                      col={{ lg: '6' }}
                      type="datepicker"
                      name="startOn"
                      label="Start On"
                      control={control}
                      rules={{ required: 'Required field' }}
                      errors={errors}
                      defaultValue={startOn}
                      dateProps={{ maxDate: endsOn }}
                      onChange={(value) => setStartOn(value)}
                    />

                    <FormGroup
                      col={{ lg: '6' }}
                      type="datepicker"
                      name="endsOn"
                      label="Ends On"
                      control={control}
                      rules={{ required: 'Required field' }}
                      errors={errors}
                      defaultValue={endsOn}
                      dateProps={{ minDate: startOn }}
                      onChange={(value) => setEndsOn(value)}
                    />
                    <FormGroup
                      label="Total Uses"
                      type="number"
                      control={control}
                      rules={{ required: 'Required field' }}
                      errors={errors}
                      name="usageLimits.totalUses"
                      defaultValue={promotionState.usageLimits.totalUses}
                    />
                    <FormGroup
                      label="Exclude Sale"
                      type="checkbox"
                      control={control}
                      name="usageLimits.excludeSale"
                      defaultValue={promotionState.usageLimits.excludeSale}
                    />
                    <FormGroup
                      label="Once Per User"
                      type="checkbox"
                      control={control}
                      name="usageLimits.oncePerUser"
                      defaultValue={promotionState.usageLimits.oncePerUser}
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <FormGroup
                  col={{ lg: '2' }}
                  label="Allow Stack"
                  type="checkbox"
                  control={control}
                  onChange={(checked) => handleAllowStackChange(checked)}
                  name="usageLimits.canStack"
                  defaultValue={promotionState.usageLimits.canStack}
                />
                {promotionState.usageLimits.canStack && (
                  <>
                    <FormGroup
                      col={{ lg: '2' }}
                      type="select"
                      options={promoStackTypesOptions}
                      name="usageLimits.stackType"
                      label="Stack Type"
                      control={control}
                      rules={{ required: 'Required field' }}
                      errors={errors}
                      onChange={(e) => handleStackTypeChange(e)}
                      defaultValue={promotionState.usageLimits.stackType}
                    />
                    <FormGroup
                      col={{ lg: '6' }}
                      type="searchable-select"
                      isMulti
                      name="usageLimits.stackPromos"
                      label="Stack Promos"
                      placeholder="Type at least 3 characters"
                      onInputChange={handlePromoInputChange}
                      options={promoOptions}
                      control={control}
                      rules={{ required: 'Required field' }}
                      errors={errors}
                      defaultValue={
                        promotionState.usageLimits.selectedStackPromos
                      }
                    />
                  </>
                )}
              </Row>
            </FormCard>
          </Col>
          <Col md="12">
            <FormCard
              title="Enter promotion type"
              button={{ theme: 'primary', text: 'Save', isLoading }}
              onSubmit={handleSubmit(onSubmit)}
              onDelete={handleDelete}
            >
              <Row>
                <FormGroup
                  col={{ lg: '6' }}
                  type="select"
                  options={promoTypesOptions}
                  name="type"
                  label="Promo Type"
                  control={control}
                  rules={{ required: 'Required field' }}
                  errors={errors}
                  onChange={(e) => handlePromoTypeChange(e)}
                  defaultValue={promotionState.type}
                />
              </Row>
              <Row>
                <Col lg="12">
                  {promoType === 'shipping' && generateShippingPromo()}

                  {promoType === 'discount' && generateDiscountPromo()}

                  {promoType === 'bundle' && (
                    <>
                      {generatePromoBundleBuy()}
                      {generatePromoBundleGet()}
                    </>
                  )}
                </Col>
              </Row>
            </FormCard>
          </Col>
        </Row>
      </MainStage>
    )
  );
};

export default PromotionForm;
