import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Card, CardBody, CardHeader } from 'shards-react';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import CalendarEventModal from './CalendarEventModal';
import { getDeliverySlotCalendarEvents } from '../../store/actions/reports';

const localizer = momentLocalizer(moment);

const FullCalendar = () => {
  const dispatch = useDispatch();
  const { deliveryEvents } = useSelector(({ reports }) => reports);
  const wrapperRef = useRef();
  const [selectedEvent, setSelectedEvent] = useState({});
  const [openEventModal, setOpenEventModal] = useState(false);

  useEffect(() => {
    const initButton = wrapperRef.current.querySelector(
      '.rbc-toolbar .rbc-btn-group button.rbc-active'
    );
    initButton.click();
  }, []);

  return (
    <>
      <Card innerRef={wrapperRef}>
        <CardHeader className="border-bottom">
          <h6 className="m-0">Delivery Schedules</h6>
        </CardHeader>
        <CardBody>
          <Calendar
            on
            localizer={localizer}
            events={deliveryEvents.events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            onRangeChange={(range) => {
              dispatch(getDeliverySlotCalendarEvents(range));
            }}
            onSelectEvent={(event) => {
              setSelectedEvent(event);
              setOpenEventModal(true);
            }}
          />
        </CardBody>
      </Card>
      <CalendarEventModal
        open={openEventModal}
        onClose={() => setOpenEventModal(false)}
        event={selectedEvent}
      />
    </>
  );
};

export default FullCalendar;
