import { PostCategoryActionTypes } from '../actions/postCategories';

const initialState = {
  postCategories: [],
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {PostCategoryActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PostCategoryActionTypes.GET_POST_CATEGORIES:
    case PostCategoryActionTypes.CREATE_POST_CATEGORY:
    case PostCategoryActionTypes.DELETE_POST_CATEGORY:
      return { ...state, postCategories: [...action.postCategories] };
    default:
      return state;
  }
}
