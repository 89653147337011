import { error } from 'react-toastify-redux';
import { startLoading, stopLoading } from './ui';
import { getToken } from '../../utils/auth';

export const MiscActionTypes = {
  GET_PAYMENT_TOKEN: 'GET_PAYMENT_TOKEN',
};

const apiEndpoint = `${process.env.REACT_APP_WOOSA_API_URI}/api/misc`;

/**
 * Get payment token from api
 * @param {string} id get by object id
 */
export const getOrderByPaymentToken = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const response = await fetch(
        `${apiEndpoint}/payment-token?orderId=${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken().token}`,
          },
        }
      );
      if (response.status !== 201 && response.status !== 200) throw response;
      const dataText = await response.text();
      const data = await dataText.toString();

      dispatch({
        type: MiscActionTypes.GET_PAYMENT_TOKEN,
        paymentToken: { id, token: data },
      });
    } catch (err) {
      dispatch(error('Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};
