import { auth as firebaseAuth } from '../firebase';

export const AuthStorageKeys = {
  ADMIN: 'sg.woosa.admin',
  TOKEN: 'sg.woosa.token',
};

export const AuthAlertStorageKeys = {
  DENIED_MODULE: 'denied_module',
  LOGIN_REQUIRED: 'login_required',
};

/**
 * Check is admin is logged in
 */
export const isAuth = (routing = false) => {
  const isAuthenticated = Object.keys(AuthStorageKeys).every((key) =>
    localStorage.getItem(AuthStorageKeys[key])
  );

  if (!isAuthenticated && routing)
    localStorage.setItem(AuthAlertStorageKeys.LOGIN_REQUIRED, true);

  return isAuthenticated;
};

/**
 * Clear authenciation in localStorage
 */
export const clearAuth = () => {
  Object.keys(AuthStorageKeys).forEach((key) =>
    localStorage.removeItem(AuthStorageKeys[key])
  );
};

/**
 * Check is admin has access
 * @param {string} module
 * @param {string} action
 */
export const hasAccess = (module, action, routing = false) => {
  const admin = JSON.parse(localStorage.getItem(AuthStorageKeys.ADMIN));
  const access =
    admin &&
    admin.role &&
    admin.role.modules &&
    module in admin.role.modules &&
    admin.role.modules[module].includes(action);

  if (!access && routing)
    localStorage.setItem(AuthAlertStorageKeys.DENIED_MODULE, module);
  return access;
};

/**
 * Retrieve admin from localStorage
 */
export const getAdmin = () => {
  return JSON.parse(localStorage.getItem(AuthStorageKeys.ADMIN));
};

/**
 * Set admin to localStorage
 */
export const setAdmin = (admin) => {
  localStorage.setItem(AuthStorageKeys.ADMIN, JSON.stringify(admin));
};

/**
 * Set token to localStorage
 * @param {firebase.auth.IdTokenResult} token
 */
export const setToken = (token) => {
  const newToken = {
    exp: token.expirationTime,
    token: token.token,
  };
  localStorage.setItem(AuthStorageKeys.TOKEN, JSON.stringify(newToken));
  return newToken;
};

/**
 * Retrieve token from localStorage
 */
export const getToken = () => {
  if (firebaseAuth.currentUser) {
    firebaseAuth.currentUser.getIdTokenResult(true).then((res) => {
      setToken(res);
      return res.token;
    });
  }
  return JSON.parse(localStorage.getItem(AuthStorageKeys.TOKEN));
};

const authUtils = {
  isAuth,
  clearAuth,
  hasAccess,
  getAdmin,
  setAdmin,
  getToken,
  setToken,
  AuthAlertStorageKeys,
};

export default authUtils;
