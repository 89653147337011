import { error } from 'react-toastify-redux';
import { orderFiltersCollection, productsCollection } from '../../firebase';
import { startLoading, stopLoading } from './ui';

export const OrderFilterActionTypes = {
  GET_ORDER_FILTERS: 'GET_ORDER_FILTERS',
};

/**
 * Retrieve all order filters from firebase
 */
export const getOrderFilters = () => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const orderFilterRef = await orderFiltersCollection.get();
      const orderFilter = orderFilterRef.docs[0].data();
      const items = [];

      await Promise.all(
        orderFilter.products.map(async (p) => {
          const productrDoc = await productsCollection.doc(p.id).get();
          const productData = productrDoc.data();
          if (productData) p.name = productData.name;
          items.push(p);
        })
      );

      dispatch({
        type: OrderFilterActionTypes.GET_ORDER_FILTERS,
        orderFilters: { items },
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
