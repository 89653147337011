import React from 'react';

// Layout Types
import { DefaultLayout, BlankLayout } from './layouts';

// Route Views
import { ResetPassword, Login, Logout } from './views/auth';
import { RoleListing, RoleForm } from './views/roles';
import { AdminListing, AdminForm } from './views/admins';
import { ProductListing, ProductForm } from './views/products';
import { Dashboard } from './views/dashboard';
import { StatusError } from './views/errors';
import { CustomerListing, CustomerDetail } from './views/customers';
import { OrderListing, OrderViewForm, OrderForm } from './views/orders';
import { DeliverySlotListing, DeliverySlotForm } from './views/deliveryslots';
import { ReviewListing, ReviewForm } from './views/reviews';
import { PromoListing, PormotionForm } from './views/promotions';
import { PostListing, PostForm } from './views/posts';
import { ReferralForm } from './views/referrals';
import { DeliveryOrders } from './views/deliveryOrders';

export default [
  {
    path: '/',
    exact: true,
    layout: BlankLayout,
    component: Login,
  },
  {
    path: '/logout',
    exact: true,
    layout: BlankLayout,
    component: Logout,
  },
  {
    path: '/reset-password',
    exact: true,
    layout: BlankLayout,
    component: ResetPassword,
  },
  {
    path: '/roles',
    exact: true,
    auth: true,
    module: 'roles',
    layout: DefaultLayout,
    component: RoleListing,
  },
  {
    path: '/roles/new',
    exact: true,
    auth: true,
    layout: DefaultLayout,
    component: RoleForm,
  },
  {
    path: '/roles/:id',
    exact: true,
    auth: true,
    layout: DefaultLayout,
    component: RoleForm,
  },
  {
    path: '/admins',
    exact: true,
    auth: true,
    module: 'admins',
    layout: DefaultLayout,
    component: AdminListing,
  },
  {
    path: '/admins/new',
    exact: true,
    auth: true,
    module: 'admins',
    layout: DefaultLayout,
    component: AdminForm,
  },
  {
    path: '/admins/:id',
    exact: true,
    auth: true,
    module: 'admins',
    layout: DefaultLayout,
    component: AdminForm,
  },
  {
    path: '/customers',
    exact: true,
    auth: true,
    module: 'customers',
    layout: DefaultLayout,
    component: CustomerListing,
  },
  {
    path: '/customers/:id',
    exact: true,
    auth: true,
    module: 'customers',
    layout: DefaultLayout,
    component: CustomerDetail,
  },
  {
    path: '/products',
    exact: true,
    auth: true,
    module: 'products',
    layout: DefaultLayout,
    component: ProductListing,
  },
  {
    path: '/products/new',
    exact: true,
    auth: true,
    module: 'products',
    layout: DefaultLayout,
    component: ProductForm,
  },
  {
    path: '/products/:id',
    exact: true,
    auth: true,
    module: 'products',
    layout: DefaultLayout,
    component: ProductForm,
  },
  {
    path: '/promotions',
    exact: true,
    auth: true,
    module: 'promos',
    layout: DefaultLayout,
    component: PromoListing,
  },
  {
    path: '/promotions/new',
    exact: true,
    auth: true,
    module: 'promos',
    layout: DefaultLayout,
    component: PormotionForm,
  },
  {
    path: '/promotions/:id',
    exact: true,
    auth: true,
    module: 'promos',
    layout: DefaultLayout,
    component: PormotionForm,
  },
  {
    path: '/referrals',
    exact: true,
    auth: true,
    module: 'referrals',
    layout: DefaultLayout,
    component: ReferralForm,
  },
  {
    path: '/orders',
    exact: true,
    auth: true,
    module: 'orders',
    layout: DefaultLayout,
    component: OrderListing,
  },
  {
    path: '/orders/new',
    exact: true,
    auth: true,
    module: 'orders',
    layout: DefaultLayout,
    component: OrderForm,
  },
  {
    path: '/orders/:userId',
    exact: true,
    auth: true,
    module: 'orders',
    layout: DefaultLayout,
    component: OrderListing,
  },
  {
    path: '/orders/view/:id',
    exact: true,
    auth: true,
    module: 'orders',
    layout: DefaultLayout,
    component: OrderViewForm,
  },
  {
    path: '/orders/edit/:id',
    exact: true,
    auth: true,
    module: 'orders',
    layout: DefaultLayout,
    component: OrderForm,
  },
  {
    path: '/delivery-slots',
    exact: true,
    auth: true,
    module: 'deliverySlots',
    layout: DefaultLayout,
    component: DeliverySlotListing,
  },
  {
    path: '/delivery-slots/new',
    exact: true,
    auth: true,
    module: 'deliverySlots',
    layout: DefaultLayout,
    component: DeliverySlotForm,
  },
  {
    path: '/delivery-slots/:id',
    exact: true,
    auth: true,
    module: 'deliverySlots',
    layout: DefaultLayout,
    component: DeliverySlotForm,
  },
  {
    path: '/delivery-orders',
    exact: true,
    auth: true,
    module: 'deliverySlots',
    layout: DefaultLayout,
    component: DeliveryOrders,
  },
  {
    path: '/reviews',
    exact: true,
    auth: true,
    module: 'reviews',
    layout: DefaultLayout,
    component: ReviewListing,
  },
  {
    path: '/reviews/:id',
    exact: true,
    auth: true,
    module: 'reviews',
    layout: DefaultLayout,
    component: ReviewForm,
  },
  {
    path: '/posts',
    exact: true,
    auth: true,
    module: 'posts',
    layout: DefaultLayout,
    component: PostListing,
  },
  {
    path: '/posts/new',
    exact: true,
    auth: true,
    module: 'posts',
    layout: DefaultLayout,
    component: PostForm,
  },
  {
    path: '/posts/:id',
    exact: true,
    auth: true,
    module: 'posts',
    layout: DefaultLayout,
    component: PostForm,
  },
  {
    path: '/dashboard',
    exact: true,
    auth: true,
    layout: DefaultLayout,
    component: Dashboard,
  },
  {
    path: '',
    layout: BlankLayout,
    component: () => (
      <StatusError
        statusCode={404}
        title="Oops, something went wrong here!"
        message="The requested URL is not found on the server. That's all we know."
      />
    ),
  },
];
