import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FileInputContainer = styled.div`
  background-color: #fbfbfb;
  border: 1px dashed #8a8a8a;
  border-radius: 0.625rem;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
`;

const FileInput = ({ reset, className, onSelectFile, width, height }) => {
  const refUpload = useRef(null);

  useEffect(() => {
    if (reset) refUpload.current.value = null;
  }, [reset]);

  const handleClickUpload = () => {
    refUpload.current.click();
  };

  return (
    <div
      className={className}
      style={{
        width: width,
        height: height,
        lineHeight: height,
      }}
    >
      <FileInputContainer onClick={() => handleClickUpload()}>
        +
      </FileInputContainer>
      <input
        className="d-none"
        type="file"
        ref={refUpload}
        onChange={(event) => onSelectFile(event)}
      />
    </div>
  );
};

FileInput.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  reset: PropTypes.bool,
  className: PropTypes.string,
  onSelectFile: PropTypes.func,
};

FileInput.defaultProps = {
  width: '150px',
  height: '150px',
  reset: false,
  className: '',
  onSelectFile: () => {},
};

export default FileInput;
