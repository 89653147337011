import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Badge,
} from 'shards-react';
import moment from 'moment';

import { MaterialIcon } from '../../components/common';
import { ReferralWithdrawMode } from '../../constants';

const CustomerOverview = ({
  data: {
    firstName,
    lastName,
    displayPic,
    email,
    contactNo,
    joinedOn,
    address,
    country,
    postal,
    uid,
    referrals,
  },
}) => (
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded-circle"
          src={displayPic}
          alt=""
          role="presentation"
          width="110"
        />
      </div>
      <h4 className="mb-2">
        {firstName} {lastName}
      </h4>
      <span className="text-muted d-block mb-2">
        {uid &&
          joinedOn &&
          `Since ${joinedOn && moment.unix(joinedOn.seconds).format('ll')}`}
        {(!uid || !joinedOn) && 'Guest'}
      </span>
      <span className="text-muted d-block mb-2">
        <MaterialIcon icon="email" className="mr-2" />
        <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
          {email}
        </a>
      </span>
      <span className="text-muted d-block mb-2">
        <MaterialIcon icon="phone" className="mr-2" />
        <a href={`tel:${contactNo}`}>{contactNo}</a>
      </span>
      {referrals && referrals.mode === ReferralWithdrawMode.BANK && (
        <span className="text-muted d-block mb-2">
          <MaterialIcon icon="account_balance" className="mr-2" />
          {referrals.bankName} {referrals.bankAccountNo}
        </span>
      )}
      <Badge>{referrals && referrals.code}</Badge>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-4">
        <strong className="text-muted d-block mb-2">Address</strong>
        {address}
        <br />
        {country}
        <br />
        {postal}
      </ListGroupItem>
    </ListGroup>
  </Card>
);

CustomerOverview.propTypes = {
  data: PropTypes.object,
};

CustomerOverview.defaultProps = {
  data: {
    firstName: '',
    lastName: '',
    displayPic: '',
    email: '',
    contactNo: '',
    joinedOn: {
      seconds: '',
    },
    address: '',
    country: '',
    postal: '',
    referrals: {
      code: '',
    },
  },
};

export default CustomerOverview;
