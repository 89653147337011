import React from 'react';
import PropTypes from 'prop-types';

import PostEditorV1 from './PostEditorV1';
import PostEditorV2 from './PostEditorV2';

const PostEditor = (props) => {
  const { version } = props;
  switch (version) {
    case 'v2':
      return <PostEditorV2 {...props} />;
    default:
      return <PostEditorV1 {...props} />;
  }
};

PostEditor.propTypes = {
  onChange: PropTypes.func,
  version: PropTypes.oneOf(['v1', 'v2']),
};

PostEditor.defaultProps = {
  version: 'v1',
  onChange: () => {},
};

export default PostEditor;
