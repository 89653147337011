import React, { useState } from 'react';
import { Badge, Modal, ModalBody } from 'shards-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getPromoSummary } from '../../utils/promo';

const StyledBadge = styled(Badge)`
  cursor: pointer;
`;

const PromoBadge = ({ promo, className }) => {
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const renderContent = (content) => {
    if (Array.isArray(content)) {
      return content.map((c, i) => {
        if (i === 0) {
          return c;
        }
        return (
          <React.Fragment key={i}>
            <br /> {c}
          </React.Fragment>
        );
      });
    }

    return content;
  };

  return (
    <>
      <StyledBadge
        theme="secondary"
        className={className}
        onClick={toggleModal}
      >
        {promo.code}
      </StyledBadge>
      <Modal open={openModal} toggle={toggleModal}>
        <ModalBody>
          <h5>
            <Link to={`/promos/${promo.id}`}>{promo.code}</Link>
          </h5>
          <ul>
            {getPromoSummary(promo).map((summary, i) => (
              <li key={i}>{renderContent(summary)}</li>
            ))}
          </ul>
        </ModalBody>
      </Modal>
    </>
  );
};

PromoBadge.propTypes = {
  promo: PropTypes.object.isRequired,
  className: PropTypes.string,
};

PromoBadge.defaultProps = {
  className: '',
};

export default PromoBadge;
