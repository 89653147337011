import React, { useState } from 'react';
import classNames from 'classnames';
import {
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText,
} from 'shards-react';
import PropTypes from 'prop-types';
import MaterialIcon from './MaterialIcon';

import '../../assets/range-date-picker.css';

const RangeDatePicker = ({ className }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const classes = classNames(className, 'd-flex', 'my-auto', 'date-range');

  const handleStartDateChange = (value) => {
    setStartDate(new Date(value));
  };

  const handleEndDateChange = (value) => {
    setEndDate(new Date(value));
  };

  return (
    <InputGroup className={classes}>
      <DatePicker
        size="sm"
        selected={startDate}
        onChange={handleStartDateChange}
        placeholderText="Start Date"
        dropdownMode="select"
        className="text-center"
      />
      <DatePicker
        size="sm"
        selected={endDate}
        onChange={handleEndDateChange}
        placeholderText="End Date"
        dropdownMode="select"
        className="text-center"
      />
      <InputGroupAddon type="append">
        <InputGroupText>
          <MaterialIcon icon="&#xE916;" />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};

RangeDatePicker.propTypes = {
  className: PropTypes.string,
};

RangeDatePicker.defaultProps = {
  className: '',
};

export default RangeDatePicker;
