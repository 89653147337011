import { error, success } from 'react-toastify-redux';
import {
  adminsCollection,
  archivesCollection,
  firebase,
  firestore,
  auth as firebaseAuth,
} from '../../firebase';
import { startLoading, stopLoading, redirect } from './ui';
import { authResetPassword } from './auth';
import { passwordGenerator } from '../../utils';
import { getAdmin as findAdmin, getToken } from '../../utils/auth';

const apiEndpoint = `${process.env.REACT_APP_WOOSA_API_URI}/api/auth`;

export const AdminActionTypes = {
  GET_ADMINS: 'GET_ADMINS',
  GET_ADMIN: 'GET_ADMIN',
  CREATE_ADMIN: 'CREATE_ADMIN',
  UPDATE_ADMIN: 'UPDATE_ADMIN',
  DELETE_ADMIN: 'DELETE_ADMIN',
};

/**
 * Retrieve multiple admins from firebase
 * @param {object} paging
 * @param {string} paging.orderBy order by field name
 * @param {string} paging.order get desc or asc
 * @param {number} paging.page current page
 * @param {number} paging.size per page size
 */
export const getAdmins = (paging = {}) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      if (!paging.orderBy) paging.orderBy = 'audit.createdOn';
      if (!paging.order) paging.order = 'desc';
      if (!paging.page) paging.page = 1;
      if (!paging.size) paging.size = 10;

      const adminResponse = await adminsCollection
        .orderBy(paging.orderBy, paging.order)
        .limit(paging.size)
        .get();

      const items = [];

      adminResponse.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
      });

      dispatch({
        type: AdminActionTypes.GET_ADMINS,
        admins: { items, paging },
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve single admin from firebase
 * @param {string} id get by object id
 */
export const getAdmin = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const doc = await adminsCollection.doc(id).get();

      const admin = {
        id: doc.id,
        ...doc.data(),
      };

      dispatch({
        type: AdminActionTypes.GET_ADMIN,
        admin,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Update admin from firebase
 * @param {string} id
 * @param {Admin} admin
 */
export const updateAdmin = (id, admin) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      admin.audit = {
        ...admin.audit,
        updatedBy: findAdmin().name,
        updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };
      await adminsCollection.doc(id).set(admin);
      dispatch(success('Successfully updated!'));
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Update admin from firebase
 * @param {Admin} admin
 */
export const createAdmin = (admin) => {
  return async (dispatch) => {
    let authResponse;
    try {
      dispatch(startLoading());
      const { currentUser } = firebaseAuth;
      authResponse = await firebaseAuth.createUserWithEmailAndPassword(
        admin.email,
        passwordGenerator(8)
      );
      const { uid } = authResponse.user;
      await firebaseAuth.updateCurrentUser(currentUser);

      admin.audit = {
        createdBy: findAdmin().name,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      };
      await adminsCollection.doc(uid).set(admin);
      dispatch(authResetPassword(admin.email));

      dispatch(redirect(`/admins/${uid}`));
      setTimeout(() => dispatch(success('Successfully created!')), 200);
    } catch (err) {
      dispatch(error(err.message));
      if (authResponse) authResponse.user.delete();
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Update admin from firebase
 * @param {string} id
 * @param {Admin} admin
 */
export const deleteAdmin = (id) => {
  return async (dispatch, getState) => {
    try {
      const {
        auth: { admin },
      } = getState();

      dispatch(startLoading());

      admin.audit = {
        ...admin.audit,
        deletedBy: findAdmin().name,
        deletedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const batch = firestore.batch();
      batch.delete(adminsCollection.doc(id));
      batch.update(archivesCollection.doc('admins'), { [id]: admin });

      const headers = {
        Authorization: `Bearer ${getToken().token}`,
      };
      const response = await fetch(`${apiEndpoint}/${id}`, {
        method: 'DELETE',
        headers,
      });
      if (response.status === 403) throw new Error('Unauthorized');
      if (response.status !== 201 && response.status !== 200) throw response;

      await batch.commit();

      dispatch({
        type: AdminActionTypes.DELETE_ADMIN,
      });
      dispatch(redirect('/admins'));

      setTimeout(() => dispatch(success('Successfully deleted!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
