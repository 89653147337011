import { ReviewActionTypes } from '../actions/reviews';

const initialState = {
  reviews: {
    items: [],
    paging: {
      page: 0,
      size: 20,
      orderBy: 'submittedOn',
      order: 'desc',
      filter: '',
    },
    lastSnapshot: null,
  },
  review: {},
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {ReviewActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ReviewActionTypes.GET_REVIEWS:
      return { ...state, reviews: { ...action.reviews } };
    case ReviewActionTypes.GET_REVIEW:
      return {
        ...state,
        review: { ...action.review },
      };
    case ReviewActionTypes.UPDATE_REVIEW:
      return {
        ...state,
        review: { ...action.review },
        reviews: { ...action.reviews },
      };
    case ReviewActionTypes.DELETE_REVIEW:
      return { ...state, review: {} };
    default:
      return state;
  }
}
