import { error, success, warning, info } from 'react-toastify-redux';

import { adminsCollection, auth as firebaseAuth } from '../../firebase';
import { startLoading, stopLoading } from './ui';
import {
  getAdmin as getAdminFromLocalStorage,
  setAdmin,
  getToken as getTokenFromLocalStorage,
  setToken,
  clearAuth,
  AuthAlertStorageKeys,
} from '../../utils/auth';

export const AuthActionTypes = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
};

/**
 * Helps to retrieve information regarding admin from firebase and set localStorage
 * @param {string} uid
 */
const getAdmin = async (uid) => {
  let admin = getAdminFromLocalStorage();
  if (!admin) {
    const response = await adminsCollection.doc(uid).get();
    const data = response.data();
    if (data) admin = { id: response.id, ...response.data() };
    setAdmin(admin);
  }
  return admin;
};

/**
 * Helps to perform token operations and set localStorage
 * @param {firebase.auth.UserCredential} authUser
 */
const getToken = async () => {
  let token = getTokenFromLocalStorage();
  if (!token) {
    token = await firebaseAuth.currentUser.getIdTokenResult();
    token = setToken(token);
  }
  return token;
};

/**
 * Logout
 */
export const authLogOut = () => {
  firebaseAuth.signOut();
  clearAuth();
  return {
    type: AuthActionTypes.LOGOUT,
  };
};

/**
 * Log in
 * @param {string} email
 * @param {string} password
 */
export const authLogIn = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const authResponse = await firebaseAuth.signInWithEmailAndPassword(
        email,
        password
      );

      const admin = await getAdmin(authResponse.user.uid);
      if (!admin) throw new Error('Invalid account');

      if (!authResponse.user.emailVerified) {
        authResponse.user.sendEmailVerification({
          url: process.env.REACT_APP_AUTH_REDIRECT_URI,
        });
        throw new Error('Please verify your email');
      }

      dispatch({
        type: AuthActionTypes.LOGIN,
        admin,
        token: await getToken(),
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Reset Password
 * @param {string} email
 */
export const authResetPassword = (email) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      await firebaseAuth.sendPasswordResetEmail(email, {
        url: process.env.REACT_APP_AUTH_REDIRECT_URI,
      });
      dispatch(success('Reset password link has been sent out'));
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * show alert when user is not authenticated
 */
export const executeAuthAlert = () => {
  return async (dispatch) => {
    const accessDenied = localStorage.getItem(
      AuthAlertStorageKeys.LOGIN_REQUIRED
    );
    if (accessDenied) {
      localStorage.removeItem(AuthAlertStorageKeys.LOGIN_REQUIRED);
      dispatch(warning('Authentication required, please login before proceed'));
    }
  };
};

/**
 * show alert when user does not has access to the module
 */
export const executeAccessAlert = () => {
  return async (dispatch) => {
    const deniedModule = localStorage.getItem(
      AuthAlertStorageKeys.DENIED_MODULE
    );
    if (deniedModule) {
      localStorage.removeItem(AuthAlertStorageKeys.DENIED_MODULE);
      dispatch(
        info(
          `Access denied, you do not have the permission to access ${deniedModule} module`
        )
      );
    }
  };
};
