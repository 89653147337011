import React from 'react';
import { Button as ShardsButton } from 'shards-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MaterialIcon from './MaterialIcon';

const Button = styled(ShardsButton)`
  display: flex;
  flex-wrap: nowrap;
  padding: 0.6rem 0.7rem;

  i {
    font-size: 1rem;
    font-weight: bold;
    top: 0;
  }
`;

const IconButton = ({
  id,
  icon,
  theme,
  onClick,
  disabled,
  className,
  type,
  children,
  position,
  size,
}) => {
  return (
    <Button
      id={id}
      type={type}
      className={className}
      theme={theme}
      onClick={onClick}
      disabled={disabled}
      size={size}
    >
      {position === 'before' && children}
      <MaterialIcon icon={icon} />
      {position === 'after' && children}
    </Button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  theme: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  position: PropTypes.string,
  size: PropTypes.string,
};

IconButton.defaultProps = {
  theme: 'primary',
  onClick: () => {},
  disabled: false,
  className: '',
  type: 'button',
  id: '',
  children: null,
  position: 'after',
  size: '',
};

export default IconButton;
