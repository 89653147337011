import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormCheckbox,
  FormInput,
  FormSelect,
  FormGroup as ShardsFormGroup,
  DatePicker,
} from 'shards-react';
import moment from 'moment';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { warning } from 'react-toastify-redux';
import { confirmAlert } from 'react-confirm-alert';
import { DeliveryStatus } from '../../constants';
import { Dialog } from '../../components/common';

const deliveryStatusOptions = [];
Object.keys(DeliveryStatus).forEach((key) => {
  const status = DeliveryStatus[key];
  deliveryStatusOptions.push({ value: status, label: status });
});

const ProductTable = styled.table`
  font-size: 13px;
  td {
    vertical-align: middle;
    img {
      max-width: 80px;
      margin-right: 10px;
    }
  }
`;

const ProductTd = styled.td`
  display: flex;
  align-items: center;
`;

const ScrollableModalBody = styled(ModalBody)`
  max-height: 500px;
  min-height: 400px;
  overflow: auto;
`;

const ScheduleModal = ({
  delivery,
  onAddSchedule,
  onUpdateSchedule,
  open,
  onClose,
  products,
  deliverySlots,
  existingSchdules,
}) => {
  const dispatch = useDispatch();
  const [deliveryTimeOptions, setDeliveryTimeOptions] = useState([]);
  const [scheduleProducts, setScheduleProducts] = useState([]);

  const [deliveryState, setDeliveryState] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryDateId, setDeliveryDateId] = useState('');
  const [deliveryStatus, setDeliveryStatus] = useState(
    DeliveryStatus.PENDING_DELIVERY
  );
  const [deliveryTime, setDeliveryTime] = useState('');

  useEffect(() => {
    if (!deliverySlots.length && open)
      dispatch(warning('Available slots not found'));

    const newScheduleProducts = [];
    const cloneProducts = _.cloneDeep(products);
    cloneProducts
      .filter((p) => p.shippable)
      .forEach((p) => {
        let totalDeliveredQty = 0;
        let totalAllocatedQty = 0;
        if (existingSchdules.length) {
          existingSchdules.forEach((s) => {
            s.products.forEach((sp) => {
              if (sp.uuid === p.uuid) {
                totalDeliveredQty += sp.qty;
                totalAllocatedQty += sp.allocatedQty;
              }
            });
          });
        }
        p.remainingQty = p.qty - totalDeliveredQty;
        p.remainingQty = p.remainingQty < 0 ? 0 : p.remainingQty;
        p.deliveryQty = p.remainingQty;
        p.totalAllocatedQty = totalAllocatedQty;
        p.allocationQty = p.qty - totalAllocatedQty;
        p.checked = false;
        newScheduleProducts.push(p);
      });
    setScheduleProducts([...newScheduleProducts]);

    if (!delivery)
      handleSelectDeliveryDate(moment().add(1, 'days').startOf('day').toDate());
  }, [open, existingSchdules, products]);

  useEffect(() => {
    if (delivery) {
      const data = _.cloneDeep(delivery);
      setDeliveryStatus(data.status);
      data.products.map((dp) => {
        const product = products.find((p) => p.uuid === dp.uuid);
        if (product) {
          dp.image = product.image;
          dp.collection = product.collection;
          dp.orderQty = product.qty;
          dp.remainingQty = product.qty;
        }
        return dp;
      });
      setDeliveryState(data);
    }
  }, [delivery]);

  const handleSelectDeliveryDate = (selectedDate) => {
    setDeliveryDate(selectedDate);
    const seconds = moment(selectedDate).unix();
    const timeOptions = [];
    const deliverySlot = deliverySlots.find((s) => s.date.seconds === seconds);

    if (deliverySlot)
      deliverySlot.slots.forEach((slot) => {
        if (slot.count < deliverySlot.maxPerSlot) {
          timeOptions.push({
            value: slot.time,
            label: slot.time,
          });
        }
      });

    setDeliveryTime('');
    setDeliveryDateId(deliverySlot ? deliverySlot.id : '');
    setDeliveryTimeOptions(timeOptions);
  };

  const handleSelectDeliveryStatus = (e) => {
    const { value } = e.target;
    setDeliveryStatus(value);
  };

  const handleSelectDeliveryTime = (e) => {
    const { value } = e.target;
    setDeliveryTime(value);
  };

  const handleOnChangeDeliveryQty = (e, index) => {
    const { value } = e.target;
    const qty = parseFloat(value);
    const newData = [...scheduleProducts];
    newData[index].deliveryQty =
      qty > newData[index].remainingQty || qty <= 0
        ? newData[index].remainingQty
        : qty;
    setScheduleProducts(newData);
  };

  const handleOnChangeAllocationQty = (e, index) => {
    const { value } = e.target;
    const qty = parseFloat(value);
    const newData = [...scheduleProducts];
    newData[index].allocationQty = qty;
    setScheduleProducts(newData);
  };

  const handleOnChangeAllocatedQty = (e, index) => {
    const { value } = e.target;
    const qty = parseFloat(value);
    deliveryState.products[index].allocatedQty = qty;
    setScheduleProducts({ ...deliveryState });
  };

  const handleOnChangeQty = (e, index) => {
    const { value } = e.target;
    const qty = parseFloat(value);
    deliveryState.products[index].qty = qty;
    setScheduleProducts({ ...deliveryState });
  };

  const handleSelect = (index) => {
    const newData = [...scheduleProducts];
    newData[index].checked = !newData[index].checked;
    setScheduleProducts(newData);
  };

  const handleOnClose = () => {
    setDeliveryState(null);
    setDeliveryStatus('pending delivery');
    setDeliveryDateId('');
    setDeliveryDate('');
    setDeliveryTime('');
    setScheduleProducts([]);
    onClose();
  };

  const handleAlert = () => {
    return new Promise((resolve) => {
      confirmAlert({
        customUI: (res) => {
          return (
            <Dialog>
              <p>
                Allocation qty is less than delivery qty, are you sure you want
                to proceed?
              </p>
              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2"
                  outline
                  theme="secondary"
                  type="button"
                  onClick={() => {
                    resolve(false);
                    res.onClose();
                  }}
                >
                  No
                </Button>
                <Button
                  outline
                  type="button"
                  onClick={() => {
                    resolve(true);
                    res.onClose();
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Dialog>
          );
        },
      });
    });
  };

  const handleAddSchedule = async () => {
    if (!deliveryDateId || !deliveryTime) {
      dispatch(warning(`Delivery datetime is required!`));
      return;
    }
    const checkedProducts = scheduleProducts.filter((p) => p.checked);
    if (!checkedProducts.length) {
      dispatch(warning(`Select at least one product to deliver!`));
      return;
    }

    let showAlert = false;
    for (const p of checkedProducts) {
      if (p.allocationQty > p.deliveryQty) {
        dispatch(warning(`Allocated qty cannot be greater than delivery qty!`));
        return;
      }
      if (p.allocationQty < p.deliveryQty) showAlert = true;
    }

    if (showAlert) {
      const confirmed = await handleAlert();
      if (!confirmed) return;
    }

    const deliverySlot = deliverySlots.find((d) => d.id === deliveryDateId);
    const schedule = {
      uuid: uuidv4(),
      id: deliverySlot.id,
      createdUnix: moment().valueOf(),
      date: moment.unix(deliverySlot.date.seconds).toDate(),
      time: deliveryTime,
      status: deliveryStatus,
      products: [],
    };
    checkedProducts.forEach((p) => {
      schedule.products.push({
        uuid: p.uuid,
        id: p.id,
        name: p.name,
        variant: p.variant,
        qty: p.deliveryQty,
        allocatedQty: p.allocationQty,
        subVariants: p.subVariants || [],
      });
    });
    onAddSchedule(schedule);
    handleOnClose();
  };

  const handleUpdateSchedule = () => {
    delivery.status = deliveryStatus;
    for (const p of deliveryState.products) {
      if (p.allocatedQty > p.qty) {
        dispatch(warning(`Allocated qty cannot be greater than delivery qty!`));
        return;
      }
    }
    delivery.products = deliveryState.products.map((p) => {
      return {
        uuid: p.uuid,
        id: p.id,
        name: p.name,
        variant: p.variant,
        qty: p.qty,
        allocatedQty: p.allocatedQty,
        subVariants: p.subVariants || [],
      };
    });
    onUpdateSchedule(delivery);
    handleOnClose();
  };

  return (
    <Modal centered open={open} toggle={handleOnClose} size="xl">
      <ModalHeader>Add Schedule</ModalHeader>
      <ScrollableModalBody>
        <Row>
          <Col lg={4} className="mb-3">
            <ShardsFormGroup>
              <label>Delivery Status</label>
              <FormSelect
                disabled={
                  deliveryState &&
                  deliveryState.status !== DeliveryStatus.PENDING_DELIVERY
                }
                value={deliveryStatus}
                onChange={handleSelectDeliveryStatus}
              >
                {deliveryStatusOptions.map((option, oIndex) => {
                  return (
                    <option key={oIndex} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </FormSelect>
            </ShardsFormGroup>
          </Col>

          {deliveryState && (
            <>
              <Col lg={4} className="mb-3">
                <label>Schedule Date</label>
                <br />
                <label>{moment(deliveryState.date).format('D MMM YYYY')}</label>
              </Col>
              <Col lg={4} className="mb-3">
                <label>Schedule Time</label>
                <br />
                <label>{deliveryState.time}</label>
              </Col>
            </>
          )}

          {!deliveryState && (
            <>
              {deliverySlots.length > 0 && (
                <Col lg={4} className="mb-3">
                  <ShardsFormGroup>
                    <label>Schedule Date</label>
                    <DatePicker
                      dateFormat="d MMM yyyy"
                      showTimeSelect={false}
                      dropdownMode="select"
                      placeholderText="Select Date"
                      autoComplete="off"
                      onChange={handleSelectDeliveryDate}
                      selected={deliveryDate}
                    />
                  </ShardsFormGroup>
                </Col>
              )}
              {deliveryTimeOptions.length > 0 && (
                <Col lg={4} className="mb-3">
                  <ShardsFormGroup>
                    <label>Schedule Time</label>
                    <FormSelect
                      value={deliveryTime}
                      onChange={handleSelectDeliveryTime}
                    >
                      <option value="">Please select</option>
                      {deliveryTimeOptions.map((item, oIndex) => {
                        return (
                          <option key={oIndex} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </FormSelect>
                  </ShardsFormGroup>
                </Col>
              )}
            </>
          )}
        </Row>

        {deliveryState && (
          <div className="table-responsive">
            <ProductTable className="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Variant</th>
                  <th>Order Qty</th>
                  <th>Delivery Qty</th>
                  <th>Allocation Qty</th>
                </tr>
              </thead>
              <tbody>
                {deliveryState.products.map((product, index) => {
                  return (
                    <tr key={index}>
                      <ProductTd className="d-flex">
                        <img src={product.image} alt="" />
                        <div>
                          <strong>{product.collection}</strong>
                          <br />
                          {product.name}
                        </div>
                      </ProductTd>
                      <td>{product.variant}</td>
                      <td>{product.orderQty}</td>
                      <td>
                        {deliveryState.status ===
                        DeliveryStatus.PENDING_DELIVERY ? (
                          <FormInput
                            className="py-1 px-2"
                            type="number"
                            min={1}
                            max={product.remainingQty}
                            value={product.qty}
                            onChange={(e) => {
                              handleOnChangeQty(e, index);
                            }}
                          />
                        ) : (
                          product.orderQty
                        )}
                      </td>
                      <td>
                        {deliveryState.status ===
                        DeliveryStatus.PENDING_DELIVERY ? (
                          <FormInput
                            className="py-1 px-2"
                            type="number"
                            min={0}
                            max={product.qty}
                            value={product.allocatedQty}
                            onChange={(e) => {
                              handleOnChangeAllocatedQty(e, index);
                            }}
                          />
                        ) : (
                          product.allocatedQty
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </ProductTable>
          </div>
        )}

        {!deliveryState && scheduleProducts.length > 0 && (
          <div className="table-responsive">
            <ProductTable className="table">
              <thead>
                <tr>
                  <th> </th>
                  <th>Item</th>
                  <th>Variant</th>
                  <th width="100">Order Qty</th>
                  <th width="100">Pending Delivery</th>
                  <th width="100">Delivery Qty</th>
                  <th width="100">Pending Allocation</th>
                  <th width="100">Allocation Qty</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {scheduleProducts.map((product, index) => {
                  if (product.remainingQty <= 0) return null;
                  return (
                    <tr key={index}>
                      <td>
                        {product.remainingQty > 0 && (
                          <FormCheckbox
                            className="mb-0"
                            onChange={() => handleSelect(index)}
                            checked={product.checked}
                          />
                        )}
                      </td>
                      <ProductTd className="d-flex">
                        <img src={product.image} alt="" />
                        <div>
                          <strong>{product.collection}</strong>
                          <br />
                          {product.name}
                        </div>
                      </ProductTd>
                      <td>{product.variant}</td>
                      <td>{product.qty}</td>
                      <td>{product.remainingQty}</td>
                      <td>
                        <FormInput
                          className="py-1 px-2"
                          type="number"
                          min={1}
                          max={product.remainingQty}
                          value={product.deliveryQty}
                          onChange={(e) => handleOnChangeDeliveryQty(e, index)}
                        />
                      </td>
                      <td>{product.qty - product.totalAllocatedQty}</td>
                      <td>
                        <FormInput
                          className="py-1 px-2"
                          type="number"
                          min={0}
                          max={product.qty - product.totalAllocatedQty}
                          value={product.allocationQty}
                          onChange={(e) => {
                            handleOnChangeAllocationQty(e, index);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </ProductTable>
          </div>
        )}
      </ScrollableModalBody>
      <ModalFooter>
        {!deliveryState && (
          <Button type="button" theme="primary" onClick={handleAddSchedule}>
            Add
          </Button>
        )}
        {deliveryState && (
          <Button type="button" theme="primary" onClick={handleUpdateSchedule}>
            Update
          </Button>
        )}
        <Button type="button" theme="secondary" onClick={handleOnClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ScheduleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onAddSchedule: PropTypes.func,
  onUpdateSchedule: PropTypes.func,
  onClose: PropTypes.func,
  products: PropTypes.array,
  deliverySlots: PropTypes.array,
  existingSchdules: PropTypes.array,
  delivery: PropTypes.object,
};

ScheduleModal.defaultProps = {
  products: [],
  deliverySlots: [],
  existingSchdules: [],
  delivery: null,
  onAddSchedule: () => {},
  onUpdateSchedule: () => {},
  onClose: () => {},
};

export default ScheduleModal;
