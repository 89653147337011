import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardBody, FormTextarea } from 'shards-react';

const MAX_DESCRIPTION_LENGTH = 160;

const PostSidebarMeta = ({ onChange }) => {
  const { post } = useSelector(({ posts }) => posts);
  const [meta, setMeta] = useState(post.meta || {});

  useEffect(() => {
    setMeta(post.meta || {});
  }, [post.meta]);

  const handleDescriptionChange = (event) => {
    const description = event.target.value;
    if (description.length > MAX_DESCRIPTION_LENGTH) return;
    const newMeta = { ...meta, description };
    setMeta(newMeta);
    onChange(newMeta);
  };

  return (
    <Card small className="mb-3">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Meta</h6>
      </CardHeader>
      <CardBody>
        <label htmlFor="meta_description">Short Description</label>
        <FormTextarea
          id="meta_description"
          size="lg"
          className="mb-0"
          value={meta.description || ''}
          onChange={handleDescriptionChange}
        />
        <div className="d-flex justify-content-end">
          <small>
            {(meta.description || '').length}/{MAX_DESCRIPTION_LENGTH}
          </small>
        </div>
      </CardBody>
    </Card>
  );
};

PostSidebarMeta.propTypes = {
  onChange: PropTypes.func,
};

PostSidebarMeta.defaultProps = {
  onChange: () => {},
};

export default PostSidebarMeta;
