import React from 'react';
import PropTypes from 'prop-types';

const HitPayPaymentInfo = ({ payment }) => {
  const { remarks, ipAddress } = payment;

  return (
    <div>
      {remarks.toLowerCase().includes('via')
        ? remarks
        : `Payment via ${remarks}`}
      {process.env.REACT_APP_HITPAY_MERCHANT_PORTAL_URL && (
        <>
          <br />
          <a
            href={process.env.REACT_APP_HITPAY_MERCHANT_PORTAL_URL}
            target="_blank"
            rel="noreferrer"
          >
            {process.env.REACT_APP_HITPAY_MERCHANT_PORTAL_URL}
          </a>
        </>
      )}
      {ipAddress && (
        <>
          <br />
          Customer IP: {ipAddress}
        </>
      )}
    </div>
  );
};

HitPayPaymentInfo.propTypes = {
  payment: PropTypes.shape({
    type: PropTypes.string,
    remarks: PropTypes.string,
    ipAddress: PropTypes.string,
  }),
};

HitPayPaymentInfo.defaultProps = {
  payment: {},
};

export default HitPayPaymentInfo;
