import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, Redirect } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormInput,
  Button,
} from 'shards-react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { authLogIn, executeAuthAlert } from '../../store/actions/auth';
import logo from '../../images/logo.svg';

const LoginContainer = styled.div`
  width: 450px;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Logo = styled.img`
  max-width: 50%;
  margin: 0 auto;
  display: block;
`;

const Login = () => {
  const dispatch = useDispatch();
  const { admin, token } = useSelector(({ auth }) => auth);
  const isLoading = useSelector(({ ui }) => ui.isLoading);
  const { handleSubmit, control } = useForm();

  const onSubmit = ({ email, password }) => {
    dispatch(authLogIn(email, password));
  };

  useEffect(() => {
    dispatch(executeAuthAlert());
  }, [dispatch]);

  if (admin && token) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <LoginContainer>
      <Logo className="mb-4" src={logo} role="presentation" alt="Woosa CMS" />
      <Card small className="mb-4">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <Row>
              <Col md="12" className="form-group">
                <label htmlFor="email">Email</label>
                <Controller
                  as={FormInput}
                  name="email"
                  placeholder="Email Address"
                  type="email"
                  control={control}
                  required
                  defaultValue=""
                />
              </Col>
              <Col md="12" className="form-group">
                <label htmlFor="password">Password</label>
                <Controller
                  as={FormInput}
                  name="password"
                  placeholder="Password"
                  type="password"
                  required
                  control={control}
                  defaultValue=""
                />
              </Col>
            </Row>
            <Button className="mt-2 w-100" disabled={isLoading} theme="accent">
              Login
            </Button>
            <div className="text-right mt-3">
              <Link to="/reset-password">Forget password?</Link>
            </div>
          </CardBody>
        </Form>
      </Card>
    </LoginContainer>
  );
};

export default Login;
