import { OrderReportActionTypes } from '../actions/orderReports';

const initialState = {
  orderReport: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {OrderReportActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OrderReportActionTypes.GET_ORDER_REPORT:
      return { ...state, orderReport: { ...action.orderReport } };
    default:
      return state;
  }
}
