import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'shards-react';
import styled from 'styled-components';
import DialogButton from './DialogButton';
import MaterialIcon from './MaterialIcon';

const ButtonContainer = styled.div`
  display: flex;
`;

const StyledDeleteButton = styled(DialogButton)`
  margin-left: 0.5rem;
`;

const FormCard = ({
  title,
  children,
  button,
  onSubmit,
  onDelete,
  actions,
  onAction,
}) => {
  const [openAction, setOpenAction] = useState(false);

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <div className="d-flex align-items-center">
          <h6 className="m-0 mr-auto">{title}</h6>
          {actions && actions.length > 0 && (
            <>
              <Dropdown
                open={openAction}
                toggle={() => setOpenAction(!openAction)}
              >
                <DropdownToggle theme="secondary" outline>
                  Actions <MaterialIcon className="ml-2" icon="expand_more" />
                </DropdownToggle>
                <DropdownMenu className="p-1" right>
                  {actions.map((action, index) => (
                    <React.Fragment key={index}>
                      {action.showDialog && (
                        <DialogButton
                          key={index}
                          mainButtonText={action.text}
                          className="w-100 text-left"
                          disabled={button.isLoading}
                          onConfirm={() => {
                            onAction(index, action);
                          }}
                        />
                      )}
                      {!action.showDialog && (
                        <Button
                          key={index}
                          type="button"
                          className="w-100 text-left btn-outline-light"
                          theme="secondary"
                          disabled={button.isLoading}
                          onClick={() => {
                            onAction(index, action);
                          }}
                        >
                          {action.text}
                        </Button>
                      )}
                    </React.Fragment>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </>
          )}
        </div>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form onSubmit={onSubmit}>
                {children}

                <ButtonContainer className="mt-2">
                  {onSubmit && (
                    <Button theme={button.theme} disabled={button.isLoading}>
                      {button.text}
                    </Button>
                  )}
                  {onDelete && (
                    <StyledDeleteButton
                      theme="danger"
                      disabled={button.isLoading}
                      onConfirm={onDelete}
                      icon="delete_outline"
                    />
                  )}
                </ButtonContainer>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

FormCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  button: PropTypes.shape({
    theme: PropTypes.string,
    text: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onAction: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      showDialog: PropTypes.bool,
    })
  ),
};

FormCard.defaultProps = {
  button: {
    theme: '',
    text: '',
    isLoading: false,
  },
  onSubmit: null,
  onDelete: null,
  onAction: null,
  actions: [],
};

export default FormCard;
