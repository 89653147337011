import { UserActionTypes } from '../actions/users';

const initialState = {
  users: {
    items: [],
    paging: {
      page: 0,
      size: 30,
      orderBy: 'joinedOn',
      order: 'desc',
      filter: '',
    },
    lastSnapshot: null,
  },
  user: {},
  orders: [],
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {UserActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UserActionTypes.GET_USERS:
      return { ...state, users: { ...action.users } };
    case UserActionTypes.GET_USER:
      return {
        ...state,
        user: { ...action.user },
        orders: [...action.orders],
      };
    case UserActionTypes.UPDATE_REFERRAL_WITHDRAWAL:
      return {
        ...state,
        user: { ...action.user },
      };
    default:
      return state;
  }
}
