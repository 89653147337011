import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import 'react-quill/dist/quill.snow.css';

import { UploadDialog } from '../../../components/common';

const Quill = styled(ReactQuill)`
  .ql-container,
  .ql-editor {
    min-height: 400px;
  }
`;

const PostEditor = ({ onChange }) => {
  const editorRef = useRef(null);
  const { post } = useSelector(({ posts }) => posts);
  const [content, setContent] = useState(post.content || '');
  const contentRef = useRef(content);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);

  const handleInsert = (media) => {
    const editor = editorRef.current.getEditor();
    const range = editor.getSelection();
    media.forEach((src) => editor.insertEmbed(range.index, 'image', src));
    editor.setSelection(range.index + 1);
  };

  useEffect(() => {
    const contentUpdates = post.content || '';
    setContent(contentUpdates);
    contentRef.current = contentUpdates;

    // eslint-disable-next-line
  }, [post.content]);

  return (
    <>
      <Quill
        ref={editorRef}
        className="mb-1"
        onChange={(values) => {
          contentRef.current = values;
          onChange(values);
        }}
        value={content}
        modules={{
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ['image'],
              ['clean'],
            ],
            handlers: {
              image: () => {
                setContent(contentRef.current);
                setOpenUploadDialog(true);
              },
            },
          },
        }}
      />
      <UploadDialog
        onInsert={handleInsert}
        onClose={() => setOpenUploadDialog(false)}
        open={openUploadDialog}
        uploadPath={`images/posts/${shortid.generate()}`}
      />
    </>
  );
};

PostEditor.propTypes = {
  onChange: PropTypes.func,
};

PostEditor.defaultProps = {
  onChange: () => {},
};

export default PostEditor;
