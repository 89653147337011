import React, { useEffect, useState } from 'react';
import { Row, Col } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getReferral, updateReferral } from '../../store/actions/referrals';
import { getProducts } from '../../store/actions/products';
import { MainStage } from '../../components/layout';
import { FormCard, FormGroup } from '../../components/common';
import { hasAccess } from '../../utils/auth';

const ReferralForm = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(({ ui }) => ui.isLoading);
  const referralData = useSelector(({ referrals: { referral } }) => referral);
  const productsData = useSelector(({ products: { products } }) => products);
  const [referralState, setReferralState] = useState({
    referee: { products: [] },
    referrer: { products: [] },
  });
  const { control, handleSubmit, errors, setValue } = useForm();
  const canUpdate = hasAccess('referrals', 'update');

  useEffect(() => {
    dispatch(getReferral());
    dispatch(getProducts());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (productsData.items.length && referralData) {
      const products = productsData.items;
      const referrerProducts = [];
      const refereeProducts = [];
      const { referrer, referee } = referralData;
      products.forEach((p) => {
        const product = {
          id: p.id,
          name: p.name,
          variants: p.variants.map((v) => {
            return { type: v.type };
          }),
        };
        referrerProducts.push(product);
        refereeProducts.push(product);
      });

      referrerProducts.forEach((p) => {
        const referrerProduct = referrer.products
          ? referrer.products.find((rp) => rp.id === p.id)
          : null;
        p.variants.map((v) => {
          if (referrerProduct) {
            const referrerVariant = referrerProduct.variants.find(
              (rv) => rv.type === v.type
            );
            v.amount = referrerVariant ? referrerVariant.amount : 0;
          }
          return v;
        });
        return p;
      });

      refereeProducts.forEach((p) => {
        const refereeProduct = referee.products
          ? referee.products.find((rp) => rp.id === p.id)
          : null;
        p.variants.map((v) => {
          if (refereeProduct) {
            const refereeVariant = refereeProduct.variants.find(
              (rv) => rv.type === v.type
            );
            v.amount = refereeVariant ? refereeVariant.amount : 0;
          }
          return v;
        });
        return p;
      });

      const referral = { ...referralData };
      referral.referrer.products = referrerProducts;
      referral.referee.products = refereeProducts;
      setReferralState(referral);
      setValue('referrer.active', referral.referrer.active);
      setValue('referee.active', referral.referee.active);
    }
    // eslint-disable-next-line
  }, [productsData, referralData]);

  const onSubmit = (data) => {
    const { referrer, referee } = data;
    referrer.products.map((p) => {
      if (p.variants) {
        p.variants.map((v) => {
          v.amount = parseFloat(v.amount);
          return v;
        });
      }
      return p;
    });

    referee.products.map((p) => {
      if (p.variants) {
        p.variants.map((v) => {
          v.amount = parseFloat(v.amount);
          return v;
        });
      }
      return p;
    });

    if (canUpdate) dispatch(updateReferral(referralData.id, data));
  };

  return (
    referralState && (
      <MainStage title="Overview" subtitle="Referral Program" to="/referrals">
        <FormCard
          title="Enter referral details"
          button={{ theme: 'primary', text: 'Save', isLoading }}
          onSubmit={handleSubmit(onSubmit)}
          form
        >
          <Row>
            <Col lg="6">
              <div className="d-flex px-2">
                <h5>Referrer</h5>
                <FormGroup
                  type="checkbox"
                  name="referrer.active"
                  toggle
                  control={control}
                  errors={errors}
                  defaultValue={referralState.referrer.active}
                />
              </div>
              <table className="table form-table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Variant</th>
                    <th>Rebate Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {referralState.referrer.products.length > 0 &&
                    referralState.referrer.products.map((product, pIndex) => {
                      return product.variants.map((variant, vIndex) => {
                        return (
                          <tr key={`${pIndex}_${vIndex}`}>
                            <td>{product.name}</td>
                            <td>{variant.type}</td>
                            <td>
                              <Row>
                                <FormGroup
                                  name={`referrer.products[${pIndex}].id`}
                                  control={control}
                                  className="d-none"
                                  defaultValue={product.id}
                                />
                                <FormGroup
                                  name={`referrer.products[${pIndex}].variants[${vIndex}].type`}
                                  control={control}
                                  className="d-none"
                                  defaultValue={variant.type}
                                />
                                <FormGroup
                                  name={`referrer.products[${pIndex}].variants[${vIndex}].amount`}
                                  type="number"
                                  min="0"
                                  control={control}
                                  rules={{ required: 'Required field' }}
                                  errors={errors}
                                  defaultValue={variant.amount}
                                />
                              </Row>
                            </td>
                          </tr>
                        );
                      });
                    })}
                </tbody>
              </table>
            </Col>
            <Col lg="6">
              <div className="d-flex px-2">
                <h5>Referee</h5>
                <FormGroup
                  type="checkbox"
                  name="referee.active"
                  toggle
                  control={control}
                  errors={errors}
                  defaultValue={referralState.referee.active}
                />
              </div>
              <table className="table form-table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Variant</th>
                    <th>Rebate Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {referralState.referee.products.length > 0 &&
                    referralState.referee.products.map((product, pIndex) => {
                      return product.variants.map((variant, vIndex) => {
                        return (
                          <tr key={`${pIndex}_${vIndex}`}>
                            <td>{product.name}</td>
                            <td>{variant.type}</td>
                            <td>
                              <Row>
                                <FormGroup
                                  name={`referee.products[${pIndex}].id`}
                                  control={control}
                                  className="d-none"
                                  defaultValue={product.id}
                                />
                                <FormGroup
                                  name={`referee.products[${pIndex}].variants[${vIndex}].type`}
                                  control={control}
                                  className="d-none"
                                  defaultValue={variant.type}
                                />
                                <FormGroup
                                  name={`referee.products[${pIndex}].variants[${vIndex}].amount`}
                                  type="number"
                                  min="0"
                                  control={control}
                                  rules={{ required: 'Required field' }}
                                  errors={errors}
                                  defaultValue={variant.amount}
                                />
                              </Row>
                            </td>
                          </tr>
                        );
                      });
                    })}
                </tbody>
              </table>
            </Col>
          </Row>
        </FormCard>
      </MainStage>
    )
  );
};

export default ReferralForm;
