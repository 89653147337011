import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const StripePaymentInfo = ({ payment, separator }) => {
  const data = payment.stripe || {};
  const sep = separator === 'lineBreak' ? <br /> : <> </>;
  const remarks = data._payment_method_title || payment.remarks;
  const transactionId =
    _.get(data, 'data.object.id') || data._transaction_id || data.id;
  const ipAddress = data._customer_ip_address || payment.ipAddress;

  return (
    <div>
      {remarks.toLowerCase().includes('payment via')
        ? remarks
        : `Payment via ${remarks}`}
      {transactionId && (
        <>
          {sep}
          <a
            href={`https://dashboard.stripe.com/payments/${transactionId}`}
            target="_blank"
            rel="noreferrer"
          >
            {transactionId}
          </a>
        </>
      )}
      {ipAddress && (
        <>
          {sep}
          Customer IP: {ipAddress}
        </>
      )}
    </div>
  );
};

StripePaymentInfo.propTypes = {
  payment: PropTypes.shape({
    type: PropTypes.string,
    stripe: PropTypes.shape({
      _payment_method_title: PropTypes.string,
      _transaction_id: PropTypes.string,
      _customer_ip_address: PropTypes.string,
    }),
    remarks: PropTypes.string,
    ipAddress: PropTypes.string,
  }),
  separator: PropTypes.oneOf(['lineBreak', 'space']),
};

StripePaymentInfo.defaultProps = {
  payment: {},
  separator: 'lineBreak',
};

export default StripePaymentInfo;
