import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'shards-react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { MainStage } from '../../components/layout';
import { getUser } from '../../store/actions/users';
import CustomerOverview from './CustomerOverview';
import CustomerOrders from './CustomerOrders';
import CustomerReferral from './CustomerReferral';

const CustomerDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user, orders } = useSelector(({ users }) => users);
  const { isLoading } = useSelector(({ ui }) => ui);
  const [userTabs, setUserTabs] = useState([]);

  useEffect(() => {
    if (id && user.id !== id) {
      dispatch(getUser(id));
    }
    // eslint-disable-next-line
  }, [id]);
  
  useEffect(() => {
    const tabs = [];

    tabs.push({
      title: 'Recent order',
      getContent: () => (
        <CustomerOrders orders={orders} isLoading={isLoading} />
      ),
      key: 0,
    });

    tabs.push({
      title: 'Referrals',
      getContent: () => <CustomerReferral user={user} />,
      key: 1,
    });
    
    setUserTabs(tabs);
  }, [orders]);


  return (
    <MainStage title="Details" subtitle="Customers" to="/customers">
      {id && user.id === id && (
        <Row>
          <Col lg="3">
            <CustomerOverview data={user} />
          </Col>
          <Col lg="9">
            <Tabs>
              <TabList>
                {userTabs.map((t) => (
                  <Tab>
                    {t.title}
                  </Tab>
                ))}
              </TabList>
              {userTabs.map((t) => (
                <TabPanel>
                  {t.getContent()}
                </TabPanel>
              ))}
            </Tabs>
          </Col>
        </Row>
      )}
    </MainStage>
  );
};

export default CustomerDetail;
