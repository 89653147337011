import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormInput } from 'shards-react';
import { useSelector } from 'react-redux';

import { slugify } from '../../utils';

const PostTitle = ({ onChange }) => {
  const { post, previewToken } = useSelector(({ posts }) => posts);
  const [title, setTitle] = useState(post.title);
  const [slug, setSlug] = useState(post.slug);
  const [invalid, setInvalid] = useState(false);
  const webUrl = process.env.REACT_APP_WOOSA_WEB_URL;
  const permalink = `${webUrl}/post/${slug}`;

  useEffect(() => {
    setSlug(post.slug);
    setTitle(post.title);

    // eslint-disable-next-line
  }, [post.slug, post.title]);

  const handleChange = (event) => {
    setTitle(event.target.value);
    const newTitle = event.target.value;

    if (!newTitle) {
      setInvalid(true);
      setSlug('');
      onChange({ title: '', slug: '' });
      return;
    }

    const newSlug = slugify(newTitle);
    onChange({ title: newTitle, slug: newSlug });
    setSlug(newSlug);
    setInvalid(false);
  };

  return (
    <>
      <FormInput
        size="lg"
        className="mb-3"
        invalid={invalid}
        value={title || ''}
        placeholder="Your Post Title"
        onChange={handleChange}
      />
      {slug && post.id && (
        <span className="mb-3 d-block">
          Permalink:{' '}
          {previewToken && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${webUrl}/post/preview?token=${previewToken}`}
            >
              {permalink}
            </a>
          )}
          {!previewToken && permalink}
        </span>
      )}
    </>
  );
};

PostTitle.propTypes = {
  onChange: PropTypes.func,
};

PostTitle.defaultProps = {
  onChange: () => {},
};

export default PostTitle;
