import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from 'shards-react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ProductStatus } from '../../constants';
import { FormGroup } from '../../components/common';

const productStatusOptions = Object.keys(ProductStatus).map((key) => {
  return { value: ProductStatus[key], label: ProductStatus[key] };
});

const CustomProductModal = ({ onAddProduct, open, onClose }) => {
  const { control, handleSubmit, errors } = useForm();
  const [status, setStatus] = useState('');

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const onSubmit = (data) => {
    data.qty = parseFloat(data.qty);
    data.unitPrice = parseFloat(data.unitPrice);

    const product = {
      uuid: uuidv4(),
      id: null,
      name: data.name,
      shippable: true,
      collection: data.collection,
      variant: data.variant,
      currency: 'SGD',
      status: data.status,
      statusDate: data.statusDate,
      unitPrice: data.unitPrice,
      comparePrice: 0,
      sku: '',
      image: '',
      qty: data.qty,
      subtotal: data.qty * data.unitPrice,
    };
    onAddProduct(product);
  };

  return (
    <Modal centered open={open} toggle={onClose} form size="lg">
      <ModalHeader>Custom Product</ModalHeader>
      <ModalBody>
        <Row>
          <FormGroup
            name="name"
            col={{ lg: '12' }}
            label="Product Name"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
          />
          <FormGroup
            name="collection"
            col={{ lg: '6' }}
            label="Collection"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
          />
          <FormGroup
            type="number"
            name="unitPrice"
            col={{ lg: '6' }}
            label="Unit Price"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
          />
          <FormGroup
            name="variant"
            col={{ lg: '6' }}
            label="Variant"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
          />
          <FormGroup
            type="number"
            name="qty"
            col={{ lg: '6' }}
            label="Qty"
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
          />
          <FormGroup
            type="select"
            name="status"
            col={{ lg: '6' }}
            label="Product Status"
            options={productStatusOptions}
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
            onChange={(e) => handleStatusChange(e)}
          />
          {status === ProductStatus.PRE_ORDER && (
            <FormGroup
              type="datepicker"
              name={`statusDate`}
              col={{ lg: '6' }}
              label="Pre-Order Date"
              control={control}
              rules={{ required: 'Required field' }}
              errors={errors}
            />
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" theme="primary" onClick={handleSubmit(onSubmit)}>
          Add
        </Button>
        <Button type="button" theme="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CustomProductModal.propTypes = {
  onAddProduct: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

CustomProductModal.defaultProps = {
  onAddProduct: () => {},
  onClose: () => {},
};

export default CustomProductModal;
