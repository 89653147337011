import moment from 'moment';
import { ReferralEarnStatus } from '../constants';

/**
 * Check referral condition to determine the status it is current at
 * @param {Object} referee
 * @returns {ReferralEarnStatus}
 */
export const getReferralRebateStatus = (referee) => {
  const { earned } = referee;
  if (!earned) return ReferralEarnStatus.ERROR;

  if (earned.hasWithdrawn) return ReferralEarnStatus.PAID;

  const canWithdrawAfter = moment.unix(earned.canWithdrawAfter.seconds);

  if (moment().isBefore(canWithdrawAfter)) return ReferralEarnStatus.PENDING;

  if (earned.hasRequestedWithdrawal) return ReferralEarnStatus.PROCESSING;

  return ReferralEarnStatus.CONFIRMED;
};
