import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  FormCheckbox,
  Form,
  InputGroup,
  FormInput,
  InputGroupAddon,
  Button,
} from 'shards-react';
import styled from 'styled-components';

import DialogButton from './DialogButton';
import MaterialIcon from './MaterialIcon';

const DeleteButton = styled(DialogButton)`
  padding: 0.2rem;
  display: flex;
  align-items: center;
  margin-left: auto;

  i {
    font-size: 0.8rem;
  }
`;

const ValueManager = ({
  label,
  onSelected,
  onSubmitNewValue,
  onDeleteValue,
  initialValues,
  listValues,
  isLoading,
  className,
}) => {
  const [values, setValues] = useState(listValues || []);
  const [selectedValues, setSelectedValues] = useState(initialValues || []);
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    setValues(listValues || []);
  }, [listValues]);

  useEffect(() => {
    setSelectedValues(initialValues || []);
  }, [initialValues]);

  const handleSelect = (value) => {
    const newSelectedValues = [];
    if (selectedValues.includes(value)) {
      newSelectedValues.push(...selectedValues.filter((v) => v !== value));
    } else {
      newSelectedValues.push(...selectedValues);
      newSelectedValues.push(value);
    }

    // return selected to callback function
    onSelected(newSelectedValues);
    setSelectedValues(newSelectedValues);
  };

  const handleDelete = (value) => {
    const newSelectedValues = selectedValues.filter((v) => v !== value);
    const newValues = values.filter((v) => v !== value);
    onDeleteValue(value);
    setSelectedValues(newSelectedValues);
    setValues(newValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmitNewValue(newValue);
    setValues([...values, newValue]);
    setNewValue('');
  };

  return (
    <Card small className={className}>
      <CardHeader className="border-bottom">
        <h6 className="m-0">{label}</h6>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="px-3 pb-2">
            {values.map((value) => (
              <div className="d-flex mb-2 align-items-center" key={value}>
                <FormCheckbox
                  className="mb-0"
                  onChange={() => handleSelect(value)}
                  checked={selectedValues.includes(value)}
                >
                  {value}
                </FormCheckbox>
                <DeleteButton
                  theme="danger"
                  disabled={isLoading}
                  onConfirm={() => handleDelete(value)}
                  icon="delete_outline"
                  size="sm"
                />
              </div>
            ))}
          </ListGroupItem>
          <ListGroupItem className="d-flex px-3">
            <Form className="w-100" onSubmit={handleSubmit}>
              <InputGroup className="ml-auto">
                <FormInput
                  placeholder={`New ${label}`}
                  value={newValue}
                  onChange={(event) => setNewValue(event.target.value)}
                />
                <InputGroupAddon type="append">
                  <Button
                    theme="white"
                    className="px-2"
                    disabled={isLoading}
                    type="submit"
                  >
                    <MaterialIcon icon="add" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Form>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
};

ValueManager.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onSelected: PropTypes.func,
  onSubmitNewValue: PropTypes.func,
  onDeleteValue: PropTypes.func,
  listValues: PropTypes.array,
  initialValues: PropTypes.array,
  className: PropTypes.string,
};

ValueManager.defaultProps = {
  isLoading: false,
  label: '',
  onSelected: () => {},
  onSubmitNewValue: () => {},
  onDeleteValue: () => {},
  listValues: [],
  initialValues: [],
  className: '',
};

export default ValueManager;
