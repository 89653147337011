import React, { useEffect, useState } from 'react';
import {
  Row,
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from 'shards-react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';
import * as _ from 'lodash';
import { FormGroup, IconButton, MaterialIcon } from '../../components/common';
import { ProductStatus, StatusType } from '../../constants';
import { getVariantImage } from '../../utils/variant';

const VariantThumbnail = styled.img`
  height: 50px;
  border-radius: 0.3rem;
  cursor: pointer;
`;

const SubVariantImage = styled.img`
  height: 75px;
  border-radius: 0.625rem;
  margin-right: 15px;
`;

const DragUl = styled.ul`
  list-style: none;
  padding: 0;
`;

const DragLi = styled.li`
  cursor: move;
`;

const ProductSubVariant = ({
  onDragOver,
  onDragStart,
  onDrop,
  handleAddVariant,
  handleRemoveVariant,
  handleRemoveVariantGroup,
  handleEditVariantGroup,
  handleVariantGroupCollapse,
  variantGroupStatusChange,
  variantGroupValueChange,
  variantGroupQtyChange,
  variantGroupPriceChange,
  handleAddVarianGroupModal,
  openVariantGroupThumbnailModal,
  variantGroupDateNoteChange,
  variantGroups,
  productStatusOptions,
  customProductStatusOptions,
  productAssets,
  isLoading,
  control,
  errors,
}) => {
  const [productTabs, setProductTabs] = useState([]);

  useEffect(() => {
    if (variantGroups) {
      const tabs = [];
      const variantGroupFolder = _.groupBy(variantGroups, 'meta.group');
      _.forEach(variantGroupFolder, (value, key) => {
        tabs.push({
          title: key || 'Default',
          value: value,
          key,
        });
      });
      setProductTabs(tabs);
    }
  }, [variantGroups]);

  // for sub varaint
  const getVariantGroupIndex = (v) => {
    const variantGroupIndex = variantGroups.findIndex(
      (g) =>
        g.statusType === v.statusType &&
        g.design.assetId === v.design.assetId &&
        g.material.assetId === v.material.assetId &&
        g.colour.assetId === v.colour.assetId
    );
    return variantGroupIndex;
  };
  
  const minQty = -1000

  const renderTabContent = (variantGroupsData) => {
    return (
      <>
        {variantGroupsData.length > 0 && (
          <DragUl>
            {variantGroupsData.map((variantGroup) => {
              const gIndex = getVariantGroupIndex(variantGroup);
              return (
                <DragLi
                  key={gIndex}
                  data-position={gIndex}
                  draggable
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  className="mb-3"
                >
                  <Card>
                    <CardHeader>
                      <div className="d-flex align-items-center">
                        <div className="mr-auto">
                          <div className="d-flex align-items-center">
                            {!variantGroup.thumbnail && (
                              <div title="Upload Thumbnail">
                                <IconButton
                                  onClick={() =>
                                    openVariantGroupThumbnailModal(gIndex)
                                  }
                                  theme="info"
                                  disabled={isLoading}
                                  icon="crop_original"
                                  type="button"
                                />
                              </div>
                            )}
                            {variantGroup.thumbnail && (
                              <VariantThumbnail
                                title="Upload Thumbnail"
                                onClick={() =>
                                  openVariantGroupThumbnailModal(gIndex)
                                }
                                src={variantGroup.thumbnail}
                              />
                            )}
                            <strong className="ml-3">
                              {variantGroup.statusType} -
                              {` ${variantGroup.design.value} / ${variantGroup.material.value} / ${variantGroup.colour.value}`}
                            </strong>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <Button
                            className="mr-2"
                            size="sm"
                            theme="danger"
                            type="button"
                            onClick={() => handleRemoveVariantGroup(gIndex)}
                          >
                            Remove
                          </Button>
                          <Button
                            className="mr-2"
                            size="sm"
                            type="button"
                            onClick={() =>
                              handleEditVariantGroup(variantGroup, gIndex)
                            }
                          >
                            Edit
                          </Button>

                          <IconButton
                            onClick={() => handleVariantGroupCollapse(gIndex)}
                            theme="default"
                            disabled={isLoading}
                            icon={
                              variantGroup.open ? 'expand_less' : 'expand_more'
                            }
                            type="button"
                          />
                        </div>
                      </div>
                    </CardHeader>
                    <Collapse open={variantGroup.open}>
                      <CardBody>
                        <div className="mb-3">
                          {variantGroup.design && (
                            <SubVariantImage src={getVariantImage(productAssets, variantGroup.design.assetId)} />
                          )}
                          {variantGroup.material && (
                            <SubVariantImage src={getVariantImage(productAssets, variantGroup.material.assetId)} />
                          )}
                          {variantGroup.colour && (
                            <SubVariantImage src={getVariantImage(productAssets, variantGroup.colour.assetId)} />
                          )}
                        </div>
                        <div
                          className="custom-table-responsive mb-4"
                          style={{ minHeight: '250px' }}
                        >
                          <table className="table form-table">
                            <thead>
                              <tr>
                                <th width="60">
                                  <IconButton
                                    onClick={() => handleAddVariant(gIndex)}
                                    theme="success"
                                    disabled={isLoading}
                                    icon="add"
                                    type="button"
                                  />
                                </th>
                                <th width="180">Variant</th>
                                <th width="130">Status</th>
                                <th width="150">
                                  <Row>
                                    {variantGroup.statusType ===
                                      StatusType.STANDARD && (
                                      <FormGroup
                                        type="custom-checkbox"
                                        label="apply all"
                                        name={`variantGroup[${gIndex}].apply_statusDate`}
                                        control={control}
                                        defaultValue
                                      />
                                    )}
                                    {variantGroup.statusType ===
                                      StatusType.CUSTOM && (
                                      <FormGroup
                                        type="custom-checkbox"
                                        label="apply all"
                                        name={`variantGroup[${gIndex}].apply_statusNote`}
                                        control={control}
                                        defaultValue
                                      />
                                    )}
                                  </Row>
                                  <hr className="mt-0 mb-1" />
                                  Status Date/Note
                                </th>
                                <th width="150">SKU</th>
                                <th width="100">On Hand Qty</th>
                                <th width="100">Reserved Qty</th>
                                <th width="100">Available Qty</th>
                                <th width="120">
                                  <Row>
                                    <FormGroup
                                      type="custom-checkbox"
                                      label="apply all"
                                      name={`variantGroup[${gIndex}].apply_amount`}
                                      control={control}
                                      defaultValue
                                    />
                                  </Row>
                                  <hr className="mt-0 mb-1" />
                                  Selling Price
                                </th>
                                <th width="120">
                                  <Row>
                                    <FormGroup
                                      type="custom-checkbox"
                                      label="apply all"
                                      name={`variantGroup[${gIndex}].apply_compareAmount`}
                                      control={control}
                                      defaultValue
                                    />
                                  </Row>
                                  <hr className="mt-0 mb-1" />
                                  Compare Price
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {variantGroup.variants.map((variant, index) => {
                                return (
                                  <tr key={variant.rowId}>
                                    <td>
                                      <IconButton
                                        onClick={() =>
                                          handleRemoveVariant(index, gIndex)
                                        }
                                        theme="danger"
                                        disabled={isLoading}
                                        icon="delete_outline"
                                        type="button"
                                      />
                                    </td>
                                    <td>
                                      <Row>
                                        <FormGroup
                                          name={`variantGroup[${gIndex}].variants[${index}].type`}
                                          control={control}
                                          rules={{
                                            required: 'Required field',
                                          }}
                                          errors={errors}
                                          defaultValue={variant.type}
                                          onChange={(e) =>
                                            variantGroupValueChange(
                                              e,
                                              gIndex,
                                              index,
                                              'type'
                                            )
                                          }
                                        />
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        <FormGroup
                                          name={`variantGroup[${gIndex}].variants[${index}].status`}
                                          type="select"
                                          options={
                                            variant.statusType ===
                                            StatusType.STANDARD
                                              ? productStatusOptions
                                              : customProductStatusOptions
                                          }
                                          control={control}
                                          rules={{
                                            required: 'Required field',
                                          }}
                                          errors={errors}
                                          defaultValue={variant.status}
                                          onChange={(e) =>
                                            variantGroupStatusChange(
                                              e,
                                              gIndex,
                                              index
                                            )
                                          }
                                        />
                                      </Row>
                                    </td>
                                    <td>
                                      {variant.statusType ===
                                        StatusType.STANDARD &&
                                        variant.status ===
                                          ProductStatus.PRE_ORDER && (
                                          <Row>
                                            <FormGroup
                                              type="datepicker"
                                              preventOverflow
                                              name={`variantGroup[${gIndex}].variants[${index}].statusDate`}
                                              control={control}
                                              rules={{
                                                required: 'Required field',
                                              }}
                                              errors={errors}
                                              defaultValue={variant.statusDate}
                                              onChange={(e) =>
                                                variantGroupDateNoteChange(
                                                  e,
                                                  gIndex,
                                                  index,
                                                  'statusDate'
                                                )
                                              }
                                            />
                                          </Row>
                                        )}
                                      {variant.statusType ===
                                        StatusType.CUSTOM &&
                                        variant.status ===
                                          ProductStatus.PRE_ORDER && (
                                          <Row>
                                            <FormGroup
                                              name={`variantGroup[${gIndex}].variants[${index}].statusNote`}
                                              control={control}
                                              rules={{
                                                required: 'Required field',
                                              }}
                                              errors={errors}
                                              defaultValue={variant.statusNote}
                                              onChange={(e) =>
                                                variantGroupDateNoteChange(
                                                  e,
                                                  gIndex,
                                                  index,
                                                  'statusNote'
                                                )
                                              }
                                            />
                                          </Row>
                                        )}
                                      {variant.status !==
                                        ProductStatus.PRE_ORDER && (
                                        <p className="m-0 text-center">--</p>
                                      )}
                                    </td>
                                    <td>
                                      <Row>
                                        <FormGroup
                                          name={`variantGroup[${gIndex}].variants[${index}].sku`}
                                          control={control}
                                          defaultValue={variant.sku}
                                          onChange={(e) =>
                                            variantGroupValueChange(
                                              e,
                                              gIndex,
                                              index,
                                              'sku'
                                            )
                                          }
                                        />
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        <FormGroup
                                          name={`variantGroup[${gIndex}].variants[${index}].onHandQty`}
                                          type="number"
                                          control={control}
                                          rules={{
                                            required: 'Required field',
                                          }}
                                          errors={errors}
                                          defaultValue={variant.onHandQty}
                                          min={minQty}
                                          onChange={(e) => {
                                            variantGroupQtyChange(
                                              e,
                                              gIndex,
                                              index,
                                              'onHandQty'
                                            );
                                          }}
                                        />
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        <FormGroup
                                          name={`variantGroup[${gIndex}].variants[${index}].reservedQty`}
                                          type="number"
                                          control={control}
                                          rules={{
                                            required: 'Required field',
                                          }}
                                          errors={errors}
                                          defaultValue={variant.reservedQty}
                                          min={minQty}
                                          onChange={(e) => {
                                            variantGroupQtyChange(
                                              e,
                                              gIndex,
                                              index,
                                              'reservedQty'
                                            );
                                          }}
                                        />
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        <FormGroup
                                          name={`variantGroup[${gIndex}].variants[${index}].availableQty`}
                                          type="number"
                                          control={control}
                                          rules={{
                                            required: 'Required field',
                                          }}
                                          errors={errors}
                                          readOnly
                                          defaultValue={variant.availableQty}
                                          min={minQty}
                                        />
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        <FormGroup
                                          name={`variantGroup[${gIndex}].variants[${index}].prices[0].amount`}
                                          type="number"
                                          control={control}
                                          rules={{
                                            required: 'Required field',
                                          }}
                                          errors={errors}
                                          defaultValue={
                                            variant.prices[0].amount
                                          }
                                          onChange={(e) =>
                                            variantGroupPriceChange(
                                              e,
                                              gIndex,
                                              index,
                                              'amount'
                                            )
                                          }
                                        />
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        <FormGroup
                                          name={`variantGroup[${gIndex}].variants[${index}].prices[0].compareAmount`}
                                          type="number"
                                          control={control}
                                          rules={{
                                            required: 'Required field',
                                          }}
                                          errors={errors}
                                          defaultValue={
                                            variant.prices
                                              ? variant.prices[0].compareAmount
                                              : 0
                                          }
                                          onChange={(e) =>
                                            variantGroupPriceChange(
                                              e,
                                              gIndex,
                                              index,
                                              'compareAmount'
                                            )
                                          }
                                        />
                                      </Row>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Collapse>
                  </Card>
                </DragLi>
              );
            })}
          </DragUl>
        )}
      </>
    );
  };

  return (
    <>
      <div className="text-right mb-3">
        <Button
          size="sm"
          className="ml-3"
          theme="success"
          type="button"
          onClick={() => handleAddVarianGroupModal()}
        >
          <MaterialIcon icon="add" className="mr-2" />
          Variant Group
        </Button>
      </div>
      <div className="mb-3">
        
        <Tabs>
          <TabList>
            {productTabs.map((t) => (
              <Tab>
                {t.title}
              </Tab>
            ))}
          </TabList>
          {productTabs.map((t) => (
            <TabPanel>
              {renderTabContent(t.value)}
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </>
  );
};

ProductSubVariant.propTypes = {
  onDragOver: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  handleAddVariant: PropTypes.func,
  handleRemoveVariant: PropTypes.func,
  handleRemoveVariantGroup: PropTypes.func,
  handleEditVariantGroup: PropTypes.func,
  handleVariantGroupCollapse: PropTypes.func,
  variantGroupStatusChange: PropTypes.func,
  variantGroupValueChange: PropTypes.func,
  variantGroupQtyChange: PropTypes.func,
  variantGroupPriceChange: PropTypes.func,
  handleAddVarianGroupModal: PropTypes.func,
  openVariantGroupThumbnailModal: PropTypes.func,
  variantGroupDateNoteChange: PropTypes.func,
  variantGroups: PropTypes.array,
  productStatusOptions: PropTypes.array,
  customProductStatusOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  control: PropTypes.any,
  errors: PropTypes.any,
};

ProductSubVariant.defaultProps = {
  onDragOver: () => {},
  onDragStart: () => {},
  onDrop: () => {},
  handleAddVariant: () => {},
  handleRemoveVariant: () => {},
  handleRemoveVariantGroup: () => {},
  handleEditVariantGroup: () => {},
  handleVariantGroupCollapse: () => {},
  variantGroupStatusChange: () => {},
  variantGroupValueChange: () => {},
  variantGroupQtyChange: () => {},
  variantGroupPriceChange: () => {},
  handleAddVarianGroupModal: () => {},
  openVariantGroupThumbnailModal: () => {},
  variantGroupDateNoteChange: () => {},
  variantGroups: [],
  productStatusOptions: [],
  customProductStatusOptions: [],
  isLoading: false,
  control: null,
  errors: null,
};

export default ProductSubVariant;
