import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Button, FormRadio } from 'shards-react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { SmartTable, SearchCard, FormGroup } from '../../components/common';
import { getOrders, exportCsv } from '../../store/actions/orders';
import { getUsers } from '../../store/actions/users';
import { getPromotions } from '../../store/actions/promotions';
import { getOrderFilters } from '../../store/actions/orderFilters';
import { MainStage } from '../../components/layout';
import {
  StatusColors,
  OrderStatus,
  DeliveryStatus,
  OrderDeliveryStatus,
  DeliveryDateStatus,
  AllocationStockStatus,
} from '../../constants';

const _ = require('lodash');

const orderStatusOptions = Object.keys(OrderStatus).map((key) => {
  return { value: OrderStatus[key], label: OrderStatus[key] };
});
const deliveryDateStatusOptions = Object.keys(DeliveryDateStatus).map((key) => {
  return { value: DeliveryDateStatus[key], label: DeliveryDateStatus[key] };
});
const orderDeliveryStatusOptions = Object.keys(OrderDeliveryStatus).map(
  (key) => {
    return { value: OrderDeliveryStatus[key], label: OrderDeliveryStatus[key] };
  }
);
const allocationStatusOptions = Object.keys(AllocationStockStatus).map(
  (key) => {
    return {
      value: AllocationStockStatus[key],
      label: AllocationStockStatus[key],
    };
  }
);

const OrderListing = () => {
  const history = useHistory();
  const { userId } = useParams();
  const { control } = useForm();
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [variantOptions, setVariantOptions] = useState([]);
  const [promoOptions, setPromoOptions] = useState([]);
  const [status, setStatus] = useState('');
  const [orderDeliveryStatus, setOrderDeliveryStatus] = useState('');
  const [deliveryDateStatus, setDeliveryDateStatus] = useState('');
  const [allocationStatus, setAllocationStatus] = useState('');
  const [productFilter, setProductFilter] = useState('');
  const [variantFilter, setVariantFilter] = useState('');
  const [promoIndex, setPromoIndex] = useState('');
  const [filter, setFilter] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [searchFilter, setSearchFilter] = useState(true);
  const dispatch = useDispatch();
  const { items, paging } = useSelector(({ orders: { orders } }) => orders);
  const usersData = useSelector(({ users: { users } }) => users);
  const orderFiltersData = useSelector(
    ({ orderFilters: { orderFilters } }) => orderFilters
  );
  const promosData = useSelector(
    ({ promotions: { promotions } }) => promotions
  );
  const promoOptionDefault = { label: 'Please select', value: '' };

  const columns = [
    {
      header: 'Ref No.',
      accessor: 'refNum',
      cell: (row, data) => (
        <Link to={`/orders/view/${data.id}`}>{row.value}</Link>
      ),
    },
    {
      header: 'Customer',
      accessor: 'firstName',
      cell: (row, data) => (
        <span>
          {data.user.firstName} {data.user.lastName}
        </span>
      ),
    },
    {
      header: 'Status',
      accessor: 'status',
      cell: (row) => (
        <Badge theme={StatusColors[row.value.replace(' ', '_').toUpperCase()]}>
          {row.value}
        </Badge>
      ),
    },
    {
      header: 'Delivery Date',
      accessor: 'deliveryDateStatus',
      cell: (row, data) => {
        let deliveryQty = 0;
        if (data.schedules) {
          data.schedules.forEach((s) => {
            deliveryQty += _.sumBy(s.products, 'qty') || 0;
          });
        }
        const qty = _.sumBy(
          data.products.filter((p) => p.shippable),
          'qty'
        );
        return (
          <Badge
            theme={
              qty === deliveryQty && deliveryQty > 0 ? 'success' : 'danger'
            }
          >
            {deliveryQty}/{qty}
          </Badge>
        );
      },
    },
    {
      header: 'Delivery',
      accessor: 'deliveryStatus',
      cell: (row, data) => {
        let deliveredCount = 0;
        const schedulesCount = data.schedules ? data.schedules.length : 0;
        if (data.schedules) {
          deliveredCount = data.schedules.filter(
            (s) => s.status === DeliveryStatus.DELIVERED
          ).length;
        }
        return (
          <Badge
            theme={
              schedulesCount === deliveredCount && deliveredCount > 0
                ? 'success'
                : 'danger'
            }
          >
            {deliveredCount}/{schedulesCount}
          </Badge>
        );
      },
    },
    {
      header: 'Allocation',
      accessor: 'allocationStatus',
      cell: (row, data) => {
        let allocatedQty = 0;
        if (data.schedules) {
          data.schedules.forEach((s) => {
            allocatedQty += _.sumBy(s.products, 'allocatedQty') || 0;
          });
        }
        const qty = _.sumBy(
          data.products.filter((p) => p.shippable),
          'qty'
        );
        return (
          <Badge
            theme={
              qty === allocatedQty && allocatedQty > 0 ? 'success' : 'danger'
            }
          >
            {allocatedQty}/{qty}
          </Badge>
        );
      },
    },
    {
      header: 'Total Price',
      accessor: 'totalPrice',
      cell: (row, data) => (
        <span>
          {data.currency} {data.totalPrice}
        </span>
      ),
    },
    {
      header: 'Discount',
      accessor: 'discount',
      cell: (row, data) => (
        <span className="text-danger">
          {data.discount ? `${data.currency} ${data.discount}` : '-'}
        </span>
      ),
    },
    {
      header: 'Final Price',
      accessor: 'finalPrice',
      cell: (row, data) => (
        <span>
          {data.currency} {data.finalPrice}
        </span>
      ),
    },
    {
      header: 'Order Date',
      accessor: 'orderedOn',
      cell: ({ value }) => moment.unix(value.seconds).format('MMM DD, YYYY'),
    },
    {
      header: 'Created By',
      accessor: 'audit',
      cell: (row, data) => <span>{data.audit.createdBy}</span>,
    },
  ];

  const handleSearchOrders = (page) => {
    const productIndex = variantFilter
      ? `${productFilter}-${variantFilter}`
      : productFilter;

    const params = {
      userId: userId || selectedUserId,
      dateFrom,
      dateTo,
      status,
      deliveryDateStatus,
      orderDeliveryStatus,
      allocationStatus,
      productIndex,
      promoIndex,
    };
    dispatch(getOrders({ page, filter, params }));
  };
  useEffect(() => {
    dispatch(getOrderFilters());
    dispatch(getPromotions({ size: 500 }));
    handleSearchOrders();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const options = [{ value: '', label: 'All customers' }];
    if (usersData.items.length) {
      const [users] = usersData.items;
      users.forEach((i) => {
        options.push({ value: i.id, label: `${i.firstName} ${i.lastName}` });
      });
    }
    setCustomerOptions(options);
  }, [usersData]);

  useEffect(() => {
    const options = [];
    if (orderFiltersData.items.length) {
      orderFiltersData.items.forEach((p) => {
        options.push({ value: p.id, label: p.name });
      });
    }
    setProductOptions(options);
  }, [orderFiltersData]);

  useEffect(() => {
    const options = [promoOptionDefault];
    if (promosData.items.length) {
      const [promos] = promosData.items;
      _.orderBy(promos, ['active', 'code'], ['desc', 'asc']).forEach((p) => {
        options.push({
          value: p.id,
          label: (
            <label className="m-0">
              {p.code}&nbsp;&nbsp;-&nbsp;&nbsp;
              <Badge theme={p.active ? 'success' : 'danger'}>
                {p.active ? 'active' : 'expired'}
              </Badge>
            </label>
          ),
          promo: p,
        });
      });
    }
    setPromoOptions(options);
  }, [promosData]);

  const handleInputChange = _.debounce((value) => {
    if (value && value.length >= 3) {
      dispatch(getUsers({ filter: value }));
    }
  }, 1000);

  const handleExportCsv = () => {
    const productIndex = variantFilter
      ? `${productFilter}-${variantFilter}`
      : productFilter;

    const params = {
      userId: userId || selectedUserId,
      dateFrom,
      dateTo,
      status,
      productIndex,
      promoIndex,
    };
    dispatch(exportCsv({ filter, params }));
  };

  const handleProductOnChange = (value) => {
    setProductFilter(value);
    const selectedProduct = orderFiltersData.items.find((i) => i.id === value);
    const options = [];
    if (selectedProduct.variants.length) {
      selectedProduct.variants.forEach((v) => {
        options.push({ value: v, label: v });
      });
    }
    setVariantOptions(options);
  };

  const handleVariantOnChange = (value) => {
    setVariantFilter(value);
  };

  const handleOnCheckSearch = () => {
    setSearchFilter(true);
    setProductFilter('');
    setVariantFilter('');
  };

  const handleOnCheckProduct = () => {
    setSearchFilter(false);
    setFilter('');
  };

  return (
    <MainStage title="Overview" subtitle="Orders">
      <Row>
        <Col lg="12">
          <SearchCard title="Search orders">
            <Row>
              <FormGroup
                col={{ lg: '3' }}
                type="datepicker"
                name="dateFrom"
                label="Order Date From"
                control={control}
                defaultValue={dateFrom}
                onChange={(selected) => setDateFrom(selected)}
                dateProps={{ maxDate: dateTo }}
              />

              <FormGroup
                col={{ lg: '3' }}
                type="datepicker"
                name="dateTo"
                label="Order Date To"
                control={control}
                defaultValue={dateTo}
                onChange={(selected) => setDateTo(selected)}
                dateProps={{ minDate: dateFrom }}
              />

              {!userId && (
                <FormGroup
                  col={{ lg: '3' }}
                  type="searchable-select"
                  name="customer"
                  label="Customer"
                  placeholder="Type at least 3 characters"
                  onInputChange={handleInputChange}
                  onChange={(option) => setSelectedUserId(option.value)}
                  options={customerOptions}
                  control={control}
                />
              )}

              <FormGroup
                col={{ lg: '3' }}
                type="select"
                options={orderStatusOptions}
                name="status"
                label="Status"
                control={control}
                onChange={(e) => setStatus(e.target.value)}
              />

              <FormGroup
                col={{ lg: '3' }}
                type="select"
                options={deliveryDateStatusOptions}
                name="deliveryDateStatus"
                label="Delivery Date Status"
                control={control}
                onChange={(e) => setDeliveryDateStatus(e.target.value)}
              />

              <FormGroup
                col={{ lg: '3' }}
                type="select"
                options={orderDeliveryStatusOptions}
                name="deliveryStatus"
                label="Delivery Status"
                control={control}
                onChange={(e) => setOrderDeliveryStatus(e.target.value)}
              />

              <FormGroup
                col={{ lg: '3' }}
                type="select"
                options={allocationStatusOptions}
                name="allocationStatus"
                label="Allocation Status"
                control={control}
                onChange={(e) => setAllocationStatus(e.target.value)}
              />

              <Col lg={3}>
                <div className="d-flex">
                  <FormRadio
                    name="filter"
                    checked={searchFilter}
                    onChange={() => handleOnCheckSearch()}
                  >
                    Search
                  </FormRadio>
                  <div className="mr-3" />
                  <FormRadio
                    name="filter"
                    checked={!searchFilter}
                    onChange={() => handleOnCheckProduct()}
                  >
                    Product
                  </FormRadio>
                </div>
                <Row>
                  {searchFilter && (
                    <FormGroup
                      name="filter"
                      control={control}
                      placeholder="Type at least 3 characters"
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  )}

                  {!searchFilter && productOptions.length > 0 && (
                    <FormGroup
                      type="select"
                      options={productOptions}
                      name="product"
                      control={control}
                      onChange={(e) => handleProductOnChange(e.target.value)}
                    />
                  )}
                </Row>
              </Col>

              {!searchFilter && variantOptions.length > 0 && (
                <FormGroup
                  col={{ lg: '3' }}
                  type="select"
                  options={variantOptions}
                  name="variant"
                  label="Variant"
                  control={control}
                  onChange={(e) => handleVariantOnChange(e.target.value)}
                />
              )}

              <FormGroup
                col={{ lg: '3' }}
                type="searchable-select"
                options={promoOptions}
                defaultValue={promoOptionDefault}
                name="promo"
                label="Promo Code"
                control={control}
                onChange={(option) => setPromoIndex(option.value)}
                onSearch={(option, inputValue) =>
                  option.data?.promo?.code
                    ?.toLowerCase()
                    .includes(inputValue?.toLowerCase())
                }
              />
            </Row>
            <div className="d-flex">
              <Button
                type="button"
                theme="success"
                onClick={handleSearchOrders}
              >
                Search
              </Button>
              <Button
                className="ml-2"
                type="button"
                theme="primary"
                onClick={handleExportCsv}
              >
                Export
              </Button>
            </div>
          </SearchCard>
        </Col>
        <Col>
          <SmartTable
            title="Active Orders"
            data={items}
            columns={columns}
            onCreate={() => history.push('/orders/new')}
            page={paging.page}
            onPageChange={(page) => {
              handleSearchOrders(page);
            }}
          />
        </Col>
      </Row>
    </MainStage>
  );
};

export default OrderListing;
