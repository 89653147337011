import { ReportActionTypes } from '../actions/reports';

const initialState = {
  last6MonthsRevenue: null,
  monthlyRevenue: null,
  productSoldQty: null,
  topProductsSold: null,
  noOfOrders: null,
  noOfDeliveries: null,
  noOfCustomers: null,
  deliveryEvents: { events: [] },
  orderPromos: null,
  productInventory: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {ReportActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ReportActionTypes.LAST_6_MONTHS_REVENUE:
      return { ...state, last6MonthsRevenue: { ...action.last6MonthsRevenue } };
    case ReportActionTypes.GET_MONTHLY_REVENUE:
      return { ...state, monthlyRevenue: { ...action.monthlyRevenue } };
    case ReportActionTypes.PRODUCT_SOLD_QTY:
      return { ...state, productSoldQty: { ...action.productSoldQty } };
    case ReportActionTypes.TOP_PRODUCT_SOLD:
      return { ...state, topProductsSold: { ...action.topProductsSold } };
    case ReportActionTypes.GET_NO_OF_ORDERS:
      return { ...state, noOfOrders: { ...action.noOfOrders } };
    case ReportActionTypes.GET_NO_OF_DELIVERIES:
      return { ...state, noOfDeliveries: { ...action.noOfDeliveries } };
    case ReportActionTypes.GET_NO_OF_CUSTOMERS:
      return { ...state, noOfCustomers: { ...action.noOfCustomers } };
    case ReportActionTypes.GET_DELIVERY_EVENTS:
      return { ...state, deliveryEvents: { ...action.deliveryEvents } };
    case ReportActionTypes.GET_ORDER_PROMOTIONS:
      return { ...state, orderPromos: { ...action.orderPromos } };
    case ReportActionTypes.GET_IVENTORY_REPORT:
      return { ...state, productInventory: { ...action.productInventory } };
    default:
      return state;
  }
}
