import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'react-confirm-alert/src/react-confirm-alert.css';

const DialogContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 16px 24px;
  max-width: 600px;
  display: flex;
  max-height: calc(100% - 64px);
  flex-direction: column;
  overflow-y: auto;
  width: 100%;

  @media (min-width: 768px) {
    min-width: 500px;
  }
`;

const Dialog = ({ children, className }) => (
  <DialogContainer className={className}>{children}</DialogContainer>
);

Dialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

Dialog.defaultProps = {
  className: '',
};

export default Dialog;
