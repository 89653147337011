import React, { useEffect, useState } from 'react';
import { Row, Col } from 'shards-react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { error } from 'react-toastify-redux';
import {
  getAdmin,
  createAdmin,
  updateAdmin,
  deleteAdmin,
} from '../../store/actions/admins';
import { authResetPassword } from '../../store/actions/auth';
import { getRoles } from '../../store/actions/roles';
import { MainStage } from '../../components/layout';
import { FormCard, FormGroup } from '../../components/common';
import { hasAccess } from '../../utils/auth';

const AdminForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(({ ui }) => ui.isLoading);
  const adminData = useSelector(({ admins: { admin } }) => admin);
  const rolesData = useSelector(({ roles: { roles } }) => roles);
  const [adminState, setAdminState] = useState(null);
  const { control, handleSubmit, errors } = useForm();
  const canUpdate = hasAccess('admins', 'update');
  const canCreate = hasAccess('admins', 'create');

  useEffect(() => {
    if (id) dispatch(getAdmin(id));
    if (!rolesData.length) dispatch(getRoles());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id && adminData) {
      setAdminState(adminData);
    } else {
      const newAdmin = {
        name: '',
        email: '',
        role: {},
      };
      setAdminState(newAdmin);
    }
  }, [id, adminData]);

  const onSubmit = (data) => {
    const selectedRole = rolesData.find((role) => role.id === data.role);
    if (!selectedRole) {
      dispatch(error('Invalid role'));
      return;
    }

    const admin = {
      ...adminState,
      email: data.email,
      name: data.name,
      role: {
        id: selectedRole.id,
        name: selectedRole.name,
        modules: selectedRole.modules,
      },
    };
    delete admin.id;
    if (id && canUpdate) dispatch(updateAdmin(id, admin));
    else if (!id && canCreate) dispatch(createAdmin(admin));
  };

  const handleDelete = () => {
    dispatch(deleteAdmin(adminState.id));
  };

  const handleAction = (index) => {
    switch (index) {
      case 0:
        dispatch(authResetPassword(adminState.email));
        break;
      default:
    }
  };

  return (
    rolesData &&
    adminState &&
    (id ? adminState.id === id : true) && (
      <MainStage title={!id ? 'New' : 'Update'} subtitle="Admins" to="/admins">
        <Row>
          <Col lg="6">
            <FormCard
              title="Enter admin details"
              button={{ theme: 'primary', text: 'Save', isLoading }}
              onSubmit={handleSubmit(onSubmit)}
              onDelete={handleDelete}
              actions={[{ text: 'Reset Password', showDialog: true }]}
              onAction={handleAction}
            >
              <Row form>
                <FormGroup
                  name="name"
                  label="Name"
                  control={control}
                  rules={{ required: 'Required field' }}
                  col={{ md: '12' }}
                  errors={errors}
                  defaultValue={adminState.name}
                />
                <FormGroup
                  name="email"
                  label="Email"
                  control={control}
                  rules={{ required: 'Required field' }}
                  col={{ md: '12' }}
                  readOnly={!!id}
                  errors={errors}
                  defaultValue={adminState.email}
                />

                <FormGroup
                  type="select"
                  options={rolesData.map((role) => ({
                    value: role.id,
                    label: role.name,
                  }))}
                  name="role"
                  label="Role"
                  control={control}
                  rules={{ required: 'Required field' }}
                  col={{ md: '12' }}
                  errors={errors}
                  defaultValue={adminState.role ? adminState.role.id : ''}
                />
              </Row>
            </FormCard>
          </Col>
        </Row>
      </MainStage>
    )
  );
};

export default AdminForm;
