import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  FormInput,
  FormCheckbox,
  FormSelect,
  DatePicker,
  FormTextarea,
} from 'shards-react';
import ReactSelect from 'react-select';
import { Controller, ErrorMessage } from 'react-hook-form';
import { FileInput } from '.';

const FormGroup = ({
  name,
  label,
  control,
  rules,
  defaultValue,
  errors,
  col,
  type,
  readOnly,
  options,
  isMulti,
  className,
  placeholder,
  onChange,
  onInputChange,
  dateProps,
  size,
  toggle,
  min,
  step,
  preventOverflow,
  onSearch,
}) => {
  const [checked, setChecked] = useState(defaultValue);

  const hasError = () => {
    let result = false;

    if (!errors) return result;

    Object.keys(errors).forEach((key) => {
      const value = errors[key];
      if (Array.isArray(value)) {
        value.forEach((subValue) => {
          if (subValue.ref && subValue.ref.name === name) {
            result = true;
          }
        });
      } else if (value.ref && value.ref.name === name) {
        result = true;
      }
    });

    return result;
  };

  let input;

  switch (type) {
    case 'checkbox':
      input = (
        <Controller
          as={FormCheckbox}
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          invalid={hasError()}
          type={type}
          readOnly={readOnly}
          checked={checked}
          toggle={toggle}
          small
          onChange={() => {
            setChecked(!checked);
            onChange(!checked);
            return !checked;
          }}
        />
      );
      break;
    case 'custom-checkbox':
      const CustomCheckbox = <FormCheckbox>{label}</FormCheckbox>;
      input = (
        <Controller
          as={CustomCheckbox}
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          invalid={hasError()}
          type={type}
          readOnly={readOnly}
          checked={checked}
          toggle={toggle}
          small
          onChange={() => {
            setChecked(!checked);
            onChange(!checked);
            return !checked;
          }}
        />
      );
      break;
    case 'select': {
      const Select = (
        <FormSelect value={defaultValue}>
          <option value="">Please select</option>
          {options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </FormSelect>
      );
      input = (
        <Controller
          as={Select}
          readOnly={readOnly}
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          invalid={hasError()}
          type={type}
          onChange={([e]) => {
            const selected = e.target.value;
            onChange(e);
            return selected;
          }}
        />
      );
      break;
    }
    case 'searchable-select': {
      const SearchableSelect = (
        <ReactSelect
          isMulti={isMulti}
          options={options}
          className="menu-outer-top"
          onInputChange={onInputChange}
          placeholder={<div>{placeholder}</div>}
          value={defaultValue}
          filterOption={onSearch}
        />
      );
      input = (
        <Controller
          as={SearchableSelect}
          readOnly={readOnly}
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          invalid={hasError()}
          type={type}
          onChange={([option]) => {
            onChange(option);
            return option;
          }}
        />
      );
      break;
    }
    case 'file': {
      const fileInput = <FileInput onSelectFile={(event) => onChange(event)} />;
      input = (
        <Controller
          as={fileInput}
          readOnly={readOnly}
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          invalid={hasError()}
          type={type}
        />
      );
      break;
    }
    case 'datepicker': {
      const datepicker = (
        <DatePicker
          popperModifiers={{
            // flip: {
            //   behavior: ['bottom'], // don't allow it to flip to be above
            // },
            preventOverflow: {
              enabled: preventOverflow, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
            hide: {
              enabled: preventOverflow, // turn off since needs preventOverflow to be enabled
            },
          }}
          dateFormat="d MMM yyyy"
          showTimeSelect={false}
          dropdownMode="select"
          placeholderText="Select Date"
          isClearable={!readOnly}
          autoComplete="off"
          {...dateProps}
        />
      );
      input = (
        <Controller
          as={datepicker}
          readOnly={readOnly}
          name={name}
          control={control}
          rules={rules}
          invalid={hasError()}
          type={type}
          defaultValue={defaultValue}
          valueName="selected" // DateSelect value's name is selected
          onChange={([selected]) => {
            onChange(selected);
            return selected;
          }}
        />
      );
      break;
    }
    case 'email': {
      input = (
        <Controller
          as={FormInput}
          readOnly={readOnly}
          name={name}
          control={control}
          rules={{
            ...rules,
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Invalid email',
            },
          }}
          defaultValue={defaultValue}
          invalid={hasError()}
          type={type}
        />
      );
      break;
    }
    case 'textarea': {
      input = (
        <Controller
          as={FormTextarea}
          readOnly={readOnly}
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          placeholder={placeholder}
          size={size}
          invalid={hasError()}
          onChange={([e]) => {
            onChange(e);
            return e.target.value;
          }}
        />
      );
      break;
    }
    default:
      input = (
        <Controller
          as={FormInput}
          readOnly={readOnly}
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          placeholder={placeholder}
          invalid={hasError()}
          type={type}
          min={min}
          step={step}
          onChange={([e]) => {
            onChange(e);
            return e.target.value;
          }}
        />
      );
  }

  return (
    <Col {...col} className={`form-group ${className}`}>
      {label && type !== 'custom-checkbox' && (
        <label htmlFor={name}>{label}</label>
      )}
      {input}
      {errors && Object.keys(errors).length > 0 && (
        <span className="text-danger mt-1 d-block">
          <ErrorMessage errors={errors} name={name} />
        </span>
      )}
    </Col>
  );
};

FormGroup.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.any,
  ]),
  errors: PropTypes.object,
  label: PropTypes.string,
  col: PropTypes.shape({
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
    className: PropTypes.string,
    breakpoints: PropTypes.arrayOf(PropTypes.string),
    tag: PropTypes.string,
  }),
  type: PropTypes.string,
  /**
   * Visit https://react-hook-form.com/api/#register for more information
   * regarding rules
   */
  rules: PropTypes.object,
  readOnly: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.string,
    })
  ),
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  dateProps: PropTypes.object,
  size: PropTypes.string,
  toggle: PropTypes.bool,
  min: PropTypes.number,
  step: PropTypes.number,
  preventOverflow: PropTypes.bool,
  onSearch: PropTypes.func,
};

FormGroup.defaultProps = {
  label: '',
  rules: {},
  defaultValue: '',
  errors: {},
  col: {},
  type: 'text',
  readOnly: false,
  options: [],
  isMulti: false,
  className: '',
  placeholder: '',
  onInputChange: () => {},
  onChange: () => {},
  dateProps: {},
  size: '',
  toggle: false,
  min: 0,
  step: 1,
  preventOverflow: false,
  onSearch: undefined,
};

export default FormGroup;
