import { PostActionTypes } from '../actions/posts';

const initialState = {
  posts: {
    items: [],
    paging: {
      page: 0,
      size: 30,
      orderBy: 'publishedOn',
      order: 'desc',
      filter: '',
    },
    lastSnapshot: null,
  },
  post: {},
  postCategories: [],
  previewToken: '',
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {PostActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PostActionTypes.GET_POSTS:
      return { ...state, posts: { ...action.posts } };
    case PostActionTypes.GET_POST_CATEGORIES:
      return { ...state, postCategories: [...action.postCategories] };
    case PostActionTypes.GET_POST:
    case PostActionTypes.CLEAR_POST:
    case PostActionTypes.UPDATE_POST:
      return { ...state, post: { ...action.post } };
    case PostActionTypes.DELETE_POST:
      return { ...state, post: {} };
    case PostActionTypes.GET_PREVIEW_TOKEN:
      return { ...state, previewToken: action.previewToken };
    default:
      return state;
  }
}
