import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'shards-react';
import { executeAccessAlert } from '../../store/actions/auth';
import RevenueOverview from './RevenueOverview';
import ProductSoldOverview from './ProductSoldOverview';
import MonthlyOverview from './MonthlyOverview';
import TopProductSoldBoard from './TopProductSoldBoard';
import WidgetsOverview from './WidgetsOverview';
import FullCalendar from './FullCalendar';
import PromotionCountOverview from './PromotionCountOverview';
import InventoryReport from './InventoryReport';
import { MainStage } from '../../components/layout';
import { hasAccess } from '../../utils/auth';

const BlogOverview = () => {
  const dispatch = useDispatch();
  const viewNoOfOrders = hasAccess('dashboard', 'no of orders');
  const viewRevenueOverview = hasAccess('dashboard', 'revenue overview');
  const viewProductSold = hasAccess('dashboard', 'product sold');
  const viewMonthlyOverview = hasAccess('dashboard', 'monthly overview');
  const viewPromotionOverview = hasAccess('dashboard', 'promotion overview');
  const viewTopProducts = hasAccess('dashboard', 'top products');
  const viewDeliverySchedules = hasAccess('dashboard', 'delivery schedules');
  const viewInventoryReport = hasAccess('dashboard', 'inventory report');

  useEffect(() => {
    dispatch(executeAccessAlert());
  }, [dispatch]);

  return (
    <MainStage title="Overview" subtitle="Dashboard">
      <Row>
        {viewNoOfOrders && (
          <Col xs={12} className="mb-4">
            <WidgetsOverview />
          </Col>
        )}

        {viewRevenueOverview && (
          <Col xs={12} lg={6} className="mb-4">
            <RevenueOverview />
          </Col>
        )}
        {viewProductSold && (
          <Col xs={12} lg={6} className="mb-4">
            <ProductSoldOverview />
          </Col>
        )}

        {viewMonthlyOverview && (
          <Col xs={12} className="mb-4">
            <MonthlyOverview />
          </Col>
        )}

        {viewPromotionOverview && (
          <Col xs={12} className="mb-4">
            <PromotionCountOverview />
          </Col>
        )}

        {viewTopProducts && (
          <Col xs={12} lg={4} className="mb-4">
            <TopProductSoldBoard />
          </Col>
        )}

        {viewDeliverySchedules && (
          <Col xs={12} lg={8} className="mb-4">
            <FullCalendar />
          </Col>
        )}

        {viewInventoryReport && (
          <Col xs={12} lg={8} className="mb-4">
            <InventoryReport />
          </Col>
        )}
      </Row>
    </MainStage>
  );
};

export default BlogOverview;
