import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DBURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};
firebase.initializeApp(firebaseConfig);

// firebase utils
firebase.firestore().settings({ experimentalForceLongPolling: true });
const firestore = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

// firebase collections
const adminsCollection = firestore.collection('admins');
const rolesCollection = firestore.collection('roles');
const archivesCollection = firestore.collection('archives');
const usersCollection = firestore.collection('users');
const productsCollection = firestore.collection('products');
const ordersCollection = firestore.collection('orders');
const orderLogsCollection = firestore.collection('orderLogs');
const orderReportsCollection = firestore.collection('orderReports');
const orderFiltersCollection = firestore.collection('orderFilters');
const reviewsCollection = firestore.collection('reviews');
const deliverySlotsCollection = firestore.collection('deliverySlots');
const promosCollection = firestore.collection('promos');
const postsCollection = firestore.collection('posts');
const postCategoriesCollection = firestore.collection('postCategories');
const referralsCollection = firestore.collection('referrals');

export {
  firebase,
  firestore,
  auth,
  storage,
  adminsCollection,
  rolesCollection,
  archivesCollection,
  usersCollection,
  productsCollection,
  ordersCollection,
  orderLogsCollection,
  orderReportsCollection,
  orderFiltersCollection,
  reviewsCollection,
  deliverySlotsCollection,
  promosCollection,
  postsCollection,
  postCategoriesCollection,
  referralsCollection,
};
