import { error } from 'react-toastify-redux';
import _ from 'lodash';
import { orderReportsCollection } from '../../firebase';
import { startLoading, stopLoading } from './ui';

export const OrderReportActionTypes = {
  GET_ORDER_REPORT: 'GET_ORDER_REPORT',
};

/**
 * Retrieve all order report from firebase
 */
export const getOrderReport = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const orderReportDoc = await orderReportsCollection.doc(id).get();
      if (!orderReportDoc.exists) return;

      const orderReport = orderReportDoc.data();
      orderReport.invoices = _.orderBy(orderReport.invoices, 'date', 'desc');

      dispatch({
        type: OrderReportActionTypes.GET_ORDER_REPORT,
        orderReport: { id, ...orderReport },
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
