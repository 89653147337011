import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  DatePicker,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'shards-react';
import moment from 'moment';
import { HorizontalBar } from 'react-chartjs-2';
import { warning } from 'react-toastify-redux';
import { getOrderPromos } from '../../store/actions/reports';
import MaterialIcon from '../../components/common/MaterialIcon';

const PromotionCountOverview = () => {
  const dispatch = useDispatch();
  const orderPromosData = useSelector(
    ({ reports: { orderPromos } }) => orderPromos
  );
  const [chartData, setChartData] = useState(null);
  const [salesChartData, setSalesChartData] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate());

  const chartOptions = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 5,
          },
        },
      ],
    },
  };

  const salesChartOptions = {
    tooltips: {
      callbacks: {
        label(t, d) {
          const yLabel = d.datasets[t.datasetIndex].label;
          const xLabel = `$${t.xLabel
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          return `${yLabel}: ${xLabel}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 10000,
            callback(value) {
              return `$${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    if (orderPromosData) {
      const data = {
        labels: orderPromosData.labels,
        datasets: [
          {
            label: 'No of Orders',
            data: orderPromosData.counts,
            backgroundColor: orderPromosData.backgroundColors,
            borderColor: orderPromosData.borderColors,
            barPercentage: 0.7,
            borderWidth: 1,
          },
        ],
      };
      setChartData(data);

      const salesData = {
        labels: orderPromosData.labels,
        datasets: [
          {
            label: 'Total Sales',
            data: orderPromosData.sales,
            backgroundColor: orderPromosData.backgroundColors,
            borderColor: orderPromosData.borderColors,
            barPercentage: 0.7,
            borderWidth: 1,
          },
        ],
      };
      setSalesChartData(salesData);

      setStartDate(orderPromosData.dateFrom);
      setEndDate(orderPromosData.dateTo);
    }
  }, [orderPromosData]);

  useEffect(() => {
    if (!orderPromosData) dispatch(getOrderPromos(startDate, endDate));
  }, []);

  const handleGetOrderPromos = (from, to) => {
    const fromMoment = moment(from);
    const toMoment = moment(to);
    if (toMoment.diff(fromMoment, 'month') > 11)
      dispatch(warning('Date range must be within 12 months'));
    else if (fromMoment.diff(toMoment) > 0)
      dispatch(warning('Invalid date range'));
    else dispatch(getOrderPromos(from, to));
  };

  const handleStartDateChange = (value) => {
    setStartDate(new Date(value));
    handleGetOrderPromos(value, endDate);
  };

  const handleEndDateChange = (value) => {
    setEndDate(new Date(value));
    handleGetOrderPromos(startDate, value);
  };

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Promotion Overview</h6>
      </CardHeader>
      <CardBody className="py-0">
        <Row>
          <Col xs={12} lg={6}>
            {chartData && (
              <HorizontalBar data={chartData} options={chartOptions} />
            )}
          </Col>
          <Col xs={12} lg={6}>
            {salesChartData && (
              <HorizontalBar
                data={salesChartData}
                options={salesChartOptions}
              />
            )}
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="border-top">
        <div className="d-flex align-items-center">
          <div>
            <DatePicker
              size="sm"
              selected={startDate}
              style={{ maxWidth: '130px' }}
              onChange={handleStartDateChange}
              placeholderText="Start Date"
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <MaterialIcon className="mx-2" icon="sync_alt" />
          <div>
            <DatePicker
              size="sm"
              selected={endDate}
              style={{ maxWidth: '130px' }}
              onChange={handleEndDateChange}
              placeholderText="Start Date"
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default PromotionCountOverview;
