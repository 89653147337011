import { PromotionActionTypes } from '../actions/promotions';

const initialState = {
  promotions: {
    items: [],
    paging: {
      page: 1,
      size: 50,
      orderBy: 'startOn',
      order: 'desc',
    },
  },
  promotion: null,
  orderPromotion: null,
  productPromotion: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {PromotionActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PromotionActionTypes.GET_PROMOTIONS:
      return { ...state, promotions: { ...action.promotions } };
    case PromotionActionTypes.GET_PROMOTION:
      return { ...state, promotion: { ...action.promotion } };
    case PromotionActionTypes.APPLY_ORDER_PROMOTION:
      return { ...state, orderPromotion: { ...action.orderPromotion } };
    case PromotionActionTypes.CLEAR_ORDER_PROMOTION:
      return { ...state, orderPromotion: null };
    case PromotionActionTypes.APPLY_PRODUCT_PROMOTION:
      return { ...state, productPromotion: { ...action.productPromotion } };
    case PromotionActionTypes.CLEAR_PRODUCT_PROMOTION:
      return { ...state, productPromotion: null };
    default:
      return state;
  }
}
