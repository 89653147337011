import { storage } from '../firebase';

/**
 * Upload file to firebase storage
 * @param {File} file
 * @param {string} path
 */
export const uploadFile = async (file, path) => {
  const storageRef = storage.ref(`${path}/${file.name}`);
  const snap = await storageRef.put(file);
  return snap;
};

/**
 * Get the download url of images using firebase storage
 * @param {string} path
 */
export const getDownloadUrl = async (path) => {
  const url = await storage.ref().child(path).getDownloadURL();
  return url.split('&')[0];
};

/**
 * Helps to download all images and not breaking promise advantages.
 * Returns an object with the path as the key for constant read to the promised result
 * @param {string[]} paths
 * @returns {Object}
 */
export const getDownloadUrls = async (paths) => {
  const result = {};
  const keys = Array.from(new Set(paths));
  const promises = [];

  keys.forEach((key) =>
    promises.push(storage.ref().child(key).getDownloadURL())
  );

  const refs = await Promise.all(promises);

  refs.forEach((ref, i) => {
    result[keys[i]] = ref;
  });

  return result;
};

/**
 * Get file extension
 * @param {string} filename
 */
export const getExtension = (filename) => {
  const parts = filename.split('.');
  return parts[parts.length - 1];
};

/**
 * Get file type
 * @param {string} filename
 */
export const getFileType = (filename) => {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
    case 'jpeg':
      return 'image';
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      return 'video';
    default:
  }
  return false;
};

/**
 * @typedef FileBase64
 * @property {string} type
 * @property {string | ArrayBuffer} path
 *
 * convert file into base64
 * @param {File}
 * @returns {Promise<Object>}
 */
export const readFileInBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        type: getFileType(file.name),
        path: reader.result,
      });
    };
    reader.onerror = reject;
  });
};

export const isFileSizeValid = (fileSize, maxFileSize) => {
  if (fileSize / 1024 > maxFileSize) {
    return false;
  }
  return true;
};
