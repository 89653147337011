import { AdminActionTypes } from '../actions/admins';

const initialState = {
  admins: {
    items: [],
    paging: {
      page: 1,
      size: 1000,
      orderBy: 'audit.createdOn',
      order: 'desc',
    },
  },
  admin: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {AdminActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AdminActionTypes.GET_ADMINS:
      return { ...state, admins: { ...action.admins } };
    case AdminActionTypes.GET_ADMIN:
      return { ...state, admin: { ...action.admin } };
    case AdminActionTypes.DELETE_ADMIN:
      return { ...state, admin: {} };
    default:
      return state;
  }
}
