import React, { useRef } from 'react';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import shortid from 'shortid';
import { useSelector } from 'react-redux';

import { uploadFile, getDownloadUrls } from '../../../utils/media';

const contentCss = css`
  * {
    font-family: 'Acumin', 'sans-serif';
    font-weight: 400;
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote {
    font-family: 'Orpheus', 'serif';
    font-weight: 400;
    color: #0d1e2a;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.33;
    color: #65726b;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  figure.image {
    display: inline-block;
    margin: 0;
    background: transparent;
  }

  figure.image img {
    margin: 0;
  }

  figure.image figcaption {
    margin: 6px 0;
    color: #65726b;
  }

  blockquote {
    line-height: 1.1;
  }
  blockquote > * {
    margin: 0 !important;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .col {
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    width: 100%;
    min-height: 1px;
  }

  .accordion {
    width: 100%;
    color: #65726b;
  }

  .accordion-item {
    width: 100%;
    border-top: 1px dashed #65726b;
  }

  .accordion-item:last-child {
    border-bottom: 1px dashed #65726b;
  }

  .accordion-header {
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
  }

  .accordion-button {
    position: relative;
    width: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 30px;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.33;
    color: #65726b;
  }

  .accordion-button::before {
    content: '+';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #ccc;
    font-size: 1.5rem;
  }

  .accordion-body {
    padding: 15px 30px;
  }

  .accordion-body > p:first-child {
    margin-top: 0;
  }

  .accordion-body > p:last-child {
    margin-bottom: 0;
  }
`;

const PostEditor = ({ onChange }) => {
  const editorRef = useRef(null);
  const { post } = useSelector(({ posts }) => posts);

  const handleUploadImage = async (blobInfo, success, failure) => {
    try {
      const snapshot = await uploadFile(
        blobInfo.blob(),
        `images/posts/${shortid.generate()}`
      );
      const { fullPath } = snapshot.metadata;
      const downloadedUrls = await getDownloadUrls([fullPath]);
      const urls = Object.keys(downloadedUrls).map(
        (key) => downloadedUrls[key]
      );
      success(urls[0]);
    } catch (error) {
      failure(error);
    }
  };

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      initialValue={post.content}
      onInit={(_, editor) => {
        editorRef.current = editor;
      }}
      onChange={() => {
        if (editorRef.current && typeof onChange === 'function') {
          onChange(editorRef.current.getContent());
        }
      }}
      init={{
        height: 500,
        menubar: true,
        plugins: [
          'advlist autolink lists link image imagetools charmap print preview anchor',
          'searchreplace visualblocks code fullscreen template',
          'insertdatetime media table paste code help wordcount emoticons hr',
        ],
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic blockquote forecolor backcolor emoticons | ' +
          'alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist outdent indent | ' +
          'link image template | ' +
          'help',
        content_css: false,
        content_style: ['../../../../assets/fonts/fonts.css', contentCss].join(
          '\n'
        ),
        image_caption: true,
        images_upload_handler: handleUploadImage,
        visualblocks_default_state: true,
        end_container_on_empty_block: true,
        templates: [
          {
            title: '2 Columns Layout',
            description: 'A simple 2 columns layout',
            content:
              '<div class="row"><div class="col"><p>Column 1</p></div><div class="col"><p>Column 2</p></div></div>',
          },
          {
            title: '3 Columns Layout',
            description: 'A simple 3 columns layout',
            content:
              '<div class="row"><div class="col"><p>Column 1</p></div><div class="col"><p>Column 2</p></div><div class="col"><p>Column 3</p></div></div>',
          },
          {
            title: 'Accordion',
            description: 'An interactive accordion',
            content: `
              <div class="accordion">
                <div class="accordion-item">
                  <div class="accordion-header">
                    <button class="accordion-button">Accordion Item #1</button>
                  </div>
                  <div class="accordion-collapse">
                    <div class="accordion-body">
                      <p>First item's accordion body.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header">
                    <button class="accordion-button">Accordion Item #2</button>
                  </div>
                  <div class="accordion-collapse">
                    <div class="accordion-body">
                      <p>Second item's accordion body.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header">
                    <button class="accordion-button">Accordion Item #3</button>
                  </div>
                  <div class="accordion-collapse">
                    <div class="accordion-body">
                      <p>Third item's accordion body.</p>
                    </div>
                  </div>
                </div>
              </div>`,
          },
        ],
      }}
    />
  );
};

PostEditor.propTypes = {
  onChange: PropTypes.func,
};

PostEditor.defaultProps = {
  onChange: () => {},
};

export default PostEditor;
