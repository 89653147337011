import React from 'react';
import { Container, Row } from 'shards-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageTitle, MaterialIcon } from '../common';

const PageTitleLink = styled(Link)`
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainStage = ({ children, title, subtitle, to, className }) => {
  const pageTitle = (
    <PageTitle title={title} subtitle={subtitle} className="text-sm-left" />
  );
  return (
    <Container
      fluid
      className={`main-content-container px-4 pb-4 ${className}`}
    >
      <Row noGutters className="page-header py-4 align-items-center">
        {to && (
          <PageTitleLink to={to}>
            <IconContainer>
              <MaterialIcon className="mb-1" size={24} icon="arrow_back" />
              Back
            </IconContainer>
            {pageTitle}
          </PageTitleLink>
        )}
        {!to && pageTitle}
      </Row>
      {children}
    </Container>
  );
};

MainStage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
};

MainStage.defaultProps = {
  to: '',
  className: '',
};

export default MainStage;
