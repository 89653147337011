import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';

import { MainStage } from '../../components/layout';
import PostEditor from './PostEditor';
import PostTitle from './PostTitle';
import PostSidebarAction from './PostSidebarAction';
import PostSidebarCategory from './PostSidebarCategory';
import PostSidebarThumbnail from './PostSidebarThumbnail';
import PostSidebarMeta from './PostSidebarMeta';
import {
  createPost,
  getPost,
  clearPost,
  deletePost,
  updatePost,
  getPreviewToken,
} from '../../store/actions/posts';

const PostForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const postData = useSelector(({ posts }) => posts.post);
  const canSubmit = useRef(true);
  const post = useRef({ ...postData });

  useEffect(() => {
    if (id) {
      dispatch(getPost(id));
      dispatch(getPreviewToken(id));
    } else {
      dispatch(clearPost());
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    post.current = { ...postData };
    // eslint-disable-next-line
  }, [postData]);

  const handleSelectCategories = (selected) => {
    post.current.categories = selected.map((c) => ({ id: c.id, name: c.name }));
    post.current.categoryIndexes = selected.reduce(
      (map, c) => ({ ...map, [c.id]: c.name }),
      {}
    );
  };

  const handleSubmit = (status) => {
    if (!canSubmit.current) return;
    post.current.status = status;
    if (!id) {
      dispatch(createPost(post.current));
    } else {
      dispatch(updatePost(id, post.current));
    }
  };

  const handleDateChange = (date) => {
    post.current.publishedOn = date;
  };

  const handleTitleChange = ({ title, slug }) => {
    post.current.title = title;
    post.current.slug = slug;
    canSubmit.current = !!(
      post.current.title && post.current.title.trim().length > 0
    );
  };

  const handleEditorChange = (htmlContent) => {
    post.current.content = htmlContent;
  };

  const handleThumbnailChange = (media) => {
    const [thumbnail] = media;
    post.current.thumbnail = thumbnail;
  };

  const handleAuthorChange = (author) => {
    post.current.author = author;
  };

  const handleIsFeaturedChange = (isFeatured) => {
    post.current.isFeatured = isFeatured;
  };

  const handleMetaChange = (meta) => {
    post.current.meta = meta;
  };

  const handleDelete = () => {
    if (!id) return;
    dispatch(deletePost(id));
  };

  return (
    <MainStage title={!id ? 'New' : 'Update'} subtitle="Blog Posts" to="/posts">
      <Row>
        <Col lg="9" md="12">
          <Card>
            <CardBody>
              <PostTitle onChange={handleTitleChange} />
              <PostEditor onChange={handleEditorChange} version="v2" />
            </CardBody>
          </Card>
        </Col>
        <Col lg="3" md="12">
          <PostSidebarAction
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            onDateChange={handleDateChange}
            onAuthorChange={handleAuthorChange}
            onIsFeaturedChange={handleIsFeaturedChange}
          />
          <PostSidebarCategory onSelected={handleSelectCategories} />
          <PostSidebarThumbnail onChange={handleThumbnailChange} />
          <PostSidebarMeta onChange={handleMetaChange} />
        </Col>
      </Row>
    </MainStage>
  );
};

export default PostForm;
