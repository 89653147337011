export const UIActionTypes = {
  SHOW_MENU: 'SHOW_MENU',
  HIDE_MENU: 'HIDE_MENU',
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
  PAGE_START_LOADING: 'PAGE_START_LOADING',
  PAGE_STOP_LOADING: 'PAGE_STOP_LOADING',
  REDIRECT: 'REDIRECT',
};

/**
 * Toggle side nav menu for mobile viewport
 */
export const toggleMenu = () => {
  return (dispatch, getState) => {
    dispatch({
      type: getState().ui.menuVisible
        ? UIActionTypes.HIDE_MENU
        : UIActionTypes.SHOW_MENU,
    });
  };
};

/**
 * Start loading
 */
export const startLoading = () => {
  return {
    type: UIActionTypes.START_LOADING,
  };
};

/**
 * Stop loading
 */
export const stopLoading = () => {
  return {
    type: UIActionTypes.STOP_LOADING,
  };
};

/**
 * Page start loading
 */
export const pageStartLoading = () => {
  return {
    type: UIActionTypes.PAGE_START_LOADING,
  };
};

/**
 * Page stop loading
 */
export const pageStopLoading = () => {
  return {
    type: UIActionTypes.PAGE_STOP_LOADING,
  };
};

/**
 * Redirect to another page
 * @param {string} to
 */
export const redirect = (to) => {
  return {
    type: UIActionTypes.REDIRECT,
    to,
  };
};
