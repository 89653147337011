import { UIActionTypes } from '../actions/ui';

const initialState = {
  menuVisible: false,
  isLoading: false,
  pageLoading: false,
  redirectTo: '',
  sidebarNavItems: [
    {
      title: 'Dashboard',
      to: '/dashboard',
      icon: 'dashboard',
    },
    {
      title: 'Orders',
      to: '/orders',
      icon: 'shopping_cart',
    },
    {
      title: 'Customers',
      to: '/customers',
      icon: 'account_circle',
    },
    {
      title: 'Products',
      to: '/products',
      icon: 'single_bed',
    },
    {
      title: 'Promotions',
      to: '/promotions',
      icon: 'local_offer',
    },
    {
      title: 'Referrals',
      to: '/referrals',
      icon: 'group',
    },
    {
      title: 'Delivery Slots',
      to: '/delivery-slots',
      icon: 'local_shipping',
    },
    {
      title: 'Delivery Orders',
      to: '/delivery-orders',
      icon: 'local_shipping',
    },
    {
      title: 'Reviews',
      to: '/reviews',
      icon: 'rate_review',
    },
    {
      title: 'Blog Posts',
      to: '/posts',
      icon: 'push_pin',
    },
    {
      title: 'Roles',
      to: '/roles',
      icon: 'verified_user',
    },
    {
      title: 'Admins',
      to: '/admins',
      icon: 'supervisor_account',
    },
  ],
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {UIActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UIActionTypes.SHOW_MENU:
      return { ...state, menuVisible: true };
    case UIActionTypes.HIDE_MENU:
      return { ...state, menuVisible: false };
    case UIActionTypes.START_LOADING:
      return { ...state, isLoading: true };
    case UIActionTypes.STOP_LOADING:
      return { ...state, isLoading: false };
    case UIActionTypes.PAGE_START_LOADING:
      return { ...state, pageLoading: true };
    case UIActionTypes.PAGE_STOP_LOADING:
      return { ...state, pageLoading: false };
    case UIActionTypes.REDIRECT:
      return { ...state, redirectTo: action.to };
    default:
      return state;
  }
}
