import { warning, error, success } from 'react-toastify-redux';
import { startLoading, stopLoading } from './ui';
import { usersCollection, ordersCollection, firebase } from '../../firebase';
import { getFirebasePagingQuery } from '../../utils';
import { getToken } from '../../utils/auth';
import { CustomerType } from '../../constants';
import { parse } from 'json2csv';
import moment from 'moment';
import _ from 'lodash';

export const UserActionTypes = {
  GET_USERS: 'GET_USERS',
  GET_USER: 'GET_USER',
  UPDATE_REFERRAL_WITHDRAWAL: 'UPDATE_REFERRAL_WITHDRAWAL',
};
const apiEndpoint = `${process.env.REACT_APP_WOOSA_API_URI}/api/referrals`;

/**
 * Retrieve multiple users from firebase
 * @param {Object} options
 * @param {string} options.orderBy order by field name
 * @param {string} options.order desc or asc order
 * @param {number} options.page the current page number
 * @param {number} options.size per page size
 * @param {string} options.filter search filter
 */
export const getUsers = (options) => {
  return async (dispatch, getState) => {
    try {
      const {
        users: { users },
      } = getState();

      dispatch(startLoading());

      const result = await getFirebasePagingQuery(
        options,
        users,
        usersCollection
      );

      if (!result) return;

      dispatch({
        type: UserActionTypes.GET_USERS,
        users: {
          ...result,
        },
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Get user by Id
 * @param {string} id
 */
export const getUser = (id) => {
  return async (dispatch) => {
    let user = {};
    const orders = [];

    try {
      dispatch(startLoading());

      const userRef = await usersCollection.doc(id).get();
      user = { id: userRef.id, ...userRef.data() };
      const promises = [];

      // Get orders
      promises.push(
        ordersCollection
          .where('userId', '==', id)
          .orderBy('orderedOn')
          .limit(1)
          .get()
      );

      // Execute all async calls
      const refs = await Promise.all(promises);
      const recentOrdersRef = refs[0];

      // Consolidate orders
      recentOrdersRef.forEach((doc) => {
        orders.push({ id: doc.id, ...doc.data() });
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
      dispatch({
        type: UserActionTypes.GET_USER,
        user,
        orders,
      });
    }
  };
};

/**
 * Update user referral progress
 * @param {string} id
 * @param {string} refereeId
 * @param {bool} userDhasWithdrawnata
 */
export const updateUserReferral = (id, refereeId, hasWithdrawn) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const {
        auth: { admin },
        users: { user },
      } = getState();

      await usersCollection.doc(id).update({
        'audit.updatedBy': admin.name,
        'audit.updatedOn': firebase.firestore.FieldValue.serverTimestamp(),
        [`referrals.referees.${refereeId}.earned.hasWithdrawn`]: hasWithdrawn,
      });

      user.referrals.referees[refereeId].earned.hasWithdrawn = hasWithdrawn;

      if (hasWithdrawn) {
        const response = await fetch(
          `${apiEndpoint}/withdrawal/${id}/${refereeId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${getToken().token}`,
            },
            body: JSON.stringify({ env: process.env.REACT_APP_ENV }),
          }
        );
        if (response.status !== 201 && response.status !== 200)
          dispatch(error('Sending of email failed'));
      }

      dispatch({
        type: UserActionTypes.UPDATE_REFERRAL_WITHDRAWAL,
        user,
      });
      dispatch(success('Successfully updated!'));
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

export const exportCSV = (params) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      let { filter, filterType, joinedDate } = params;
      let query = usersCollection;

      if (filterType === CustomerType.REGISTERED) {
        query = query.orderBy('uid');
      }

      if (filter) {
        query = query.where('indexes', 'array-contains-any', [filter]);
      }

      const documentSnapshots = await query.get();
      let users = [];
      documentSnapshots.forEach((doc) => {
        const data = doc.data();

        if (filterType === CustomerType.GUEST && data.uid) return;

        if (
          joinedDate &&
          moment.unix(data.joinedOn.seconds) < moment(joinedDate)
        )
          return;

        users.push({
          id: doc.id,
          ...data,
        });
      });
      console.log('users', users);
      if (!users.length) {
        dispatch(warning('Customers not found.'));
        return;
      }

      users = _.orderBy(users, 'joinedOn');

      const csvData = users.map((data) => {
        return {
          csv_email: data.email,
          csv_firstName: data.firstName,
          csv_lastName: data.lastName,
        };
      });

      const fields = Object.keys(csvData[0]);
      const opts = { fields };
      let csv = parse(csvData, opts);
      csv = csv
        .replace('csv_email', 'Email')
        .replace('csv_firstName', 'First Name')
        .replace('csv_lastName', 'Last Name');
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', csv]);
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'customers.csv';
      downloadLink.click();
      downloadLink.remove();
    } catch (err) {
      console.log('err', err);
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
