import React from 'react';
import { Container, Button } from 'shards-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const StatusError = ({ statusCode, title, message }) => {
  const history = useHistory();

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <div className="error">
        <div className="error__content">
          <h2>{statusCode}</h2>
          <h3>{title}</h3>
          <p>{message}</p>
          <Button pill onClick={() => history.goBack()}>
            &larr; Go Back
          </Button>
        </div>
      </div>
    </Container>
  );
};

StatusError.propTypes = {
  statusCode: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default StatusError;
