import { error, success } from 'react-toastify-redux';
import {
  productsCollection,
  archivesCollection,
  firebase,
  firestore,
} from '../../firebase';
import { startLoading, stopLoading, redirect } from './ui';
import { getAdmin } from '../../utils/auth';

export const ProductActionTypes = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCT: 'GET_PRODUCT',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
};

/**
 * Retrieve multiple products from firebase
 * @param {object} paging
 * @param {string} paging.orderBy order by field name
 * @param {string} paging.order get desc or asc
 * @param {number} paging.page current page
 * @param {number} paging.size per page size
 */
export const getProducts = (paging = {}) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      if (!paging.orderBy) paging.orderBy = 'audit.createdOn';
      if (!paging.order) paging.order = 'desc';
      if (!paging.page) paging.page = 1;
      if (!paging.size) paging.size = 1000;

      const productResponse = await productsCollection
        .orderBy(paging.orderBy, paging.order)
        .limit(paging.size)
        .get();

      const items = [];

      productResponse.forEach((doc) => {
        const data = doc.data();
        if (!data.isDeleted) items.push({ id: doc.id, ...data });
      });

      dispatch({
        type: ProductActionTypes.GET_PRODUCTS,
        products: { items, paging },
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve single product from firebase
 * @param {string} id get by object id
 */
export const getProduct = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const doc = await productsCollection.doc(id).get();

      const product = {
        id: doc.id,
        ...doc.data(),
      };

      dispatch({
        type: ProductActionTypes.GET_PRODUCT,
        product,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Update product
 * @param {string} id
 * @param {object} product
 */
export const updateProduct = (id, product) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      product.audit = {
        ...product.audit,
        updatedBy: getAdmin().name,
        updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };
      await productsCollection.doc(id).set(product);
      dispatch(getProduct(id));
      dispatch(success('Successfully updated!'));
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Create product
 * @param {object} product
 */
export const createProduct = (product) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      product.audit = {
        createdBy: getAdmin().name,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      };
      const productRef = await productsCollection.add(product);
      dispatch(redirect(`/products/${productRef.id}`));
      setTimeout(() => dispatch(success('Successfully created!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Delete product
 * @param {string} id
 * @param {object} product
 */
export const deleteProduct = (id) => {
  return async (dispatch, getState) => {
    try {
      const {
        products: { product },
      } = getState();

      dispatch(startLoading());

      product.audit = {
        ...product.audit,
        deletedBy: getAdmin().name,
        deletedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const batch = firestore.batch();
      batch.delete(productsCollection.doc(id));
      batch.update(archivesCollection.doc('products'), { [id]: product });
      await batch.commit();

      dispatch({
        type: ProductActionTypes.DELETE_PRODUCT,
      });
      dispatch(redirect('/products'));

      setTimeout(() => dispatch(success('Successfully deleted!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
