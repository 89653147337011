import { OrderFilterActionTypes } from '../actions/orderFilters';

const initialState = {
  orderFilters: {
    items: [],
  },
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {OrderFilterActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OrderFilterActionTypes.GET_ORDER_FILTERS:
      return { ...state, orderFilters: { ...action.orderFilters } };
    default:
      return state;
  }
}
