import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Collapse } from 'shards-react';
import MaterialIcon from './MaterialIcon';
import DialogButton from './DialogButton';

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  .log-content {
    padding: 10px 14px;
    p {
      font-weight: 400;
    }
    small {
      font-weight: 500;
    }
  }
`;

const ValueList = styled.ul`
  list-style: none;
  padding: 0;
  li {
    display: block !important;
    padding: 2px 0 !important;
    label {
      font-size: 12px;
      margin: 0;
    }
  }
`;

const ValueContainer = styled.div`
  display: flex;
`;

const CollapseIcon = styled(MaterialIcon)`
  cursor: pointer !important;
  position: absolute !important;
  right: 8px !important;
  top: 10px !important;
  font-size: 20px !important;
`;

const DialogButtonIcon = styled(DialogButton)`
  cursor: pointer !important;
  position: absolute !important;
  right: 8px !important;
  top: 10px !important;
  padding: 2px !important;
`;

const OrderLog = ({ className, data, onDelete }) => {
  const [collapse, setCollapse] = useState(false);

  const createdOnFormatted = moment
    .unix(data.createdOn.seconds)
    .format('DD MMM YYYY, hh:mm a');

  const getContent = () => {
    switch (data.type) {
      case 'comment': {
        return (
          <div className="log-content bg-warning">
            <strong className="d-flex text-white pr-4">
              {data.name} commented on the order
              <DialogButtonIcon
                theme="danger"
                icon="delete_outline"
                message=""
                onConfirm={() => onDelete()}
              />
            </strong>
            <p className="text-white my-2">{data.comment}</p>
            <div className="text-right">
              <small className="text-white mt-2">{createdOnFormatted}</small>
            </div>
          </div>
        );
      }
      case 'created': {
        return (
          <div className="log-content">
            <strong className="text-secondary">
              {data.name} created the order
            </strong>
            <small className="text-muted float-right mt-2">
              {createdOnFormatted}
            </small>
          </div>
        );
      }
      case 'modified': {
        return (
          <div className="log-content">
            <strong className="d-flex text-secondary">
              {data.name} modified the order
              <CollapseIcon
                onClick={() => setCollapse(!collapse)}
                icon={collapse ? 'expand_more' : 'expand_less'}
              />
            </strong>
            <Collapse open={collapse}>
              <ValueList className="my-2">
                {data.values.map((v, index) => {
                  let { oldValue, newValue } = v;
                  if (
                    typeof oldValue === 'object' ||
                    typeof newValue === 'object'
                  )
                    return null;

                  if (oldValue && oldValue.seconds)
                    oldValue = moment
                      .unix(oldValue.seconds)
                      .format('DD MMM YYYY');
                  if (newValue && newValue.seconds)
                    newValue = moment
                      .unix(newValue.seconds)
                      .format('DD MMM YYYY');

                  return (
                    <li key={index}>
                      <label>{v.property}</label>
                      <ValueContainer>
                        {oldValue !== '' && (
                          <label className="text-danger">{oldValue}</label>
                        )}
                        {oldValue !== '' && newValue !== '' && (
                          <MaterialIcon className="mx-2" icon="forward" />
                        )}
                        {newValue !== '' && (
                          <label className="text-success">{newValue}</label>
                        )}
                      </ValueContainer>
                    </li>
                  );
                })}
              </ValueList>
            </Collapse>
            <small className="text-muted float-right mt-2">
              {createdOnFormatted}
            </small>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return <Container className={className}>{getContent()}</Container>;
};

OrderLog.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  onDelete: PropTypes.func,
};

OrderLog.defaultProps = {
  className: '',
  onDelete: () => {},
};

export default OrderLog;
