import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder } from '../../store/actions/orders';
import { MainStage } from '../../components/layout';
import { OrderDetailCard } from '../../components/common';

const OrderViewForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const orderData = useSelector(({ orders: { order } }) => order);
  const [orderState, setOrderState] = useState(null);

  useEffect(() => {
    if (id) dispatch(getOrder(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (id && orderData) setOrderState(orderData);
  }, [id, orderData]);

  return (
    orderState &&
    orderState.id === id && (
      <MainStage title="Details" subtitle="Orders" to="/orders">
        <OrderDetailCard order={orderState} />
      </MainStage>
    )
  );
};

export default OrderViewForm;
