import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  CardFooter,
  DatePicker,
} from 'shards-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { warning } from 'react-toastify-redux';
import MaterialIcon from '../../components/common/MaterialIcon';
import { getProductInventory } from '../../store/actions/reports';

const CollapseIcon = styled(MaterialIcon)`
  cursor: pointer !important;
  position: absolute !important;
  right: 8px !important;
  top: 10px !important;
  font-size: 20px !important;
`;

const HeaderUl = styled.ul`
   {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #f9f9f9;
    margin: 0;
    border-bottom: 1px solid #e1e5eb;
    font-weight: bold;
    > li {
      padding: 7px 15px;
      width: 30%;
      &:first-child {
        width: 50%;
      }
    }
  }
`;
const BodyUl = styled.ul`
   {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #e1e5eb;
    > li {
      padding: 9px 15px;
      width: 30%;
      &:first-child {
        width: 50%;
      }
    }
  }
`;

const VariantUl = styled.ul`
   {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    background-color: #f9f9f9;
    font-size: 13px;
    > li {
      padding: 7px 15px;
      width: 30%;
      &:first-child {
        width: 50%;
      }
    }
  }
`;

const InventoryReport = () => {
  const dispatch = useDispatch();
  const inventoryData = useSelector(
    ({ reports: { productInventory } }) => productInventory
  );
  const [productInventory, setProductInventory] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate());

  useEffect(() => {
    if (!inventoryData) dispatch(getProductInventory(startDate, endDate));
  }, []);

  useEffect(() => {
    if (inventoryData) {
      setProductInventory(inventoryData.orderProducts);
    }
  }, [inventoryData]);

  const handleGetInventory = (from, to) => {
    const fromMoment = moment(from);
    const toMoment = moment(to);
    if (toMoment.diff(fromMoment, 'month') > 11)
      dispatch(warning('Date range must within 12 months'));
    else if (fromMoment.diff(toMoment) > 0)
      dispatch(warning('Invalid date range'));
    else dispatch(getProductInventory(from, to));
  };

  const handleStartDateChange = (value) => {
    setStartDate(new Date(value));
    handleGetInventory(value, endDate);
  };

  const handleEndDateChange = (value) => {
    setEndDate(new Date(value));
    handleGetInventory(startDate, value);
  };

  const handleSetCollapse = (index) => {
    productInventory[index].open = !productInventory[index].open;
    setProductInventory([...productInventory]);
  };

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Product Inventory</h6>
      </CardHeader>
      <CardBody className="p-0">
        <HeaderUl>
          <li>Product</li>
          <li>Collection</li>
          <li>Total Qty</li>
        </HeaderUl>
        {productInventory.length > 0 &&
          productInventory.map((op, index) => {
            return (
              <div
                key={op.id}
                className="position-relative"
                onClick={() => handleSetCollapse(index)}
              >
                <CollapseIcon icon={op.open ? 'expand_less' : 'expand_more'} />
                <BodyUl>
                  <li>{op.name}</li>
                  <li>{op.collection}</li>
                  <li>{op.totalQty}</li>
                </BodyUl>
                <Collapse open={op.open} className="border-bottom">
                  {op.products.map((v, i) => {
                    return (
                      <>
                        {v.groupDescription && (
                          <VariantUl>
                            <li className="w-100 font-italic">
                              {v.groupDescription} - {v.statusType}
                            </li>
                          </VariantUl>
                        )}
                        <VariantUl key={`${op.id}-${v.variant}-${i}`}>
                          <li>
                            <Link to={`/orders/view/${v.id}`}>{v.refNum}</Link>
                          </li>
                          <li>{v.variant}</li>
                          <li>{v.qty}</li>
                        </VariantUl>
                      </>
                    );
                  })}
                </Collapse>
              </div>
            );
          })}
      </CardBody>
      <CardFooter className="border-top">
        <div className="d-flex align-items-center">
          <div className="ml-2">
            <DatePicker
              size="sm"
              selected={startDate}
              style={{ maxWidth: '130px' }}
              onChange={handleStartDateChange}
              placeholderText="Start Date"
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <MaterialIcon className="mx-2" icon="sync_alt" />
          <div>
            <DatePicker
              size="sm"
              selected={endDate}
              style={{ maxWidth: '130px' }}
              onChange={handleEndDateChange}
              placeholderText="End Date"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default InventoryReport;
