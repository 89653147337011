import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from 'shards-react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import {
  FormGroup,
  StripePaymentInfo,
  AtomePaymentInfo,
} from '../../components/common';
import { PaymentTypes, PaymentStatus } from '../../constants';

const PaymentModal = ({
  onAddPayment,
  onUpdatePayment,
  editIndex,
  payments,
  open,
  onClose,
}) => {
  const { control, handleSubmit, errors } = useForm();
  const paymentOptions = Object.keys(PaymentTypes)
    .filter((key) => ![PaymentTypes.HOOLAH].includes(key)) // * Remove deprecated payment types
    .map((key) => {
      return { value: PaymentTypes[key], label: PaymentTypes[key] };
    });
  const statusOptions = Object.keys(PaymentStatus).map((key) => {
    return { value: PaymentStatus[key], label: PaymentStatus[key] };
  });

  const getDefaultValue = (name) => {
    if (editIndex < 0) return '';
    if (!payments[editIndex]) return '';
    return payments[editIndex][name] || '';
  };

  const renderReadonly = () => {
    if (editIndex < 0) return false;
    const payment = payments[editIndex];
    if (payment[payment.type]) return true;
    return false;
  };

  const renderPaymentInfo = (payment) => {
    switch (payment.type) {
      case PaymentTypes.ATOME:
        return <AtomePaymentInfo payment={payment} />;
      default:
        return <StripePaymentInfo payment={payment} />;
    }
  };

  const onSubmit = (data) => {
    if (!renderReadonly()) {
      data.amount = parseFloat(data.amount);
    }

    if (editIndex < 0) {
      onAddPayment({ uuid: uuidv4(), currency: 'SGD', ...data });
      return;
    }

    onUpdatePayment({ ...payments[editIndex], ...data }, editIndex);
  };

  return (
    <Modal centered open={open} toggle={onClose} size="lg">
      <ModalHeader>{editIndex > -1 ? 'Update' : 'Add'} Payment</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          {renderReadonly() && (
            <Row>
              <Col>
                {moment(payments[editIndex].paidOn).format('D MMM YYYY h:mm a')}
                <br />
                {payments[editIndex].currency}{' '}
                {payments[editIndex].amount.toFixed(2)}
                <br />
                {renderPaymentInfo(payments[editIndex])}
              </Col>
              <FormGroup
                col={{ lg: '4' }}
                type="select"
                name="status"
                options={statusOptions}
                label="Payment Status"
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
                defaultValue={getDefaultValue('status')}
              />
            </Row>
          )}
          {!renderReadonly() && (
            <Row>
              <FormGroup
                col={{ lg: '4' }}
                type="datepicker"
                name="paidOn"
                label="Payment Date"
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
                defaultValue={getDefaultValue('paidOn')}
              />

              <FormGroup
                col={{ lg: '4' }}
                type="select"
                name="type"
                options={paymentOptions}
                label="Payment Type"
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
                defaultValue={getDefaultValue('type')}
              />

              <FormGroup
                col={{ lg: '4' }}
                type="select"
                name="status"
                options={statusOptions}
                label="Payment Status"
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
                defaultValue={getDefaultValue('status')}
              />

              <FormGroup
                col={{ lg: '4' }}
                type="number"
                step={0.01}
                name="amount"
                label="Amount"
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
                defaultValue={getDefaultValue('amount')}
              />

              <FormGroup
                col={{ lg: '4' }}
                name="remarks"
                label="Remarks"
                control={control}
                defaultValue={getDefaultValue('remarks')}
              />
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button type="submit" theme="primary">
            Save
          </Button>
          <Button type="button" theme="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

PaymentModal.propTypes = {
  onAddPayment: PropTypes.func,
  onUpdatePayment: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  payments: PropTypes.arrayOf(PropTypes.object),
  editIndex: PropTypes.number,
};

PaymentModal.defaultProps = {
  onAddPayment: () => {},
  onUpdatePayment: () => {},
  onClose: () => {},
  payments: [],
  editIndex: -1,
};

export default PaymentModal;
