import moment from 'moment';
import _ from 'lodash';
import { ordersCollection } from '../firebase';

/**
 * Helps to access nested accessor values
 * @param {any} object
 * @param {string} accessor
 * @returns {any}
 */
export const objectAccessor = (object, accessor) => {
  const nested = accessor.split('.');
  let result = object;
  nested.forEach((acc) => {
    if (!(acc in result)) {
      result = '';
      return;
    }
    result = result[acc];
  });
  return result;
};

/**
 * Generate random password
 * @param {number} length
 */
export const passwordGenerator = (length) => {
  let passwd = '';
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (let i = 1; i < length; i += 1) {
    const c = Math.floor(Math.random() * chars.length + 1);
    passwd += chars.charAt(c);
  }

  return passwd;
};

/**
 * Create indexes to be used for searching
 * @param {Object} obj
 * @param {string[]} accessors
 * @param {number} threshold
 * @returns {string[]}
 */
export const createIndexes = (obj, accessors, threshold = 3) => {
  const indexes = new Set();

  accessors.forEach((accessor) => {
    let data = objectAccessor(obj, accessor);

    // convert number to string
    if (typeof data === 'number') data = data.toString();

    // only allow string search
    if (typeof data !== 'string') return;

    for (let i = 0; i < data.length; i++) {
      const contextData = data.substr(i);
      for (let j = threshold; j < contextData.length + 1; ++j) {
        indexes.add(contextData.substring(0, j).toLocaleLowerCase());
      }
    }
  });

  return Array.from(indexes);
};

/**
 * Convert object map to array, making the key as id
 * @param {Object} map
 */
export const mapToArray = (map) => {
  return Object.keys(map).map((key) => {
    return { id: key, ...map[key] };
  });
};

/**
 * Helps to access nested accessor values
 * @param {date} orderedOn
 * @param {number} orderCount
 */
export const generateOrderRef = async (orderedOn) => {
  const orderDateFrom = moment(orderedOn).startOf('day').toDate();
  const orderDateTo = moment(orderedOn).endOf('day').toDate();
  let orderCount = await (
    await ordersCollection
      .where('orderedOn', '>=', orderDateFrom)
      .where('orderedOn', '<=', orderDateTo)
      .get()
  ).size;

  orderCount += 1;
  if (orderCount < 10)
    return `WS${moment(orderedOn).format('YYMMDD')}-000${orderCount}`;
  if (orderCount < 100)
    return `WS${moment(orderedOn).format('YYMMDD')}-00${orderCount}`;
  if (orderCount < 1000)
    return `WS${moment(orderedOn).format('YYMMDD')}-0${orderCount}`;
  return `WS${moment(orderedOn).format('YYMMDD')}-${orderCount}`;
};

/**
 * @typedef Paging
 * @property {string} orderBy
 * @property {string} order
 * @property {number} page
 * @property {number} size
 * @property {string} filter
 *
 * @typedef StoreState
 * @property {Array<object[]>} items
 * @property {Paging} paging
 *
 * @typedef DispatchObject
 * @property {Array<object[]>} items
 * @property {Paging} paging
 * @property {firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>.docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>} lastSnapshot
 *
 * Helps to perform some common operations needed do Firebase pagination and returning a dispatch object for redux
 * @param {Paging} pagingOpts - paging options
 * @param {StoreState} state - state object in redux
 * @param {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>} collection - firebase firestore collection
 * @returns {DispatchObject}
 */
export const getFirebasePagingQuery = async (
  { orderBy, order, page, size, filter },
  state,
  collection
) => {
  orderBy = orderBy || state.paging.orderBy;
  order = order || state.paging.order;
  page = page !== null ? page : state.paging.page;
  size = size || state.paging.size;
  filter = (filter === undefined ? state.paging.filter : filter).toLowerCase();

  // if nothing has changed, exit
  if (
    state.items.length - 1 >= page &&
    state.paging.page === page &&
    state.paging.order === order &&
    state.paging.orderBy === orderBy &&
    state.paging.size === size &&
    state.paging.filter === filter
  ) {
    return null;
  }

  let items = [...state.items];
  let { lastSnapshot } = state;
  const paging = { page, order, orderBy, size, filter };

  // if the page already has users data just update the paging
  if (state.items.length - 1 >= page && state.paging.page !== page) {
    return {
      items,
      paging,
      lastSnapshot,
    };
  }

  let query = collection.orderBy(orderBy, order);

  if (filter) {
    query = query.where('indexes', 'array-contains-any', [filter]);
  }

  if (state.paging.page !== page && state.items.length - 1 < page) {
    query = query.startAfter(lastSnapshot);
  } else {
    items = [];
    paging.page = 0;
  }

  const documentSnapshots = await query.limit(size).get();
  const results = [];

  documentSnapshots.forEach((doc) => {
    results.push({ id: doc.id, ...doc.data() });
  });

  lastSnapshot = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  if (results.length === 0) return null;

  items.push(results);

  return {
    items,
    paging,
    lastSnapshot,
  };
};

/**
 * Convert text into a friendly slug
 * @param {string} text
 */
export const slugify = (text) => {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
};

/**
 * Convert text into a friendly slug
 * @param {momentDate} date
 */
export const convertToDate = (date) => {
  const dateTime = moment(date);

  const dateValue = moment({
    year: dateTime.year(),
    month: dateTime.month(),
    day: dateTime.date(),
  }).toDate();

  return dateValue;
};

/**
 * Helps to check whether 2 objects are equal
 * @param {object} a
 * @param {object} b
 * @param {string[]} props
 * @return {boolean}
 */
export const isObjectEqual = (a, b, props) => {
  if (!a || !b) return false;
  const aData = _.pick(a, props);
  const bData = _.pick(b, props);
  const aKeys = Object.keys(aData);
  const bKeys = Object.keys(bData);
  if (aKeys.length !== bKeys.length) return false;
  return aKeys.reduce((equal, key) => {
    if (!equal) return false;
    return aData[key] === bData[key];
  }, true);
};

/**
 * Helps to check whether 2 arrays are equal
 * @param {[]} a
 * @param {[]} b
 * @param {string[]} props
 * @return {boolean}
 */
export const isArrayObjectEqual = (a, b, props) => {
  if (!a || !b) return false;
  const results = a.reduce((acc, aItem, i) => {
    acc.push(isObjectEqual(aItem, b[i], props));
    return acc;
  }, []);
  return results.every((r) => r);
};

/**
 * Backward tax calculation from total amount
 * @param {number} totalAmount
 * @param {{
 *  rate: number
 * }} option
 * @returns {{ amount: number, rate: number }}
 */
export const calculateTax = (totalAmount, option = {}) => {
  const rate = option?.rate || parseFloat(process.env.REACT_APP_GST_SG_RATE);
  const amount = totalAmount - totalAmount / (1 + rate);
  return { amount, rate };
};
