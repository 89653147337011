import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import routes from './routes';
import { isAuth, hasAccess } from './utils/auth';
import { Theme } from './utils/theme';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/shards-dashboards.1.1.0.min.css';
import 'react-tabs/style/react-tabs.css';

const App = ({ store }) => {
  return (
    <>
      <Theme />
      <Provider store={store}>
        <HashRouter basename={process.env.REACT_APP_BASENAME || ''}>
          <Switch>
            {routes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => {
                    if (route.auth && !isAuth(true)) {
                      return <Redirect to="/logout" />;
                    }

                    if (
                      route.module &&
                      !hasAccess(route.module, 'read', true)
                    ) {
                      return <Redirect to="/dashboard" />;
                    }

                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  }}
                />
              );
            })}
          </Switch>
        </HashRouter>
      </Provider>
    </>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
