import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DatePicker,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'shards-react';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { warning } from 'react-toastify-redux';
import { getLast6MonthRevenue } from '../../store/actions/reports';
import MaterialIcon from '../../components/common/MaterialIcon';

const RevenueOverview = () => {
  const dispatch = useDispatch();
  const last6MonthsRevenueData = useSelector(
    ({ reports: { last6MonthsRevenue } }) => last6MonthsRevenue
  );
  const [chartData, setChartData] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().subtract(6, 'months').startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState(moment().startOf('month').toDate());

  const chartOptions = {
    tooltips: {
      callbacks: {
        label(t, d) {
          const xLabel = d.datasets[t.datasetIndex].label;
          const yLabel = `$${t.yLabel
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          return `${xLabel}: ${yLabel}`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback(value) {
              return `$${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    if (last6MonthsRevenueData) {
      const data = {
        labels: last6MonthsRevenueData.labels,
        datasets: [
          {
            label: 'Revenue',
            data: last6MonthsRevenueData.revenues,
            backgroundColor: last6MonthsRevenueData.backgroundColors,
            borderColor: last6MonthsRevenueData.borderColors,
            barPercentage: 0.7,
            borderWidth: 1,
          },
        ],
      };
      setChartData(data);
      setStartDate(last6MonthsRevenueData.dateFrom);
      setEndDate(last6MonthsRevenueData.dateTo);
    }
  }, [last6MonthsRevenueData]);

  useEffect(() => {
    if (!last6MonthsRevenueData)
      dispatch(getLast6MonthRevenue(startDate, endDate));
  }, []);

  const handleGetLast6MonthRevenue = (from, to) => {
    const fromMoment = moment(from);
    const toMoment = moment(to);
    if (toMoment.diff(fromMoment, 'month') > 11)
      dispatch(warning('Date range must within 12 months'));
    else if (fromMoment.diff(toMoment) > 0)
      dispatch(warning('Invalid date range'));
    else dispatch(getLast6MonthRevenue(from, to));
  };

  const handleStartDateChange = (value) => {
    setStartDate(new Date(value));
    handleGetLast6MonthRevenue(value, endDate);
  };

  const handleEndDateChange = (value) => {
    setEndDate(new Date(value));
    handleGetLast6MonthRevenue(startDate, value);
  };

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Revenue Overview</h6>
      </CardHeader>
      <CardBody className="d-flex py-0">
        {chartData && <Bar data={chartData} options={chartOptions} />}
      </CardBody>
      <CardFooter className="border-top">
        <div className="d-flex align-items-center">
          <div>
            <DatePicker
              size="sm"
              selected={startDate}
              style={{ maxWidth: '130px' }}
              onChange={handleStartDateChange}
              startDate={startDate}
              endDate={endDate}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </div>
          <MaterialIcon className="mx-2" icon="sync_alt" />
          <div>
            <DatePicker
              size="sm"
              selected={endDate}
              style={{ maxWidth: '130px' }}
              onChange={handleEndDateChange}
              startDate={startDate}
              endDate={endDate}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default RevenueOverview;
