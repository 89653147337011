import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardImg, CardBody, Button } from 'shards-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';

import {
  DialogButton,
  TooltipCard,
  MaterialIcon,
} from '../../components/common';
import { ProductAssetKey } from '../../constants';

const TabWrap = styled.div`
  max-width: 100%;
  overflow-x: auto;
  display: flex;
`;
const TabContent = styled.div`
  min-width: fit-content;
  margin-right: 20px;
  margin-bottom: 10px;
  &:last-child {
    margin-right: 0;
  }
`;

const TabCardImg = styled(CardImg)`
  height: 120px;
`;

const ProductAssetTab = ({
  subVariantKeys,
  productAssets,
  onRemove,
  onEdit,
  handleAddProductAssetModal,
}) => {
  const renderTabContent = (assets) => {
    return (
      <div>
        <div className="text-right mb-3">
          <Button
            size="sm"
            className="ml-3"
            theme="success"
            type="button"
            onClick={() => handleAddProductAssetModal()}
          >
            <MaterialIcon icon="add" className="mr-2" />
            Asset
          </Button>
        </div>
        <TabWrap>
          {assets.map((a, index) => {
            return (
              <TabContent key={`${a.assetId}_${index}`}>
                <Card>
                  <TooltipCard id={`asset_${index}`} message={a.value}>
                    <TabCardImg top src={a.image} onClick={() => onEdit(a)} />
                  </TooltipCard>
                  <CardBody className="p-2">
                    <DialogButton
                      className="p-1"
                      theme="danger"
                      icon="delete_outline"
                      message=""
                      onConfirm={() => onRemove(a.assetId)}
                    />
                  </CardBody>
                </Card>
              </TabContent>
            );
          })}
        </TabWrap>
      </div>
    );
  };

  const getTabs = () => {
    const tabs = [];
    if (subVariantKeys.includes(ProductAssetKey.DESIGN)) {
      const designAssets = productAssets.filter(
        (a) => a.key === ProductAssetKey.DESIGN
      );
      tabs.push({
        title: 'Design',
        assets: designAssets,
        key: 0,
      });
    }
    if (subVariantKeys.includes(ProductAssetKey.MATERIAL)) {
      const materialAssets = productAssets.filter(
        (a) => a.key === ProductAssetKey.MATERIAL
      );
      tabs.push({
        title: 'Material',
        assets: materialAssets,
        key: 1,
      });
    }

    if (subVariantKeys.includes(ProductAssetKey.COLOUR)) {
      const colourAssets = productAssets.filter(
        (a) => a.key === ProductAssetKey.COLOUR
      );
      tabs.push({
        title: 'Colour',
        assets: colourAssets,
        key: 2,
      });
    }

    return tabs;
  };

  return (
    <div className="mb-3">
      <Tabs>
        <TabList>
          {getTabs().map((t) => (
            <Tab>
              {t.title}
            </Tab>
          ))}
        </TabList>
        {getTabs().map((t) => (
          <TabPanel>
            {renderTabContent(t.assets)}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

ProductAssetTab.propTypes = {
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  handleAddProductAssetModal: PropTypes.func,
  productAssets: PropTypes.array,
  subVariantKeys: PropTypes.array,
};

ProductAssetTab.defaultProps = {
  onEdit: () => {},
  onRemove: () => {},
  handleAddProductAssetModal: () => {},
  productAssets: [],
  subVariantKeys: [],
};

export default ProductAssetTab;
