import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { authLogOut } from '../../store/actions/auth';

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logoutAndRedirect = useCallback(() => {
    dispatch(authLogOut());
    history.push('/');
  }, [dispatch, history]);

  useEffect(() => {
    logoutAndRedirect();
  }, [logoutAndRedirect]);

  return <div>Logging out...</div>;
};

export default Logout;
