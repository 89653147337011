import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DatePicker,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'shards-react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { getMonthlyRevenue } from '../../store/actions/reports';

const MonthlyOverview = () => {
  const dispatch = useDispatch();
  const monthlyRevenueData = useSelector(
    ({ reports: { monthlyRevenue } }) => monthlyRevenue
  );
  const [chartData, setChartData] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate()
  );

  const chartOptions = {
    tooltips: {
      callbacks: {
        label(t, d) {
          const xLabel = d.datasets[t.datasetIndex].label;
          const yLabel = `$${t.yLabel
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          return `${xLabel}: ${yLabel}`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback(value) {
              return `$${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    if (monthlyRevenueData) {
      const data = {
        labels: monthlyRevenueData.labels,
        datasets: [
          {
            label: 'Revenue',
            data: monthlyRevenueData.revenues,
            backgroundColor: 'rgba(255, 99, 132, 0.4)',
            borderColor: 'rgba(255, 99, 132)',
            tension: 0.1,
          },
        ],
      };
      setChartData(data);
      setStartDate(monthlyRevenueData.date);
    }
  }, [monthlyRevenueData]);

  useEffect(() => {
    if (!monthlyRevenueData) dispatch(getMonthlyRevenue(startDate));
  }, []);

  const handleDateChange = (value) => {
    setStartDate(new Date(value));
    dispatch(getMonthlyRevenue(value));
  };

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Monthly Overview</h6>
      </CardHeader>
      <CardBody className="d-flex py-0">
        {chartData && (
          <Line data={chartData} height={100} options={chartOptions} />
        )}
      </CardBody>
      <CardFooter className="border-top">
        <div className="d-flex">
          <div>
            <DatePicker
              size="sm"
              selected={startDate}
              style={{ maxWidth: '130px' }}
              onChange={handleDateChange}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default MonthlyOverview;
