import React, { useEffect } from 'react';
import { Row, Col } from 'shards-react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { SmartTable } from '../../components/common';
import { getProducts } from '../../store/actions/products';
import { MainStage } from '../../components/layout';

const ProductListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const productsData = useSelector(({ products: { products } }) => products);

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
      cell: (row, data) => <Link to={`/products/${data.id}`}>{row.value}</Link>,
    },
    {
      header: 'Collection',
      accessor: 'collection',
    },
    {
      header: 'Created By',
      accessor: 'audit.createdBy',
    },
    {
      header: 'Created On',
      accessor: 'audit.createdOn',
      cell: ({ value }) => moment.unix(value.seconds).format('lll'),
    },
  ];

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <MainStage title="Overview" subtitle="Products">
      <Row>
        <Col>
          <SmartTable
            title="Active Products"
            data={productsData.items}
            columns={columns}
            onCreate={() => history.push('/products/new')}
          />
        </Col>
      </Row>
    </MainStage>
  );
};

export default ProductListing;
