import React from 'react';
import { Alert, Row } from 'shards-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import CustomerReferralProfile from './CustomerReferralProfile';
import CustomerRefereeDataBlock from './CustomerRefereeDataBlock';
import CustomerReferralStatusBadge from './CustomerReferralStatusBadge';
import { ReferralEarnStatus } from '../../constants';
import { mapToArray } from '../../utils';
import { getReferralRebateStatus } from '../../utils/rebate';

const CustomerReferees = ({ referees }) => {
  const refereesArr = mapToArray(referees || {});

  if (refereesArr.length > 0) {
    const filteredRefs = (status, currency = 'SGD', getSum = true) => {
      let query = refereesArr;

      if (status) {
        query = query.filter(
          ({ earned }) =>
            earned &&
            getReferralRebateStatus({ earned }) === status &&
            earned.currency === currency
        );
      }

      if (getSum) {
        return query
          .reduce((acc, ref) => {
            if (ref.earned) {
              return acc + (ref.earned.amount || 0);
            }

            return acc;
          }, 0)
          .toFixed(2);
      }

      return query.length;
    };

    return (
      <>
        <Row>
          <CustomerRefereeDataBlock title="Referred">
            <h3>{refereesArr.length}</h3>
          </CustomerRefereeDataBlock>
          <CustomerRefereeDataBlock title="Earned">
            <h4>{`$${filteredRefs()}`}</h4>
          </CustomerRefereeDataBlock>
          <CustomerRefereeDataBlock title="Withdrawn">
            <h4>{`$${filteredRefs(ReferralEarnStatus.PAID)}`}</h4>
          </CustomerRefereeDataBlock>
          <CustomerRefereeDataBlock title="Confirmed">
            <h4>{`$${filteredRefs(ReferralEarnStatus.CONFIRMED)}`}</h4>
          </CustomerRefereeDataBlock>
          <CustomerRefereeDataBlock title="Pending">
            <h4>{filteredRefs(ReferralEarnStatus.PENDING)}</h4>
          </CustomerRefereeDataBlock>
          <CustomerRefereeDataBlock className="" title="Processing">
            <h4>{`$${filteredRefs(ReferralEarnStatus.PROCESSING)}`}</h4>
          </CustomerRefereeDataBlock>
        </Row>
        <div className="d-flex border-top pt-4">
          {refereesArr.map((ref) => (
            <div className="text-center px-3" key={ref.id}>
              <CustomerReferralProfile profile={ref} />
              {ref.order && ref.earned && (
                <>
                  <span className="d-block mb-2">
                    Earned{' '}
                    <Link to={`/orders/view/${ref.order.id}`}>
                      {`${ref.earned.currency} ${ref.earned.amount.toFixed(2)}`}
                    </Link>
                  </span>
                  <CustomerReferralStatusBadge referee={ref} />
                </>
              )}
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <Alert className="mt-2" theme="info">
      Hasn&#39;t referred anyone yet.
    </Alert>
  );
};

CustomerReferees.propTypes = {
  referees: PropTypes.object.isRequired,
};

export default CustomerReferees;
