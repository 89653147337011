import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouteNavLink } from 'react-router-dom';
import { NavItem, Badge } from 'shards-react';
import { useDispatch } from 'react-redux';
import { toggleMenu } from '../../../store/actions/ui';
import { MaterialIcon } from '../../common';

const SidebarNavItem = ({ item }) => {
  const dispatch = useDispatch();

  return (
    <NavItem>
      <RouteNavLink
        className="nav-link"
        to={item.to}
        onClick={() => dispatch(toggleMenu())}
      >
        {item.icon && (
          <div className="d-inline-block item-icon-wrapper">
            <MaterialIcon icon={item.icon} />
          </div>
        )}
        {item.title && <span>{item.title}</span>}
        {item.badge && (
          <div className="d-inline-block item-icon-wrapper">
            <Badge theme={item.badge.theme}>{item.badge.text}</Badge>
          </div>
        )}
      </RouteNavLink>
    </NavItem>
  );
};

SidebarNavItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    to: PropTypes.string,
    icon: PropTypes.string,
    badge: PropTypes.shape({
      theme: PropTypes.string,
      text: PropTypes.string,
    }),
  }).isRequired,
};

export default SidebarNavItem;
