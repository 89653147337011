import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormRadio,
  Badge,
} from 'shards-react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { warning } from 'react-toastify-redux';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { FormGroup } from '../../components/common';
import {
  ProductStatusColor,
  ProductAssetKey,
} from '../../constants';
import { isArrayObjectEqual } from '../../utils/index';
import { getVariantImage } from '../../utils/variant';

const ProductModal = ({ onAddProduct, open, onClose, products }) => {
  const { control, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();
  const [productOptions, setProductOptions] = useState([]);
  const [variantOptions, setVariantOptions] = useState([]);
  const [statusTypeOptions, setStatusTypeOptions] = useState([]);
  const [headboardOptions, setHeadboardOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [colourOptions, setColourOptions] = useState([]);
  const [variantGroups, setVariantGroups] = useState([]);
  const [selectedStatusType, setSelectedStatusType] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedVariantType, setSelectedVariantType] = useState(null);
  const [selectedHeadboard, setSelectedHeadboard] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedVariantGroups, setSelectedVariantGroups] = useState([]);

  useEffect(() => {
    resetOptions();
  }, [open]);

  useEffect(() => {
    if (products.length) {
      console.log('products', products);
      const options = [];
      _.orderBy(products, 'collection', 'desc').forEach((item) => {
        options.push({
          value: `${item.id}`,
          label: (
            <div className="d-flex align-items-center">
              <div
                style={{
                  height: '35px',
                  width: '35px',
                  lineHeight: '35px',
                  marginRight: '10px',
                }}
              >
                <img
                  src={item.media.length > 0 ? item.media[0].path : ''}
                  className="mw-100 mh-100"
                />
              </div>
              <div>{item.name}</div>
            </div>
          ),
        });
      });
      setProductOptions(options);
    }
  }, [products]);

  useEffect(() => {
    setValue('headboard', '');
    setValue('material', '');
    setValue('colour', '');
    setHeadboardOptions([]);
    setMaterialOptions([]);
    setColourOptions([]);

    const options = [];
    selectedVariantGroups.map((v) => {
      const headboard = v.subVariants.find(
        (s) => s.key === ProductAssetKey.DESIGN && s.assetId
      );
      if (headboard && !options.find((o) => o.value === headboard.assetId)) {
        options.push({
          value: headboard.assetId,
          label: (
            <div className="d-flex align-items-center">
              <div
                style={{
                  height: '35px',
                  width: '35px',
                  lineHeight: '35px',
                  marginRight: '10px',
                }}
              >
                <img src={getVariantImage(selectedProduct.assets, headboard.assetId)} className="mw-100 mh-100" />
              </div>
              <div>{headboard.value || '--'}</div>
            </div>
          ),
        });
      }
    });
    setHeadboardOptions(options);
  }, [selectedStatusType]);

  const onSubmit = (data) => {
    console.log('data', data);
    data.qty = parseFloat(data.qty);
    const selectedProduct = products.find((p) => p.id === data.productId.value);
    const selectedMedia = selectedProduct.media.find((m) => m.default);
    let selectedVariant = null;
    if (data.headboard && data.material && data.colour) {
      const selectedSubVairants = [
        {
          key: ProductAssetKey.DESIGN,
          assetId: data.headboard.value,
        },
        {
          key: ProductAssetKey.MATERIAL,
          assetId: data.material.value,
        },
        {
          key: ProductAssetKey.COLOUR,
          assetId: data.colour.value,
        },
      ];
      console.log('selectedSubVairants', selectedSubVairants);
      selectedProduct.variants.map((v) => {
        if (
          v.type === data.variant.value &&
          isArrayObjectEqual(v.subVariants, selectedSubVairants, [
            'key',
            'assetId',
          ])
        ) {
          selectedVariant = v;
          return;
        }
      });
      
      // double check selected variant
      if (!selectedVariant) {
        selectedProduct.variants.map((v) => {
          if (v.type === selectedVariantType && 
            v.subVariants.find(s => s.assetId === data.headboard.value && s.key === 'design') &&
            v.subVariants.find(s => s.assetId === data.material.value && s.key === 'material') &&
            v.subVariants.find(s => s.assetId === data.colour.value && s.key === 'colour')
          ) {
            selectedVariant = v;
            return;
          }
          return;
        });
      }
    } else
      selectedVariant = selectedProduct.variants.find(
        (v) => v.type === data.variant.value
      );

    if (!selectedVariant) {
      dispatch(warning('Variant not found'));
      return;
    }

    const unitPrice = parseFloat(selectedVariant.prices[0].amount);
    const product = {
      isNew: true,
      uuid: uuidv4(),
      id: selectedProduct.id,
      name: selectedProduct.name,
      shippable: selectedProduct.shippable,
      collection: selectedProduct.collection,
      variant: selectedVariant.type,
      currency: selectedVariant.prices[0].currency,
      status: selectedVariant.status,
      statusType: selectedVariant.statusType,
      sku: selectedVariant.sku,
      unitPrice,
      comparePrice: parseFloat(selectedVariant.prices[0].compareAmount) || 0,
      image: selectedMedia ? selectedMedia.path : '',
      qty: data.qty,
      subtotal: data.qty * unitPrice,
      subVariants: selectedVariant.subVariants || [],
    };
    if (selectedVariant.statusDate)
      product.statusDate = moment
        .unix(selectedVariant.statusDate.seconds)
        .toDate();
    if (selectedVariant.statusNote)
      product.statusNote = selectedVariant.statusNote;

    console.log('onAddProduct', product);
    onAddProduct(product);
    setSelectedProduct(null);
  };

  const handleChangeProduct = (e) => {
    resetOptions();
    const { value } = e;
    const product = products.find((p) => p.id === value);
    if (!product) return;

    setSelectedProduct(product);
    const options = [];
    product.variants.forEach((variant) => {
      if (!options.find((o) => o.value === variant.type)) {
        options.push({
          value: `${variant.type}`,
          label: `${variant.type}`,
        });
      }
    });
    setVariantOptions(options);
    setValue('variant', '');
  };

  const handleChangeVariant = (e) => {
    const { value } = e;
    setSelectedVariantType(value);
    const variants = selectedProduct.variants.filter((v) => v.type === value);
    if (variants.length === 1) {
      setSelectedVariant(variants[0]);
      return;
    }

    const variantGroups = _.groupBy(variants, 'statusType');
    const statusTypes = Object.keys(variantGroups).map((key) => key);
    setVariantGroups(variantGroups);
    setStatusTypeOptions(statusTypes);

    setSelectedStatusType(statusTypes[0]);
    setSelectedVariantGroups(variantGroups[statusTypes[0]]);
    setValue('material', '');
  };

  const handleChangeStatusType = (value) => {
    setSelectedStatusType(value);
    setSelectedVariantGroups(variantGroups[value]);
  };

  const handleChangeHeadboard = (e) => {
    const { value } = e;
    setSelectedHeadboard(value);
    const options = populateMaterialOptions(value);
    setMaterialOptions([...options]);
    setValue('material', '');
    setValue('colour', '');
  };

  const handleChangeMaterial = (e) => {
    const { value } = e;
    setSelectedMaterial(value);
    const options = populateColourOptions(selectedHeadboard, value);
    setColourOptions([...options]);
    setValue('colour', '');
  };

  const handleChangeColour = (e) => {
    const { value } = e;
    let selectedVariant = null;
    const selectedSubVairants = [
      {
        key: ProductAssetKey.DESIGN,
        assetId: selectedHeadboard,
      },
      {
        key: ProductAssetKey.MATERIAL,
        assetId: selectedMaterial,
      },
      {
        key: ProductAssetKey.COLOUR,
        assetId: value,
      },
    ];
    selectedProduct.variants.map((v) => {
      if (
        v.type === selectedVariantType &&
        isArrayObjectEqual(v.subVariants, selectedSubVairants, [
          'key',
          'assetId',
        ])
      ) {
        selectedVariant = v;
        return;
      }
    });

    // double check selected variant
    if (!selectedVariant) {
      selectedProduct.variants.map((v) => {
        if (v.type === selectedVariantType && 
          v.subVariants.find(s => s.assetId === selectedHeadboard && s.key === 'design') &&
          v.subVariants.find(s => s.assetId === selectedMaterial && s.key === 'material') &&
          v.subVariants.find(s => s.assetId === value && s.key === 'colour')
        ) {
          selectedVariant = v;
          return;
        }
        return;
      });
    }

    if (!selectedVariant) {
      dispatch(warning('Variant not found'));
      return;
    }
    setSelectedVariant(selectedVariant);
  };

  const populateMaterialOptions = (assetId) => {
    const options = [];
    selectedVariantGroups.map((v) => {
      const design = v.subVariants.find(
        (s) => s.key === ProductAssetKey.DESIGN && s.assetId === assetId
      );
      if (design) {
        const material = v.subVariants.find(
          (s) => s.key === ProductAssetKey.MATERIAL
        );
        if (!options.find((o) => o.value === material.assetId))
          options.push({
            value: material.assetId,
            label: (
              <div className="d-flex align-items-center">
                <div
                  style={{
                    height: '35px',
                    width: '35px',
                    lineHeight: '35px',
                    marginRight: '10px',
                  }}
                >
                  <img src={getVariantImage(selectedProduct.assets, material.assetId)} className="mw-100 mh-100" />
                </div>
                <div>{material.value || '--'}</div>
              </div>
            ),
          });
      }
    });
    return options;
  };

  const populateColourOptions = (designAssetId, materialAssetId) => {
    const options = [];
    console.log(designAssetId, materialAssetId);
    selectedVariantGroups.map((v) => {
      const design = v.subVariants.find(
        (s) => s.key === ProductAssetKey.DESIGN && s.assetId === designAssetId
      );
      const material = v.subVariants.find(
        (s) =>
          s.key === ProductAssetKey.MATERIAL && s.assetId === materialAssetId
      );
      if (design && material) {
        const colour = v.subVariants.find(
          (s) => s.key === ProductAssetKey.COLOUR
        );
        if (!options.find((o) => o.value === colour.assetId))
          options.push({
            value: colour.assetId,
            label: (
              <div className="d-flex align-items-center">
                <div
                  style={{
                    height: '35px',
                    width: '35px',
                    lineHeight: '35px',
                    marginRight: '10px',
                  }}
                >
                  <img src={getVariantImage(selectedProduct.assets, colour.assetId)} className="mw-100 mh-100" />
                </div>
                <div>{colour.value || '--'}</div>
              </div>
            ),
          });
      }
    });
    return options;
  };

  const resetOptions = () => {
    setVariantOptions([]);
    setStatusTypeOptions([]);
    setHeadboardOptions([]);
    setMaterialOptions([]);
    setVariantGroups([]);
    setSelectedStatusType('');
    setSelectedVariantGroups([]);
    setSelectedProduct(null);
    setSelectedVariant(null);
  };

  return (
    <Modal open={open} toggle={() => {}} form size="lg">
      <ModalHeader>
        Add Product -{' '}
        {selectedProduct && (
          <>
            {selectedProduct.name}
            {selectedVariant && (
              <small>
                <Badge
                  className="ml-3"
                  theme={
                    ProductStatusColor[
                      selectedVariant.status
                        .replaceAll(' ', '_')
                        .replaceAll('-', '_')
                        .toUpperCase()
                    ]
                  }
                >
                  {selectedVariant.status}
                </Badge>
                <Badge className="ml-3" theme="secondary">
                  Stock: {selectedVariant.availableQty}
                </Badge>
              </small>
            )}
          </>
        )}
      </ModalHeader>
      <ModalBody>
        <Row>
          {productOptions.length > 0 && (
            <>
              <FormGroup
                col={{ lg: '12' }}
                type="searchable-select"
                name="productId"
                label="Product"
                onChange={(e) => handleChangeProduct(e)}
                options={productOptions}
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
              />
            </>
          )}

          {variantOptions.length > 0 && (
            <>
              <FormGroup
                col={{ lg: '4' }}
                type="searchable-select"
                name="variant"
                label="Variant"
                onChange={(e) => handleChangeVariant(e)}
                options={variantOptions}
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
              />

              <FormGroup
                type="number"
                name="qty"
                col={{ lg: '4' }}
                label="Qty"
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
              />
            </>
          )}

          {statusTypeOptions.length > 0 && (
            <Col lg="4">
              <div className="mb-3">
                <label>Type</label>
                <div className="d-flex">
                  {statusTypeOptions.map((statusTypeOption, i) => {
                    return (
                      <div
                        key={`statustype_${i}`}
                        className={i === 0 ? 'mr-4' : ''}
                      >
                        <FormRadio
                          name="statusType"
                          onChange={() =>
                            handleChangeStatusType(statusTypeOption)
                          }
                          checked={selectedStatusType === statusTypeOption}
                        >
                          {statusTypeOption}
                        </FormRadio>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
          )}

          {headboardOptions.length > 0 && (
            <>
              <FormGroup
                col={{ lg: '4' }}
                type="searchable-select"
                name="headboard"
                label="Headboard Design"
                onChange={(e) => handleChangeHeadboard(e)}
                options={headboardOptions}
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
              />
            </>
          )}

          {materialOptions.length > 0 && (
            <>
              <FormGroup
                col={{ lg: '4' }}
                type="searchable-select"
                name="material"
                onChange={(e) => handleChangeMaterial(e)}
                label="Material"
                options={materialOptions}
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
              />
            </>
          )}

          {colourOptions.length > 0 && (
            <>
              <FormGroup
                col={{ lg: '4' }}
                type="searchable-select"
                name="colour"
                onChange={(e) => handleChangeColour(e)}
                label="Colour"
                options={colourOptions}
                control={control}
                rules={{ required: 'Required field' }}
                errors={errors}
              />
            </>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" theme="primary" onClick={handleSubmit(onSubmit)}>
          Add
        </Button>
        <Button type="button" theme="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ProductModal.propTypes = {
  onAddProduct: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  products: PropTypes.array,
};

ProductModal.defaultProps = {
  products: [],
  onAddProduct: () => {},
  onClose: () => {},
};

export default ProductModal;
