import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem, Alert, Button } from 'shards-react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

import { OrderDetailCard } from '../../components/common';

const StyledListGroupItem = styled(ListGroupItem)`
  .card {
    box-shadow: none;
  }
`;

const CustomerOrders = ({ orders, isLoading }) => {
  const history = useHistory();
  const { id } = useParams();

  if (orders.length > 0) {
    return (
      <>
        <ListGroup>
          {orders.map((order) => (
            <StyledListGroupItem key={order.id} className="border-0 p-0">
              <OrderDetailCard order={order} />
            </StyledListGroupItem>
          ))}
        </ListGroup>
        <Button
          className="mt-3"
          block
          onClick={() => history.push(`/orders/${id}`)}
        >
          View more
        </Button>
      </>
    );
  }

  if (!isLoading) {
    return <Alert theme="info">No orders yet</Alert>;
  }

  return null;
};

CustomerOrders.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CustomerOrders;
