import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { warning } from 'react-toastify-redux';
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from 'shards-react';
import { useForm } from 'react-hook-form';
import { IconButton, FileInput } from '../../components/common';
import {
  readFileInBase64,
  getFileType,
  isFileSizeValid,
} from '../../utils/media';
import { ProductImage } from '../../constants';

const VariantThumbnailModal = ({ variant, onAddThumbnail, open, onClose }) => {
  const dispatch = useDispatch();
  const [resetUpload, setResetUpload] = useState(false);
  const [attachedFile, setAttachedFile] = useState(null);
  const { handleSubmit } = useForm();

  useEffect(() => {
    if (variant.thumbnail)
      setAttachedFile({
        file: null,
        path: variant.thumbnail,
      });
    else setAttachedFile(null);
  }, [open]);

  const handleSelectFile = (event) => {
    if (!event.target.files.length) return;
    const file = event.target.files[0];
    if (getFileType(file.name) !== 'image') {
      dispatch(warning('Invalid file type'));
      return;
    }
    // if (!isFileSizeValid(file.size, ProductImage.MAX_SIZE)) {
    //   dispatch(
    //     warning(
    //       `Image size has been exceeded the limit of ${ProductImage.MAX_SIZE}KB`
    //     )
    //   );
    //   return;
    // }
    readFileInBase64(file).then((res) => {
      const newAttachedFile = {
        file,
        path: res.path,
      };
      setAttachedFile(newAttachedFile);
    });
  };

  const handleRemoveUpload = () => {
    setAttachedFile(null);
    setResetUpload(true);
  };

  const handleClose = () => {
    setAttachedFile(null);
    setResetUpload(true);
    onClose();
  };

  const onSubmit = () => {
    const variantThumbnail = {
      gIndex: variant.gIndex,
      path: attachedFile ? attachedFile.path : '',
      file: attachedFile ? attachedFile.file : null,
    };
    onAddThumbnail(variantThumbnail);
  };

  return (
    <Modal centered open={open} toggle={handleClose}>
      <ModalHeader>Variant thumbnail</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="6">
            <label>
              Upload Image <small>1024px x 683px</small>
            </label>
            {!attachedFile && (
              <FileInput
                className="mb-3"
                reset={resetUpload}
                onSelectFile={(event) => handleSelectFile(event)}
              />
            )}
            {attachedFile && (
              <Card className="product-card">
                <CardImg top src={attachedFile.path} />
                <CardBody className="p-2">
                  <div>
                    <IconButton
                      theme="danger"
                      icon="delete_outline"
                      onClick={() => handleRemoveUpload()}
                    />
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" theme="primary" onClick={handleSubmit(onSubmit)}>
          Update
        </Button>
        <Button type="button" theme="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VariantThumbnailModal.propTypes = {
  variant: PropTypes.object,
  onAddThumbnail: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

VariantThumbnailModal.defaultProps = {
  variant: {},
  onAddThumbnail: () => {},
  onClose: () => {},
};

export default VariantThumbnailModal;
