import { error } from 'react-toastify-redux';
import moment from 'moment';
import { parse } from 'json2csv';
import { deliverySlotsCollection } from '../../firebase';
import { startLoading, stopLoading } from './ui';

export const DeliveryOrdersActionTypes = {
  GET_DELIVERY_ORDERS: 'GET_DELIVERY_ORDERS',
};

/**
 * Retrieve multiple delivery orders from firebase
 */
export const getDeliveryOrder = ({ params }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const {
        deliveryOrders: { deliveryOrders },
      } = getState();

      let { dateFrom, dateTo, productId, variant } = params;
      let query = deliverySlotsCollection;
      if (dateFrom && dateTo) {
        dateFrom = moment(dateFrom).startOf('day').toDate();
        dateTo = moment(dateTo).endOf('day').toDate();
        query = query.where('date', '>=', dateFrom).where('date', '<=', dateTo);
      }

      const documentSnapshots = await query.get();
      const items = [];

      documentSnapshots.docs.map(async (doc) => {
        const data = doc.data();
        data.slots.map((s) => {
          if (s.orders && s.orders.length) {
            s.orders.map((o) => {
              if (productId && !o.products.find((p) => p.id === productId))
                return;
              if (variant && !o.products.find((p) => p.variant === variant))
                return;
              items.push({
                deliveryDate: data.date,
                deliveryTime: s.time,
                ...o,
              });
            });
          }
        });
      });

      const { size } = deliveryOrders.paging;
      const totalPages = parseInt(items.length / size) + 1;
      deliveryOrders.paging.totalPages = totalPages;

      dispatch({
        type: DeliveryOrdersActionTypes.GET_DELIVERY_ORDERS,
        deliveryOrders: {
          items,
          paging: deliveryOrders.paging,
        },
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Export multiple orders from firebase
 * @param {string} paging.orderBy order by field name
 * @param {string} paging.order get desc or asc
 * @param {string} filter filter text
 * @param {object} params collection fields
 */
export const exportCsv = (orders) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const csvData = orders.map((data) => {
        let productList = '';
        let shippingDetails = '';

        shippingDetails = `${data.shipping.address}, ${data.shipping.country}`;

        data.products.map((product, _) => {
          productList += `Qty:${product.qty}  ${product.name}--${product.variant}\r\n`;
          if (product.subVariants && product.subVariants.length > 0) {
            product.subVariants
              .filter((sub) => sub.value)
              .map((sub, i) => {
                productList += `    +${sub.value}\r\n`;
              });
            productList += `\r\n`;
          }
        });

        return {
          csv_refNum: data.refNum,
          csv_customer: `${data.shipping.firstName} ${data.shipping.lastName}`,
          csv_productList: productList,
          csv_shippingDetails: shippingDetails,
          csv_contact: data.shipping.contactNo,
        };
      });

      const fields = Object.keys(csvData[0]);
      const opts = { fields };
      let csv = parse(csvData, opts);
      csv = csv
        .replace('csv_refNum', 'Ref No')
        .replace('csv_customer', 'Customer')
        .replace('csv_productList', 'Products')
        .replace('csv_shippingDetails', 'Shipping Details')
        .replace('csv_contact', 'Contact No.');
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', csv]);
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'deliveryOrders.csv';
      downloadLink.click();
      downloadLink.remove();
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
