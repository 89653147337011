import { error, success } from 'react-toastify-redux';
import moment from 'moment';
import {
  promosCollection,
  archivesCollection,
  usersCollection,
  firebase,
  firestore,
} from '../../firebase';
import { startLoading, stopLoading, redirect } from './ui';
import { getAdmin, getToken } from '../../utils/auth';
import { createIndexes, convertToDate } from '../../utils';

const apiEndpoint = `${process.env.REACT_APP_WOOSA_API_URI}/api/public/promos`;

export const PromotionActionTypes = {
  GET_PROMOTIONS: 'GET_PROMOTIONS',
  GET_PROMOTION: 'GET_PROMOTION',
  CREATE_PROMOTION: 'CREATE_PROMOTION',
  UPDATE_PROMOTION: 'UPDATE_PROMOTION',
  DELETE_PROMOTION: 'DELETE_PROMOTION',
  APPLY_ORDER_PROMOTION: 'APPLY_ORDER_PROMOTION',
  CLEAR_ORDER_PROMOTION: 'CLEAR_ORDER_PROMOTION',
  APPLY_PRODUCT_PROMOTION: 'APPLY_PRODUCT_PROMOTION',
  CLEAR_PRODUCT_PROMOTION: 'CLEAR_PRODUCT_PROMOTION',
};

/**
 * Retrieve multiple promotions from firebase
 * @param {string} paging.orderBy order by field name
 * @param {string} paging.order get desc or asc
 * @param {number} paging.page current page
 * @param {number} paging.size per page size
 */
export const getPromotions = ({ orderBy, order, page, size, filter }) => {
  return async (dispatch, getState) => {
    try {
      const {
        promotions: { promotions },
      } = getState();

      orderBy = orderBy || promotions.paging.orderBy;
      order = order || promotions.paging.order;
      page = page !== null ? page : promotions.paging.page;
      size = size || promotions.paging.size;

      // if nothing has changed, return
      if (
        promotions.items.length - 1 >= page &&
        promotions.paging.page === page &&
        promotions.paging.order === order &&
        promotions.paging.orderBy === orderBy &&
        promotions.paging.size === size &&
        promotions.paging.filter === filter
      )
        return;

      let items = [...promotions.items];
      let { lastSnapshot } = promotions;
      const paging = { page, order, orderBy, size, filter };

      // if the page already has promotions data just update the paging
      if (
        promotions.items.length - 1 >= page &&
        promotions.paging.page !== page
      ) {
        dispatch({
          type: PromotionActionTypes.GET_PROMOTIONS,
          promotions: {
            items,
            paging,
            lastSnapshot,
          },
        });
        return;
      }

      dispatch(startLoading());
      let query = promosCollection.orderBy(orderBy, order);

      if (filter)
        query = query.where('indexes', 'array-contains-any', [filter]);

      if (
        promotions.paging.page !== page &&
        promotions.items.length - 1 < page
      ) {
        query = query.startAfter(lastSnapshot);
      } else {
        items = [];
        paging.page = 0;
      }

      const documentSnapshots = await query.limit(size).get();
      const results = [];

      documentSnapshots.docs.map(async (doc) => {
        const data = doc.data();
        const startDate = moment.unix(data.startOn.seconds);
        const endsDate = moment.unix(data.endsOn.seconds);
        const currentdate = moment().startOf('day');
        data.active = !!(
          currentdate.diff(startDate) >= 0 && currentdate.diff(endsDate) <= 0
        );
        results.push({ id: doc.id, ...data });
      });

      lastSnapshot = documentSnapshots.docs[documentSnapshots.docs.length - 1];

      if (results.length > 0) {
        items.push(results);

        dispatch({
          type: PromotionActionTypes.GET_PROMOTIONS,
          promotions: {
            items,
            paging,
            lastSnapshot,
          },
        });
      }
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve single promotion slot from firebase
 * @param {string} id get by object id
 */
export const getPromotion = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const doc = await promosCollection.doc(id).get();
      const data = doc.data();

      if (data.eligibility.users) {
        data.eligibility.users = await Promise.all(
          data.eligibility.users.map(async (uid) => {
            const user = await usersCollection.doc(uid).get();
            return { id: uid, ...user.data() };
          })
        );
      }

      const promotion = {
        id: doc.id,
        ...data,
      };

      dispatch({
        type: PromotionActionTypes.GET_PROMOTION,
        promotion,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Enter promo code
 * @param {string} code
 * @param {Object} order
 * @param {Array[string]} appliedPromos
 */
export const applyPromo = async (code, order, appliedPromos) => {
  try {
    const response = await fetch(`${apiEndpoint}/apply/${code}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken().token}`,
      },
      body: JSON.stringify({ order, appliedPromos }),
    });
    if (response.status !== 201 && response.status !== 200) throw response;
    const result = await response.json();
    return result;
  } catch (res) {
    const result = await res.json();
    return {
      message: result && result.message ? result.message : 'Unexpected error!',
    };
  }
};

/**
 * redeem promo
 * @param {Object} order
 * @param {Array} promos
 */
export const porcessPromo = async (order, promos) => {
  try {
    const response = await fetch(`${apiEndpoint}/process`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken().token}`,
      },
      body: JSON.stringify({ order, promos }),
    });
    if (response.status !== 201 && response.status !== 200) throw response;
    const result = await response.json();
    return result;
  } catch (res) {
    return { message: 'Unexpected error!' };
  }
};

/**
 * Create multiple slots
 * @param {Object} promotion
 */
export const createPromotion = (promotion) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      if (!promotion) return;

      promotion.audit = {
        createdBy: getAdmin().name,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      };
      const indexes = createIndexes(promotion, ['code']);
      promotion.indexes = indexes;

      promotion.startOn = convertToDate(promotion.startOn);
      promotion.endsOn = convertToDate(promotion.endsOn);

      const promoRef = await promosCollection.add(promotion);
      dispatch(redirect(`/promotions/${promoRef.id}`));
      setTimeout(() => dispatch(success('Successfully created!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Update promotion
 * @param {string} id
 * @param {Object} promotion
 */
export const updatePromotion = (id, promotion) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      promotion.audit = {
        ...promotion.audit,
        modifiedBy: getAdmin().name,
        modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };
      const indexes = createIndexes(promotion, ['code']);
      promotion.indexes = indexes;

      promotion.startOn = convertToDate(promotion.startOn);
      promotion.endsOn = convertToDate(promotion.endsOn);

      await promosCollection.doc(id).set(promotion);
      setTimeout(() => dispatch(success('Successfully updated!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Delete promotion
 * @param {string} id
 */
export const deletePromotion = (id) => {
  return async (dispatch, getState) => {
    try {
      const {
        promotions: { promotion },
      } = getState();

      dispatch(startLoading());

      promotion.audit = {
        ...promotion.audit,
        deletedBy: getAdmin().name,
        deletedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const batch = firestore.batch();
      batch.delete(promosCollection.doc(id));
      batch.update(archivesCollection.doc('promos'), { [id]: promotion });
      await batch.commit();

      dispatch({
        type: PromotionActionTypes.DELETE_PROMOTION,
      });
      dispatch(redirect('/promotions'));

      setTimeout(() => dispatch(success('Successfully deleted!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
