import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shards-react';
import classNames from 'classnames';

const PaginationButton = ({ className, totalPages, currentPage, onClick }) => {
  const classes = classNames(className, 'mx-1', 'mb-2');

  const items = [];
  for (let i = 1; i <= totalPages; i++) {
    items.push(
      <Button
        key={`paging${i}`}
        className={classes}
        size="sm"
        type="button"
        theme={i === currentPage ? 'primary' : 'light'}
        onClick={() => onClick(i)}
      >
        {i}
      </Button>
    );
  }
  return items;
};

PaginationButton.propTypes = {
  className: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

PaginationButton.defaultProps = {
  className: '',
  totalPages: 0,
  currentPage: 0,
  onClick: () => {},
};

export default PaginationButton;
