/**
 * Helps to retrieve image from assets
 * @private
 * @param {Object[]} assets
 * @param {string} assetId
 * @returns {string}
 */
export const getVariantImage = (assets, assetId) => {
  if (!assets) return '';
  console.log('assetId', assetId);
  const variantAsset = assets.find((a) => a.assetId === assetId);
  return variantAsset.image || '';
};
