import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Button } from 'shards-react';
import { useSelector } from 'react-redux';
import shortid from 'shortid';

import { UploadDialog } from '../../components/common';

const PostSidebarThumbnail = ({ onChange }) => {
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const { post } = useSelector(({ posts }) => posts);
  const [thumbnail, setThumbnail] = useState(post.thumbnail || '');

  const handleInsert = (media) => {
    if (!media) return;
    setThumbnail(media);
    onChange(media);
  };

  useEffect(() => {
    setThumbnail(post.thumbnail);

    // eslint-disable-next-line
  }, [post.thumbnail]);

  return (
    <>
      <Card small className="mb-3">
        <CardHeader className="border-bottom">
          <h6 className="m-0">
            Thumbnail <small>(1200px by 750px)</small>
          </h6>
        </CardHeader>
        <CardBody>
          {thumbnail && (
            <>
              <img
                className="w-100 mb-2 cursor-pointer"
                src={thumbnail}
                alt=""
                role="presentation"
                onClick={() => setOpenUploadDialog(true)}
              />
              Click on the thumbnail to update
            </>
          )}
          {!thumbnail && (
            <Button onClick={() => setOpenUploadDialog(true)}>
              New thumbnail
            </Button>
          )}
        </CardBody>
      </Card>
      <UploadDialog
        onInsert={handleInsert}
        onClose={() => setOpenUploadDialog(false)}
        open={openUploadDialog}
        uploadPath={`images/posts/${shortid.generate()}`}
      />
    </>
  );
};

PostSidebarThumbnail.propTypes = {
  onChange: PropTypes.func,
};

PostSidebarThumbnail.defaultProps = {
  onChange: () => {},
};

export default PostSidebarThumbnail;
