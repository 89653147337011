import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shards-react';
import { confirmAlert } from 'react-confirm-alert';
import IconButton from './IconButton';
import Dialog from './Dialog';

const DialogButton = ({
  isLoading,
  className,
  onConfirm,
  theme,
  icon,
  confirmButtonText,
  mainButtonText,
  title,
  message,
  size,
}) => {
  const handleClick = (event) => {
    event.preventDefault();
    confirmAlert({
      customUI: (res) => {
        return (
          <Dialog>
            <h4>{title}</h4>
            <p>{message}</p>
            <div className="d-flex justify-content-end">
              <Button
                className="mr-2"
                outline
                theme="secondary"
                type="button"
                onClick={res.onClose}
              >
                No
              </Button>
              <Button
                outline
                theme={theme}
                type="button"
                onClick={() => {
                  onConfirm();
                  res.onClose();
                }}
              >
                {confirmButtonText}
              </Button>
            </div>
          </Dialog>
        );
      },
    });
  };

  return (
    <>
      {icon ? (
        <IconButton
          className={className}
          onClick={handleClick}
          theme={theme}
          disabled={isLoading}
          icon={icon}
          type="button"
          size={size}
        />
      ) : (
        <Button
          outline
          className={className}
          onClick={handleClick}
          theme={theme}
          disabled={isLoading}
          type="button"
          size={size}
        >
          {mainButtonText}
        </Button>
      )}
    </>
  );
};

DialogButton.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  confirmButtonText: PropTypes.string,
  mainButtonText: PropTypes.string,
  message: PropTypes.string,
  theme: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
};

DialogButton.defaultProps = {
  isLoading: false,
  className: '',
  title: 'Are you sure?',
  confirmButtonText: 'Yes',
  mainButtonText: '',
  message: 'This is a non-reversable action.',
  theme: 'primary',
  icon: '',
  onConfirm: () => {},
  size: '',
};

export default DialogButton;
