import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Pace from 'react-pace-progress';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify-redux';
import 'react-toastify/dist/ReactToastify.css';

const PaceContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;

  div {
    position: relative;
  }
`;

const Base = ({ children }) => {
  const isLoading = useSelector(({ ui }) => ui.isLoading);

  return (
    <>
      {isLoading && (
        <PaceContainer>
          <Pace color="#007bff" height={5} />
        </PaceContainer>
      )}
      <ToastContainer className="toast-custom" position="top-center" />
      {children}
    </>
  );
};

Base.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Base;
