import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from 'shards-react';
import OrderDetailCard from '../../components/common/OrderDetailCard';

const ScrollableModalBody = styled(ModalBody)`
  padding: 10px;
  max-height: 500px;
  overflow: auto;
`;

const PreviewModal = ({ order, onClose, open }) => {
  return (
    <Modal centered open={open} toggle={onClose} size="xl">
      <ModalHeader>Preview Order</ModalHeader>
      <ScrollableModalBody>
        <OrderDetailCard order={order} isPreview />
      </ScrollableModalBody>
      <ModalFooter>
        <Button type="button" theme="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PreviewModal.propTypes = {
  order: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

PreviewModal.defaultProps = {
  onClose: () => {},
};

export default PreviewModal;
