import React, { useEffect } from 'react';
import { Row, Col } from 'shards-react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { SmartTable } from '../../components/common';
import { getAdmins } from '../../store/actions/admins';
import { MainStage } from '../../components/layout';

const AdminListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const adminsData = useSelector(({ admins: { admins } }) => admins);

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
      width: '20%',
      cell: (row, data) => <Link to={`/admins/${data.id}`}>{row.value}</Link>,
    },
    {
      header: 'Email',
      accessor: 'email',
      width: '20%',
    },
    {
      header: 'Created By',
      accessor: 'audit.createdBy',
    },
    {
      header: 'Created On',
      accessor: 'audit.createdOn',
      cell: ({ value }) => moment.unix(value.seconds).format('lll'),
    },
    {
      header: 'Updated By',
      accessor: 'audit.updatedBy',
    },
    {
      header: 'Updated On',
      accessor: 'audit.updatedOn',
      cell: ({ value }) =>
        value ? moment.unix(value.seconds).format('lll') : '',
    },
  ];

  useEffect(() => {
    dispatch(getAdmins());
  }, [dispatch]);

  return (
    <MainStage title="Overview" subtitle="Admins">
      <Row>
        <Col>
          <SmartTable
            title="Active Admins"
            data={adminsData.items}
            columns={columns}
            onCreate={() => history.push('/admins/new')}
          />
        </Col>
      </Row>
    </MainStage>
  );
};

export default AdminListing;
