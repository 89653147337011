import { ReferralActionTypes } from '../actions/referrals';

const initialState = {
  referral: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {ReferralActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ReferralActionTypes.GET_REFERRAL:
      return { ...state, referral: { ...action.referral } };
    default:
      return state;
  }
}
