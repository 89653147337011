import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormSelect } from 'shards-react';

import { MaterialIcon } from '../../components/common';
import { getAdmins } from '../../store/actions/admins';

const PostSidebarAuthor = ({ onChange, LinkButton }) => {
  const { post } = useSelector(({ posts }) => posts);
  const adminsData = useSelector(({ admins: { admins } }) => admins);
  const [toggle, setToggle] = useState(false);
  const [author, setAuthor] = useState(post.author || {});
  const dispatch = useDispatch();

  useEffect(() => {
    if (adminsData.items.length === 0) {
      dispatch(getAdmins());
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAuthor(post.author || {});

    // eslint-disable-next-line
  }, [post.author, adminsData]);

  return (
    <span className="d-flex mb-2">
      <MaterialIcon className="material-icons mr-1" icon="supervisor_account" />
      {!toggle && author.name}
      {toggle && (
        <FormSelect
          className="mr-2 w-100"
          onChange={(event) => {
            onChange({
              id: event.target.value,
              name: event.target[event.target.selectedIndex].text,
            });
          }}
          defaultValue={author.id}
        >
          {adminsData.items.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </FormSelect>
      )}
      <LinkButton
        className="ml-auto"
        onClick={() => {
          onChange(author);
          setToggle(!toggle);
        }}
      >
        {toggle ? 'Undo' : 'Edit'}
      </LinkButton>
    </span>
  );
};

PostSidebarAuthor.propTypes = {
  onChange: PropTypes.func,
  LinkButton: PropTypes.any.isRequired,
};

PostSidebarAuthor.defaultProps = {
  onChange: () => {},
};

export default PostSidebarAuthor;
