import React from 'react';
import { Row, Col } from 'shards-react';
import PropTypes from 'prop-types';

import CustomerReferralProfile from './CustomerReferralProfile';
import CustomerReferees from './CustomerReferees';

const CustomerReferral = ({ user }) => {
  const { referrals } = user;

  if (!referrals) return null;

  const { referees, referrer } = referrals;

  return (
    <>
      <Row className="align-items-stretch">
        {referrer && referrer.id && (
          <Col sm="3">
            <div className="d-flex flex-column text-center h-100 border-right pb-3">
              <b className="mb-2">Referred By</b>
              <CustomerReferralProfile profile={referrer} />
            </div>
          </Col>
        )}
        <Col>
          <div className="d-flex flex-column h-100">
            <b className="mb-2">Referred</b>
            <CustomerReferees referees={referees} />
          </div>
        </Col>
      </Row>
    </>
  );
};

CustomerReferral.propTypes = {
  user: PropTypes.object.isRequired,
};

export default CustomerReferral;
