import React from 'react';
import { Nav } from 'shards-react';
import { useSelector } from 'react-redux';

import SidebarNavItem from './SidebarNavItem';

const SidebarNavItems = () => {
  const sidebarNavItems = useSelector(({ ui }) => ui.sidebarNavItems);

  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {sidebarNavItems.map((item) => (
          <SidebarNavItem key={item.to} item={item} />
        ))}
      </Nav>
    </div>
  );
};

export default SidebarNavItems;
