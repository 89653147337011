import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Collapse,
  NavItem,
  NavLink,
} from 'shards-react';
import { useSelector } from 'react-redux';
import avatar from '../../../../images/avatars/empty-avatar.svg';
import { MaterialIcon } from '../../../common';

const UserActions = () => {
  const adminData = useSelector(({ auth: { admin } }) => admin);
  const [visible, setVisible] = useState(false);

  const toggleUserActions = () => {
    setVisible(!visible);
  };

  return (
    <NavItem
      tag={Dropdown}
      caret
      toggle={toggleUserActions}
      className="d-flex align-items-center"
    >
      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <img
          className="user-avatar rounded-circle mr-2"
          src={adminData.displayPic ? adminData.displayPic : avatar}
          alt="User Avatar"
        />{' '}
        <span className="d-none d-md-inline-block">{adminData.name}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        <Link className="dropdown-item text-danger" to="/logout">
          <MaterialIcon icon="&#xE879;" className="text-danger" /> Logout
        </Link>
      </Collapse>
    </NavItem>
  );
};

export default UserActions;
