import React, { useEffect } from 'react';
import { Row, Col, Badge } from 'shards-react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { MainStage } from '../../components/layout';
import { SmartTable } from '../../components/common';
import { getReviews } from '../../store/actions/reviews';

const ReviewListing = () => {
  const dispatch = useDispatch();
  const { items, paging } = useSelector(({ reviews: { reviews } }) => reviews);

  const columns = [
    {
      header: 'Date',
      accessor: 'submittedOn',
      sort: true,
      cell: ({ value }) => moment.unix(value.seconds).format('lll'),
    },
    {
      header: 'Rating',
      accessor: 'rating',
      sort: true,
    },
    {
      header: 'Customer',
      accessor: 'user.firstName',
      sort: true,
      cell: (row, data) => {
        if (data.user.id)
          return (
            <Link to={`/customers/${data.user.id}`}>
              {row.value} {data.user.lastName}
            </Link>
          );
        return (
          <span>
            {row.value} {data.user.lastName}
          </span>
        );
      },
    },
    {
      header: 'Product',
      accessor: 'product.name',
      sort: true,
      cell: (row, data) => (
        <Link to={`/products/${data.product.id}`}>{row.value}</Link>
      ),
    },
    {
      header: 'Display',
      accessor: 'display',
      sort: true,
      cell: (row) => (
        <Badge theme={row.value ? 'success' : 'secondary'}>
          {row.value ? 'Yes' : 'No'}
        </Badge>
      ),
    },
    {
      header: 'Action',
      accessor: 'id',
      cell: (row) => <Link to={`/reviews/${row.value}`}>View</Link>,
    },
  ];

  useEffect(() => {
    if (items.length === 0) {
      dispatch(getReviews(paging));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <MainStage title="Overview" subtitle="Reviews">
      <Row>
        <Col>
          <SmartTable
            title="All Reviews"
            data={items}
            columns={columns}
            page={paging.page}
            defaultFilter={paging.filter}
            defaultSortFilter={paging.orderBy}
            onSorted={(values) => {
              const orderBy = Object.keys(values)[0];
              const order = values[orderBy];
              dispatch(getReviews({ orderBy, order }));
            }}
            onFilter={(filter) => {
              dispatch(getReviews({ filter }));
            }}
            onPageChange={(page) => {
              dispatch(getReviews({ page }));
            }}
          />
        </Col>
      </Row>
    </MainStage>
  );
};

export default ReviewListing;
