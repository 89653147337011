import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Nav, NavItem } from 'shards-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledRow = styled(Row)`
  height: 100%;
`;

const MainFooter = ({ contained, menuItems, copyright }) => (
  <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
    <Container fluid={contained}>
      <StyledRow>
        <Nav>
          {menuItems.map((item, idx) => (
            <NavItem key={idx}>
              <Link className="nav-link" to={item.to}>
                {item.title}
              </Link>
            </NavItem>
          ))}
        </Nav>
        <span className="copyright ml-auto my-auto mr-2">{copyright}</span>
      </StyledRow>
    </Container>
  </footer>
);

MainFooter.propTypes = {
  contained: PropTypes.bool,
  menuItems: PropTypes.array,
  copyright: PropTypes.string,
};

MainFooter.defaultProps = {
  contained: false,
  copyright: `Copyright © ${new Date().getFullYear()} Woosa`,
  menuItems: [],
};

export default MainFooter;
