import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, ModalFooter, ModalBody, Button, FormInput } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { warning } from 'react-toastify-redux';
import { PromoBundleGetTypes } from '../../constants';
import { isArrayObjectEqual } from '../../utils/index';

const ProductTable = styled.table`
  td {
    vertical-align: top;
    img {
      max-width: 100px;
      margin-right: 10px;
    }
  }
`;

const BundleModal = ({ onConfirm, open, data }) => {
  const dispatch = useDispatch();
  const productsData = useSelector(({ products: { products } }) => products);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems([]);
    data.bundle.gets.forEach((get) => {
      if (get.type !== PromoBundleGetTypes.DISCOUNT) {
        get.products.map((p) => {
          p.checked = false;
          p.qty = 0;
          return p;
        });
        setItems((prev) => [
          ...prev,
          {
            maxQty: get.qty * get.multiplyQty,
            products: get.products,
          },
        ]);
      }
    });
    // eslint-disable-next-line
  }, [productsData, data]);

  const handleProductQty = (e, index, pindex) => {
    const value = parseFloat(e.target.value);
    const newItems = [...items];
    newItems[index].products[pindex].qty = value;
    const totalQty = newItems[index].products.reduce(
      (total, p) => total + (p.qty || 0),
      0
    );
    if (totalQty > newItems[index].maxQty)
      newItems[index].products[pindex].qty = 0;
    setItems(newItems);
  };

  const handleConfirm = () => {
    for (let i = 0; i < items.length; i++) {
      const totalQty = items[i].products.reduce(
        (total, p) => total + (p.qty || 0),
        0
      );
      if (totalQty !== items[i].maxQty) return;
    }

    const redeemProducts = [];
    items.forEach((item) => {
      item.products.forEach((product) => {
        if (product.qty === 0) return;
        const selectedProduct = productsData.items.find(
          (p) => p.id === product.id
        );
        const selectedMedia = selectedProduct.media.find((m) => m.default);
        let selectedVariant = null;
        if (product.subVariants && product.subVariants.length) {
          selectedProduct.variants.map((v) => {
            if (
              v.type === product.variant &&
              isArrayObjectEqual(v.subVariants, product.subVariants, [
                'key',
                'assetId',
              ])
            ) {
              selectedVariant = v;
            }
          });
        } else {
          selectedVariant = selectedProduct.variants.find(
            (v) => v.type === product.variant
          );
        }

        if (selectedVariant.stockQuantity < product.qty) {
          dispatch(warning(`Insufficient stock`));
          return;
        }
        const redeemProduct = {
          id: selectedProduct.id,
          name: selectedProduct.name,
          collection: selectedProduct.collection,
          variant: selectedVariant.type,
          subVariants: selectedVariant.subVariants || [],
          currency: selectedVariant.prices[0].currency,
          unitPrice: 0,
          comparePrice: 0,
          image: selectedMedia.path,
          qty: product.qty,
          subtotal: 0,
          promoCode: data.code,
        };
        redeemProducts.push(redeemProduct);
      });
    });
    onConfirm(redeemProducts);
  };

  const handleOnClose = () => {
    return null;
  };

  return (
    items.length > 0 &&
    productsData && (
      <Modal centered open={open} toggle={handleOnClose}>
        <ModalBody>
          {items.map((item, index) => {
            return (
              <div key={index}>
                <h5>Get any {item.maxQty} product(s)</h5>
                <div className="table-responsive">
                  <ProductTable className="table">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th width="120">Variant</th>
                        <th width="100">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.products.map((p, pIndex) => {
                        return (
                          <tr key={pIndex}>
                            <td>{p.name}</td>
                            <td>
                              {p.variant}
                              {p.subVariants &&
                                p.subVariants.length > 0 &&
                                p.subVariants
                                  .filter((sub) => sub.value)
                                  .map((sub, i) => {
                                    return (
                                      <div key={`${sub}_${i}`}>
                                        <small>+ {sub.value}</small>
                                      </div>
                                    );
                                  })}
                            </td>
                            <td>
                              <FormInput
                                type="number"
                                className="py-1 px-2"
                                value={p.qty}
                                min={0}
                                onChange={(e) => {
                                  handleProductQty(e, index, pIndex);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </ProductTable>
                </div>
              </div>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button type="button" theme="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    )
  );
};

BundleModal.propTypes = {
  data: PropTypes.object,
  onConfirm: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

BundleModal.defaultProps = {
  data: null,
  onConfirm: () => {},
};

export default BundleModal;
