import { ProductActionTypes } from '../actions/products';

const initialState = {
  products: {
    items: [],
    paging: {
      page: 1,
      size: 1000,
      orderBy: null,
      order: null,
      totalCount: 0,
    },
  },
  product: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {ProductActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ProductActionTypes.GET_PRODUCTS:
      return { ...state, products: { ...action.products } };
    case ProductActionTypes.GET_PRODUCT:
      return { ...state, product: { ...action.product } };
    case ProductActionTypes.CREATE_PRODUCT:
      return { ...state, product: { ...action.product } };
    case ProductActionTypes.UPDATE_PRODUCT:
      return { ...state, product: { ...action.product } };
    case ProductActionTypes.DELETE_PRODUCT:
      return { ...state, product: null };
    default:
      return state;
  }
}
