import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormInput,
  Button,
} from 'shards-react';
import logo from '../../images/logo.svg';
import { authResetPassword } from '../../store/actions/auth';

const ResetPasswordContainer = styled.div`
  width: 450px;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Logo = styled.img`
  max-width: 50%;
  margin: 0 auto;
  display: block;
`;

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(({ ui }) => ui.isLoading);

  const { handleSubmit, control } = useForm();

  const onSubmit = ({ email }) => {
    dispatch(authResetPassword(email));
  };

  return (
    <ResetPasswordContainer>
      <Logo className="mb-4" src={logo} role="presentation" alt="Woosa CMS" />
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h4 className="m-0">Reset Password</h4>
        </CardHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <Row>
              <Col md="12" className="form-group">
                <label htmlFor="feEmail">Email</label>
                <Controller
                  as={FormInput}
                  name="email"
                  placeholder="Email Address"
                  type="email"
                  required
                  control={control}
                  defaultValue=""
                />
              </Col>
            </Row>
            <div className="mt-2">
              <Button
                className="mr-3"
                disabled={isLoading}
                theme="light"
                type="button"
                onClick={() => history.push('/')}
              >
                Back
              </Button>
              <Button disabled={isLoading} theme="accent">
                Submit
              </Button>
            </div>
          </CardBody>
        </Form>
      </Card>
    </ResetPasswordContainer>
  );
};

export default ResetPassword;
