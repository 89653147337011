import React, { useEffect } from 'react';
import { Row, Col, Badge } from 'shards-react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { SmartTable } from '../../components/common';
import { getPromotions } from '../../store/actions/promotions';
import { MainStage } from '../../components/layout';
import { PromosColor } from '../../constants';

const PromotionListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { items, paging } = useSelector(
    ({ promotions: { promotions } }) => promotions
  );

  const columns = [
    {
      header: 'Code',
      accessor: 'code',
      cell: (row, data) => (
        <Link to={`/promotions/${data.id}`}>{row.value}</Link>
      ),
    },
    {
      header: 'Type',
      accessor: 'type',
      cell: (row) => (
        <Badge theme={PromosColor[row.value.replace(' ', '_').toUpperCase()]}>
          {row.value}
        </Badge>
      ),
    },
    {
      header: 'Status',
      accessor: 'active',
      cell: (row) => (
        <Badge theme={row.value ? 'success' : 'danger'}>
          {row.value ? 'active' : 'expired'}
        </Badge>
      ),
    },
    {
      header: 'Start On',
      accessor: 'startOn',
      cell: (row) => (
        <span>{moment.unix(row.value.seconds).format('D MMM YYYY')}</span>
      ),
    },
    {
      header: 'Ends On',
      accessor: 'endsOn',
      cell: (row) => (
        <span>{moment.unix(row.value.seconds).format('D MMM YYYY')}</span>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getPromotions({}));
    // eslint-disable-next-line
  }, []);

  return (
    <MainStage title="Overview" subtitle="Promotions">
      <Row>
        <Col lg="12">
          <SmartTable
            title="Active Promotions"
            data={items}
            columns={columns}
            onCreate={() => history.push('/promotions/new')}
            page={paging.page}
            onPageChange={(page) => {
              dispatch(getPromotions({ page }));
            }}
            onFilter={(filter) => {
              dispatch(getPromotions({ filter }));
            }}
          />
        </Col>
      </Row>
    </MainStage>
  );
};

export default PromotionListing;
