import React, { useCallback, useState } from 'react';
import { error, success } from 'react-toastify-redux';
import PropTypes from 'prop-types';
import { Button } from 'shards-react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from './Dialog';
import IconButton from './IconButton';
import { startLoading, stopLoading } from '../../store/actions/ui';
import { uploadFile, getDownloadUrls } from '../../utils/media';

const DragZone = styled.div`
  width: 100%;
  min-height: 400px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PreviewZone = styled.div`
  width: 100%;
  min-height: 400px;

  img {
    width: 100%;
  }
`;

const UploadDialog = ({ onInsert, uploadPath, open, onClose }) => {
  const dispatch = useDispatch();
  const [media, setMedia] = useState([]);
  const { isLoading } = useSelector(({ ui }) => ui);

  const onDrop = useCallback(async (files) => {
    try {
      dispatch(startLoading());

      const promises = [];

      files.forEach((file) => promises.push(uploadFile(file, uploadPath)));

      const snapshots = await Promise.all(promises);
      const fullPaths = snapshots.map(({ metadata }) => metadata.fullPath);
      const urls = await getDownloadUrls(fullPaths);
      setMedia(Object.keys(urls).map((key) => urls[key].split('&')[0]));
      dispatch(success('Successfully uploaded'));
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (!open) return null;

  return (
    <div className="react-confirm-alert-overlay">
      <Dialog className="py-4">
        {media.length > 0 && (
          <PreviewZone>
            {media.map((src, i) => (
              <img key={i} src={src} alt="" role="presentation" />
            ))}
          </PreviewZone>
        )}
        {media.length === 0 && (
          <DragZone {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              {isDragActive
                ? 'Drop the files here ...'
                : 'Drag & drop some files here, or click to select files'}
            </p>
          </DragZone>
        )}
        <div className="d-flex justify-content-end">
          {media.length > 0 && (
            <>
              <Button
                className="mr-2"
                theme="success"
                type="button"
                disabled={isLoading}
                onClick={() => {
                  onInsert(media);
                  onClose();
                  setMedia([]);
                }}
              >
                Insert
              </Button>
              <IconButton
                className="mr-2"
                theme="danger"
                icon="delete_outline"
                onClick={() => setMedia([])}
              />
            </>
          )}
          <Button
            outline
            theme="secondary"
            type="button"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

UploadDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onInsert: PropTypes.func.isRequired,
  uploadPath: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

UploadDialog.defaultProps = {
  open: false,
};

export default UploadDialog;
