import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const CustomerReferralProfile = ({ profile }) => {
  return (
    <Link to={`/customers/${profile.id}`} className="text-center">
      {profile.displayPic && (
        <>
          <img
            className="rounded-circle w-50"
            src={profile.displayPic}
            alt=""
            role="presentation"
          />
          <span className="d-block mt-2">
            {profile.firstName} {profile.lastName}
          </span>
        </>
      )}
      {!profile.displayPic && (
        <h6 className="d-block mt-2 text-primary">
          {profile.firstName} {profile.lastName}
        </h6>
      )}
    </Link>
  );
};

CustomerReferralProfile.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default CustomerReferralProfile;
