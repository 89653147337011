import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
  Badge,
  DatePicker,
  FormCheckbox,
} from 'shards-react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { MaterialIcon, DialogButton } from '../../components/common';
import PostSideBarAuthor from './PostSidebarAuthor';

const LinkButton = styled.button`
  border: 0;
  background: transparent;
  color: #007bff;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const PostSidebarAction = ({
  onSubmit,
  onDelete,
  onDateChange,
  onAuthorChange,
  onIsFeaturedChange,
}) => {
  const { id } = useParams();
  const { isLoading } = useSelector(({ ui }) => ui);
  const { post } = useSelector(({ posts }) => posts);
  const { status, publishedOn, isFeatured } = post;
  const [toggleDate, setToggleDate] = useState(false);
  const [date, setDate] = useState(
    publishedOn ? moment.unix(publishedOn.seconds).toDate() : new Date()
  );
  const [isPostFeatured, setIsPostFeatured] = useState(false);
  const prevDate = useRef(date);

  useEffect(() => {
    const updatedDate =
      publishedOn && publishedOn.seconds
        ? moment.unix(publishedOn.seconds).toDate()
        : publishedOn || new Date();

    setDate(updatedDate);
    prevDate.current = updatedDate;

    // eslint-disable-next-line
  }, [publishedOn]);

  useEffect(() => {
    setIsPostFeatured(isFeatured);

    // eslint-disable-next-line
  }, [isFeatured]);

  return (
    <Card small className="mb-3">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Actions</h6>
      </CardHeader>

      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <span className="d-flex mb-2">
              <MaterialIcon className="material-icons mr-1" icon="flag" />
              <strong className="mr-1">Status:</strong>
              <Badge
                className="text-capitalize"
                theme={status !== 'published' ? 'secondary' : 'primary'}
              >
                {status || 'Draft'}
              </Badge>
            </span>
            <span className="d-flex mb-2">
              <MaterialIcon
                className="material-icons mr-1"
                icon="calendar_today"
              />
              {!toggleDate && moment(date).format('ll')}
              {toggleDate && (
                <div className="mr-2 w-100">
                  <DatePicker
                    selected={date}
                    dateFormat="d MMM yyyy"
                    todayButton="Today"
                    dropdownMode="select"
                    placeholderText="Select Date"
                    onChange={(selected) => {
                      setDate(selected);
                      onDateChange(selected);
                    }}
                  />
                </div>
              )}
              <LinkButton
                className="ml-auto"
                onClick={() => {
                  onDateChange(prevDate.current);
                  setDate(prevDate.current);
                  setToggleDate(!toggleDate);
                }}
              >
                {toggleDate ? 'Undo' : 'Edit'}
              </LinkButton>
            </span>
            <PostSideBarAuthor
              LinkButton={LinkButton}
              onChange={onAuthorChange}
            />
            <FormCheckbox
              onChange={() => {
                const newIsFeatureChange = !isPostFeatured;
                onIsFeaturedChange(newIsFeatureChange);
                setIsPostFeatured(newIsFeatureChange);
              }}
              checked={isPostFeatured}
            >
              Is Featured?
            </FormCheckbox>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between px-3 border-0">
            <Button
              outline
              theme="secondary"
              size="sm"
              onClick={() => onSubmit('draft')}
              disabled={isLoading}
            >
              <MaterialIcon icon="save" /> Save Draft
            </Button>
            {id && (
              <DialogButton
                theme="danger"
                size="sm"
                onConfirm={onDelete}
                disabled={isLoading}
                icon="delete_outline"
              />
            )}
            <Button
              theme="primary"
              size="sm"
              onClick={() => onSubmit('published')}
              disabled={isLoading}
            >
              <MaterialIcon icon="file_copy" /> Publish
            </Button>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
};

PostSidebarAction.propTypes = {
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onDateChange: PropTypes.func,
  onAuthorChange: PropTypes.func,
  onIsFeaturedChange: PropTypes.func,
};

PostSidebarAction.defaultProps = {
  onSubmit: () => {},
  onDelete: () => {},
  onDateChange: () => {},
  onAuthorChange: () => {},
  onIsFeaturedChange: () => {},
};

export default PostSidebarAction;
