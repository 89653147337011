import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import { MainStage } from '../../components/layout';
import {
  getReview,
  updateReview,
  deleteReview,
} from '../../store/actions/reviews';
import { FormCard, FormGroup, Dialog } from '../../components/common';

const ReviewForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(({ ui }) => ui.isLoading);
  const { review } = useSelector(({ reviews }) => reviews);
  const { control, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    if (id) {
      dispatch(getReview(id));
    } else {
      history.goBack();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setValue('rating', review.rating || '');
    setValue('display', review.display);
    setValue('content', review.content || '');
    setValue(
      'submittedOn',
      review.submittedOn ? moment.unix(review.submittedOn.seconds).toDate() : ''
    );

    // eslint-disable-next-line
  }, [review]);

  const handleUpdate = (data) => {
    dispatch(updateReview(id, data));
  };

  const handleDelete = () => {
    dispatch(deleteReview(id));
  };

  return (
    <MainStage title="Details" subtitle="Reviews" to="/reviews">
      <Row>
        <Col lg="8">
          <FormCard
            title={`Submitted on ${
              review.submittedOn
                ? moment.unix(review.submittedOn.seconds).format('lll')
                : ''
            }`}
            button={{ theme: 'primary', text: 'save', isLoading }}
            onSubmit={handleSubmit(handleUpdate)}
            onDelete={handleDelete}
          >
            <Row form>
              <FormGroup
                type="checkbox"
                label="Display"
                name="display"
                control={control}
                defaultValue={review.display}
                col={{ lg: '2' }}
              />
              <FormGroup
                name="rating"
                label="Rating"
                control={control}
                rules={{ required: 'Required Field' }}
                col={{ lg: '5' }}
                errors={errors}
                type="number"
              />
              <FormGroup
                col={{ lg: '5' }}
                type="datepicker"
                name="submittedOn"
                label="Submitted On"
                control={control}
                rules={{ required: 'Required field' }}
                dateProps={{
                  showTimeInput: true,
                  dateFormat: 'd MMM yyyy h:mm aa',
                }}
                errors={errors}
              />
            </Row>
            <Row form>
              <FormGroup
                name="content"
                label="Content"
                control={control}
                errors={errors}
                defaultValue=""
                type="textarea"
                size="lg"
              />
            </Row>
          </FormCard>
        </Col>
        <Col lg="4">
          <Card small>
            <CardHeader className="border-bottom">
              {review.user && (
                <div className="d-flex align-items-center">
                  {review.user.displayPic && (
                    <img
                      className="rounded-circle"
                      src={review.user.displayPic}
                      alt=""
                      role="presentation"
                      width="50"
                    />
                  )}
                  <span className="mb-0 ml-3">
                    <b className="text-secondary">
                      {review.user.firstName} {review.user.lastName}
                    </b>
                    <br />
                    <span className="text-muted">{review.user.email}</span>
                  </span>
                </div>
              )}
            </CardHeader>
            <CardHeader className="border-bottom">
              {review.product && (
                <Link to={`/products/${review.product.id}`}>
                  <h5 className="m-0 text-info">{review.product.name}</h5>
                </Link>
              )}
            </CardHeader>
            <CardBody>
              {review.media && (
                <div className="d-flex">
                  {review.media.map((media, i) => (
                    <img
                      className="w-50 rounded cursor-pointer"
                      key={i}
                      src={media}
                      alt=""
                      role="presentation"
                      onClick={(event) => {
                        event.preventDefault();
                        confirmAlert({
                          customUI: () => {
                            return (
                              <Dialog>
                                <img
                                  className="w-100"
                                  src={media}
                                  alt=""
                                  role="presentation"
                                />
                              </Dialog>
                            );
                          },
                        });
                      }}
                    />
                  ))}
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </MainStage>
  );
};

export default ReviewForm;
