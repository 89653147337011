import { error, success } from 'react-toastify-redux';

import {
  rolesCollection,
  adminsCollection,
  archivesCollection,
  firebase,
  firestore,
} from '../../firebase';
import { startLoading, stopLoading, redirect } from './ui';

export const RoleActionTypes = {
  GET_ROLES: 'GET_ROLES',
  GET_ROLE: 'GET_ROLE',
  DELETE_ROLE: 'DELETE_ROLE',
};

/**
 * Retrieve multiple roles from firebase
 */
export const getRoles = () => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const response = await rolesCollection.get();
      const adminResponse = await adminsCollection.get();

      const roles = [];
      const admins = [];

      adminResponse.forEach((doc) => admins.push(doc.data()));

      response.forEach((doc) => {
        const count = admins.filter(({ role }) => role && role.id === doc.id)
          .length;
        roles.push({ id: doc.id, ...doc.data(), count });
      });

      dispatch({
        type: RoleActionTypes.GET_ROLES,
        roles,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Rerieve role document by id from firebase
 * @param {string} id
 */
export const getRole = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const roleRef = await rolesCollection.doc(id).get();
      const adminsRef = await adminsCollection.where('role.id', '==', id).get();

      const role = { id: roleRef.id, ...roleRef.data() };
      role.admins = [];

      adminsRef.forEach((doc) =>
        role.admins.push({ id: doc.id, ...doc.data() })
      );

      dispatch({
        type: RoleActionTypes.GET_ROLE,
        role,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * @typedef {Object} Role
 * @property {string} name
 * @property {Object} modules
 *
 * Create new role
 * @param {Role} role
 */
export const createRole = (role) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const {
        auth: { admin },
      } = getState();
      role.audit = {
        createdBy: admin.name,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      };
      const roleRef = await rolesCollection.add(role);

      dispatch(getRoles());
      dispatch(redirect(`/roles/${roleRef.id}`));
      setTimeout(() => dispatch(success('Successfully created!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Update existing role
 * @param {string} id
 * @param {Role} role
 */
export const updateRole = (id, role) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const {
        auth: { admin },
      } = getState();

      role.audit = {
        ...role.audit,
        updatedBy: admin.name,
        updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await rolesCollection.doc(id).set(role);
      dispatch(success('Successfully updated!'));
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Delete role and move to archive collection
 * @param {string} id
 */
export const deleteRole = (id) => {
  return async (dispatch, getState) => {
    try {
      const {
        auth: { admin },
        roles: { role },
      } = getState();

      if (role.admins.length > 0) {
        throw new Error(
          'Error deleting role. Please remove all assigned admins before proceeding.'
        );
      }

      dispatch(startLoading());

      role.audit = {
        ...role.audit,
        deletedBy: admin.name,
        deletedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      role.admins = role.admins.map((a) => {
        return { id: a.id, name: a.name, email: a.email };
      });

      const batch = firestore.batch();
      batch.delete(rolesCollection.doc(id));
      batch.update(archivesCollection.doc('roles'), { [id]: role });

      await batch.commit();

      dispatch({
        type: RoleActionTypes.DELETE_ROLE,
      });
      dispatch(getRoles());
      dispatch(redirect('/roles'));
      setTimeout(() => dispatch(success('Successfully deleted!')), 200);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
