import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
`;

const ClipLoaderStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PageLoader = ({ size, loading }) => {
  return (
    <>
      {loading && (
        <Wrapper>
          <ClipLoaderStyled>
            <ClipLoader loading={loading} size={size} />
          </ClipLoaderStyled>
        </Wrapper>
      )}
    </>
  );
};

PageLoader.propTypes = {
  size: PropTypes.number,
  loading: PropTypes.bool,
};

PageLoader.defaultProps = {
  size: 60,
  loading: false,
};

export default PageLoader;
