import { RoleActionTypes } from '../actions/roles';

const initialState = {
  roles: [],
  role: {},
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {RoleActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RoleActionTypes.GET_ROLES:
      return { ...state, roles: [...action.roles] };
    case RoleActionTypes.GET_ROLE:
      return { ...state, role: { ...action.role } };
    case RoleActionTypes.DELETE_ROLE:
      return { ...state, role: {} };
    default:
      return state;
  }
}
