import { error, success } from 'react-toastify-redux';
import { pageStartLoading, pageStopLoading } from './ui';
import { getToken } from '../../utils/auth';

export const PipelineActionTypes = {
  GET_BITBUCKET_PIPELINE: 'GET_BITBUCKET_PIPELINE',
};

const apiEndpoint = `${process.env.REACT_APP_WOOSA_API_URI}/api/pipelines`;

/**
 * Run bitbucket pipeline
 */
export const runBitbucketPipeline = () => {
  return async (dispatch) => {
    try {
      dispatch(pageStartLoading());

      const response = await fetch(`${apiEndpoint}/bitbucket/run`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
        body: JSON.stringify({
          pipeline: process.env.REACT_APP_ENV === 'dev' ? 'demo' : undefined,
        }),
      });
      if (response.status !== 201 && response.status !== 200) throw response;
      const data = await response.json();
      dispatch(
        success(
          data.message ||
            'Successfully started pipeline, please wait while we proceed to publish the changes.'
        )
      );
    } catch (err) {
      dispatch(error('Unexpected error!'));
    } finally {
      dispatch(pageStopLoading());
    }
  };
};

/**
 * Get bitbucket pipeline
 */
export const getBitbucketPipeline = () => {
  return async (dispatch) => {
    let pipeline = {};
    try {
      const response = await fetch(`${apiEndpoint}/bitbucket`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
      });
      if (response.status !== 201 && response.status !== 200) throw response;
      pipeline = await response.json();
    } catch (err) {
      console.log('err', err);
    } finally {
      dispatch({
        type: PipelineActionTypes.GET_BITBUCKET_PIPELINE,
        pipeline,
      });
    }
  };
};
