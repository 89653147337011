import { OrderActionTypes } from '../actions/orders';

const initialState = {
  orders: {
    items: [],
    paging: {
      page: 1,
      size: 20,
      orderBy: 'refNum',
      order: 'desc',
      filter: '',
      params: {},
    },
  },
  order: null,
  orderLog: null,
  lastOrder: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {OrderActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OrderActionTypes.GET_ORDERS:
      return { ...state, orders: { ...action.orders } };
    case OrderActionTypes.GET_ORDER:
      return { ...state, order: { ...action.order } };
    case OrderActionTypes.GET_LAST_ORDER:
      return { ...state, lastOrder: { ...action.lastOrder } };
    case OrderActionTypes.GET_PAYMENT_LINK:
      return { ...state, paymentLink: action.paymentLink };
    case OrderActionTypes.GET_ORDER_LOG:
      return { ...state, orderLog: { ...action.orderLog } };
    default:
      return state;
  }
}
