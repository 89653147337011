import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { toastsReducer } from 'react-toastify-redux';
import uiReducer from './reducers/ui';
import authReducer from './reducers/auth';
import rolesReducer from './reducers/roles';
import adminsReducer from './reducers/admins';
import usersReducer from './reducers/users';
import productsReducer from './reducers/products';
import ordersReducer from './reducers/orders';
import deliverySlotsReducer from './reducers/deliverySlots';
import reviewsReducer from './reducers/reviews';
import promotionsReducer from './reducers/promotions';
import postsReducer from './reducers/posts';
import postCategoriesReducer from './reducers/postCategories';
import referralsReducer from './reducers/referrals';
import orderFiltersReducer from './reducers/orderFilters';
import orderReportsReducer from './reducers/orderReports';
import reportsReducer from './reducers/reports';
import deliveryOrdersReducer from './reducers/deliveryOrders';
import miscReducer from './reducers/misc';
import pipelineReducer from './reducers/pipeline';

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  roles: rolesReducer,
  admins: adminsReducer,
  users: usersReducer,
  toasts: toastsReducer,
  products: productsReducer,
  orders: ordersReducer,
  deliverySlots: deliverySlotsReducer,
  deliveryOrders: deliveryOrdersReducer,
  reviews: reviewsReducer,
  promotions: promotionsReducer,
  posts: postsReducer,
  categories: postCategoriesReducer,
  referrals: referralsReducer,
  orderFilters: orderFiltersReducer,
  orderReports: orderReportsReducer,
  reports: reportsReducer,
  misc: miscReducer,
  pipeline: pipelineReducer,
});

const composeEnchancers = compose;

const middlewares = [thunk];

if (process.env.REACT_APP_ENV !== 'production') {
  middlewares.push(logger);
}

const configureStore = () =>
  createStore(rootReducer, composeEnchancers(applyMiddleware(...middlewares)));

export default configureStore;
