import { error } from 'react-toastify-redux';

import { startLoading, stopLoading } from './ui';
import {
  postCategoriesCollection,
  archivesCollection,
  firebase,
  firestore,
} from '../../firebase';

export const PostCategoryActionTypes = {
  CREATE_POST_CATEGORY: 'CREATE_POST_CATEGORY',
  DELETE_POST_CATEGORY: 'DELETE_POST_CATEGORY',
  GET_POST_CATEGORIES: 'GET_POST_CATEGORIES',
};

/**
 * Retrieve all post categories from firebase
 */
export const getPostCategories = () => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const documents = await postCategoriesCollection.get();

      const postCategories = [];

      documents.forEach((doc) => {
        postCategories.push({ id: doc.id, ...doc.data() });
      });

      dispatch({
        type: PostCategoryActionTypes.GET_POST_CATEGORIES,
        postCategories,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Create new post category
 * @param {string} name
 */
export const createPostCategory = (name) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const {
        auth: { admin },
        categories: { postCategories },
      } = getState();

      const audit = {
        createdBy: admin.name,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const postCategoryRef = await postCategoriesCollection.add({
        name,
        audit,
      });
      const newPostCategory = await postCategoriesCollection
        .doc(postCategoryRef.id)
        .get();

      postCategories.push({
        id: newPostCategory.id,
        ...newPostCategory.data(),
      });

      dispatch({
        type: PostCategoryActionTypes.CREATE_POST_CATEGORY,
        postCategories,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Delete post category and move to archive collection
 * @param {string} id
 */
export const deletePostCategory = (id) => {
  return async (dispatch, getState) => {
    try {
      const {
        auth: { admin },
        categories: { postCategories },
      } = getState();

      dispatch(startLoading());

      let category = postCategories.find((c) => c.id === id);

      if (!category) return;

      const audit = {
        ...category.audit,
        deletedBy: admin.name,
        deletedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      category = { ...category, audit };
      delete category.id;

      const batch = firestore.batch();
      batch.delete(postCategoriesCollection.doc(id));
      batch.update(archivesCollection.doc('postCategories'), {
        [id]: category,
      });

      await batch.commit();

      dispatch({
        type: PostCategoryActionTypes.DELETE_POST_CATEGORY,
        postCategories: postCategories.filter((c) => c.id !== id),
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};
