import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  FormInput,
  InputGroup,
  Button,
  InputGroupAddon,
} from 'shards-react';
import { Controller, ErrorMessage } from 'react-hook-form';

const FormInputGroup = ({
  name,
  label,
  control,
  rules,
  value,
  defaultValue,
  errors,
  col,
  type,
  className,
  placeholder,
  onClick,
  onChange,
}) => {
  const hasError = () => {
    let result = false;

    if (!errors) return result;

    Object.keys(errors).forEach((key) => {
      const value = errors[key];
      if (Array.isArray(value)) {
        value.forEach((subValue) => {
          if (subValue.ref && subValue.ref.name === name) {
            result = true;
          }
        });
      } else if (value.ref && value.ref.name === name) {
        result = true;
      }
    });
    return result;
  };

  const Select = (
    <InputGroup>
      <FormInput
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value);
          return e.target.value;
        }}
      />
      <InputGroupAddon type="append">
        <Button type="button" onClick={onClick} theme="secondary">
          Enter
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
  const input = (
    <Controller
      as={Select}
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      type={type}
    />
  );

  return (
    <Col {...col} className={`form-group ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      {input}
      {hasError && (
        <span className="text-danger mt-2 d-block">
          <ErrorMessage errors={errors} name={name} />
        </span>
      )}
    </Col>
  );
};

FormInputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  errors: PropTypes.object,
  label: PropTypes.string,
  col: PropTypes.shape({
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
    className: PropTypes.string,
    breakpoints: PropTypes.arrayOf(PropTypes.string),
    tag: PropTypes.string,
  }),
  type: PropTypes.string,
  /**
   * Visit https://react-hook-form.com/api/#register for more information
   * regarding rules
   */
  rules: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

FormInputGroup.defaultProps = {
  label: '',
  rules: {},
  defaultValue: '',
  value: '',
  errors: {},
  col: {},
  type: 'text',
  className: '',
  placeholder: '',
  onClick: () => {},
  onChange: () => {},
};

export default FormInputGroup;
