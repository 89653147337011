import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPostCategories,
  deletePostCategory,
  createPostCategory,
} from '../../store/actions/postCategories';
import { ValueManager } from '../../components/common';

const PostSidebarCategory = ({ onSelected }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(({ ui }) => ui);
  const { post } = useSelector(({ posts }) => posts);
  const { postCategories } = useSelector(({ categories }) => categories);

  useEffect(() => {
    dispatch(getPostCategories());
  }, []);

  const handleSelect = (selectedCategoryNames) => {
    const selectedCategories = postCategories.filter((c) =>
      selectedCategoryNames.includes(c.name)
    );

    // return selected to callback function
    onSelected(selectedCategories);
  };

  const handleDelete = (categoryName) => {
    const category = postCategories.find((c) => c.name === categoryName);
    if (!category) return;
    dispatch(deletePostCategory(category.id));
  };

  const handleSubmit = (categoryName) => {
    if (!categoryName) return;
    dispatch(createPostCategory(categoryName));
  };

  return (
    <ValueManager
      className="mb-3"
      listValues={postCategories.map(({ name }) => name)}
      isLoading={isLoading}
      label="Category"
      onSelected={handleSelect}
      onSubmitNewValue={handleSubmit}
      onDeleteValue={handleDelete}
      initialValues={
        post && post.categories ? post.categories.map((c) => c.name) : []
      }
    />
  );
};

PostSidebarCategory.propTypes = {
  onSelected: PropTypes.func,
};

PostSidebarCategory.defaultProps = {
  onSelected: () => {},
};

export default PostSidebarCategory;
