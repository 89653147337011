import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from 'shards-react';
import { warning } from 'react-toastify-redux';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { FormGroup } from '../../components/common';
import { StatusType, ProductAssetKey } from '../../constants';
import { getVariantImage } from '../../utils/variant';

const ScrollableModalBody = styled(ModalBody)`
  height: 70vh;
  overflow-y: auto;
`;

const VariantGroupModal = ({
  subVariantKeys,
  productAssets,
  variantGroup,
  onAddVariantGroup,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, errors, setValue } = useForm();

  const statusTypeOptions = Object.keys(StatusType).map((key) => {
    return { value: StatusType[key], label: StatusType[key] };
  });
  const designAssetOptions = productAssets
    .filter((a) => a.key === ProductAssetKey.DESIGN)
    .map((a) => {
      return {
        value: a.assetId,
        label: (
          <div className="d-flex align-items-center">
            <div
              style={{
                height: '35px',
                width: '35px',
                lineHeight: '35px',
                marginRight: '10px',
              }}
            >
              <img src={getVariantImage(productAssets, a.assetId)} className="mw-100 mh-100" />
            </div>
            <div>{a.value}</div>
          </div>
        ),
      };
    });
  const materialAssetOptions = productAssets
    .filter((a) => a.key === ProductAssetKey.MATERIAL)
    .map((a) => {
      return {
        value: a.assetId,
        label: (
          <div className="d-flex align-items-center">
            <div
              style={{
                height: '35px',
                width: '35px',
                lineHeight: '35px',
                marginRight: '10px',
              }}
            >
              <img src={getVariantImage(productAssets, a.assetId)} className="mw-100 mh-100" />
            </div>
            <div>{a.value}</div>
          </div>
        ),
      };
    });
  const colourAssetOptions = productAssets
    .filter((a) => a.key === ProductAssetKey.COLOUR)
    .map((a) => {
      return {
        value: a.assetId,
        label: (
          <div className="d-flex align-items-center">
            <div
              style={{
                height: '35px',
                width: '35px',
                lineHeight: '35px',
                marginRight: '10px',
              }}
            >
              <img src={getVariantImage(productAssets, a.assetId)} className="mw-100 mh-100" />
            </div>
            <div>{a.value}</div>
          </div>
        ),
      };
    });

  const handleClear = () => {
    setValue('design.id', '');
    setValue('material.id', '');
    setValue('colour.id', '');
  };

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (data) => {
    let designAsset = null;
    if (subVariantKeys.includes(ProductAssetKey.DESIGN))
      designAsset = productAssets.find(
        (a) => a.assetId === data.design.id.value
      );
    else
      designAsset = productAssets.find((a) => a.key === ProductAssetKey.DESIGN);

    if (!designAsset) {
      dispatch(
        warning('design not found!', {
          position: 'top-right',
        })
      );
      return;
    }
    data.design = {
      assetId: designAsset.assetId,
      value: designAsset.value,
      //image: designAsset.image,
      key: designAsset.key,
    };

    let materialAsset = null;
    if (subVariantKeys.includes(ProductAssetKey.MATERIAL))
      materialAsset = productAssets.find(
        (a) => a.assetId === data.material.id.value
      );
    else
      materialAsset = productAssets.find(
        (a) => a.key === ProductAssetKey.MATERIAL
      );
    if (!materialAsset) {
      dispatch(
        warning('material not found!', {
          position: 'top-right',
        })
      );
      return;
    }
    data.material = {
      assetId: materialAsset.assetId,
      value: materialAsset.value,
      //image: materialAsset.image,
      key: materialAsset.key,
    };

    let colourAsset = null;
    if (subVariantKeys.includes(ProductAssetKey.COLOUR))
      colourAsset = productAssets.find(
        (a) => a.assetId === data.colour.id.value
      );
    else
      colourAsset = productAssets.find((a) => a.key === ProductAssetKey.COLOUR);

    if (!colourAsset) {
      dispatch(
        warning('colour not found!', {
          position: 'top-right',
        })
      );
      return;
    }
    data.colour = {
      assetId: colourAsset.assetId,
      value: colourAsset.value,
      //image: colourAsset.image,
      key: colourAsset.key,
    };

    if (
      subVariantKeys.includes(ProductAssetKey.DESIGN) &&
      subVariantKeys.includes(ProductAssetKey.MATERIAL)
    )
      data.meta = { group: `${designAsset.value} ${materialAsset.value}` };
    else if (subVariantKeys.includes(ProductAssetKey.COLOUR))
      data.meta = { group: `${colourAsset.value}` };
    else data.meta = { group: '' };

    data.isNew = true;
    if (variantGroup) {
      data.isNew = false;
      data.gIndex = variantGroup.gIndex;
    }
    console.log('data', data);
    onAddVariantGroup(data);
  };

  return (
    <Modal centered open={open} toggle={() => {}} size="lg">
      <ModalHeader>Variant Group</ModalHeader>
      <ScrollableModalBody>
        <Row>
          <FormGroup
            col={{ md: '12' }}
            label="Type"
            name="statusType"
            type="select"
            options={statusTypeOptions}
            control={control}
            rules={{ required: 'Required field' }}
            errors={errors}
            defaultValue={variantGroup ? variantGroup.statusType : ''}
          />
        </Row>
        <Row>
          {subVariantKeys.includes(ProductAssetKey.DESIGN) && (
            <FormGroup
              col={{ md: '4' }}
              label="Headboard Design"
              name="design.id"
              type="searchable-select"
              options={designAssetOptions}
              control={control}
              rules={{ required: 'Required field' }}
              errors={errors}
              defaultValue={
                variantGroup
                  ? designAssetOptions.find(
                      (d) => d.value === variantGroup.design.assetId
                    )
                  : ''
              }
            />
          )}
          {subVariantKeys.includes(ProductAssetKey.MATERIAL) && (
            <FormGroup
              col={{ md: '4' }}
              label="Material"
              name="material.id"
              type="searchable-select"
              options={materialAssetOptions}
              control={control}
              rules={{ required: 'Required field' }}
              errors={errors}
              defaultValue={
                variantGroup
                  ? materialAssetOptions.find(
                      (d) => d.value === variantGroup.material.assetId
                    )
                  : ''
              }
            />
          )}
          {subVariantKeys.includes(ProductAssetKey.COLOUR) && (
            <FormGroup
              col={{ md: '4' }}
              label="Colour"
              name="colour.id"
              type="searchable-select"
              options={colourAssetOptions}
              control={control}
              rules={{ required: 'Required field' }}
              errors={errors}
              defaultValue={
                variantGroup
                  ? colourAssetOptions.find(
                      (d) => d.value === variantGroup.colour.assetId
                    )
                  : ''
              }
            />
          )}
        </Row>
      </ScrollableModalBody>
      <ModalFooter>
        <Button type="submit" theme="primary" onClick={handleSubmit(onSubmit)}>
          Update
        </Button>
        <Button type="button" theme="secondary" onClick={handleClear}>
          Clear
        </Button>
        <Button type="button" theme="outline-secondary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VariantGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAddVariantGroup: PropTypes.func,
  variantGroup: PropTypes.object,
  colourAssets: PropTypes.array,
  materialAssets: PropTypes.array,
  designAssets: PropTypes.array,
  productAssets: PropTypes.array,
  subVariantKeys: PropTypes.array,
};

VariantGroupModal.defaultProps = {
  onAddVariantGroup: () => {},
  onClose: () => {},
  variantGroup: null,
  colourAssets: [],
  materialAssets: [],
  designAssets: [],
  productAssets: [],
  subVariantKeys: [],
};

export default VariantGroupModal;
