import { error, warning } from 'react-toastify-redux';
import { pageStartLoading, pageStopLoading } from './ui';
import { getToken } from '../../utils/auth';

export const PaymentActionTypes = {
  CREATE_PAYMENT_INTENT: 'GET_PCREATE_PAYMENT_INTENTAYMENT_TOKEN',
};

const apiEndpoint = `${process.env.REACT_APP_WOOSA_API_URI}/api/payments`;

/**
 * Create payment intent from api
 * @param {string} token get by object id
 */
export const createPaymentIntent = (paymentToken, paymentType) => {
  return async (dispatch) => {
    try {
      dispatch(pageStartLoading());

      const response = await fetch(`${apiEndpoint}/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
        body: JSON.stringify({
          env: process.env.REACT_APP_ENV,
          paymentToken,
          paymentType,
          paymentSubType: 'checkout',
        }),
      });
      if (response.status !== 201 && response.status !== 200) throw response;
      const data = await response.json();
      window.location.href = data.clientSecret;
    } catch (err) {
      dispatch(error('Unexpected error!'));
    } finally {
      dispatch(pageStopLoading());
    }
  };
};

export const cnacelPayment = (orderId, paymentId, paymentType) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${apiEndpoint}/cancel-payment`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
        body: JSON.stringify({
          orderId,
          paymentId,
          paymentType,
        }),
      });
      if (response.status !== 200 && response.status !== 200) throw response;
      const data = await response.json();
      if (data.error)
        dispatch(error(data.error.message || 'Unexpected error!'));
      else dispatch(warning('Payment has been cancelled'));
    } catch (err) {
      console.log('err', err);
      dispatch(error('Unexpected error!'));
    }
  };
};
