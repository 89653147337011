import { DeliveryOrdersActionTypes } from '../actions/deliveryOrders';

const initialState = {
  deliveryOrders: {
    items: [],
    paging: {
      page: 1,
      size: 10,
      totalPages: 0,
      orderBy: 'date',
      order: 'desc',
    },
  },
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {DeliverySlotActionTypes} action.type
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DeliveryOrdersActionTypes.GET_DELIVERY_ORDERS:
      return { ...state, deliveryOrders: { ...action.deliveryOrders } };
    default:
      return state;
  }
}
